import React, { useEffect, useState } from 'react';
import {
  IntlShape,
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl';

import Checkbox from 'src/components/general-ui/Checkbox';
import { SendNotificationsBySettings } from '../../types';
import MultiSelectEmails from '../MultiSelectEmails';
import { deepEqual } from 'src/utils/comparators';

type Props = {
  settings: SendNotificationsBySettings;
  disabled: boolean;
  handleChangeSettings: (settings: SendNotificationsBySettings) => void;
  intl: IntlShape;
  isFormEnabled?: boolean;
};

const SendNotificationsBy = ({
  settings,
  disabled,
  handleChangeSettings,
  isFormEnabled = true,
  intl,
}: Props): JSX.Element => {
  const { byEmail, bySMS, extraEmails } = settings;

  const [initialEmails, setInitialEmails] = useState<string[]>([]);

  useEffect(() => {
    if (extraEmails.emails.length && !initialEmails.length) {
      setInitialEmails(extraEmails.emails);
    }
  }, [extraEmails.emails, initialEmails]);

  return (
    <>
      <FormattedMessage {...messages.sendMeNotificationBy} />
      <Checkbox
        disabled={!isFormEnabled}
        label={intl.formatMessage(messages.email)}
        checked={byEmail}
        handleChange={value =>
          handleChangeSettings({
            ...settings,
            byEmail: value,
          })
        }
      />
      <Checkbox
        disabled={!isFormEnabled}
        label={intl.formatMessage(messages.sms)}
        checked={bySMS}
        handleChange={value =>
          handleChangeSettings({
            ...settings,
            bySMS: value,
          })
        }
      />
      <Checkbox
        label={intl.formatMessage(messages.extraEmailList)}
        checked={extraEmails.isEnabled}
        handleChange={value =>
          handleChangeSettings({
            ...settings,
            extraEmails: {
              ...settings.extraEmails,
              isEnabled: value,
            },
          })
        }
        disabled={disabled || !isFormEnabled}
      />
      <MultiSelectEmails
        emails={initialEmails}
        selectedEmails={initialEmails}
        placeholder={intl.formatMessage(messages.selectEmails)}
        inputPlaceholder={intl.formatMessage(messages.addNewEmail)}
        handleChange={value =>
          handleChangeSettings({
            ...settings,
            extraEmails: {
              ...settings.extraEmails,
              emails: value,
            },
          })
        }
        disabled={disabled || !extraEmails.isEnabled || !isFormEnabled}
      />
    </>
  );
};

const messages = defineMessages({
  sendMeNotificationBy: {
    defaultMessage: 'Send me notification by:',
  },
  email: {
    defaultMessage: 'Email',
  },
  sms: {
    defaultMessage: 'SMS',
  },
  selectEmails: {
    defaultMessage: 'Select emails',
  },
  addNewEmail: {
    defaultMessage: 'Add new email',
  },
  extraEmailList: {
    defaultMessage: 'Send notification by email also to:',
  },
});

export default React.memo(
  injectIntl(SendNotificationsBy),
  (oldProps, newProps) => deepEqual(oldProps, newProps),
);
