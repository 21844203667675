import { connect, ConnectedProps } from 'react-redux';

import { selectors as alertSelectors } from 'src/redux/data/alerts';
import {
  actions as gmActions,
  selectors as gmSelectors,
  selectors as groupManagerSelectors,
} from 'src/redux/data/groupManager';
import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';
import { NoteCounterMetadata } from 'src/types/notes';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { isLatestData } from 'src/redux/data/groupManager/modules/utils';
import { API_STATUS } from 'src/utils/api-constants';

type AlertItem = {
  alertId: UUID;
  deviceId: UUID | null;
  patientId: UUID | null;
  tenantId: UUID;
  notesCount?: NoteCounterMetadata;
};

const getCurrentAlertsWithTenant = (state: RootState): AlertItem[] => {
  const currentAlertsDict = groupManagerSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.ALERTS,
  );
  return alertSelectors
    .selectLastDayActiveAlerts(state)
    .filter(alert => isLatestData(alert.id, currentAlertsDict))
    .map(alert => ({
      alertId: alert.id,
      deviceId: alert.deviceId,
      patientId: alert.patientId,
      tenantId: alert.tenantId || '',
      notesCount: alert.notesCount,
    }));
};

const mapStateToProps = (state: RootState) => ({
  alertItems: getCurrentAlertsWithTenant(state),
  alertItemsMetadata: alertSelectors.selectAlertsMetadata(state),
  areNewAlertsFromLastSync: alertSelectors.getAreNewAlertsFromLastSync(state),
  areCurrentAlertsLoading:
    gmSelectors.selectDataStatus(state, GM_DATA_KEYS.ALERTS) ===
    API_STATUS.LOADING,
});

const mapActionCreators = {
  suppressAlertType: gmActions.suppressSubtenantAlert,
  unSuppressAlertType: gmActions.unSuppressSubtenantAlert,
  clearAllPatientAlerts: gmActions.clearSubtenantPatientAlerts,
  fetchSubtenantData: gmActions.fetchSubtenantData,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
