import { styled } from 'styled-components';

import Icon from 'src/components/general-ui/Icon';

export const AlertIndicatorWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0.2rem;
  background: ${props => props.theme.colors.alertBackground};
  border: 1px solid ${props => props.color};
  border-radius: 6px;
  margin-right: 0.5rem;
  white-space: nowrap;
`;

export const StyledAlertIcon = styled(Icon)<{
  iwidth?: string;
  imarginright?: string;
}>`
  margin-right: ${props => props.imarginright || '0.3rem'};
  width: ${props => props.iwidth || '1rem'};
`;

export const StyledArrowIcon = styled(Icon)<{ height?: string }>`
  height: ${props => props.height || '0.45rem'};
`;

export const StyledTriggerValue = styled.div<{ ml?: string; color?: string }>`
  font-family: ${props => props.theme.fonts.secondary};
  font-style: normal;
  font-weight: 700;
  color: ${props => props.color};
  font-size: 0.7rem;
  margin-left: ${props => props.ml ?? '0'};
`;
