import { defineMessages } from 'react-intl';

export const StatusMessages = defineMessages({
  title: {
    defaultMessage: 'Status Icons',
  },
  patientIsOutOfBed: {
    defaultMessage: 'Patient is out of bed',
  },
  patientInBedMonitored: {
    defaultMessage: 'Patient in bed, monitored',
  },
  patientInBedWithBedExitSetting: {
    defaultMessage: 'Patient in bed, marked for Vacancy Notification',
  },
  patientInBedActiveAlert: {
    defaultMessage: 'Patient in bed, with active notification',
  },
  patientInBedWithSuppressed: {
    defaultMessage: 'Patient in bed, with supressed notification',
  },
  patientInBedVacancyNotification: {
    defaultMessage: 'Patient in bed, marked for Vacancy Notification',
  },
  activeBedExitAlert: {
    defaultMessage: 'Active BedExit',
  },
  suppressededExitAlert: {
    defaultMessage: 'Suppressed BedExit',
  },
  bedExitFrequency: {
    defaultMessage: 'BedExit Frequency',
  },
  bedExitDuration: {
    defaultMessage: 'BedExit Duration',
  },
  assignPatientButton: {
    defaultMessage:
      'Device is connected & available {br} but not assigned to a patient',
  },
  patientOutOfDeviceRange: {
    defaultMessage: 'Patient is out of device field of view',
  },
  patientInOutBedAlertValue: {
    defaultMessage:
      'Patient in/out of bed time active notification, {br} notification value is indicated',
  },
  deviceIsDisconnected: {
    defaultMessage: 'No Icon - device is Disconnected',
  },
  positionChange: {
    defaultMessage: 'Patient Position Notification',
  },
});

export const VitalSignsMessages = defineMessages({
  title: {
    defaultMessage: 'Vital Signs Box',
  },
  noConsentStatus: {
    defaultMessage: 'No Consent - not monitoring',
  },
  deviceConnected: {
    defaultMessage: 'Device is Connected',
  },
  activeAlert: {
    defaultMessage: 'Active Notification',
  },
  supressedAlert: {
    defaultMessage: 'Supressed Notification',
  },
  vitalSignsMeasurements: {
    defaultMessage: 'Vital Signs Measurements',
  },
  emptyBedNoData: {
    defaultMessage: 'Empty bed, No data',
  },
  noAvailableMeasurements: {
    defaultMessage: 'No available measurements',
  },
});

export const AlertIndicatorsMessages = defineMessages({
  title: {
    defaultMessage: 'Notification Indicators',
  },
  upperThresholdAlert: {
    defaultMessage: 'Upper Threshold Notification',
  },
  lowerThresholdAlert: {
    defaultMessage: 'Lower Threshold Notification',
  },
  highBaselineDeviationAlert: {
    defaultMessage: 'High Baseline Deviation Notification',
  },
  LowBaselineDeviationAlert: {
    defaultMessage: 'Low Baseline Deviation Notification',
  },
});
