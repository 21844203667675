import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  supportedLanguages,
  getLocaleFromUrlPath,
  getLanguageByLocale,
} from 'src/utils/languageUtils';

const MenuButton = styled(Button)`
  background-color: ${props => props.theme.colors.languageMenuButtonBackground};
  color: ${props => props.theme.colors.defaultTextColor};
  border: none;
  box-shadow: none;
  border-radius: 5px;
  padding: 5px;

  :hover,
  :active,
  :focus {
    background-color: ${props =>
      props.theme.colors.languageMenuButtonBackground};
    color: ${props => props.theme.colors.primaryButtonHover};
    border: none;
    box-shadow: none;
  }
`;

const LanguageMenu = ({ changeLanguage }) => {
  const [language, setLanguage] = useState(
    getLanguageByLocale(getLocaleFromUrlPath(window.location.pathname)),
  );

  const handleMenuClick = event => {
    const lang = event.key;
    setLanguage(lang);
    changeLanguage(lang);
  };

  const menu = () => (
    <Menu onClick={handleMenuClick}>
      {Object.values(supportedLanguages).map(lang => (
        <Menu.Item key={lang}>{lang}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown menu={menu}>
      <MenuButton>
        {language}
        <DownOutlined />
      </MenuButton>
    </Dropdown>
  );
};

LanguageMenu.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
};

export default LanguageMenu;
