import { DateUTC, SerialNumber, UUID } from './utility';

export enum SessionStatus {
  RUNNING = 'RUNNING',
  STOPPING = 'STOPPING',
  FINISHED = 'FINISHED',
  EMPTY = 'EMPTY',
  NON_CONSENTED_RUNNING = 'NON_CONSENTED_RUNNING',
}

export type Session = {
  id: UUID;
  deviceId: SerialNumber;
  patientId: UUID;
  status: SessionStatus;
  saveRawData: boolean;
  startTime: DateUTC;
  endTime: DateUTC | null;
};
