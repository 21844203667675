import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const parseDateWithFormat = (
  date: string | number,
  format?: string,
): Dayjs => (format ? dayjs(date, format) : dayjs(date));

export const createImage = (src: string): HTMLImageElement => {
  const img = new Image();
  img.src = src;

  return img;
};

const between = (x: number, min: number, max: number) => x >= min && x < max;
export const getMotionBackgroundColor = (value: number) => {
  const transparencyValue = 0.5;
  if (between(value, 0, 1)) return `rgba(179, 248, 221, ${transparencyValue})`;
  if (between(value, 1, 2)) return `rgba(148, 234, 201, ${transparencyValue})`;
  if (between(value, 2, 3)) return `rgba(111, 221, 178, ${transparencyValue})`;
  if (between(value, 3, 4)) return `rgba(82, 204, 157, ${transparencyValue})`;
  if (between(value, 4, 5)) return `rgba(97, 195, 157, ${transparencyValue})`;
  if (between(value, 5, 6)) return `rgba(86, 189, 149, ${transparencyValue})`;
  if (between(value, 6, 7)) return `rgba(65, 173, 131, ${transparencyValue})`;
  if (between(value, 7, 8)) return `rgba(71, 155, 123, ${transparencyValue})`;
  if (between(value, 8, 9)) return `rgba(58, 141, 109, ${transparencyValue})`;
  if (value >= 9 && value <= 10) {
    return `rgba(46, 102, 80, ${transparencyValue})`;
  }
  return `rgba(46, 102, 80, ${transparencyValue})`;
};

export const motionBarThickness = (selectionDaysDifferences: number) => {
  if (selectionDaysDifferences <= 1) {
    return 3;
  }
  if (selectionDaysDifferences > 1 && selectionDaysDifferences <= 2) {
    return 2;
  }
  if (selectionDaysDifferences > 2 && selectionDaysDifferences <= 4) {
    return 1;
  }
  return 0.7;
};
