import { styled } from 'styled-components';
import { Button } from 'antd';
import InputWithIcon from 'src/components/general-ui/InputWithIcon';

export const StyledFiltersContainer = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -13rem;
  background: #fff;
  top: 41px;
  border: 1px solid rgb(229, 229, 229);
  box-shadow: rgba(0, 0, 0, 0.094) 0px 2px 6px;
  border-radius: 3px;
  z-index: 12;
`;
export const StyledWrapper = styled.div`
  padding: 1rem;
  width: 25rem;
`;

export const StyledTitle = styled.div`
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: 0.6rem;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const StyledOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 0.65rem;
  gap: 0.5rem;
  justify-content: space-between;
  & input {
    &:disabled {
      color: ${props => props.theme.colors.actionButtonDisabledColor};
    }
  }
  & .ant-form-item {
    margin: 0;
    &.ant-form-item-has-error {
      input {
        border: 1px solid #ca0000;
      }
    }
  }
`;

export const StyledInput = styled(InputWithIcon)`
  max-width: 4rem;
`;

export const StyledFooter = styled.div`
  margin-top: 0.8rem;
  gap: 0.5rem;
  display: flex;
  justify-content: right;
`;
export const StyledCheckboxWrapper = styled.div`
  width: 220px;
  display: flex;
  gap: 0.5rem;
`;

export const StyledBtn = styled(Button)`
  font-weight: 500;
  font-size: 0.65rem;
  cursor: pointer;
  &:first-child {
    color: ${props => props.theme.colors.simpleModalHeaderTitle};
  }
`;
export const StyledFiltersWrapper = styled.div`
  position: relative;
`;
