import { useEffect } from 'react';
import { mapCurrentAlerts } from 'src/redux/data/alerts/modules/utils';
import {
  actions as alertActions,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { isLatestData } from 'src/redux/data/groupManager/modules/utils';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as groupManagerSelectors } from 'src/redux/data/groupManager';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { selectors as userSelectors } from 'src/redux/data/loggedInUser';
import { RootState } from 'src/redux/store';
import { GenericThresholdMetrics, OccupancyAlert } from 'src/types/alerts';
import { getAlertLogsUpdated } from 'src/utils/alertHelpers';

export const useGMCalculateLongOutOfBedTime = (): void => {
  const dispatch = useDispatch();
  const userTenantTimezone = useSelector((state: RootState) =>
    userSelectors.getUserTenantTimezone(state),
  );
  const currentAlertsDict = useSelector((state: RootState) =>
    groupManagerSelectors.selectDataTenantDict(state, GM_DATA_KEYS.ALERTS),
  );
  const gmAlerts = useSelector(
    (state: RootState) =>
      alertSelectors
        .selectAlerts(state)
        .filter(
          alert =>
            isLatestData(alert.id, currentAlertsDict) &&
            ['SUPPRESSED', 'ON'].includes(alert.status) &&
            [
              ALERT_METRIC_ENUM.LONG_OUT_OF_BED as keyof GenericThresholdMetrics,
              ALERT_METRIC_ENUM.POSITION_CHANGE as keyof GenericThresholdMetrics,
            ].includes(alert.thresholdMetric),
        ) as OccupancyAlert[],
  );
  useEffect(() => {
    const updateAlertValues = () => {
      const updatedAlerts = getAlertLogsUpdated(
        gmAlerts,
        userTenantTimezone,
      ) as OccupancyAlert[];

      dispatch(
        alertActions.fetchAlertsSuccess({
          alertsList: updatedAlerts,
          alertsMap: mapCurrentAlerts(updatedAlerts),
        }),
      );
    };

    updateAlertValues();
    const interval = setInterval(updateAlertValues, 5 * 60 * 1000);
    //Clearing the interval
    return () => clearInterval(interval);
  }, [JSON.stringify(gmAlerts), userTenantTimezone]);
};
