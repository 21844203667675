import * as M from 'fp-ts/lib/Monoid';
import * as R from 'fp-ts/lib/Record';
import * as Ord from 'fp-ts/lib/Ord';
import { Predicate, getMonoidAny } from 'fp-ts/lib/Predicate';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { PatientState } from 'src/types/measurements';
import { NurseStationDevice } from './types';

export enum DeviceStatusFilterOption {
  TOTAL_CONNECTED = 'TOTAL_CONNECTED',
  CONNECTED_AND_ASSIGNED = 'CONNECTED_AND_ASSIGNED',
  PATIENT_IN_BED = 'PATIENT_IN_BED',
  PATIENT_OUT_OF_BED = 'PATIENT_OUT_OF_BED',
  CONNECTED_AND_UNASSIGNED = 'CONNECTED_AND_UNASSIGNED',
  TOTAL_DISCONNECTED = 'TOTAL_DISCONNECTED',
  DISCONNECTED_AND_ASSIGNED = 'DISCONNECTED_AND_ASSIGNED',
  DISCONNECTED_AND_UNASSIGNED = 'DISCONNECTED_AND_UNASSIGNED',
}

export type DeviceStatusFilter = {
  filter: 'status';
  value: DeviceStatusFilterOption;
};
export type DeviceViewFilter = {
  filter: 'view';
  value: '7' | '14' | 'all';
};

export type DeviceFilter = DeviceStatusFilter | DeviceViewFilter;

export type StateFilters = {
  status: DeviceStatusFilterOption[];
  view: '7' | '14' | 'all';
};

export type DisplayStatusFilter = {
  label: MessageDescriptor;
  displayOrder: number;
  value: DeviceStatusFilterOption;
};

const {
  TOTAL_CONNECTED,
  CONNECTED_AND_ASSIGNED,
  PATIENT_IN_BED,
  PATIENT_OUT_OF_BED,
  CONNECTED_AND_UNASSIGNED,
  TOTAL_DISCONNECTED,
  DISCONNECTED_AND_ASSIGNED,
  DISCONNECTED_AND_UNASSIGNED,
} = DeviceStatusFilterOption;

const messages = defineMessages({
  totalConnected: {
    defaultMessage: 'Connected',
  },
  connectedAndaAssigned: {
    defaultMessage: 'Connected & Assigned',
  },
  patientInBed: {
    defaultMessage: 'Patient In Bed',
  },
  patientOutOfBed: {
    defaultMessage: 'Patient Out of Bed',
  },
  connectedAndaUnassigned: {
    defaultMessage: 'Connected & Unassigned',
  },
  totalDisconnected: {
    defaultMessage: 'Disconnected',
  },
  disconnectedAndAssigned: {
    defaultMessage: 'Disconnected & Assigned',
  },
  disconnectedAndUnassigned: {
    defaultMessage: 'Disconnected & Unassigned',
  },
});

export type FilterItem = {
  displayOrder: number;
  label: MessageDescriptor;
  filter: Predicate<NurseStationDevice>;
};

const isConnected: Predicate<NurseStationDevice> = device =>
  device.isDeviceConnected;

const isAssigned: Predicate<NurseStationDevice> = device =>
  !!(device.patientId && device.patientName);
const patientHasConsent: Predicate<NurseStationDevice> = device =>
  device.patientHasConsent;
export const isEmptyBed: Predicate<NurseStationDevice> = device =>
  device.continuousData?.currentPatientStateValue === null ||
  device.continuousData?.currentPatientStateValue === undefined ||
  device.continuousData?.currentPatientStateValue === PatientState.EMPTY;

const connectedAndAssigned: Predicate<NurseStationDevice> = device =>
  isConnected(device) && isAssigned(device) && patientHasConsent(device);

const connectedAndUnasigned: Predicate<NurseStationDevice> = device =>
  isConnected(device) && !isAssigned(device);

const disconnectedAndAssigned: Predicate<NurseStationDevice> = device =>
  !isConnected(device) && isAssigned(device) && patientHasConsent(device);

const disconnectedAndUnasigned: Predicate<NurseStationDevice> = device =>
  !isConnected(device) && !isAssigned(device);

export const STATUS_FILTERS: Record<DeviceStatusFilterOption, FilterItem> = {
  [TOTAL_CONNECTED]: {
    displayOrder: 1,
    label: messages.totalConnected,
    filter: device =>
      connectedAndAssigned(device) || connectedAndUnasigned(device),
  },
  [CONNECTED_AND_ASSIGNED]: {
    displayOrder: 3,
    label: messages.connectedAndaAssigned,
    filter: device => connectedAndAssigned(device),
  },
  [PATIENT_IN_BED]: {
    displayOrder: 1,
    label: messages.patientInBed,
    filter: device =>
      isConnected(device) &&
      isAssigned(device) &&
      !isEmptyBed(device) &&
      patientHasConsent(device),
  },
  [PATIENT_OUT_OF_BED]: {
    displayOrder: 2,
    label: messages.patientOutOfBed,
    filter: device =>
      isConnected(device) &&
      isAssigned(device) &&
      isEmptyBed(device) &&
      patientHasConsent(device),
  },
  [CONNECTED_AND_UNASSIGNED]: {
    displayOrder: 4,
    label: messages.connectedAndaUnassigned,
    filter: device => connectedAndUnasigned(device),
  },
  [TOTAL_DISCONNECTED]: {
    displayOrder: 5,
    label: messages.totalDisconnected,
    filter: device =>
      disconnectedAndAssigned(device) || disconnectedAndUnasigned(device),
  },
  [DISCONNECTED_AND_ASSIGNED]: {
    displayOrder: 5,
    label: messages.disconnectedAndAssigned,
    filter: device => disconnectedAndAssigned(device),
  },
  [DISCONNECTED_AND_UNASSIGNED]: {
    displayOrder: 5,
    label: messages.disconnectedAndUnassigned,
    filter: device => disconnectedAndUnasigned(device),
  },
};

const monoidPredicateAny: M.Monoid<Predicate<NurseStationDevice>> =
  getMonoidAny();

export const combineStatusFilters = (
  filters: DeviceStatusFilterOption[],
): Predicate<NurseStationDevice> => {
  const predicates = filters.map(filter => STATUS_FILTERS[filter].filter);

  return M.concatAll(monoidPredicateAny)(predicates);
};

export const DISPLAY_STATUS_FILTERS = R.collect(Ord.trivial)(
  (key: DeviceStatusFilterOption, item: FilterItem) => ({
    value: key,
    label: item.label,
    displayOrder: item.displayOrder,
  }),
)(STATUS_FILTERS);
