import { styled } from 'styled-components';
import { Title } from 'src/components/styled';
import { Modal } from 'antd';
import { media } from 'src/utils/mediaQueryUtils';

export const ModalTitle = styled(Title)`
  font-size: 1rem;
  margin-bottom: 0;
`;

export const StyledCloseIcon = styled.img`
  width: 9px;
  height: 9px;
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 28px;
`;

export const StyledModal = styled(Modal)`
  font-family: ${props => props.theme.fonts.primary};
  .ant-modal-content {
    border-radius: 8px;

    ${media.small`
      height: 280px;
      width: 570px;
    `}
    ${media.extraLarge`
      height: 450px;
      width: 720px;
    `}
    .ant-modal-header {
      border-radius: 8px;
      border-bottom: none;
      padding: 1rem;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      display: none;
    }
    .ant-modal-body {
      .ant-table-cell {
        ${media.small`
          min-width: 50px;
          font-size: 8px;
        `}
        ${media.extraLarge`
          min-width: 80px;
          font-size: 13px;
        `}
        .ant-table-column-sorters {
          display: block;
          .ant-table-column-sorter-inner {
            transform: scale(1, 0.7);
            position: absolute;
            top: 3px;
          }
        }
      }
      th:first-child {
        width: 100px !important;
      }
      th:last-child {
        max-width: 100px !important;
      }
      .ant-table-column-title,
      th {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: ${props => props.theme.colors.simpleModalHeaderTitle};
      }
      tr td {
        font-size: 13px;
        background: ${props => props.theme.colors.simpleModalBackground};
        padding-right: 15px;
        ${media.small`
          height: 15px;
        `}
        ${media.extraLarge`
          height: 50px;
        `}
      }
      th {
        border-bottom: ${props =>
          `1px solid ${props.theme.colors.simpleModalHeaderBorder}`};
      }
    }
  }
  .ant-modal-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-header {
    padding: 1.2rem;
    border-bottom: 1px solid #ddd;
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-body {
    padding: 1.2rem;
    font-size: 0.7rem;
  }
  .ant-modal-footer {
    padding: 0.5rem 1rem;
    button {
      padding: 0 0.7rem;
      font-size: 0.7rem;
      height: 2rem;
    }
  }
  label {
    font-size: 0.7rem;
  }
  input {
    font-size: 0.7rem;
  }
  .ant-form-item {
    margin-bottom: 1rem;
  }
  .ant-form-item-label {
    padding: 0 0 0.1rem;
  }
  .ant-form-item-control-input {
    min-height: 2rem;
    .ant-select
      .ant-select-selector
      .ant-select-selection
      .ant-select-selection-item {
      height: 1.5rem !important;
    }
  }
  .ant-select-selector {
    height: 1.5rem !important;
    padding: 0 0.4rem !important;
    font-size: 0.7rem;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    line-height: 1.5rem !important;
  }
  .ant-select-arrow svg {
    width: 0.7rem;
  }
  .ant-modal-close {
    width: 0.9rem;
    height: 0.9rem;

    &:hover {
      background: none;
    }

    span {
      svg {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
  }
`;
