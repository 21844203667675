import { styled } from 'styled-components';

export const Wrapper = styled.div`
  padding: 0.3rem 0.6rem;
`;

export const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  color: #000000;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.3rem;
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.3rem;
  padding: 0rem 1rem;
`;

export const StyledChartsLayout = styled.div`
  height: 100%;
  position: relative;
`;

export const MultiChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const MultiChartContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding-top: 0.2rem;
  padding-left: 0.5rem;

  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  color: #252525;
`;

export const MultiChartContainerContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
`;

export const MultiChartContainerTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #252525;
`;

export const StyledLogo = styled.img`
  width: 2rem;
`;

export const ChartBox = styled.div<{ size: string }>`
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  flex: 1;

  canvas {
    height: 100% !important;
  }

  max-width: ${props => props.size};
`;

export const ChartUnit = styled.div`
  font-weight: 600;
  font-size: 0.7rem;
  margin-bottom: -1.4rem;

  color: #252525;
`;

export const StyledTrends = styled.div`
  gap: 0.2rem;
  display: flex;
  flex-direction: row;
`;
