import { styled } from 'styled-components';

import Table from 'src/components/CrudCommonComponents/Table';
import { SpacedRow } from 'src/components/styled';
import ActionButton from 'src/components/general-ui/Buttons/ActionButton';

export const StyledTable = styled(Table)`
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 0.5rem;
  }

  .ant-table-cell {
    min-width: 4rem !important;
  }

  input {
    font-size: 0.5rem;
    padding: 0.2rem;
    width: calc(100% - 40px);
    margin-top: 0;
    position: absolute;
    top: 2;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 0.2rem;
    cursor: pointer;
    min-height: 1.2rem;
    border: 1px solid white;
    border-radius: 2px;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 0.2rem;
    border: ${props => `1px solid ${props.theme.colors.hoverColor}`};
    border-radius: 2px;
  }
`;

export const StyledSpacedRow = styled(SpacedRow)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 50%;
  position: relative;
`;

export const StyledActionButton = styled(ActionButton)`
  margin-top: 0.3rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
