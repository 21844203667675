import AwsIot, { Client, DeviceOptions } from 'aws-iot-device-sdk-browser';
import * as RR from 'fp-ts/lib/ReadonlyRecord';

import AppConfig from 'src/config/AppConfig';
import { graphDataTypesEnum } from 'src/routes/Monitor/modules/constants';
import { PatientAlerts } from 'src/services/types';
import { DeviceConnectionState, DeviceCredentials } from 'src/types/devices';
import { ContinuousMeasurement, SpotMeasurement } from 'src/types/measurements';
import { SerialNumber, UUID } from 'src/types/utility';
import { noOp } from 'src/utils/fpUtils';
import CredentialsManager from './CredentialsManager';
import { onlineMonitorDataTypes } from './constants';
import {
  extractHriData,
  extractSpotData,
  extractVs1Data,
  extractVs2Data,
  extractVs3Data,
  extractVs4Data,
  isQuality,
  toFixedNumber,
} from './messageProcessors';
import {
  DeviceStateMessage,
  HriMessage,
  SessionInfo,
  SessionUpdateMessage,
  SpotMessage,
  Vs1Message,
  Vs2Message,
  Vs3Message,
  Vs4Message,
  PatientUpdatesMessage,
} from './types';
import { parseVs3ContinuousMessage } from './utils';

const basicTopic = `${AppConfig.ENV_NAME}`;

const parseTopic = (
  topic: string,
): { deviceId: SerialNumber; tenantId: UUID; topicType: string } => {
  const topicParts = topic.split('/');
  const deviceId = topicParts[2] || '';
  const tenantId = topicParts[1] || '';
  const topicType = topicParts.slice(3, topicParts.length).join('/');

  return { deviceId, tenantId, topicType };
};

type Channels = {
  onDataReceived: (message: ContinuousMeasurement[]) => void;
  onAlertsReceived: (message: PatientAlerts) => void;
  onHriReceived: (message: unknown) => void;
  onStateReceived: (message: DeviceConnectionState) => void;
  onSessionStatusReceived: (message: SessionInfo) => void;
  onSpotReceived: (message: SpotMeasurement) => void;
  onPatientUpdates: (message: PatientUpdatesMessage) => void;
};

type Topics = {
  vs1: string[];
  vs2: string[];
  vs3: string[];
  vs4: string[];
  spot: string[];
  hri: string[];
  sessionStatus: string[];
  connectionStatus: string[];
  alerts: string[];
  patientUpdates: string[];
};

type VsData = {
  x: number;
  y: string;
  isQualityMeasurement: boolean;
};

const topicsTemplates: RR.ReadonlyRecord<keyof Topics, string> = Object.freeze({
  vs1: 'vs1',
  vs2: 'vs2',
  vs3: 'vs3',
  vs4: 'vs4',
  spot: 'spot',
  hri: 'hriResult',
  sessionStatus: 'sts/session',
  connectionStatus: 'connectionStatus',
  alerts: 'alert',
  patientUpdates: 'patientDetails',
});

class OnlineMonitor {
  _client: Client | null = null;
  _hrData: Record<string, VsData[]> = {};
  _rrData: Record<string, VsData[]> = {};
  _deviceIds: SerialNumber[] = [];
  _tenantId: string | null = null;
  _channels: Channels = {
    onDataReceived: noOp,
    onAlertsReceived: noOp,
    onHriReceived: noOp,
    onStateReceived: noOp,
    onSessionStatusReceived: noOp,
    onSpotReceived: noOp,
    onPatientUpdates: noOp,
  };
  _measurements: ContinuousMeasurement[] = [];
  _measurementsQueueMap: Record<string, ContinuousMeasurement[]> = {};
  _topics: Topics = {
    vs1: [],
    vs2: [],
    vs3: [],
    vs4: [],
    spot: [],
    hri: [],
    sessionStatus: [],
    connectionStatus: [],
    alerts: [],
    patientUpdates: [],
  };
  _measurementsInterval: ReturnType<typeof setInterval> | null = null;
  _credentialsInterval: ReturnType<typeof setInterval> | null = null;

  init = async (
    deviceIds: SerialNumber[],
    tenantId: UUID,
    {
      onDataReceived,
      onAlertsReceived,
      onHriReceived,
      onStateReceived,
      onSessionStatusReceived,
      onSpotReceived,
      onPatientUpdates,
    }: Channels,
  ) => {
    this._client = null;
    this._hrData = {};
    this._rrData = {};
    this._deviceIds = deviceIds;
    this._channels = {
      onDataReceived,
      onAlertsReceived,
      onHriReceived,
      onStateReceived,
      onSessionStatusReceived,
      onSpotReceived,
      onPatientUpdates,
    };
    this._tenantId = tenantId;
    this._measurements = [];
    this._topics = {
      vs1: [],
      vs2: [],
      vs3: [],
      vs4: [],
      spot: [],
      hri: [],
      sessionStatus: [],
      connectionStatus: [],
      alerts: [],
      patientUpdates: [],
    };

    this._initialDataForDevices(deviceIds);
    this._createTopicsForAllDevices();

    await CredentialsManager.init(
      deviceIds,
      // TODO: Check if can be reworked
      // eslint-disable-next-line @typescript-eslint/require-await
      async (credentials: DeviceCredentials) => {
        console.log('Updating IOT credentials in client.');
        this._client?.updateWebSocketCredentials(
          credentials.accessKeyId,
          credentials.secretAccessKey,
          credentials.sessionToken,
          // @ts-ignore Check if expiration is used
          credentials.expiration,
        );
        console.log('Successfully updated IOT credentials in client.');
      },
    );

    this._measurementsQueueMap = {};

    this._measurementsInterval = setInterval(() => {
      Object.values(this._measurementsQueueMap).forEach(measurementQueue => {
        const firstVal = measurementQueue.shift();

        firstVal && this._measurements.push(firstVal);
      });

      this._channels.onDataReceived(this._measurements);
      this._measurements = [];
    }, 1000);
  };

  _initialDataForDevices(deviceIds: SerialNumber[]) {
    deviceIds.forEach(deviceId => {
      this._hrData[deviceId] = [];
      this._rrData[deviceId] = [];
    });
  }

  _createTopicsForAllDevices() {
    if (!this._tenantId) {
      throw Error('Undefined tenant id in Online Monitor');
    }
    const topicWithTenantAndDevice = basicTopic.concat(`/${this._tenantId}/+`);

    const mappedTopics = RR.map<string, string[]>(topicTemplate => {
      const topic = topicWithTenantAndDevice.concat(`/${topicTemplate}`);

      switch (topicTemplate) {
        case topicsTemplates.hri:
          return AppConfig.HRI_ENABLED ? [topic] : [];
        default:
          return [topic];
      }
    })(topicsTemplates);

    this._topics = { ...mappedTopics };
  }

  getDataForDevice(dataType: 'hrData' | 'rrData', deviceId: SerialNumber) {
    let temp;

    if (!this._hrData || !this._rrData) {
      return [];
    }

    const dataByDataType = {
      [graphDataTypesEnum.HR_DATA]: () => {
        temp = [...(this._hrData[deviceId] || [])];
        this._hrData[deviceId] = [];
        return temp;
      },
      [graphDataTypesEnum.RR_DATA]: () => {
        temp = [...(this._rrData[deviceId] || [])];
        this._rrData[deviceId] = [];
        return temp;
      },
    };
    return dataByDataType[dataType]();
  }

  startLoadingData = () =>
    new Promise(res => {
      if (!this._credentialsInterval) {
        this._credentialsInterval = setInterval(
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          CredentialsManager.refreshCredentialsIfRequired,
          30000,
        );
      } else {
        console.warn(
          'Tried to set a credentialsInterval, but there was another one defined. This isnt supposed to happen, did not set another one',
        );
      }

      if (
        !CredentialsManager ||
        !CredentialsManager.credentials ||
        !CredentialsManager.endpoint
      ) {
        console.error('Invalid Credentials Manager');
        return;
      }

      const config: DeviceOptions = {
        host: CredentialsManager.endpoint,
        protocol: 'wss',
        clientId: `client-${Math.floor(Math.random() * 100000 + 1)}`,
        accessKeyId: CredentialsManager.credentials.accessKeyId,
        secretKey: CredentialsManager.credentials.secretAccessKey,
        sessionToken: CredentialsManager.credentials.sessionToken,
        // @ts-ignore Ignore this parameter for now
        expiration: CredentialsManager.credentials.expiration,
      };

      this._client = AwsIot.device(config);

      this._client.on('connect', () => {
        Object.values(this._topics).forEach(topicTypeArr => {
          topicTypeArr.forEach(topic => {
            this._client?.subscribe(topic);
          });
        });
      });

      const handleContinuousMessage = (
        messageJson: Vs1Message | undefined,
        deviceId: SerialNumber,
      ) => {
        if (!messageJson) {
          return;
        }

        const { patientId, sessionId, state, hr, rr, rabin } =
          extractVs1Data(messageJson);

        let data: Partial<ContinuousMeasurement['data']> = {};

        if (hr[0]) {
          const currentHrValue = Number(hr[0]).toFixed();
          const currentHrQuality = isQuality(hr[2]);

          this._hrData[deviceId] = [
            ...(this._hrData[deviceId] || []),
            {
              x: Date.now(),
              y: currentHrValue,
              isQualityMeasurement: currentHrQuality,
            },
          ];
          data = { ...data, currentHrValue, currentHrQuality };
        }

        if (rr[0]) {
          const currentRrValue = Number(rr[0]).toFixed();
          const currentRrQuality = isQuality(rr[2]);

          this._rrData[deviceId] = this._rrData[deviceId] || [];
          this._rrData[deviceId] = [
            ...(this._rrData[deviceId] || []),
            {
              x: Date.now(),
              y: currentRrValue,
              isQualityMeasurement: currentRrQuality,
            },
          ];
          data = { ...data, currentRrValue, currentRrQuality };
        }

        if (rabin[0]) {
          const currentRabinValue = Number(rabin[0]).toFixed();
          const currentRabinQuality = isQuality(rabin[2]);
          data = { ...data, currentRabinValue, currentRabinQuality };
        }

        if (state || state === 0) {
          data = { ...data, currentPatientStateValue: state };
        }

        this._measurements.push({
          dataType: onlineMonitorDataTypes.CONTINUOUS,
          data,
          patientId,
          sessionId,
          deviceId,
        });
      };

      const handleContinuousMessage2 = (
        messageJson: Vs2Message | undefined,
        deviceId: SerialNumber,
      ) => {
        if (!messageJson) {
          return;
        }

        const { patientId, sessionId, i, e } = extractVs2Data(messageJson);

        const iValue = i[0];
        const eValue = e[0];
        const currentIERatioValue =
          iValue && eValue && iValue > 0 && eValue > 0
            ? Number(iValue / eValue).toFixed(1)
            : '-1';

        this._measurements.push({
          dataType: onlineMonitorDataTypes.CONTINUOUS,
          data: { currentIERatioValue },
          patientId,
          sessionId,
          deviceId,
        });
      };

      const handleContinuousMessage3 = (
        messageJson: Vs3Message | undefined,
        deviceId: SerialNumber,
      ) => {
        if (!messageJson) {
          return;
        }

        const extractedVS3Data = extractVs3Data(messageJson);

        const parsedVS3Message = parseVs3ContinuousMessage(
          extractedVS3Data,
          deviceId,
          this._hrData,
          this._rrData,
        );

        this._measurements.push(parsedVS3Message);
      };

      const handleContinuousMessage4 = (
        messageJson: Vs4Message | undefined,
        deviceId: SerialNumber,
      ) => {
        if (!messageJson) {
          return;
        }

        this._measurementsQueueMap[deviceId] = [];

        const extractedVS3DataArray = extractVs4Data(messageJson);

        extractedVS3DataArray.forEach(VS4data => {
          const parsedVS4Message = parseVs3ContinuousMessage(
            VS4data,
            deviceId,
            this._hrData,
            this._rrData,
          );

          this._measurementsQueueMap[deviceId]?.push(parsedVS4Message);
        });
      };

      const handleAlertsMessage = (messageJson: PatientAlerts) => {
        this._channels.onAlertsReceived(messageJson);
      };

      const handlePatientUpdatesMessage = (
        messageJson: PatientUpdatesMessage,
      ) => {
        if (!this?._channels?.onPatientUpdates) {
          return;
        }
        this._channels.onPatientUpdates(messageJson);
      };

      const handleSpotMessage = (
        messageJson: SpotMessage | undefined,
        deviceId: SerialNumber,
      ) => {
        if (!messageJson) {
          return;
        }

        const { patientId, sessionId, hrt, rsp } = extractSpotData(messageJson);

        const hr = hrt.med;
        const rr = rsp.med;
        let data: Partial<{ hr: string; rr: string; lastReceived: number }> = {
          lastReceived: Date.now(),
        };

        if (hr) {
          data = { ...data, hr: Number(hr).toFixed() };
        }

        if (rr) {
          data = { ...data, rr: Number(rr).toFixed() };
        }

        this._channels.onSpotReceived({
          dataType: onlineMonitorDataTypes.SPOT,
          data,
          patientId,
          sessionId,
          deviceId,
        });
      };

      const handleHriMessage = (
        messageJson: HriMessage | undefined,
        deviceId: SerialNumber,
      ) => {
        if (!messageJson) {
          return;
        }

        const { patientId, sessionId, sdnn, rmssd, statusCode, error } =
          extractHriData(messageJson);
        const sdnnValue = sdnn ? toFixedNumber(sdnn) : undefined;
        const rmssdValue = rmssd ? toFixedNumber(rmssd) : undefined;

        const data = {
          lastReceived: Date.now(),
          sdnn: sdnnValue,
          rmssd: rmssdValue,
          statusCode,
          isHriArrived: !statusCode && !error,
          record: messageJson,
        };

        this._channels.onHriReceived({
          dataType: onlineMonitorDataTypes.HRI,
          data,
          patientId,
          sessionId,
          deviceId,
        });
      };

      const handleStateMessage = (
        messageJson: DeviceStateMessage | undefined,
      ) => {
        if (!messageJson) {
          return;
        }

        const { deviceId, currentTime, timestamp, connectionStatus } =
          messageJson.data;

        this._channels.onStateReceived({
          currentTime,
          timestamp,
          connectionStatus,
          deviceId,
        });
      };

      const handleSessionUpdateMessage = (
        messageJson: SessionUpdateMessage | undefined,
      ) => {
        if (!messageJson) {
          return;
        }

        const { sid, pid, data } = messageJson;
        const { status, deviceId, timestamp, tenantId } = data;

        this._channels.onSessionStatusReceived({
          status,
          deviceId,
          sessionId: sid,
          patientId: pid,
          startTime: timestamp,
          tenantId,
        });
      };

      this._client.on('message', (topic, rawMessage) => {
        const messageString = (rawMessage as object).toString();
        try {
          // TODO: Add better type handling
          const messageJson = JSON.parse(messageString) as { pid: string };
          const { topicType, deviceId } = parseTopic(topic);
          switch (topicType) {
            case topicsTemplates.vs1:
              handleContinuousMessage(messageJson as Vs1Message, deviceId);
              res({ patientId: messageJson.pid });
              break;
            case topicsTemplates.vs2:
              handleContinuousMessage2(messageJson as Vs2Message, deviceId);
              res({ patientId: messageJson.pid });
              break;
            case topicsTemplates.vs3:
              handleContinuousMessage3(messageJson as Vs3Message, deviceId);
              res({ patientId: messageJson.pid });
              break;
            case topicsTemplates.vs4:
              handleContinuousMessage4(messageJson as Vs4Message, deviceId);
              res({ patientId: messageJson.pid });
              break;
            case topicsTemplates.spot:
              handleSpotMessage(messageJson as SpotMessage, deviceId);
              res({ patientId: messageJson.pid });
              break;
            case topicsTemplates.hri:
              handleHriMessage(messageJson as HriMessage, deviceId);
              res({ patientId: messageJson.pid });
              break;
            case topicsTemplates.alerts:
              handleAlertsMessage(messageJson as unknown as PatientAlerts);
              res({ patientId: messageJson.pid });
              break;
            case topicsTemplates.connectionStatus:
              handleStateMessage(messageJson as unknown as DeviceStateMessage);
              break;
            case topicsTemplates.sessionStatus:
              handleSessionUpdateMessage(messageJson as SessionUpdateMessage);
              res({ patientId: messageJson.pid });
              break;
            case topicsTemplates.patientUpdates:
              handlePatientUpdatesMessage(
                messageJson as unknown as PatientUpdatesMessage,
              );
              break;
            default:
              console.log('Unknown topic:', topic);
          }
        } catch (error) {
          console.log(
            'Could not parse message: [',
            messageString,
            '] because of error: ',
            error,
          );
        }
      });
      this._client.on('error', error => {
        console.log(error);
      });
    });

  stopAllLoadingData = () => {
    this._client?.end && this._client.end();

    if (this._credentialsInterval) {
      clearInterval(this._credentialsInterval);
      this._credentialsInterval = null;
    }

    if (this._measurementsInterval) {
      clearInterval(this._measurementsInterval);
      this._measurementsInterval = null;
    }
  };

  clearDeviceCache(deviceId: SerialNumber) {
    this._hrData = {
      ...this._hrData,
      [deviceId]: [],
    };
    this._rrData = {
      ...this._rrData,
      [deviceId]: [],
    };
  }
}
export default new OnlineMonitor();
