import { styled } from 'styled-components';
import { Switch } from 'antd';

import theme from 'src/themes/theme';
import { ALERT_STATUSES } from './constants';
import { media } from 'src/utils/mediaQueryUtils';

const getColorByStatus = status => {
  switch (status) {
    case ALERT_STATUSES.ENABLED:
      return theme.colors.actionButtonColor;
    case ALERT_STATUSES.DISABLED:
      return 'rgba(0, 0, 0, 0.25)';
    case ALERT_STATUSES.SUPPRESSED:
      return theme.colors.alertStatus;
    default:
      return 'rgba(0, 0, 0, 0.25)';
  }
};

export const StyledWrapper = styled.div`
  ${props =>
    !props.isNormalFlow
      ? `
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 20px 35px 20px;
    `
      : ''}
  ${media.extraSmall`
    padding: 0px !important;
  `}
`;

export const StyledSwitch = styled(Switch)`
  margin-left: 20px;

  background-color: ${props =>
    props.status === ALERT_STATUSES.ENABLED
      ? props.theme.colors.actionButtonBackground
      : 'rgba(0, 0, 0, 0.25)'};
`;

export const StatusWrapper = styled.div`
  color: ${props => getColorByStatus(props.alertsStatus)};
  margin-bottom: 15px;
  font-weight: bold;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.colors.defaultTextColor};
`;

export const StyledSubheader = styled.div`
  font-size: 16px;
  color: ${props => props.theme.colors.defaultTextColor};
  margin-bottom: 5px;
`;
