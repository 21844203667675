import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Modal from 'src/components/Modal';
import { Button } from 'antd';
import { styled } from 'styled-components';

const Line = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LineHeader = styled.div`
  font-weight: bold;
`;

const LineContent = styled.div``;

const DeviceInfoModal = ({
  isModalVisible,
  setIsModalVisible,
  onClick,
  device,
  intl,
}) => {
  const [deviceInfo, setDeviceInfo] = useState({});

  useEffect(() => {
    if (device) {
      setDeviceInfo(device.inf);
    }
  }, [device]);
  return (
    <Modal
      title={intl.formatMessage(messages.infoDevice)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={intl.formatMessage(messages.close)}
      footer={[
        <Button key="okButton" onClick={onClick}>
          {intl.formatMessage(messages.close)}
        </Button>,
      ]}
    >
      {deviceInfo ? (
        <div>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.serialNumber} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.deviceId}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.hw} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.hwVersion}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.versionName} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.fwVersion}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.versionId} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.versionId}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.state} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.state}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.wirelesshw} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.wirelessHWVersion}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.wirelessverId} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.wirelessVersionId}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.ssid} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.ssid}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.ip} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.ipAddress}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.mac} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.macAddress}</LineContent>
          </Line>
          <Line>
            <LineHeader>
              {' '}
              <FormattedMessage {...messages.applicationId} />{' '}
            </LineHeader>
            <LineContent>{deviceInfo.applicationId}</LineContent>
          </Line>
        </div>
      ) : (
        <> </>
      )}
    </Modal>
  );
};

const messages = defineMessages({
  infoDevice: {
    defaultMessage: 'Device Information',
  },
  close: {
    defaultMessage: 'Close',
  },
  serialNumber: {
    defaultMessage: 'Serial Number',
  },
  hw: {
    defaultMessage: 'Hardware',
  },
  versionName: {
    defaultMessage: 'Version Name',
  },
  versionId: {
    defaultMessage: 'Version ID',
  },
  state: {
    defaultMessage: 'State',
  },
  wirelesshw: {
    defaultMessage: 'Wireless Hardware',
  },
  wirelessverId: {
    defaultMessage: 'Wireless Version',
  },
  ssid: {
    defaultMessage: 'WIFI SSID',
  },
  ip: {
    defaultMessage: 'IP',
  },
  mac: {
    defaultMessage: 'MAC',
  },
  applicationId: {
    defaultMessage: 'Application ID',
  },
});

DeviceInfoModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  device: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

DeviceInfoModal.defaultProps = {
  device: {},
};

export default injectIntl(DeviceInfoModal);
