import { connect, ConnectedProps } from 'react-redux';
import { selectors as tenantDataSelectors } from 'src/redux/data/tenant';
import { selectors as alertsSelectors } from 'src/redux/data/alerts';
import { RootState } from 'src/redux/store';
import { combineStatusFilters, StateFilters } from '../../modules/filterUtils';
import { selectors, extraSelectors } from '../../modules/slice';
import { SubTenantOverview } from '../../modules/types';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { selectors as userSelectors } from 'src/redux/data/loggedInUser';

const filterSubtenants = (
  subtenants: SubTenantOverview[],
  filters: StateFilters,
): SubTenantOverview[] => {
  if (!filters.status.length) {
    return subtenants;
  }

  const applyStatusFilters = combineStatusFilters(filters.status);

  return subtenants.filter(subtenant => applyStatusFilters(subtenant));
};

const searchSubtenants = (
  subtenants: SubTenantOverview[],
  searchQuery: string,
): SubTenantOverview[] => {
  if (!searchQuery) {
    return subtenants;
  }

  return subtenants.filter(subtenant => {
    const { name } = subtenant;

    return name.toLowerCase().includes(searchQuery.toLowerCase());
  });
};

const selectFilteredSubtenants = (state: RootState) => {
  const subtenants = extraSelectors.selectSubtenants(state);
  const filters = selectors.selectFilters(state);
  const searchQuery = selectors.selectSearchQuery(state);

  return [
    ...searchSubtenants(filterSubtenants(subtenants, filters), searchQuery),
  ].sort((a, b) => a.name.localeCompare(b.name));
};

const mapStateToProps = (state: RootState) => ({
  userTenantName: userSelectors.getUserTenantName(state),
  subtenants: selectFilteredSubtenants(state),
  tenantsListMetadata: tenantDataSelectors.getTenantsListMetadata(state),
  filters: selectors.selectFilters(state),
  searchQuery: selectors.selectSearchQuery(state),
  areSubtenantsLoading:
    tenantDataSelectors.getStatus(state) === DATA_FETCHING_STATUS.LOADING ||
    alertsSelectors.getAlertsStatus(state) === DATA_FETCHING_STATUS.LOADING,
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
