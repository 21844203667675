import {
  all,
  takeLatest,
  put,
  select,
  race,
  take,
  call,
} from 'typed-redux-saga/macro';
import { eventChannel } from 'redux-saga';

import { actions as gmActions } from 'src/redux/data/groupManager';
import { actions as tenantActions } from 'src/redux/data/tenant';
import { actions as alertActions } from 'src/redux/data/alerts';
import { actions as deviceActions } from 'src/redux/data/device';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { actions as authActions } from 'src/redux/data/auth';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { actions } from './slice';
import { UUID } from 'src/types/utility';
import {
  ATTENTION_LIST,
  CURRENT_ALERTS,
} from 'src/components/Sidebars/AlertSidebarGroupManager/modules/tabConfig';
import { selectors } from 'src/components/Sidebars/AlertSidebarGroupManager';

const DEFAULT_REFRESH_INTERVAL = 60 * 1000; // 60s
const DEFAULT_CONTINUOUS_DISC_SEC = 24 * 60 * 60; // 24h;

function setUpIntervalChannel(intervalMs: number) {
  return eventChannel(emitter => {
    const refreshIntervalId = setInterval(() => {
      emitter(true);
    }, intervalMs);

    return () => {
      clearInterval(refreshIntervalId);
    };
  });
}

function* refreshGMData(gmTenantId: UUID) {
  const refreshChannel = yield* call(
    setUpIntervalChannel,
    DEFAULT_REFRESH_INTERVAL,
  );

  while (true) {
    const raceResult = yield* race({
      channel: take(refreshChannel),
      sidebarUnmount: take(actions.alertSidebarUnmounted),
      logout: take(authActions.logoutFinish),
    });

    const sidebarCurrentTab = yield* select(selectors.selectActiveTab);
    if (!raceResult.channel) {
      refreshChannel.close();
      console.log('Closing channel');
      return;
    }

    yield* put(tenantActions.getSubtenantsList(gmTenantId));
    yield* put(tenantActions.getSubtenantsAlertCounters());

    if (sidebarCurrentTab === CURRENT_ALERTS) {
      yield* put(
        gmActions.fetchSubtenantData({
          dataKey: GM_DATA_KEYS.ALERTS,
          isRefreshFunctionFired: true,
        }),
      );
    }
    if (sidebarCurrentTab === ATTENTION_LIST) {
      yield* put(
        alertActions.fetchMTMAttentionList({ isRefreshFunctionFired: true }),
      );
    }

    yield* put(
      deviceActions.fetchMTMDeviceConnectionInfo(DEFAULT_CONTINUOUS_DISC_SEC),
    );
  }
}

function* onLoadAlertSidebar() {
  try {
    const gmTenantId = yield* select(loggedInUserSelectors.getUserTenantId);
    if (!gmTenantId) {
      console.error('No GM tenant found', gmTenantId);
      return;
    }
    const sidebarCurrentTab = yield* select(selectors.selectActiveTab);

    yield* all([
      put(tenantActions.getSubtenantsList(gmTenantId)),
      put(tenantActions.getSubtenantsAlertCounters()),
      put(
        gmActions.fetchSubtenantData({ dataKey: GM_DATA_KEYS.ALERT_SETTINGS }),
      ),
      put(
        gmActions.fetchSubtenantData({
          dataKey: GM_DATA_KEYS.ALERT_THRESHOLDS,
        }),
      ),
      put(
        gmActions.fetchSubtenantData({
          dataKey: GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS,
        }),
      ),
      sidebarCurrentTab === CURRENT_ALERTS &&
        put(
          gmActions.fetchSubtenantData({
            dataKey: GM_DATA_KEYS.ALERTS,
            pagination: {
              page: 1,
              limit: 20,
            },
            isRefreshFunctionFired: true,
          }),
        ),

      sidebarCurrentTab === ATTENTION_LIST &&
        put(
          alertActions.fetchMTMAttentionList({ isRefreshFunctionFired: true }),
        ),

      put(
        deviceActions.fetchMTMDeviceConnectionInfo(DEFAULT_CONTINUOUS_DISC_SEC),
      ),

      put(alertActions.getThresholdsList()),
      put(alertActions.getBaselineThresholdsList()),
      put(alertActions.fetchMTMActivityAlertSettings()),
      put(alertActions.fetchAlertSettings()),
      refreshGMData(gmTenantId),
    ]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in onLoadGMAlertSidebar: ', e);
  }
}

export default function* watchAlertSidebarActions() {
  yield* all([takeLatest(actions.alertSidebarMounted, onLoadAlertSidebar)]);
}
