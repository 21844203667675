import { defineMessages } from 'react-intl';
import React from 'react';
import { styled } from 'styled-components';

export const StyledStatusColumn = styled.div`
  color: ${props =>
    props.status === USER_STATUS.ACTIVE
      ? props.theme.colors.activeUserColor
      : props.theme.colors.pendingUserColor};
`;

export const USER_STATUS = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
};

export const USERS_TABLE_COLUMNS_KEYS = {
  EMAIL: 'EMAIL',
  FULL_NAME: 'FULL_NAME',
  TYPE: 'TYPE',
  STATUS: 'STATUS',
};

export const USERS_TABLE_ACTIONS_KEYS = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESEND_INVITE: 'RESEND_INVITE',
};
export const usersActionKeysArray = [
  USERS_TABLE_ACTIONS_KEYS.DELETE,
  USERS_TABLE_ACTIONS_KEYS.EDIT,
  USERS_TABLE_ACTIONS_KEYS.RESEND_INVITE,
  USERS_TABLE_ACTIONS_KEYS.RESET_PASSWORD,
];

export const userFormModes = {
  INVITE: 'INVITE',
  EDIT: 'EDIT',
};

export const userFormFields = Object.freeze({
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  USER_TYPE: 'userType',
  PHONE: 'phone',
  MFA_PHONE: 'mfaPhone',
  MFA: 'mfa',
});

export const userFormFieldsLabelsMessages = defineMessages({
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  email: {
    defaultMessage: 'Email',
  },
  phoneNumber: {
    defaultMessage: 'Phone Number',
  },
  mfaPhoneNumber: {
    defaultMessage: 'MFA Phone Number',
  },
  enableMfa: {
    defaultMessage: 'Enable MFA',
  },
  userType: {
    defaultMessage: 'User Type',
  },
});

export const userFormFieldsPlaceholdersMessages = defineMessages({
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  email: {
    defaultMessage: 'Email',
  },
  phoneNumber: {
    defaultMessage: 'Phone Number',
  },
  mfaPhoneNumber: {
    defaultMessage: 'MFA Phone Number',
  },
  userType: {
    defaultMessage: 'User Type',
  },
});

/* eslint-disable no-template-curly-in-string */
export const userFormValidateMessages = {
  required: '${label} is required!',
  pattern: {
    mismatch: '${label} is not a valid ${label}!',
  },
  whitespace: '${label} must not be empty',
}; /* eslint-enable no-template-curly-in-string */

const userPageColumnsMessages = defineMessages({
  email: {
    defaultMessage: 'Email',
  },
  fullName: {
    defaultMessage: 'Full Name',
  },
  type: {
    defaultMessage: 'Type',
  },
  status: {
    defaultMessage: 'Status',
  },
});

export const getUserPageColumns = intl => [
  {
    title: intl.formatMessage(userPageColumnsMessages.email),
    dataIndex: 'email',
    key: USERS_TABLE_COLUMNS_KEYS.EMAIL,
    filtered: true,
    onFilter: (value, record) =>
      record?.email?.toLowerCase().includes(value?.toLowerCase()) ||
      record?.fullName?.toLowerCase().includes(value?.toLowerCase()),
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: intl.formatMessage(userPageColumnsMessages.fullName),
    dataIndex: 'fullName',
    key: USERS_TABLE_COLUMNS_KEYS.FULL_NAME,
    filtered: true,
    sorter: (a, b) => a.fullName.localeCompare(b.fullName),
  },
  {
    title: intl.formatMessage(userPageColumnsMessages.type),
    dataIndex: 'type',
    key: USERS_TABLE_COLUMNS_KEYS.TYPE,
    sorter: (a, b) => a.userType.name.localeCompare(b.userType.name),
    render: (_, record) => record.userType?.name,
  },
  {
    title: intl.formatMessage(userPageColumnsMessages.status),
    dataIndex: 'status',
    key: USERS_TABLE_COLUMNS_KEYS.STATUS,
    sorter: (a, b) => a.status.localeCompare(b.status),
    render: (_, record) => (
      <StyledStatusColumn status={record.status}>
        {record.status}
      </StyledStatusColumn>
    ),
  },
];

export const systemAdmin = 'System admin';
export const MFA_OPTIONS_VALUES = {
  TRUE: 'true',
  FALSE: 'false',
};
export const MFA_OPTIONS_LABELS = {
  ENABLE: 'Enable',
  DISABLE: 'Disable',
};

export const MFA_OPTIONS = [
  {
    id: '0',
    label: MFA_OPTIONS_LABELS.ENABLE,
    value: MFA_OPTIONS_VALUES.TRUE,
  },
  {
    id: '1',
    label: MFA_OPTIONS_LABELS.DISABLE,
    value: MFA_OPTIONS_VALUES.FALSE,
  },
];
