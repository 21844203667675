import { useState } from 'react';
import { NoteCounterMetadata } from 'src/types/notes';

type SearchableField =
  | number
  | string
  | undefined
  | NoteCounterMetadata
  | null
  | boolean;

type Searchable<T> = {
  [K in keyof T]: T[K] extends SearchableField ? T[K] : never;
};

const searchBy =
  <T>(searchKeys: (keyof T)[]) =>
  (items: Searchable<T>[], query: string): T[] =>
    items.filter(item =>
      searchKeys.some(key => {
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(query.toLowerCase());
      }),
    );

export const useSearch = <T extends Searchable<T>>(
  items: T[],
  searchKeys: (keyof T)[],
  initialSearch = '',
): [T[], string, React.Dispatch<React.SetStateAction<string>>] => {
  const [query, setQuery] = useState<string>(initialSearch);
  const searchFn = searchBy(searchKeys);

  return [searchFn(items, query), query, setQuery];
};
