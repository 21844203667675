import { styled } from 'styled-components';
import { media } from 'src/utils/mediaQueryUtils';

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid
    ${props => props.theme.colors.individualDashboardBoxWrapperBorder};
  border-radius: 8px;
  padding: 0.55rem 1rem;
  margin-right: 0.8rem;
`;
export const BoxTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const BoxTitle = styled.div`
  font-weight: 400;
  color: ${props => props.theme.colors.individualDashboardBoxTitleColor};
  white-space: pre;
  ${media.small`
    font-size: 9px;
  `}
  ${media.extraLarge`
    font-size: 12px;
  `}
`;
export const BoxContent = styled.div`
  display: flex;
  align-items: baseline;
`;
export const BoxValue = styled.div`
  white-space: nowrap;
  font-weight: 600;
  ${media.small`
    font-size: 12px;
  `}
  ${media.extraLarge`
    font-size: 16px;
  `}
`;
export const BoxValueSuffix = styled.div`
  margin-left: 5px;
  font-weight: 400;
  color: ${props => props.theme.colors.individualDashboardBoxValueSuffixColor};
  ${media.small`
    font-size: 7px;
  `}
  ${media.extraLarge`
    font-size: 10px;
  `}
`;
export const StyledExtend = styled.div`
  cursor: pointer;
  margin-left: 30px;
`;
export const StyledQuestionMarkInfo = styled.div`
  margin-left: auto;
  cursor: pointer;
`;
