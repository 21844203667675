import { styled } from 'styled-components';

export const StyledAssignBtn = styled.div`
  width: 2.5rem;
  border: 1px solid #252525;
  border-radius: 0.65rem;
  font-weight: 600;
  font-size: 0.5rem;
  line-height: 0.6rem;
  padding: 0.3rem 0.3rem;
  white-space: pre-line;
  margin-left: 0.25rem;
  cursor: pointer;
  flex-shrink: 0;
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 0.75rem;
`;

export const StyledTextBtn = styled.div`
  font-weight: 500;
  font-size: 0.65rem;
  text-align: center;
  color: #767881;
  cursor: pointer;
`;

export const StyledPrimaryBtn = styled.div<{ isDisabled?: boolean }>`
  padding: 0.3rem 1rem;
  background: #385b75;
  border-radius: 0.9rem;
  font-weight: 500;
  font-size: 0.7rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;

  ${props => (props.isDisabled ? 'cursor: not-allowed' : '')}
`;
