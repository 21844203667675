import React, { useEffect, useState, RefObject } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import SubtenantSettings from './SubtenantSettings';
import Connector, { PropsFromRedux } from './Connector';
import { Header, StyledCounter, Wrapper } from './styled';
import { DASHBOARD_VIEW_MODE } from 'src/routes/GMDashboard/components/SubtenantCards/tabConfig';
import { LOCAL_STORAGE_ITEMS } from 'src/utils/constants';
import GridView from 'src/routes/GMDashboard/components/SubtenantCards/GridView';
import ListView from 'src/routes/GMDashboard/components/SubtenantCards/ListView';
import { deepEqual } from 'src/utils/comparators';

type Props = PropsFromRedux & { intl: IntlShape };

const SubtenantCards = ({
  subtenants,
  tenantsListMetadata,
  filters,
  searchQuery,
}: Props): JSX.Element => {
  const [currentViewMode, setCurrentViewMode] = useState(
    localStorage.getItem(LOCAL_STORAGE_ITEMS.mtm_dashboard_view_mode) ??
      DASHBOARD_VIEW_MODE.GRID,
  );
  const [tableRef, setTableRef] = useState<RefObject<HTMLDivElement> | null>(
    null,
  );

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.mtm_dashboard_view_mode,
      currentViewMode,
    );
  }, [currentViewMode]);

  const totalTenantsNumber =
    searchQuery || !!filters?.status?.length
      ? subtenants.length
      : tenantsListMetadata.page.totalResults;
  return (
    <Wrapper>
      <Header>
        <StyledCounter>
          {totalTenantsNumber}
          &nbsp;
          {totalTenantsNumber === 1 ? (
            <FormattedMessage {...messages.tenant} />
          ) : (
            <FormattedMessage {...messages.tenants} />
          )}
        </StyledCounter>
        <SubtenantSettings
          ref={tableRef}
          currentViewMode={currentViewMode}
          onCurrentViewModeChange={viewMode => setCurrentViewMode(viewMode)}
        />
      </Header>
      {currentViewMode === DASHBOARD_VIEW_MODE.GRID && <GridView />}
      {currentViewMode === DASHBOARD_VIEW_MODE.LIST && (
        <ListView onTableLoad={ref => setTableRef(ref)} />
      )}
    </Wrapper>
  );
};

const messages = defineMessages({
  tenant: {
    defaultMessage: 'Tenant',
  },
  tenants: {
    defaultMessage: 'Tenants',
  },
});

export default Connector(
  injectIntl(
    React.memo(SubtenantCards, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
