import React, { useState } from 'react';
import { UUID } from 'src/types/utility';

import { Label } from './styled';
import SubtenantAlertLogModal from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantCard/SubtenantAlerts/SubtenantAlertLogModal';

type Props = {
  subtenantId: UUID;
  subtenantName: string;
  value: number;
};

const NotificationsCell = ({
  subtenantId,
  subtenantName,
  value,
}: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <Label withUnderline withPointer onClick={() => setIsModalVisible(true)}>
      {value >= 0 ? value : ''}
      <SubtenantAlertLogModal
        subtenantId={subtenantId}
        subtenantName={subtenantName}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Label>
  );
};

export default NotificationsCell;
