import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Cron, CronType, SetValueFunctionExtra } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';

import {
  AllowedPeriodsWithoutWeekDays,
  AllowedPeriodsWithWeekDays,
  EmrDataParamaters,
} from './Constants';
import {
  StyledTitle,
  StyledWrapper,
  StyledParameterTitle,
  ParametersWrapper,
} from './styled';
import { EmrDataParamtersType } from 'src/routes/Customers/modules/types';

const EmrFrequencySettings = (): JSX.Element => {
  const [emrFrequencySetting, setFrequencySetting] =
    useState<EmrDataParamtersType>({});
  const [allowedPeriod, setAllowedPeriod] = useState<{
    [x: string]: CronType[];
  }>({});

  const handleChange =
    (emrParameter: string) => (value: string, extra: SetValueFunctionExtra) => {
      const { selectedPeriod } = extra;

      if (selectedPeriod === 'month') {
        setAllowedPeriod(prevState => ({
          ...prevState,
          [emrParameter]: AllowedPeriodsWithoutWeekDays,
        }));
      } else if (selectedPeriod === 'week') {
        setAllowedPeriod(prevState => ({
          ...prevState,
          [emrParameter]: AllowedPeriodsWithWeekDays,
        }));
      }
      setFrequencySetting(prevState => ({
        ...prevState,
        [emrParameter]: {
          ...prevState[emrParameter],
          value,
        },
      }));
    };

  return (
    <StyledWrapper>
      <StyledTitle>
        <FormattedMessage {...messages.emrFrequencyTitle} />
      </StyledTitle>
      {EmrDataParamaters.map(emrParamter => (
        <ParametersWrapper key={emrParamter.label}>
          <StyledParameterTitle>{emrParamter.label}</StyledParameterTitle>
          {console.log(emrFrequencySetting[emrParamter.value]?.value)}
          <Cron
            value={emrFrequencySetting[emrParamter.value]?.value || '* * * * *'}
            setValue={handleChange(emrParamter.value)}
            humanizeValue={true}
            clearButton={true}
            clockFormat={'12-hour-clock'}
            defaultPeriod={'day'}
            leadingZero={['month-days']}
            className="styled-cron-component"
            mode={'single'}
            allowedDropdowns={allowedPeriod[emrParamter.value]}
            allowedPeriods={['month', 'week', 'day']}
            clearButtonProps={{
              type: 'default',
            }}
          />
          {/* <p style={{ marginTop: 20 }}>Selected period: {selectedPeriod}</p>
          {displayErrorText && (
            <p style={{ marginTop: 20 }}>
              Error: {error ? error.description : 'undefined'}
            </p>
          )} */}
        </ParametersWrapper>
      ))}
    </StyledWrapper>
  );
};

const messages = defineMessages({
  emrFrequencyTitle: {
    defaultMessage: 'Select EMR Update frequency',
  },
  every: {
    defaultMessage: 'Every',
  },
  on: {
    defaultMessage: 'on',
  },
  at: {
    defaultMessage: 'at',
  },
});

export default EmrFrequencySettings;
