import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { SerialNumber, UUID } from 'src/types/utility';
import { actions as sessionsActions } from 'src/redux/data/sessions';
import {
  DeviceConnectionState,
  DeviceMonitorStatus,
  MonitoredDevice,
} from 'src/types/devices';
import { SessionStatus } from 'src/types/sessions';
import { StartSessionResponse } from 'src/services/types';
import { nowUTC } from 'src/utils/timeUtils';
import { RootState } from 'src/redux/store';
import {
  ContinuousMeasurement,
  ContinuousMeasurementValue,
  PatientState,
  SpotMeasurement,
  SpotMeasurementsState,
} from 'src/types/measurements';
import { onlineMonitorDataTypes } from './constants';
import { DATA_STATE_KEY } from '../../constants';
import OnlineMonitor from './OnlineMonitor';
import { DeviceErrorPayload, LivePatientDevice, SessionInfo } from './types';
import { truthy } from 'src/utils/fpUtils';

export const STATE_KEY = 'monitor';

const sessionUpdatesToMonitorStatusesMap = {
  [SessionStatus.RUNNING]: DeviceMonitorStatus.ACTIVE,
  [SessionStatus.STOPPING]: DeviceMonitorStatus.STOPPING,
  [SessionStatus.EMPTY]: DeviceMonitorStatus.INACTIVE,
  [SessionStatus.FINISHED]: DeviceMonitorStatus.INACTIVE,
  [SessionStatus.NON_CONSENTED_RUNNING]: DeviceMonitorStatus.ACTIVE,
};

const initialStateForDevice: MonitoredDevice = {
  status: DeviceMonitorStatus.INACTIVE,
  continuousData: {},
  spotData: {},
  hriData: {},
  patientId: null,
  time: null,
  sessionId: null,
};
export const INITIAL_STATE: {
  devices: Record<SerialNumber, MonitoredDevice>;
} = {
  devices: {},
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    onClickStartContinuous: (
      state,
      action: PayloadAction<{
        deviceId: SerialNumber;
        patientId: UUID;
        saveRawData: boolean;
      }>,
    ) => {
      const { deviceId } = action.payload;
      state.devices = {
        ...state.devices,
        [deviceId]: {
          ...initialStateForDevice,
          status: DeviceMonitorStatus.STARTING,
        },
      };
    },
    onSessionStarted: (state, action: PayloadAction<SessionInfo>) => {
      const { deviceId } = action.payload;
      const deviceState = state.devices[deviceId];
      if (deviceState) {
        deviceState.status = DeviceMonitorStatus.ACTIVE;
      } else {
        state.devices = {
          ...state.devices,
          [deviceId]: {
            ...initialStateForDevice,
            status: DeviceMonitorStatus.ACTIVE,
          },
        };
      }
    },
    onStartContinuousSuccess: (
      state,
      action: PayloadAction<StartSessionResponse>,
    ) => {
      const { deviceId, startTime, currentTime, patientId } = action.payload;
      const targetDevice = state.devices[deviceId];

      if (!targetDevice) {
        return;
      }

      targetDevice.patientId = patientId;
      targetDevice.time = {
        startTime,
        currentTime,
      };
    },
    onStartContinuousFail: (
      state,
      action: PayloadAction<{
        actionType: string;
        deviceId: SerialNumber;
        error: unknown;
      }>,
    ) => {
      const { deviceId } = action.payload;
      state.devices = {
        ...state.devices,
        [deviceId]: {
          ...initialStateForDevice,
          ...state.devices[deviceId],
          status: DeviceMonitorStatus.INACTIVE,
        },
      };
    },
    onClickStopContinuous: (
      state,
      action: PayloadAction<{ deviceId: SerialNumber }>,
    ) => {
      const { deviceId } = action.payload;
      const targetDevice = state.devices[deviceId];

      if (!targetDevice) {
        return;
      }

      targetDevice.status = DeviceMonitorStatus.STOPPING;
    },
    onStopContinuousFail: (
      state,
      action: PayloadAction<{
        actionType: string;
        deviceId: SerialNumber;
        error: unknown;
      }>,
    ) => {
      const { deviceId } = action.payload;
      state.devices = {
        ...state.devices,
        [deviceId]: {
          ...initialStateForDevice,
          ...state.devices[deviceId],
          status: DeviceMonitorStatus.ACTIVE,
        },
      };
    },
    onSessionStopping: (state, action: PayloadAction<SessionInfo>) => {
      const { deviceId } = action.payload;
      const deviceState = state.devices[deviceId];
      if (deviceState) {
        deviceState.status = DeviceMonitorStatus.STOPPING;
      } else {
        state.devices = {
          ...state.devices,
          [deviceId]: {
            ...initialStateForDevice,
            status: DeviceMonitorStatus.STOPPING,
          },
        };
      }
    },
    onSessionStopped: (state, action: PayloadAction<SessionInfo>) => {
      const { deviceId } = action.payload;
      const deviceState = state.devices[deviceId];

      if (deviceState) {
        deviceState.status = DeviceMonitorStatus.INACTIVE;
        deviceState.continuousData = {};
        deviceState.patientId = null;

        OnlineMonitor.clearDeviceCache(deviceId);
      }
    },
    onDeviceDisconnected: (
      state,
      action: PayloadAction<DeviceConnectionState>,
    ) => {
      const { deviceId } = action.payload;
      const deviceState = state.devices[deviceId];

      if (deviceState) {
        deviceState.continuousData = {};
      }
    },
    gotContinuousDataFromOnlineMonitor: (
      state,
      action: PayloadAction<ContinuousMeasurement[]>,
    ) => {
      const measurements = action.payload;
      measurements.forEach(measurement => {
        const { data, deviceId } = measurement;
        const deviceState = state.devices[deviceId];
        if (
          deviceState &&
          [DeviceMonitorStatus.ACTIVE, DeviceMonitorStatus.STOPPING].includes(
            deviceState.status,
          )
        ) {
          deviceState.continuousData = {
            ...deviceState.continuousData,
            ...data,
          };
        }
      });
    },
    gotDataFromOnlineMonitor: (
      state,
      action: PayloadAction<ContinuousMeasurement | SpotMeasurement>,
    ) => {
      const { data, dataType, deviceId, patientId } = action.payload;
      const deviceState = state.devices[deviceId];

      if (deviceState) {
        switch (dataType) {
          case onlineMonitorDataTypes.CONTINUOUS:
            deviceState.continuousData = {
              ...deviceState.continuousData,
              ...data,
            };
            break;
          case onlineMonitorDataTypes.SPOT:
            deviceState.spotData = {
              ...deviceState.spotData,
              [patientId]: { ...data },
            };
            break;
          // TODO: Check HRI data compatibility
          // case onlineMonitorDataTypes.HRI:
          //   deviceState.hriData = {
          //     ...deviceState.hriData,
          //     [patientId]: { ...data },
          //   };
          //   break;
          default:
            break;
        }
      }
    },
    // TODO: Check if can be removed
    monitorPageUnmounted: () => INITIAL_STATE,
    // TODO: Check if can be removed
    // setPatientId: (state, action) => {
    //   const { selectedPatientId, selectedDevice } = action.payload;
    //   state.devices = {
    //     ...state.devices,
    //     [selectedDevice]: {
    //       ...initialStateForDevice,
    //       ...state.devices[selectedDevice],
    //       patientId: selectedPatientId,
    //     },
    //   };
    // },
    // TODO: Remove this if not required
    // onMonitorRefocus: (state, action) => {
    //   const devicesIds = getDevicesByStatus(state);
    //   devicesIds.forEach(deviceId => {
    //     state.devices[deviceId] = { ...state.devices[deviceId], time: null };
    //   });
    // },
    setPatientsForDevices: (
      state,
      action: PayloadAction<LivePatientDevice[]>,
    ) => {
      const patientsAndDevices = action.payload;

      patientsAndDevices.forEach(patientDeviceTuple => {
        const targetDevice = state.devices[patientDeviceTuple.deviceId];

        if (!targetDevice) {
          return;
        }

        targetDevice.patientId = patientDeviceTuple.patientId;
      });
    },
    unfocus: (
      state,
      action: PayloadAction<{ activeDeviceIds: SerialNumber[] }>,
    ) => {
      const { activeDeviceIds } = action.payload;

      activeDeviceIds.forEach(deviceId => {
        const targetDevice = state.devices[deviceId];

        if (!targetDevice) {
          return;
        }

        targetDevice.time = null;
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(sessionsActions.fetchSessionsSuccess, (state, action) => {
      const sessions = action.payload.sessions;

      sessions.forEach(session => {
        const { deviceId, status } = session;
        const deviceStatus = sessionUpdatesToMonitorStatusesMap[status];

        state.devices[deviceId] = {
          ...initialStateForDevice,
          ...state.devices[deviceId],
          status: deviceStatus,
        };

        if (deviceStatus === DeviceMonitorStatus.ACTIVE) {
          const startTime = session.startTime;
          const currentTime = nowUTC().format();

          state.devices[deviceId] = {
            ...initialStateForDevice,
            ...state.devices[deviceId],
            time: { startTime, currentTime },
            patientId: session.patientId,
            sessionId: session.id,
          };
        }
      });
    });
  },
});

const extraActions = {
  // startSpot: createAction(`${STATE_KEY}/startSpot`),
  // onDownloadHri: createAction(`${STATE_KEY}/downloadHriCsvFile`),
  onHriResult: createAction<{
    deviceId: SerialNumber;
    data: { statusCode: string };
  }>(`${STATE_KEY}/onHriResult`),
  onHRIFailed: createAction<DeviceErrorPayload>(`${STATE_KEY}/onHRIFailed`),
  onInitMonitorFailed: createAction<DeviceErrorPayload>(
    `${STATE_KEY}/onInitMonitorFailed`,
  ),
  onReceivedDataFromInactiveSession: createAction<{ actionType: string }>(
    `${STATE_KEY}/onReceivedDataFromInactiveSession`,
  ),
};

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

const getDeviceId = (_state: RootState, deviceId: SerialNumber) => deviceId;

const getDevicesByStatus = (
  state: ReturnType<typeof getState>,
  status: DeviceMonitorStatus,
) =>
  Object.keys(state.devices).filter(
    deviceKey => state.devices[deviceKey]?.status === status,
  );

export const selectors = {
  getDevices: createSelector(getState, state => state.devices),
  getDeviceIds: createSelector(getState, state => Object.keys(state.devices)),
  getActiveDeviceIds: createSelector(getState, state =>
    getDevicesByStatus(state, DeviceMonitorStatus.ACTIVE),
  ),
  getActiveDevices: createSelector(getState, state =>
    getDevicesByStatus(state, DeviceMonitorStatus.ACTIVE)
      .map(
        deviceId =>
          state.devices[deviceId] && {
            ...state.devices[deviceId],
            id: deviceId,
          },
      )
      .filter(truthy),
  ),
  getAllStoppingDeviceIds: createSelector(getState, state =>
    getDevicesByStatus(state, DeviceMonitorStatus.STOPPING),
  ),
  getDeviceStatus: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): DeviceMonitorStatus | undefined =>
      state.devices[deviceId]?.status,
  ),
  getContiuousDataForAllDevices: createSelector(getState, state =>
    Object.keys(state.devices).map(deviceId => ({
      deviceId,
      continuousData: state.devices[deviceId]?.continuousData || {},
    })),
  ),
  getPatientsAndDevices: createSelector(getState, state =>
    Object.keys(state.devices).map(deviceId => ({
      deviceId,
      patientId: state.devices[deviceId]?.patientId,
    })),
  ),
  getCurrentRrValue: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): ContinuousMeasurementValue | undefined =>
      state.devices[deviceId]?.continuousData?.currentRrValue,
  ),
  getCurrentHrValue: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): ContinuousMeasurementValue | undefined =>
      state.devices[deviceId]?.continuousData?.currentHrValue,
  ),
  getCurrentIERatioValue: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): ContinuousMeasurementValue | undefined =>
      state.devices[deviceId]?.continuousData?.currentIERatioValue,
  ),
  getCurrentRabinValue: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): ContinuousMeasurementValue | undefined =>
      state.devices[deviceId]?.continuousData?.currentRabinValue,
  ),
  getCurrentPatientStateValue: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): PatientState | null | undefined =>
      state.devices[deviceId]?.continuousData?.currentPatientStateValue,
  ),
  getPatientId: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): UUID | null | undefined =>
      state.devices[deviceId]?.patientId,
  ),
  getSpotData: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): Record<UUID, SpotMeasurementsState> | undefined =>
      state.devices[deviceId]?.spotData,
  ),
  getHriData: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): Record<string, never> | undefined =>
      state.devices[deviceId]?.hriData,
  ),
  getStatus: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): DeviceMonitorStatus | undefined =>
      state.devices[deviceId]?.status,
  ),
  getIsActive: createSelector(
    getState,
    getDeviceId,
    (state, deviceId) =>
      state.devices[deviceId]?.status === DeviceMonitorStatus.ACTIVE,
  ),
  getIsStartContinuousLoading: createSelector(
    getState,
    getDeviceId,
    (state, deviceId) =>
      state.devices[deviceId]?.status === DeviceMonitorStatus.STARTING,
  ),
  getIsStopContinuousLoading: createSelector(
    getState,
    getDeviceId,
    (state, deviceId) =>
      state.devices[deviceId]?.status === DeviceMonitorStatus.STOPPING,
  ),
  getIsTimerOn: createSelector(
    getState,
    getDeviceId,
    (state, deviceId) =>
      state.devices[deviceId]?.status !== DeviceMonitorStatus.INACTIVE,
  ),
  getStartTime: createSelector(
    getState,
    getDeviceId,
    (state, deviceId) => state.devices[deviceId]?.time?.startTime,
  ),
  getCurrentTime: createSelector(
    getState,
    getDeviceId,
    (state, deviceId) => state.devices[deviceId]?.time?.currentTime,
  ),
  getSessionId: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): UUID | null | undefined =>
      state.devices[deviceId]?.sessionId,
  ),
  getCurrentRrQuality: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): boolean | undefined =>
      state.devices[deviceId]?.continuousData?.currentRrQuality,
  ),
  getCurrentHrQuality: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): boolean | undefined =>
      state.devices[deviceId]?.continuousData?.currentHrQuality,
  ),
  getCurrentRabinQuality: createSelector(
    getState,
    getDeviceId,
    (state, deviceId): boolean | undefined =>
      state.devices[deviceId]?.continuousData?.currentRabinQuality,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
