import { useEffect } from 'react';
import { mapCurrentAlerts } from 'src/redux/data/alerts/modules/utils';
import {
  actions as alertActions,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as userSelectors } from 'src/redux/data/loggedInUser';
import { RootState } from 'src/redux/store';
import {
  Alert,
  GenericThresholdMetrics,
  OccupancyAlert,
} from 'src/types/alerts';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { getAlertLogsUpdated } from 'src/utils/alertHelpers';

export const useCalculateLongOutOfBedTime = (): void => {
  const dispatch = useDispatch();
  const userTenantTimezone = useSelector((state: RootState) =>
    userSelectors.getUserTenantTimezone(state),
  );
  const alerts = useSelector(
    (state: RootState) =>
      A.filterMap<Alert, Alert>(alert =>
        ['SUPPRESSED', 'ON'].includes(alert.status) &&
        [
          ALERT_METRIC_ENUM.LONG_OUT_OF_BED as keyof GenericThresholdMetrics,
          ALERT_METRIC_ENUM.POSITION_CHANGE as keyof GenericThresholdMetrics,
        ].includes(alert.thresholdMetric)
          ? O.some({ ...alert })
          : O.none,
      )(alertSelectors.selectAlerts(state)) as OccupancyAlert[],
  );
  useEffect(() => {
    const updateAlertValues = () => {
      const updatedAlerts = getAlertLogsUpdated(
        alerts,
        userTenantTimezone,
      ) as OccupancyAlert[];

      dispatch(
        alertActions.fetchAlertsSuccess({
          alertsList: updatedAlerts,
          alertsMap: mapCurrentAlerts(updatedAlerts),
        }),
      );
    };

    updateAlertValues();
    const interval = setInterval(updateAlertValues, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [JSON.stringify(alerts), userTenantTimezone]);
};
