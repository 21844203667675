import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors as alertsSelectors } from 'src/redux/data/alerts';
import { NurseStationDevice } from 'src/routes/NursesStation/modules/types';
import { selectDeviceAlerts } from './utils';
import { selectors as patientSelectors } from 'src/redux/data/patient';

type OwnProps = NurseStationDevice;

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  ongoingDeviceAlerts: selectDeviceAlerts(
    alertsSelectors.selectOngoingAlertsByPatientId(
      state,
      ownProps.patientId ?? '',
    ),
  ),
  isVacancyAlertActive: alertsSelectors.selectIsBedExitAlertEnabled(
    state,
    ownProps.patientId ?? '',
  ),
  isPositionChangeAlertActive:
    alertsSelectors.selectIsPositionChangeAlertEnabled(
      state,
      ownProps.patientId ?? '',
    ),
  isLongOutOfBedAlertActive: alertsSelectors.selectLongOutOfBedAlertActive(
    state,
    ownProps.patientId ?? '',
  ),
  shouldDisplayNoConsent:
    !!ownProps.patientId &&
    !patientSelectors.selectPatient(state, ownProps.patientId ?? '')
      ?.isConsented,
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = OwnProps & PropsFromRedux;

export default Connector;
