import React from 'react';
import { Form, Input } from 'antd';
import { defineMessages, FormattedMessage, IntlShape } from 'react-intl';
import { apiStatusEnum } from 'src/utils/constants';
import { AuthButton, FormItem } from '../components/styled';

import { PropsFromRedux } from './Connector';
import { StyledMfaFormContainer } from 'src/routes/Auth/Login/styled';

type Props = Pick<PropsFromRedux, 'mfaLogin' | 'mfaPhone' | 'loginStatus'> & {
  intl: IntlShape;
};

const MfaForm = ({
  intl,
  loginStatus,
  mfaLogin,
  mfaPhone,
}: Props): JSX.Element => {
  const onMfaFinish = (values: { code: string }) => {
    mfaLogin(values);
  };

  const isLoading = !!loginStatus && loginStatus === apiStatusEnum.LOADING;
  return (
    <Form layout="vertical" onFinish={onMfaFinish}>
      <StyledMfaFormContainer>
        <FormItem
          data-cy="login-code-field"
          label={intl.formatMessage(messages.code, { mfaPhone: mfaPhone })}
          name="code"
          hasFeedback
          rules={[
            {
              required: true,
              message: `${intl.formatMessage(messages.requiredCode)}`,
            },
          ]}
        >
          <Input />
        </FormItem>
        <Form.Item data-cy="login-submit-btn">
          <AuthButton isLoading={isLoading}>
            <FormattedMessage {...messages.submitCode} />
          </AuthButton>
        </Form.Item>
      </StyledMfaFormContainer>
    </Form>
  );
};

const messages = defineMessages({
  code: {
    defaultMessage: 'Please insert the code sent to {mfaPhone}',
  },
  requiredCode: {
    defaultMessage: 'Code is required',
  },
  submitCode: {
    defaultMessage: 'Send code',
  },
});

export default MfaForm;
