import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import Search from 'src/components/general-ui/Search';
import { handleClickSubtenantPatientName } from 'src/routes/GMDashboard/modules/utils';
import Connector, { PropsFromRedux } from './Connector';
import { SortWrapper } from 'src/components/general-ui/Sort';
import AttentionListCard from '../../components/AttentionListCard';
import { buildSorters, messages } from './utils';
import { AttentionListWrap, PaginationWrapper, StyledText } from '../../styled';
import { deepEqual } from 'src/utils/comparators';
import { ReloadOutlined } from '@ant-design/icons';
import ButtonWithIcon from 'src/components/general-ui/Buttons/ButtonWithIcon';
import { useGetServerCalls } from './useGetServerCalls';
import { Spin } from 'antd';
import { SpinnerContainer } from 'src/components/Sidebars/AlertSidebarGroupManager/CurrentAlerts/styled';
import {
  FieldsContainer,
  ListWrapperContainer,
  SortItemWrapper,
} from './styled';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const AttentionList = ({
  alertItems,
  metadata,
  fetchMTMAttentionList,
  areNewAttentionListAlertsFromLastSync,
  isLoading,
  intl,
}: Props): JSX.Element => {
  const sorters = buildSorters(intl);
  const {
    currentPage,
    searchQuery,
    attentionListMetadata,
    onSearchChange,
    onPaginationChange,
    onRefreshHandler,
  } = useGetServerCalls(fetchMTMAttentionList, metadata);

  return (
    <>
      {areNewAttentionListAlertsFromLastSync && (
        <ButtonWithIcon
          type="primary"
          icon={<ReloadOutlined />}
          onClick={onRefreshHandler}
        >
          <FormattedMessage {...messages.newAlertItems} />
        </ButtonWithIcon>
      )}
      <AttentionListWrap data-cy="alerts-sidebar-attention-list">
        <FieldsContainer>
          <Search value={searchQuery} onChange={onSearchChange} />
          {!!alertItems.length && (
            <SortWrapper>
              {sorters.map(({ key, label }) => (
                <SortItemWrapper key={key}>
                  <span>{label}</span>
                </SortItemWrapper>
              ))}
            </SortWrapper>
          )}
        </FieldsContainer>

        <ListWrapperContainer id="attentionListScrollableDiv">
          {isLoading && (
            <SpinnerContainer centered>
              <Spin spinning size="large" />
            </SpinnerContainer>
          )}
          {!isLoading &&
            alertItems?.map(alertItem => (
              <AttentionListCard
                key={alertItem.id}
                {...alertItem}
                onPatientNameClick={() =>
                  alertItem.patientIsConsented &&
                  alertItem.patientId &&
                  handleClickSubtenantPatientName(
                    alertItem.tenantId,
                    alertItem.patientId,
                  )
                }
              />
            ))}
          {!alertItems.length && !isLoading && (
            <StyledText>
              <FormattedMessage {...messages.noAlertsRecieved} />
            </StyledText>
          )}
        </ListWrapperContainer>
      </AttentionListWrap>
      {!!attentionListMetadata?.page?.limit && (
        <PaginationWrapper
          size={'small'}
          hideOnSinglePage={true}
          defaultCurrent={1}
          defaultPageSize={attentionListMetadata?.page?.limit}
          current={currentPage}
          showSizeChanger={false}
          showLessItems={true}
          total={attentionListMetadata?.page?.totalResults}
          onChange={onPaginationChange}
        />
      )}
    </>
  );
};

export default Connector(
  injectIntl(
    React.memo(AttentionList, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
