import {
  all,
  takeLatest,
  call,
  put,
  take,
  select,
  race,
} from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { API_STATUS } from 'src/utils/api-constants';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { toApiCallFormat } from 'src/utils/timeUtils';
import { fetchPatientsList } from 'src/redux/data/patient/modules/saga';
import BackendService from 'src/services/BackendService';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { actions as monitorActions } from 'src/redux/data/monitor';
import { loadRoomsList } from 'src/redux/data/rooms/modules/saga';
import { fetchPatientNotes } from 'src/redux/data/notes/modules/saga';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import { UUID } from 'src/types/utility';
import {
  processHistogramData,
  partitionHistogramData,
} from './histogram-processor';
import { actions } from './slice';
import {
  extractQpPatientId,
  getBedOccupancyInterval,
  getMetricHistogramIntervals,
} from './utils';
import { processBedOccupancyData } from './data-processors';
import { AlertLog } from 'src/types/alerts';
import { getAlertLogsUpdated } from 'src/utils/alertHelpers';
import {
  FetchAggregatedContinuousMeasurementsResponse,
  FetchMotionIndexMeasurementsResponse,
} from 'src/services/types';
import { AxiosResponse } from 'axios';

// eslint-disable-next-line require-yield, @typescript-eslint/no-explicit-any
function* displayErrorNotification(action: PayloadAction<any>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = action.payload;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  notifyUserByActionTypeAndCode(data.actionType, data.deviceId, data.error);
}

function* takeMonitorErrorsActions() {
  yield* all([
    takeLatest(monitorActions.onStartContinuousFail, displayErrorNotification),
    takeLatest(monitorActions.onStopContinuousFail, displayErrorNotification),
    takeLatest(monitorActions.onHRIFailed, displayErrorNotification),
    takeLatest(monitorActions.onInitMonitorFailed, displayErrorNotification),
  ]);
}

function* fetchPatientDeviceInfo(patientId: UUID | null) {
  if (!patientId) {
    return;
  }

  try {
    const { data } = yield* call(
      BackendService.getPatientsLatestSessions,
      patientId,
    );

    const [patientSession] = data.sessions;

    if (!patientSession || patientSession.endTime) {
      yield* put(
        actions.setDeviceInfo({
          deviceId: '',
          deviceConnectionStatus: false,
        }),
      );
      return;
    }

    const deviceStatusReponse = yield* call(
      BackendService.getDeviceById,
      patientSession.deviceId,
    );

    yield* put(
      actions.setDeviceInfo({
        deviceId: patientSession.deviceId,
        deviceConnectionStatus:
          deviceStatusReponse?.data?.data[0]?.connectionStatus?.connected ||
          false,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      'error in fetching current patient session and device information: ',
      e,
    );
  }
}

function* onLoadIndividualDashboard() {
  try {
    const initialPatientId = extractQpPatientId();
    yield* put(actions.setIndividualDashboardStatus(API_STATUS.LOADING));
    yield* put(actions.setSelectedPatientId(extractQpPatientId()));
    yield* put(actions.setIndividualDashboardStatus(API_STATUS.OK));

    yield* all([
      call(fetchPatientDeviceInfo, initialPatientId),
      call(fetchPatientsList),
      call(loadRoomsList),
      // @ts-ignore find a way to import other sagas with return typed actions
      call(fetchPatientNotes, { payload: initialPatientId }),
    ]);

    yield* race({
      monitorErrorsActions: call(takeMonitorErrorsActions),
      monitorPageUnmounted: take(actions.individualDashboardUnmounted),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in onLoadIndividualDashboard: ', e);
    yield* put(actions.loadIndividualDashboardFailed());
  }
}

export function* getBedOccupancyData(
  action: ReturnType<typeof actions.getBedOccupancyData>,
) {
  const { patientId } = action.payload;
  try {
    const { startTime, endTime } = getBedOccupancyInterval();
    const { data } = yield* call(BackendService.getBedOccupancyData, {
      patientId,
      startTime,
      endTime,
    });
    yield* put(
      actions.loadBedOccupancyDataSuccess(
        processBedOccupancyData(data.bedOccupancies),
      ),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in getBedOccupancyData: ', e);
    yield* put(actions.loadBedOccupancyDataFailed());
  }
}

function* getHistogramData(
  action: ReturnType<typeof actions.getHistogramData>,
) {
  const patientId = action.payload;
  const { startTimeOneDay, startTimeOneWeek, endTime } =
    getMetricHistogramIntervals();

  yield* put(actions.getHistogramDataRequest(API_STATUS.LOADING));

  try {
    const [lastDayDataResponse, lastWeekDataResponse] = yield* all([
      call(BackendService.getHistogramData, {
        patientId,
        startTime: startTimeOneDay,
        endTime,
      }),
      call(BackendService.getHistogramData, {
        patientId,
        startTime: startTimeOneWeek,
        endTime,
      }),
    ]);

    yield* put(
      actions.getHistogramDataSuccess(
        partitionHistogramData({
          // @ts-ignore TODO: Think about workaround
          lastDay: processHistogramData(lastDayDataResponse.data),
          // @ts-ignore TODO: Think about workaround
          lastWeek: processHistogramData(lastWeekDataResponse.data),
        }),
      ),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error occurred when getting histogram data', e);
  }
}

export function* getAlertsLogData(
  action: ReturnType<typeof actions.getAlertsLogData>,
) {
  const { patientId, startDate, endDate, currentPage, pageSize } =
    action.payload;
  try {
    yield* put(actions.setAlertsLogStatus(API_STATUS.LOADING));
    const { data } = yield* call(BackendService.getAlertsLogData, {
      patientId,
      startDate: toApiCallFormat(startDate),
      endDate: toApiCallFormat(endDate),
      page: currentPage,
      limit: pageSize,
    });
    const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);
    const updatedAlertLogs = getAlertLogsUpdated(
      data.alertLogs,
      timezone,
    ) as AlertLog[];

    yield* put(
      actions.setAlertsLogData({
        alerts: updatedAlertLogs,
        pageMetadata: data.metadata.page,
      }),
    );
    yield* put(actions.setAlertsLogStatus(API_STATUS.OK));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in getAlertsLogData: ', e);
    yield* put(actions.setAlertsLogStatus(API_STATUS.ERROR));
    yield* put(actions.setIndividualDashboardStatus(API_STATUS.ERROR));
  }
}

export function* getAlertsLogDataForModal(
  action: ReturnType<typeof actions.getAlertsLogData>,
) {
  const { patientId, startDate, endDate, currentPage, pageSize } =
    action.payload;
  try {
    yield* put(actions.setAlertsLogForModalStatus(API_STATUS.LOADING));
    const { data } = yield* call(BackendService.getAlertsLogData, {
      patientId,
      startDate: toApiCallFormat(startDate),
      endDate: toApiCallFormat(endDate),
      page: currentPage,
      limit: pageSize,
    });
    const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);
    const updatedAlertLogs = getAlertLogsUpdated(
      data.alertLogs,
      timezone,
    ) as AlertLog[];

    yield* put(
      actions.setAlertsLogDataForModal({
        alerts: updatedAlertLogs,
        pageMetadata: data.metadata.page,
      }),
    );
    yield* put(actions.setAlertsLogForModalStatus(API_STATUS.OK));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in getAlertsLogData: ', e);
    yield* put(actions.setAlertsLogForModalStatus(API_STATUS.ERROR));
    yield* put(actions.setIndividualDashboardStatus(API_STATUS.ERROR));
  }
}

function* fetchPatientMetricDataPointsByDateTime(
  action: ReturnType<typeof actions.getPatientMetricData>,
) {
  const { patientId, startTime, endTime } = action.payload;
  try {
    const [hrResponse, rrResponse, motionResponse] = yield* all([
      call(BackendService.getGraphDataByDateTime, {
        patientId,
        metric: ALERT_METRIC_ENUM.HR,
        startTime: toApiCallFormat(startTime),
        endTime: toApiCallFormat(endTime),
      }),
      call(BackendService.getGraphDataByDateTime, {
        patientId,
        metric: ALERT_METRIC_ENUM.RR,
        startTime: toApiCallFormat(startTime),
        endTime: toApiCallFormat(endTime),
      }),
      call(BackendService.getGraphDataMotionIndexByDateTime, {
        patientId,
        startTime: toApiCallFormat(startTime),
        endTime: toApiCallFormat(endTime),
      }),
    ]);

    const timezone = (yield* select(
      loggedInUserSelectors.getUserTenantTimezone,
    )) as unknown as string;
    const motionData = motionResponse
      ? (
          motionResponse as AxiosResponse<
            FetchMotionIndexMeasurementsResponse,
            any
          >
        ).data
      : undefined;
    yield* put(
      actions.getPatientMetricDataSuccess({
        startTime,
        endTime,
        hrData: (
          hrResponse as AxiosResponse<
            FetchAggregatedContinuousMeasurementsResponse,
            any
          >
        ).data.continuousMeasurements,
        rrData: (
          rrResponse as AxiosResponse<
            FetchAggregatedContinuousMeasurementsResponse,
            any
          >
        ).data.continuousMeasurements,
        motionData: motionData?.motion_aggregation,
        positionData: motionData?.posture_count,
        timezone,
      }),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `An error occurred while trying to fetch metric graph points for patient id: ${patientId} from date: ${startTime.format()} to date: ${endTime.format()} .\n Error:`,
      error,
    );
    yield* put(actions.getPatientMetricDataFailure());
  }
}

export default function* watchIndividualDashboardActions() {
  yield* all([
    takeLatest(actions.onLoadIndividualDashboard, onLoadIndividualDashboard),
    takeLatest(actions.getHistogramData, getHistogramData),
    takeLatest(actions.getBedOccupancyData, getBedOccupancyData),
    takeLatest(
      actions.getPatientMetricData,
      fetchPatientMetricDataPointsByDateTime,
    ),
    takeLatest(actions.getAlertsLogData, getAlertsLogData),
    takeLatest(actions.getAlertsLogDataForModal, getAlertsLogDataForModal),
  ]);
}
