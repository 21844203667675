import { IconType } from 'src/components/general-ui/Icon';
import {
  isAverageAlert,
  isBaselineAlert,
} from 'src/redux/data/alerts/modules/typeGuards';
import { ALERT_METRIC_PREPOSITION_ENUM } from 'src/redux/data/constants';
import { ThresholdPreposition, AlertType } from 'src/types/alerts';

export const alertIcons = {
  HR: 'hr-alert-icon',
  RR: 'rr-alert-icon',
  HR_BASELINE: 'hr-alert-icon',
  RR_BASELINE: 'rr-alert-icon',
  BED_EXIT: 'bed-icon',
  LONG_OUT_OF_BED: 'bed-icon',
  POSITION_CHANGE: 'position-change',
  BED_EXIT_FREQUENCY: 'bed-exit-frequency-icon',
  BED_TIME_BASELINE: 'patient-in-bed-icon',
  DEVICE_DISCONNECTED: 'wifi-disconnected-icon-v2',
  ALL_DEVICES_DISCONNECTED: 'wifi-disconnected-icon-v2',
} satisfies Record<AlertType, IconType>;

export const getArrowIcons = (
  thresholdMetric: AlertType,
  thresholdPreposition?: ThresholdPreposition | null,
) => {
  if (isAverageAlert(thresholdMetric)) {
    switch (thresholdPreposition) {
      case ALERT_METRIC_PREPOSITION_ENUM.ABOVE:
        return 'up-right-arrow-icon';
      case ALERT_METRIC_PREPOSITION_ENUM.BELOW:
        return 'down-right-arrow-icon';
    }
  }

  if (isBaselineAlert(thresholdMetric)) {
    switch (thresholdPreposition) {
      case ALERT_METRIC_PREPOSITION_ENUM.ABOVE:
        return 'up-trend-arrow-icon';
      case ALERT_METRIC_PREPOSITION_ENUM.BELOW:
        return 'down-trend-arrow-icon';
    }
  }

  return 'right-arrow-icon';
};
