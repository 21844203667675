import { styled } from 'styled-components';

export const MainGraphWrapper = styled.div`
  display: flex;
  height: 79%;
  flex-direction: column;
  border: ${props =>
    `1px solid ${props.theme.colors.individualDashboardHeaderBorder}`};
  padding: 0.75rem 1.2rem 1.2rem;
  border-radius: 8px;
  color: ${props => props.theme.colors.individualDashboardBlackText};
  background-color: ${props =>
    props.theme.colors.measurementCardBackgroundColor};
  margin-top: 2%;
`;

export const MainGraphTitle = styled.div`
  font-weight: 600;
  height: 2rem;
  font-size: 0.8rem;
`;

export const MainGraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BoxesWrapper = styled.div`
  display: flex;
`;
