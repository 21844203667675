import React, { useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { useSearch } from 'src/components/general-ui/Search';
import { UUID } from 'src/types/utility';
import { MinimalPatient } from '../PatientAssigner/types';
import {
  ItemsWrapper,
  StyledItem,
  SearchWrapper,
  StyledIcon,
  StyledInput,
  StyledOccupiedText,
  EllipsesPatientName,
} from './styled';

type Props = {
  patients: MinimalPatient[];
  onSelect: (item: MinimalPatient) => void;
  intl: IntlShape;
};

const searchKeys: (keyof MinimalPatient)[] = ['fullName'];

const PatientSearchSelector = ({
  patients,
  onSelect,
  intl,
}: Props): JSX.Element => {
  const [selectedPatientId, setSelectedPatient] = useState<UUID>('');
  const [filteredPatients, query, setQuery] = useSearch(patients, searchKeys);

  const handleSelect = (patient: MinimalPatient) => {
    setSelectedPatient(patient.id);
    onSelect(patient);
  };

  return (
    <>
      <SearchWrapper>
        <StyledInput
          type="search"
          value={query}
          onChange={e => setQuery(e.target.value)}
          placeholder={intl.formatMessage(messages.placeholder)}
        />
        <StyledIcon type="search-icon" color="#767881" />
      </SearchWrapper>
      <ItemsWrapper>
        {filteredPatients.map(patient => (
          <StyledItem
            key={patient.id}
            onClick={() => handleSelect(patient)}
            isSelected={patient.id === selectedPatientId}
          >
            <EllipsesPatientName
              isAssigned={!!patient.deviceId}
              ellipsis={{
                rows: 1,
                tooltip: patient.fullName,
              }}
            >
              <span>{patient.fullName}</span>
            </EllipsesPatientName>
            {!!patient.deviceId && patient.isConsented && (
              <StyledOccupiedText>
                <FormattedMessage {...messages.occupied} />
              </StyledOccupiedText>
            )}
            {!patient.isConsented && (
              <StyledOccupiedText>
                <FormattedMessage {...messages.noConsent} />
              </StyledOccupiedText>
            )}
          </StyledItem>
        ))}
      </ItemsWrapper>
    </>
  );
};

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Search ',
  },
  occupied: {
    defaultMessage: 'Occupied',
  },
  noConsent: {
    defaultMessage: 'No Consent',
  },
});

export default injectIntl(PatientSearchSelector);
