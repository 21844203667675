import { styled } from 'styled-components';

export const CardWrapper = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  padding: 0.6rem 0.5rem 0.5rem 0.5rem;
  background: #fefeff;
  box-shadow: 0px 3px 2px rgba(78, 83, 96, 0.15);
  border-radius: 0.5rem;
  color: #252525;
  height: 11.5rem;
  width: 11.85rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTenantName = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
  cursor: pointer;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTenantInfo = styled.div`
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 400;
  font-size: 0.55rem;
  color: #767881;
`;

export const StyledTimezoneOffset = styled.span`
  font-weight: 700;
  padding-left: 0.15rem;
`;

export const Column = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  ${props => (props.width ? `width: ${props.width}` : '')};
`;

export const VerticalRule = styled.div<{ isAlert?: boolean }>`
  height: 90%;
  width: 0px;
  border-left: 1.5px solid
    ${props => (props.isAlert ? props.theme.colors.alertBorder : '#e5e5e5')};
`;

export const Label = styled.div<{
  withUnderline?: boolean;
  withPointer?: boolean;
}>`
  text-align: center;
  font-weight: 400;
  font-size: 0.55rem;
  color: #767881;
  margin-bottom: 0.3rem;
  ${props => (props.withUnderline ? 'text-decoration-line: underline;' : '')}
  ${props => (props.withPointer ? 'cursor: pointer;' : '')}
`;

export const Value = styled.div<{ isAlert?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 700;
  font-size: 0.8rem;
  white-space: nowrap;
  ${props => (props.isAlert ? 'color: #CA0000' : '')}
`;
