import React from 'react';
import { styled } from 'styled-components';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';

import Icon from 'src/components/general-ui/Icon';
import { ToolTipMessages } from 'src/translations/messages/toolTipMessages';

const AlertCounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem;
  background: #fdede9;
  border: 1px solid #cc3311;
  border-radius: 6px;
  white-space: nowrap;
`;

export const StyledBellIcon = styled(Icon)`
  margin-right: 0.2rem;
  width: 1rem;
`;

export const StyledValue = styled.div`
  font-family: ${props => props.theme.fonts.secondary};
  font-style: normal;
  font-weight: 700;
  font-size: 0.7rem;
  margin-right: 0.3rem;
`;

type Props = {
  counter: number;
};

const AlertCounter = ({ counter }: Props): JSX.Element => {
  return (
    <Tooltip
      title={
        <FormattedMessage {...ToolTipMessages.attentionListAlertCounter} />
      }
    >
      <AlertCounterWrapper>
        <StyledBellIcon type="bell-icon-outlined" color="#cc3311" />
        <StyledValue>{counter}</StyledValue>
      </AlertCounterWrapper>
    </Tooltip>
  );
};
export default AlertCounter;
