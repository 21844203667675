import { styled } from 'styled-components';
import TransparentButton from './TransparentButton';

const PrimaryButton = styled(TransparentButton)`
  background: ${props => props.theme.colors.primaryButtonBackground};
  color: ${props => props.theme.colors.coloredButtonTextColor};

  :hover,
  :active {
    background-color: ${props => props.theme.colors.primaryButtonHover};
    opacity: 0.9;
  }

  :focus {
    opacity: 1;
  }

  :disabled,
  :hover {
    background: ${props => props.theme.colors.primaryButtonBackground};
  }
`;

export default PrimaryButton;
