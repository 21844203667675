import { css } from 'styled-components';

export const BREAKPOINT = {
  SM: '476px',
  MD: '768px',
  LG: '992px',
  XL: '1200px',
  XXL: '1400px',
  '3XL': '1746px',
};

export const BREAKPOINT_SIZES = {
  SM: 476,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
};

export const heightBreakpoint = {
  smallHeight: '800px',
};

export const media = {
  extraSmall: (...args) => css`
    @media screen and (max-width: ${BREAKPOINT.SM}) {
      ${css(...args)}
    }
  `,
  small: (...args) => css`
    @media screen and (min-width: ${BREAKPOINT.SM}) {
      ${css(...args)}
    }
  `,
  medium: (...args) => css`
    @media screen and (min-width: ${BREAKPOINT.MD}) {
      ${css(...args)}
    }
  `,
  large: (...args) => css`
    @media screen and (min-width: ${BREAKPOINT.LG}) {
      ${css(...args)}
    }
  `,
  extraLarge: (...args) => css`
    @media screen and (min-width: ${BREAKPOINT.XL}) {
      ${css(...args)}
    }
  `,
  extraExtraLarge: (...args) => css`
    @media screen and (min-width: ${BREAKPOINT.XXL}) {
      ${css(...args)}
    }
  `,
  extraExtraExtraLarge: (...args) => css`
    @media screen and (min-width: ${BREAKPOINT['3XL']}) {
      ${css(...args)}
    }
  `,
  smallHeight: (...args) => css`
    @media screen and (max-height: ${heightBreakpoint.smallHeight}) {
      ${css(...args)}
    }
  `,
};
