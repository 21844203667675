import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';
import { Tabs } from 'antd';
import { media } from 'src/utils/mediaQueryUtils';

import { StyledTabs } from './styled';
import BedOccupancyHistogram from '../BedOccupancyHistogram';

const BedOcuppancyBoxWrapper = styled.div`
  border: ${props =>
    `1px solid ${props.theme.colors.individualDashboardHeaderBorder}`};
  border-radius: 8px;
  padding-top: 4px;
  height: 38%;
  background-color: ${props =>
    props.theme.colors.measurementCardBackgroundColor};
  .ant-tabs-nav-list {
    ${media.small`
      padding: 0px;
      .ant-tabs-ink-bar {
        left: 0.7rem;
      }
    `}
  }
  .ant-tabs-content-holder {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
    }
  }
`;

const BedOccupancy = ({
  intl,
  patient,
  getBedOccupancyData,
  hoursInBedData,
  numberOfBedExitAlertsData,
  clearBedOccupancyGraph,
  isLoading,
}) => {
  const patientId = patient?.id;
  useEffect(() => {
    if (patientId) {
      getBedOccupancyData({ patientId });
    }
    return () => {
      clearBedOccupancyGraph();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.id]);
  return (
    <BedOcuppancyBoxWrapper
      data-cy={`bed-occupancy-graph-loading=${isLoading.toString()}`}
    >
      <StyledTabs
        items={[
          {
            label: intl.formatMessage(messages.bedOccupancy),
            key: 'BedOccupancy',
            children: (
              <BedOccupancyHistogram
                hoursInBedData={patientId ? hoursInBedData : []}
                numberOfBedExitAlertsData={
                  patientId ? numberOfBedExitAlertsData : []
                }
                isLoading={isLoading}
              />
            ),
          },
        ]}
      />
    </BedOcuppancyBoxWrapper>
  );
};

BedOccupancy.propTypes = {
  intl: PropTypes.object.isRequired,
  getBedOccupancyData: PropTypes.func.isRequired,
  patient: PropTypes.object,
  hoursInBedData: PropTypes.array.isRequired,
  numberOfBedExitAlertsData: PropTypes.array.isRequired,
  clearBedOccupancyGraph: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

BedOccupancy.defaultProps = {
  patient: {},
};

const messages = defineMessages({
  bedOccupancy: {
    defaultMessage: 'Bed occupancy',
  },
});

export default injectIntl(BedOccupancy);
