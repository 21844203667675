import React, { useState, useEffect } from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { styled } from 'styled-components';
import { Form } from 'antd';

import Modal from 'src/components/Modal';
import { patientFormModes } from './constants';
import PatientForm from './PatientForm';
import { PropsFromRedux } from './Connector';
import { PatientWithFullName } from 'src/types/patients';

const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    overflow: auto !important;
  }
`;

type Props = {
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  currentPatient?: PatientWithFullName;
  // TODO: To be updated to be typesafe
  onSubmit: (data: unknown) => void;
  mode: keyof typeof patientFormModes;
  modalStatus: string;
  setModalStatus: PropsFromRedux['setModalStatus'];
  intl: IntlShape;
};

const PatientModal = ({
  mode,
  isModalVisible,
  setIsModalVisible,
  onSubmit,
  currentPatient,
  intl,
  modalStatus,
  setModalStatus,
}: Props): JSX.Element => {
  const [isOkLoading, setIsOkLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setIsOkLoading(false);
  }, [isModalVisible]);

  const modeToTextMap = Object.freeze({
    [patientFormModes.ADD]: {
      title: intl.formatMessage(messages.addPatient),
      okButtonText: intl.formatMessage(messages.add),
    },
    [patientFormModes.EDIT]: {
      title: intl.formatMessage(messages.editPatient),
      okButtonText: intl.formatMessage(messages.apply),
    },
  });

  return (
    <ModalWrapper
      title={modeToTextMap[mode].title}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={modeToTextMap[mode].okButtonText}
      okButtonProps={{ loading: isOkLoading }}
      onOk={() => {
        form.submit();
      }}
      bodyStyle={{ overflowY: 'scroll', overflowX: 'hidden' }}
      destroyOnClose // in order to reset the form when modal is closed
      onClose={() => {
        form.resetFields();
      }}
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
    >
      <PatientForm
        form={form}
        patient={currentPatient}
        onSubmit={values => {
          setIsOkLoading(true);
          onSubmit(values);
        }}
      />
    </ModalWrapper>
  );
};

const messages = defineMessages({
  addPatient: {
    defaultMessage: 'Add Monitored Person',
  },
  editPatient: {
    defaultMessage: 'Edit Monitored Person',
  },
  add: {
    defaultMessage: 'Add',
  },
  apply: {
    defaultMessage: 'Apply',
  },
});

export default injectIntl(PatientModal);
