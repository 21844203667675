import AlertIndicator from 'src/components/AlertIndicator';
import { styled } from 'styled-components';

export const CardWrapper = styled.div<{ alertType?: string }>`
  display: grid;
  grid-template-columns: ${props =>
    props.alertType === 'VS'
      ? '0.45fr 3.2rem 1fr minmax(3.2rem, auto) 3.2rem'
      : '0.45fr 3.2rem 1fr 1fr'};

  align-items: center;
  background: #fbfbfb;
  box-shadow: 0px 0px 3px rgba(78, 83, 96, 0.25);
  border-radius: 4px;
  padding: 0.5rem;
  color: #252525;
  text-align: left;
`;

export const StyledTimestamp = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 0.6rem;
  margin-right: 0.35rem;
  white-space: nowrap;
`;

export const RoomDetails = styled.div`
  & .ant-typography {
    font-style: normal;
    font-weight: 500;
    font-size: 0.6rem;
    margin: 0;
  }
`;

export const BoldItem = styled.span`
  font-weight: 800;
`;

export const ItemLabel = styled.span`
  font-weight: 400;
  padding-right: 0.2rem;
`;

export const PatientName = styled.div`
  & .ant-typography {
    font-family: ${props => props.theme.fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 0.55rem;
    color: #767881;
    text-decoration-line: underline;
    cursor: pointer;
    margin: 0;
  }
`;

export const DeviceDisconnectedAlertInfo = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 0.65rem;
  margin-left: -1.4rem;
`;

export const AllTenantDevicesDisconnectedAlertInfo = styled.div`
  grid-column: span 2;
  font-style: normal;
  font-weight: 500;
  font-size: 0.65rem;
`;

export const StyledAlertIndicator = styled(AlertIndicator)`
  margin-right: 0.2rem;
  margin-left: 0.2rem;
`;

export const StyledNoConsentLabel = styled.div`
  background: #ffffff;
  border: 0.7px solid #e3e3e7;
  border-radius: 0.425rem;
  padding: 0.1rem;
  font-weight: 500;
  font-size: 0.45rem;
  text-transform: uppercase;
  color: #d3444f;
  text-align: center;
`;
export const StyledAlertIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
