import { Alert } from 'src/types/alerts';
import { PatientWithFullName } from 'src/types/patients';
import { DeviceStatusFilterOption } from '../../modules/filterUtils';

export type PageColumn<T> = {
  title: string;
  dataIndex: string;
  key: string;
  filtered?: boolean;
  onFilter?: (value: string, record: T) => boolean;
  sorter?: ((a: T, b: T) => number) | boolean;
  render?: (_: unknown, record: T) => React.ReactNode;
};

export enum PatientStatus {
  DISCHARGED = 'DISCHARGED',
  DISCHARGING = 'DISCHARGING',
  ASSIGNED = 'ASSIGNED',
}

export type MonitoredPatient = PatientWithFullName &
  Partial<{
    roomName: string;
    bedName: string;
    locationName: string;
    deviceId: string;
    patientStatus: PatientStatus;
    deviceStatus: DeviceStatusFilterOption;
    currentAlert: Alert[];
    eventLogAlerts: number;
    isPatientDeviceDischarging: boolean;
  }>;
