export const ActivityAlertFields = {
  SELECTED_SUBTENANTS: ['tenantIds'],
  SHORTLY_NOTIFICATION: [
    'outOfBedAlertSettings',
    'shortlyAfterBedExitAlertEnabled',
  ],
  SHORTLY_FROM: [
    'outOfBedAlertSettings',
    'bedtimeExitAlertSettings',
    'startTime',
  ],
  SHORTLY_TO: ['outOfBedAlertSettings', 'bedtimeExitAlertSettings', 'endTime'],
  CONTINUOUS_OUT_OF_BED_ENABLED: [
    'outOfBedAlertSettings',
    'bedtimeExitAlertSettings',
    'continuousOutOfBedAlertSettings',
    'enabled',
  ],
  CONTINUOUS_OUT_OF_BED_MINUTES: [
    'outOfBedAlertSettings',
    'bedtimeExitAlertSettings',
    'continuousOutOfBedAlertSettings',
    'thresholdDuration',
  ],
  REPEATING_STEP_OUT_ENABLED: [
    'outOfBedAlertSettings',
    'bedtimeExitAlertSettings',
    'repeatingStepOutOfBedAlertSettings',
    'enabled',
  ],
  REPEATING_STEP_OUT_MIN_REPETITION: [
    'outOfBedAlertSettings',
    'bedtimeExitAlertSettings',
    'repeatingStepOutOfBedAlertSettings',
    'minimumRepetitions',
  ],
  REPEATING_STEP_OUT_MIN_DURATION: [
    'outOfBedAlertSettings',
    'bedtimeExitAlertSettings',
    'repeatingStepOutOfBedAlertSettings',
    'minimumDuration',
  ],
  REPEATING_STEP_OUT_MAX_DURATION: [
    'outOfBedAlertSettings',
    'bedtimeExitAlertSettings',
    'repeatingStepOutOfBedAlertSettings',
    'maximumDuration',
  ],
  IN_BED_ENABLED: ['inBedAlertSettings', 'enabled'],
  IN_BED_PERCENT_CHANGE: ['inBedAlertSettings', 'percentChange'],
  IN_BED_BASELINE_DAYS: ['inBedAlertSettings', 'durationBaselineDays'],
  IN_BED_DEVIATION_PERIOD: ['inBedAlertSettings', 'deviationPeriodDays'],
  EMAIL: ['recipientsAlertSettings', 'targetMedia', 'EMAIL'],
  SMS: ['recipientsAlertSettings', 'targetMedia', 'SMS'],
  POSITION_CHANGE_ENABLED: ['positionChangeAlertSettings', 'enabled'],
  POSITION_CHANGE_HOURS: ['positionChangeAlertSettings', 'thresholdDuration'],
};
