import React, {
  Dispatch,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  SetStateAction,
} from 'react';

import SubtenantSearch from './SubtenantSearch';
import SubtenantStatus from './SubtenantStatus';
import AlertSettings from './AlertSettings';
import {
  ButtonsContainer,
  ExportCsvButton,
  SubtenantSettingsWrapper,
} from './styled';
import { DASHBOARD_VIEW_MODE } from 'src/routes/GMDashboard/components/SubtenantCards/tabConfig';
import { deepEqual } from 'src/utils/comparators';
import ListViewIcon from './resources/ListViewIcon';
import GridViewIcon from './resources/GridViewIcon';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import { useTheme } from 'styled-components';
import Tooltip from 'src/components/general-ui/Tooltip';

type Props = {
  onCurrentViewModeChange: Dispatch<SetStateAction<string>>;
  currentViewMode: string;
  intl: IntlShape;
};
function SubtenantSettingsComp(
  { onCurrentViewModeChange, currentViewMode, intl }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme();

  return (
    <SubtenantSettingsWrapper>
      <ButtonsContainer>
        <Tooltip title={intl.formatMessage(messages.boxViewLabel)}>
          <GridViewIcon
            color={
              currentViewMode === DASHBOARD_VIEW_MODE.GRID
                ? theme.colors.selectedViewButtonsColor
                : theme.colors.defaultViewButtonsColor
            }
            onClick={() => onCurrentViewModeChange(DASHBOARD_VIEW_MODE.GRID)}
          />
        </Tooltip>
        <Tooltip title={intl.formatMessage(messages.listViewLabel)}>
          <ListViewIcon
            color={
              currentViewMode === DASHBOARD_VIEW_MODE.LIST
                ? theme.colors.selectedViewButtonsColor
                : theme.colors.defaultViewButtonsColor
            }
            onClick={() => onCurrentViewModeChange(DASHBOARD_VIEW_MODE.LIST)}
          />
        </Tooltip>
        {currentViewMode === DASHBOARD_VIEW_MODE.LIST && (
          <ExportCsvButton
            onClick={() => {
              if (!(ref as MutableRefObject<HTMLDivElement>).current) {
                return;
              }
              (
                (
                  ref as MutableRefObject<HTMLDivElement>
                )?.current?.querySelector(
                  '.export-csv-button',
                ) as HTMLButtonElement
              )?.click();
            }}
          >
            <FormattedMessage {...messages.exportCsvLabel} />
          </ExportCsvButton>
        )}
      </ButtonsContainer>
      <SubtenantSearch />
      <SubtenantStatus />
      <AlertSettings />
    </SubtenantSettingsWrapper>
  );
}
const messages = defineMessages({
  exportCsvLabel: {
    defaultMessage: 'Export CSV',
  },
  listViewLabel: {
    defaultMessage: 'List View',
  },
  boxViewLabel: {
    defaultMessage: 'Box View',
  },
});
const SubtenantSettings = forwardRef(SubtenantSettingsComp);
export default injectIntl(
  React.memo(SubtenantSettings, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
  { forwardRef: true },
);
