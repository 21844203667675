import { styled } from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledOptionLabel = styled.span`
  color: #252525;
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  margin-right: 0.5rem;
`;

export const StyledOptionLabelTag = styled.span<{ color: string }>`
  color: ${props => props.color};
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
`;

export const SettingsInfo = styled.div`
  color: #252525;
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 0.75rem;
`;
