import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Tooltip, Typography } from 'antd';

import { displayInTimezone, getTimezoneId } from 'src/utils/timeUtils';
import AlertIndicator from 'src/components/AlertIndicator';
import AlertTooltip from './AlertTooltip';
import Connector, { PropsFromRedux } from './Connector';
import {
  CardWrapper,
  RoomDetails,
  PatientName,
  StyledTimestamp,
  BoldItem,
  DeviceDisconnectedAlertInfo,
  AllTenantDevicesDisconnectedAlertInfo,
  StyledAlertIndicator,
  ItemLabel,
  StyledNoConsentLabel,
  StyledAlertIconsContainer,
} from './styled';
import { getTooltipMessage, messages } from './utils';
import { AlertLogItem } from './types';
import { getValueOrNA } from 'src/utils/dataUtils';
import AlertNotes from '../AlertNotes';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';

const { Paragraph } = Typography;

export type Props = PropsFromRedux &
  AlertLogItem & {
    onPatientNameClick: () => void;
    intl: IntlShape;
  };

const AlertLogCard = (props: Props): JSX.Element | null => {
  const {
    startTime,
    deviceRoomName,
    deviceBedName,
    patientFirstName,
    patientLastName,
    onPatientNameClick,
    intl,
    thresholdMetric,
    tenant,
    deviceId,
    patientId,
    patientIsConsented,
  } = props;
  const timezone = getTimezoneId(tenant?.timeZoneId);

  const displayRoomName = getValueOrNA(deviceRoomName);
  const displayBedName = getValueOrNA(deviceBedName);

  if (thresholdMetric === ALERT_METRIC_ENUM.ALL_DEVICES_DISCONNECTED) {
    return (
      <AlertTooltip
        title={getTooltipMessage(props, tenant, intl)}
        placement="top"
      >
        <CardWrapper>
          <StyledTimestamp>
            {displayInTimezone(startTime, timezone, 'HH:mm')}
          </StyledTimestamp>
          <AlertIndicator {...props} />
          <AllTenantDevicesDisconnectedAlertInfo>
            <FormattedMessage {...messages.allDevicesDisconnectedInfo} />
          </AllTenantDevicesDisconnectedAlertInfo>
        </CardWrapper>
      </AlertTooltip>
    );
  }

  if (thresholdMetric === ALERT_METRIC_ENUM.DEVICE_DISCONNECTED) {
    return (
      <AlertTooltip
        title={getTooltipMessage(props, tenant, intl)}
        placement="top"
      >
        <CardWrapper>
          <StyledTimestamp>
            {displayInTimezone(startTime, timezone, 'HH:mm')}
          </StyledTimestamp>
          <RoomDetails>
            <Paragraph
              ellipsis={{
                rows: 1,
                tooltip: displayRoomName,
              }}
            >
              <BoldItem>{displayRoomName}</BoldItem>
            </Paragraph>

            <Paragraph
              ellipsis={{
                rows: 1,
                tooltip: displayBedName,
              }}
            >
              <FormattedMessage {...messages.bed} />
              <BoldItem>{displayBedName}</BoldItem>
            </Paragraph>
          </RoomDetails>
          <StyledAlertIndicator {...props} />
          <DeviceDisconnectedAlertInfo>
            <FormattedMessage
              {...(props.sessionId
                ? messages.assignedDeviceDisconnectedInfo
                : messages.unassignedDeviceDisconnectedInfo)}
            />
          </DeviceDisconnectedAlertInfo>
        </CardWrapper>
      </AlertTooltip>
    );
  }

  return (
    <AlertTooltip
      title={
        <div
          dangerouslySetInnerHTML={{
            __html: getTooltipMessage(props, tenant, intl),
          }}
        />
      }
      placement="top"
    >
      <CardWrapper alertType="VS">
        <StyledTimestamp>
          {displayInTimezone(startTime, timezone, 'HH:mm')}
        </StyledTimestamp>
        <RoomDetails>
          <Paragraph
            ellipsis={{
              rows: 1,
              tooltip: displayRoomName,
            }}
          >
            <BoldItem>{displayRoomName}</BoldItem>
          </Paragraph>

          <Paragraph
            ellipsis={{
              rows: 1,
              tooltip: displayBedName,
            }}
          >
            <ItemLabel>
              <FormattedMessage {...messages.bed} />
            </ItemLabel>

            <BoldItem>{displayBedName}</BoldItem>
          </Paragraph>
        </RoomDetails>
        <AlertNotes
          patientId={patientId}
          deviceId={deviceId}
          tenantId={tenant?.tenantId || ''}
        />
        <StyledAlertIconsContainer>
          {!patientIsConsented && (
            <StyledNoConsentLabel>
              <FormattedMessage {...messages.noConsentLabel} />
            </StyledNoConsentLabel>
          )}
          <StyledAlertIndicator {...props} />
        </StyledAlertIconsContainer>
        <PatientName onClick={onPatientNameClick}>
          <Tooltip
            title={
              !patientIsConsented ? (
                <FormattedMessage {...messages.noConsentTooltip} />
              ) : null
            }
          >
            <Paragraph
              ellipsis={{
                rows: 2,
                tooltip: `${patientFirstName || ''} ${patientLastName || ''}`,
              }}
            >
              {`${patientFirstName || ''} ${patientLastName || ''}`}
            </Paragraph>{' '}
          </Tooltip>
        </PatientName>
      </CardWrapper>
    </AlertTooltip>
  );
};

export default Connector(injectIntl(AlertLogCard));
