import React from 'react';
import { styled } from 'styled-components';

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 0.65rem;
  cursor: pointer;
  width: fit-content;
`;

const StyledCheckbox = styled.input<{ withLabel: boolean }>`
  width: 1rem;
  height: 1rem;
  accent-color: #304e64;
  ${props => (props.withLabel ? 'margin-right: 0.75rem' : '')};
  cursor: pointer;
`;

type Props = {
  checked: boolean;
  handleChange: (value: boolean) => void;
  label?: string;
  defaultChecked?: boolean;
  className?: string;
  disabled?: boolean;
};

const Checkbox = ({
  checked,
  label,
  defaultChecked,
  handleChange,
  className,
  disabled,
}: Props): JSX.Element => (
  <StyledLabel>
    <StyledCheckbox
      className={className}
      type="checkbox"
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={() => handleChange(!checked)}
      disabled={disabled}
      withLabel={!!label}
    />
    {label}
  </StyledLabel>
);

export default Checkbox;
