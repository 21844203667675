import { InputNumber, Select } from 'antd';
import { styled } from 'styled-components';

export const StyledWrapper = styled.div`
  height: fit-content;
  padding-bottom: 1.5rem;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  margin: 0.75rem 0;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.75rem;
  flex: 1;
`;

export const ParametersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  font-size: 0.7rem;

  .react-js-cron-select {
    .ant-select-selector {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid #3d3d3d;
      font-size: 0.7rem;
      max-width: 9rem;
    }
  }
`;

export const StyledParameterTitle = styled.p`
  font-size: 0.7rem;
  margin-bottom: 0;
  padding-left: 0.5rem;
`;

export const StyledInputNumber: typeof InputNumber = styled(InputNumber)`
  width: 1.5rem;
  height: 1rem;
  padding: 0 0.25rem;
  font-size: 0.7rem;
  line-height: 1rem;

  .ant-input-number-input-wrap {
    border-bottom: 1px solid #000;

    .ant-input-number-input {
      height: 100%;
      font-weight: 700;
      text-align: center;
      padding: 0;
    }
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    width: 100px !important;
    border-bottom: 1px solid #3d3d3d !important;
  }

  .ant-select-selection {
    background-color: green;
  }
`;
