import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import Search, { useSearch } from 'src/components/general-ui/Search';
import Sort, { useSort } from 'src/components/general-ui/Sort';
import AlertLogCard from '../../components/AlertLogCard';
import Connector, { PropsFromRedux } from './Connector';
import { buildSorters, messages, searchKeys } from './utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteScrollProps } from 'src/utils/hooks/useInfiniteScrollProps';
import { EventLogWrap, ListWrapper, StyledText } from '../../styled';
import { deepEqual } from 'src/utils/comparators';
import { redirectToPatientIDB } from 'src/utils/patientHelpers';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const EventLog = ({
  alertItems,
  intl,
  areHistoricalAlertsLoading,
}: Props): JSX.Element => {
  const sorters = buildSorters(intl);
  const [filteredItems, query, setQuery] = useSearch(alertItems, searchKeys);
  const [sortedItems, sortState, setSortState] = useSort(
    filteredItems,
    sorters,
  );
  const { noOfVisibleElements, fetchMoreData } = useInfiniteScrollProps();
  if (!alertItems.length) {
    return (
      <EventLogWrap
        data-cy={`alerts-sidebar-event-log-loading=${areHistoricalAlertsLoading.toString()}`}
      >
        <StyledText>
          <FormattedMessage {...messages.noAlertsRecieved} />
        </StyledText>
      </EventLogWrap>
    );
  }

  if (!filteredItems.length) {
    return (
      <EventLogWrap
        data-cy={`alerts-sidebar-event-log-loading=${areHistoricalAlertsLoading.toString()}`}
      >
        <Search value={query} onChange={e => setQuery(e.target.value)} />
        <Sort
          sortState={sortState}
          sorters={sorters}
          setSortState={setSortState}
        />
        <StyledText>
          <FormattedMessage {...messages.noAlertsMatched} />
        </StyledText>
      </EventLogWrap>
    );
  }
  return (
    <EventLogWrap
      data-cy={`alerts-sidebar-event-log-loading=${areHistoricalAlertsLoading.toString()}`}
    >
      <Search value={query} onChange={e => setQuery(e.target.value)} />
      <Sort
        sortState={sortState}
        sorters={sorters}
        setSortState={setSortState}
      />
      <ListWrapper id={'eventLogScrollableDiv'}>
        <InfiniteScroll
          dataLength={sortedItems.slice(0, noOfVisibleElements).length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={sortedItems.length > noOfVisibleElements}
          loader={
            <StyledText>
              <FormattedMessage {...messages.loadingOnScroll} />
            </StyledText>
          }
          scrollableTarget="eventLogScrollableDiv"
          style={{ gap: '0.5rem' }}
        >
          {sortedItems.slice(0, noOfVisibleElements).map(alertItem => (
            <AlertLogCard
              key={alertItem.id}
              {...alertItem}
              onPatientNameClick={() => {
                if (!alertItem?.patientIsConsented) {
                  return;
                }
                redirectToPatientIDB(alertItem.patientId);
              }}
            />
          ))}
        </InfiniteScroll>
      </ListWrapper>
    </EventLogWrap>
  );
};

export default Connector(
  injectIntl(
    React.memo(EventLog, (oldProps, newProps) => deepEqual(oldProps, newProps)),
  ),
);
