import { styled } from 'styled-components';
import TransparentButton from './TransparentButton';

const ActionButton = styled(TransparentButton)`
  background: ${props => props.theme.colors.actionButtonBackground};
  color: ${props => props.theme.colors.coloredButtonTextColor};
  font-weight: bold;
  font-size: 0.7rem;
  white-space: nowrap;
  :hover,
  :active {
    opacity: 0.7;
  }

  :focus {
    opacity: 0.9;
  }
`;

export default ActionButton;
