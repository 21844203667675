import React from 'react';
import { Redirect } from 'react-router-dom';
import { Location } from 'history';

import {
  hasGMPermissions,
  hasSAPermissions,
  hasTAPermissions,
} from 'src/utils/permissions';
import { appRoutes } from '../modules/routesUtils';
import ums from 'src/libs/ums-js-logic';

type Props = {
  computedMatch: {
    isExact: boolean;
    params: {
      locale?: string;
    };
    path: string;
    url: string;
  };
  location: Location;
};

const RedirectToLandingPage = (props: Props): JSX.Element | null => {
  const {
    computedMatch: {
      params: { locale },
    },
    location,
  } = props;

  if (!locale) {
    return null;
  }

  if (hasGMPermissions()) {
    return (
      <Redirect
        to={{
          pathname: `/${locale}/${appRoutes.DASHBOARD}`,
          state: { from: location },
        }}
      />
    );
  }

  if (hasTAPermissions() || hasSAPermissions()) {
    return (
      <Redirect
        to={{
          pathname: `/${locale}/${appRoutes.DEVICES}`,
          state: { from: location },
        }}
      />
    );
  }
  const impersonatedSubtenantId = ums.getImpersonatedSubtenantId();
  const searchParams = new URLSearchParams();
  if (impersonatedSubtenantId) {
    searchParams.set('impersonatedSubtenantId', impersonatedSubtenantId);
  }

  return (
    <Redirect
      to={{
        pathname: `/${locale}/${appRoutes.NURSES_STATION}`,
        state: { from: location },
        search: searchParams.toString(),
      }}
    />
  );
};

export default RedirectToLandingPage;
