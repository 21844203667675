import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import Connector, { PropsFromRedux } from '../Connector';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import {
  getListViewColumns,
  getListViewTableActions,
} from 'src/routes/GMDashboard/components/SubtenantCards/ListView/utils';
import { deepEqual } from 'src/utils/comparators';
import { StyledTable, StyledTableContainer } from './styled';
import SubtenantAlertSettingsModal from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantCard/IndividualSubtenantSettings/SubtenantAlertSettingsModal';
import dayjs from 'dayjs';

type Props = PropsFromRedux & {
  intl: IntlShape;
  onTableLoad: (ref: React.RefObject<HTMLDivElement>) => void;
};

const MtmListView = ({
  subtenants,
  areSubtenantsLoading,
  userTenantName,
  onTableLoad,
  intl,
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedTenantId, setSelectedTenantId] = useState<string>('');
  const [tableParams, setTableParams] = useState({
    current: DEFAULT_PAGINATION.PAGE,
    pageSize: DEFAULT_PAGINATION.LIMIT,
    showSizeChanger: false,
    total: subtenants.length,
  });

  const paginationChange = (page: number) => {
    setTableParams(prevState => ({
      ...prevState,
      current: page,
    }));
  };
  useEffect(() => {
    setTableParams(prevState => ({
      ...prevState,
      total: subtenants.length,
    }));
  }, [subtenants]);

  const getTableHeight = () => {
    const node = ref.current;

    const { height } = node ? node.getBoundingClientRect() : { height: 130 };
    setTableHeight(height - 130);
  };

  useEffect(() => {
    window.addEventListener('resize', getTableHeight);
  }, []);

  useLayoutEffect(() => {
    getTableHeight();
    onTableLoad(ref);
  }, [ref]);
  return (
    <StyledTableContainer ref={ref}>
      <StyledTable
        sticky={true}
        withCsvExport={true}
        csvFileName={`${userTenantName}_Tenants_${dayjs().format('MMDDYYYY')}`}
        loading={areSubtenantsLoading}
        data={subtenants}
        columns={getListViewColumns(intl)}
        pagination={
          tableParams && tableParams.total > tableParams.pageSize
            ? {
                ...tableParams,
                onChange: paginationChange,
              }
            : false
        }
        scroll={{ y: tableHeight }}
        actions={getListViewTableActions(
          intl,
          setIsModalVisible,
          setSelectedTenantId,
        )}
      />
      {isModalVisible && (
        // @ts-ignore need to investigate this
        <SubtenantAlertSettingsModal
          subtenantId={selectedTenantId}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </StyledTableContainer>
  );
};

export default Connector(
  injectIntl(
    React.memo(MtmListView, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
