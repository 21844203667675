import React, { useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import {
  DISPLAY_STATUS_FILTERS,
  FilterItems,
  notNull,
  TenantStatusFilter,
} from 'src/routes/GMDashboard/modules/filterUtils';
import Connector, { PropsFromRedux } from './Connector';
import {
  StyledWrapper,
  StyledTitle,
  OptionsWrapper,
  StyledOption,
  StyledFooter,
  StyledBtn,
  StyledInput,
  StyledCheckboxWrapper,
} from './styled';
import { Form, Select } from 'antd';
import CheckboxForm from 'src/components/general-ui/CheckboxForm';
import editIcon from 'src/components/AlertSettingsComponents/AlertThresholdsForm/resources/edit-icon.svg';
import { valueValidator } from './StatusFilterValidators';
import { deepEqual } from 'src/utils/comparators';

type Props = PropsFromRedux & {
  intl: IntlShape;
  onActionsClick: () => void;
  style: React.CSSProperties;
};

const StatusFilterSelector = ({
  intl,
  appliedFilters,
  setFilters,
  onActionsClick,
  ...props
}: Props): JSX.Element => {
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const onFinish = (formValues: FilterItems) => {
    if (!DISPLAY_STATUS_FILTERS || !DISPLAY_STATUS_FILTERS.length) {
      return;
    }
    const enabledFilters: TenantStatusFilter['value'] =
      DISPLAY_STATUS_FILTERS.map(eachFilter => {
        const filterKey = eachFilter.value;
        // checkbox enabled
        return formValues[filterKey]
          ? {
              key: filterKey,
              comparasionTerm: formValues[`${filterKey}_SELECT`],
              value: formValues[`${filterKey}_VALUE`],
            }
          : null;
      }).filter(notNull);
    setFilters({ filter: 'status', value: enabledFilters });
  };
  const onReset = () => {
    setFilters({ filter: 'status', value: [] });
    form.resetFields();
  };

  return (
    <StyledWrapper {...props}>
      <StyledTitle>
        <FormattedMessage {...messages.title} />
      </StyledTitle>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
      >
        {(values, formInstance) => {
          const areChecboxesEnabled =
            DISPLAY_STATUS_FILTERS.filter(elem => !!values[elem.value]).length >
            0;
          setIsFormValid?.(
            !formInstance
              .getFieldsError()
              .some(({ errors }) => !!errors.length) && areChecboxesEnabled,
          );
          return (
            <>
              <OptionsWrapper>
                {DISPLAY_STATUS_FILTERS.sort(
                  (a, b) => a.displayOrder - b.displayOrder,
                ).map(({ value: filterName, label }) => (
                  // <FieldsOnSameRow>
                  <StyledOption key={filterName}>
                    <StyledCheckboxWrapper>
                      <Form.Item
                        noStyle
                        name={filterName}
                        valuePropName="checked"
                      >
                        {/* @ts-ignore Form.Item component will take care of passing props in from */}
                        <CheckboxForm
                          checked={appliedFilters
                            ?.map(elem => elem.key)
                            .includes(filterName)}
                          label={intl.formatMessage(label)}
                        />
                      </Form.Item>
                    </StyledCheckboxWrapper>
                    <Form.Item
                      initialValue={'gt'}
                      noStyle
                      name={`${filterName}_SELECT`}
                      dependencies={[filterName]}
                    >
                      <Select
                        options={[
                          { value: 'gt', label: 'More' },
                          { value: 'lt', label: 'Less' },
                        ]}
                        disabled={!form.getFieldValue(filterName)}
                      />
                    </Form.Item>
                    than
                    <Form.Item
                      initialValue={0}
                      name={`${filterName}_VALUE`}
                      dependencies={[filterName]}
                      rules={valueValidator(filterName)}
                    >
                      <StyledInput
                        autoComplete="off"
                        type="number"
                        min={0}
                        iconSrc={editIcon}
                        disabled={!form.getFieldValue(filterName)}
                      />
                    </Form.Item>
                  </StyledOption>
                ))}
              </OptionsWrapper>

              <StyledFooter>
                <StyledBtn
                  onClick={() => {
                    onReset();
                    onActionsClick();
                  }}
                >
                  <FormattedMessage {...messages.reset} />
                </StyledBtn>
                <StyledBtn
                  onClick={() => {
                    form.submit();
                    onActionsClick();
                  }}
                  disabled={!isFormValid}
                  type="primary"
                  htmlType="submit"
                >
                  <FormattedMessage {...messages.apply} />
                </StyledBtn>
              </StyledFooter>
            </>
          );
        }}
      </Form>
    </StyledWrapper>
  );
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Select Filters:',
  },
  reset: {
    defaultMessage: 'Reset',
  },
  apply: {
    defaultMessage: 'Apply',
  },
});

export default Connector(
  injectIntl(
    React.memo(StatusFilterSelector, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
