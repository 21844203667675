import { defineMessages } from 'react-intl';

export const addPatientFormFields = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PATIENT_ID: 'patientId',
  EMAIL: 'email',
  PHONE: 'phone',
  ADDRESS: 'address',
  GENDER: 'gender',
  DATE_OF_BIRTH: 'dateOfBirth',
};

export const genderOptions = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const addPatientFormFieldsLabelsMessages = defineMessages({
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  id: {
    defaultMessage: 'ID',
  },
  email: {
    defaultMessage: 'Email',
  },
  phone: {
    defaultMessage: 'Phone Number',
  },
  dateOfBirth: {
    defaultMessage: 'Date Of Birth',
  },
  gender: {
    defaultMessage: 'Gender',
  },
  address: {
    defaultMessage: 'Address',
  },
});

export const addPatientFormFieldsPlaceholdersMessages = defineMessages({
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  id: {
    defaultMessage: 'ID',
  },
  email: {
    defaultMessage: 'Email',
  },
  phone: {
    defaultMessage: 'Phone Number',
  },
  dateOfBirth: {
    defaultMessage: 'Date Of Birth',
  },
  gender: {
    defaultMessage: 'Select gender',
  },
  address: {
    defaultMessage: 'Address',
  },
});

export const patientFormModes = Object.freeze({
  ADD: 'ADD',
  EDIT: 'EDIT',
});

export const patientFormDateFormat = 'YYYY-MM-DD';

export const patientFormValidateMessages = {
  required: '${label} is required!',
  pattern: {
    mismatch: '${label} is not a valid ${label}!',
  },
  whitespace: '${label} must not be empty',
};

export const patientPageColumnsMessages = defineMessages({
  room: {
    defaultMessage: 'Room',
  },
  bed: {
    defaultMessage: 'Bed',
  },
  fullName: {
    defaultMessage: 'Full Name',
  },
  dateOfBirth: {
    defaultMessage: 'Age',
  },
  eventLog: {
    defaultMessage: 'Event Log',
  },
  currentEvent: {
    defaultMessage: 'Current Event',
  },
  patientStatus: {
    defaultMessage: 'Patient Status',
  },
  deviceStatus: {
    defaultMessage: 'Device Status',
  },
  ASSIGNED: {
    defaultMessage: 'Assigned',
  },
  DISCHARGED: {
    defaultMessage: 'Discharged',
  },
  DISCHARGING: {
    defaultMessage: 'Discharging',
  },
  isConsented: {
    defaultMessage: 'Consent Status',
  },
  yesLabel: {
    defaultMessage: 'Yes',
  },
  noLabel: {
    defaultMessage: 'No',
  },
  updateConsentDescription: {
    defaultMessage:
      'You are about to change a patient’s consent status. Are you sure you want to proceed?',
  },
  updateConsentTitle: {
    defaultMessage: 'Consent update',
  },
  noConsentTooltip: {
    defaultMessage: 'No Consent - access to medical information is denied',
  },
});
