import React from 'react';

import { IconType } from 'src/components/general-ui/Icon';
import {
  PatientStatusWrap,
  StyledIcon,
  StyledBellIconOutlined,
  StyledAlertIndicator,
  ContainerState,
  EachStateContainer,
} from './styled';
import { checkPatientHasAlert } from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceCard/utils';
import { DeviceAlerts } from 'src/routes/NursesStation/modules/types';
import { IconText } from 'src/routes/IndividualDashboard/components/PageHeader/styled';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

type Props = {
  isDeviceConnected: boolean;
  isUnoccupied: boolean;
  isVacancyAlertActive: boolean;
  isLongOutOfBedAlertActive?: boolean;
  isPositionChangeAlertActive?: boolean;
  showOccupancyStatus?: boolean;
  ongoingDeviceAlerts: DeviceAlerts;
  itemsAlignment?: 'row' | 'column';
  intl: IntlShape;
};

const getIconColors = (
  hasActiveAlert: boolean,
  hasSuppressedAlert: boolean,
): { fillColor: string } => {
  if (hasActiveAlert) {
    return {
      fillColor: '#CA0000',
    };
  }

  if (hasSuppressedAlert) {
    return {
      fillColor: '#FFAD0D',
    };
  }

  return {
    fillColor: '#252525',
  };
};

const PatientStatusComponent = ({
  isUnoccupied,
  isDeviceConnected,
  ongoingDeviceAlerts,
  isVacancyAlertActive,
  isLongOutOfBedAlertActive = false,
  isPositionChangeAlertActive = false,
  itemsAlignment = 'column',
  showOccupancyStatus = false,
}: Props) => {
  const { bedTimeBaseline, bedExitFrequency, positionChange } =
    ongoingDeviceAlerts;
  const hasActiveAlert = checkPatientHasAlert(
    'ON',
    isUnoccupied,
  )(ongoingDeviceAlerts);

  const hasSuppressedAlert = checkPatientHasAlert(
    'SUPPRESSED',
    true,
  )(ongoingDeviceAlerts);

  const { fillColor } = getIconColors(hasActiveAlert, hasSuppressedAlert);

  const getPatientIcon = (isUnoccupied: boolean): IconType[] => {
    const arrayIcons = [];

    isUnoccupied || isLongOutOfBedAlertActive
      ? arrayIcons.push('bed-icon')
      : arrayIcons.push('patient-in-bed-icon');

    if (bedExitFrequency) {
      arrayIcons.push('bed-exit-frequency-icon');
    }
    if (positionChange) {
      arrayIcons.push('position-change');
    }

    return arrayIcons as IconType[];
  };

  const alertValue = bedExitFrequency
    ? bedExitFrequency?.triggerValue
    : `${bedTimeBaseline?.triggerValue ?? '-'}%`;
  return (
    <PatientStatusWrap itemsAlignment={itemsAlignment}>
      {getPatientIcon(isUnoccupied).map((icon, _idx, arr) => {
        if (
          (icon === 'bed-icon' || icon === 'patient-in-bed-icon') &&
          arr?.length >= 3
        ) {
          return;
        }
        return (
          <EachStateContainer key={icon}>
            {icon === 'bed-exit-frequency-icon' && (
              <>
                <StyledIcon key={icon} type={icon} color={fillColor} />
                <StyledAlertIndicator color={fillColor}>
                  {alertValue}
                </StyledAlertIndicator>
              </>
            )}
            {(icon === 'bed-icon' || icon === 'patient-in-bed-icon') && (
              <>
                <ContainerState>
                  <StyledIcon key={icon} type={icon} color={fillColor} />
                  {(isVacancyAlertActive || isPositionChangeAlertActive) && (
                    <StyledBellIconOutlined
                      opacity={isDeviceConnected ? '1' : '0.5'}
                      type="bell-icon-filled"
                      color="#63B7C3"
                    />
                  )}
                </ContainerState>
                {showOccupancyStatus && (
                  <IconText>
                    {isUnoccupied ? (
                      <FormattedMessage {...messages.unoccupied} />
                    ) : (
                      <FormattedMessage {...messages.occupied} />
                    )}
                  </IconText>
                )}
              </>
            )}
            {icon === 'position-change' && (
              <>
                <ContainerState>
                  <StyledIcon key={icon} type={icon} color={fillColor} />
                </ContainerState>
              </>
            )}
          </EachStateContainer>
        );
      })}
    </PatientStatusWrap>
  );
};

const messages = defineMessages({
  occupied: { defaultMessage: 'Occupied' },
  unoccupied: { defaultMessage: 'Unoccupied' },
});

export default injectIntl(PatientStatusComponent);
