import { ActivityAlertFields } from 'src/components/AlertSettingsComponents/ActivityAlerts/constants';
import { Dayjs } from 'dayjs';
import { Rule } from 'rc-field-form/lib/interface';
import { getIntl } from 'src/utils/languageUtils';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  continuousOutOfBedValidation: {
    defaultMessage: 'Valid Range: 10-120',
  },
  positionChangeValidation: {
    defaultMessage: 'Valid Range: 1-23',
  },
  stepOutMinRepetitionValidation: {
    defaultMessage: 'Valid Range: 1-7',
  },
  stepOutMinDurationValidation: {
    defaultMessage: 'Valid Range: 1-60',
  },
  stepOutRepeatingMaxDuration: {
    defaultMessage: 'Valid Range: 1-60',
  },
  inBedPercentageValidation: {
    defaultMessage: 'Valid Range: 20-100',
  },
  inBedBaselineDaysValidation: {
    defaultMessage: 'Valid Range: 3-7',
  },
  inBedDeviationPeriodValidation: {
    defaultMessage: 'Valid Range: 1-7',
  },
  incorrectMinAndMaxValues: {
    defaultMessage: 'Value should be greater than {val}',
  },
  incorrectMaxAndMinValues: {
    defaultMessage: 'Value should be less than {val}',
  },
  timeIntervalValidation: {
    defaultMessage:
      'Value should be less than-equal to the time window ({val})',
  },
  mandatoryFieldValue: {
    defaultMessage: 'Please add a value',
  },
});
const intl = getIntl(messages);
export const datepickerFromValidator = (
  areSelectedTenantsSettingsEquals?: boolean,
): Rule[] => [
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (
        !getFieldValue(ActivityAlertFields.REPEATING_STEP_OUT_ENABLED) &&
        !getFieldValue(ActivityAlertFields.CONTINUOUS_OUT_OF_BED_ENABLED)
      ) {
        return Promise.resolve();
      }
      if (!value) {
        return Promise.reject(intl.formatMessage(messages.mandatoryFieldValue));
      }
      if (!areSelectedTenantsSettingsEquals) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
  }),
];
export const datepickerToValidator = (
  areSelectedTenantsSettingsEquals?: boolean,
): Rule[] => [
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (
        !getFieldValue(ActivityAlertFields.REPEATING_STEP_OUT_ENABLED) &&
        !getFieldValue(ActivityAlertFields.CONTINUOUS_OUT_OF_BED_ENABLED)
      ) {
        return Promise.resolve();
      }
      if (!value) {
        return Promise.reject(intl.formatMessage(messages.mandatoryFieldValue));
      }
      if (!areSelectedTenantsSettingsEquals) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
  }),
];

export const continuousHoursValidator = (
  areSelectedTenantsSettingsEquals?: boolean,
): Rule[] => [
  ({ getFieldValue }) => ({
    validator(_, value: number) {
      if (!getFieldValue(ActivityAlertFields.CONTINUOUS_OUT_OF_BED_ENABLED)) {
        return Promise.resolve();
      }
      if (!areSelectedTenantsSettingsEquals) {
        return Promise.reject();
      }
      const to = getFieldValue(ActivityAlertFields.SHORTLY_TO) as Dayjs;
      const from = getFieldValue(ActivityAlertFields.SHORTLY_FROM) as Dayjs;
      if (to && from) {
        const diff = to.diff(from, 'hours');
        const maximumAllowedValue = diff <= 0 ? diff + 24 : diff;

        if (maximumAllowedValue * 60 < value) {
          return Promise.reject(
            intl.formatMessage(messages.timeIntervalValidation, {
              val: maximumAllowedValue * 60,
            }),
          );
        }
      }
      if (value >= 10 && value <= 120) {
        return Promise.resolve();
      }
      return Promise.reject(
        intl.formatMessage(messages.continuousOutOfBedValidation),
      );
    },
  }),
];
export const minRepetitionValidator = (
  areSelectedTenantsSettingsEquals?: boolean,
): Rule[] => [
  ({ getFieldValue }) => ({
    validator(_, value: string) {
      if (!getFieldValue(ActivityAlertFields.REPEATING_STEP_OUT_ENABLED)) {
        return Promise.resolve();
      }
      if (!areSelectedTenantsSettingsEquals) {
        return Promise.reject();
      }
      if (parseInt(value) > 0 && parseInt(value) < 8) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(intl.formatMessage(messages.stepOutMinRepetitionValidation)),
      );
    },
  }),
];
export const minDurationValidator = (
  areSelectedTenantsSettingsEquals?: boolean,
): Rule[] => [
  ({ getFieldValue }) => ({
    validator(_, value: string) {
      const maxValue = parseInt(
        getFieldValue(
          ActivityAlertFields.REPEATING_STEP_OUT_MAX_DURATION,
        ) as string,
      );
      const minValue = parseInt(
        getFieldValue(
          ActivityAlertFields.REPEATING_STEP_OUT_MIN_DURATION,
        ) as string,
      );
      if (
        !getFieldValue(ActivityAlertFields.REPEATING_STEP_OUT_ENABLED) ||
        !maxValue
      ) {
        return Promise.resolve();
      }
      if (!areSelectedTenantsSettingsEquals) {
        return Promise.reject();
      }
      if (parseInt(value) < 1 || parseInt(value) > 60) {
        return Promise.reject(
          intl.formatMessage(messages.stepOutMinDurationValidation),
        );
      }

      if (minValue > maxValue) {
        return Promise.reject(
          intl.formatMessage(messages.incorrectMaxAndMinValues, {
            val: getFieldValue(
              ActivityAlertFields.REPEATING_STEP_OUT_MAX_DURATION,
            ) as string,
          }),
        );
      }
      return Promise.resolve();
    },
  }),
];
export const maxDurationValidator = (
  areSelectedTenantsSettingsEquals?: boolean,
): Rule[] => [
  ({ getFieldValue }) => ({
    validator(_, value: string) {
      if (!getFieldValue(ActivityAlertFields.REPEATING_STEP_OUT_ENABLED)) {
        return Promise.resolve();
      }
      if (!areSelectedTenantsSettingsEquals) {
        return Promise.reject();
      }
      if (parseInt(value) < 1 || parseInt(value) > 60) {
        return Promise.reject(
          intl.formatMessage(messages.stepOutRepeatingMaxDuration),
        );
      }
      if (
        parseInt(
          getFieldValue(
            ActivityAlertFields.REPEATING_STEP_OUT_MIN_DURATION,
          ) as string,
        ) >
        parseInt(
          getFieldValue(
            ActivityAlertFields.REPEATING_STEP_OUT_MAX_DURATION,
          ) as string,
        )
      ) {
        return Promise.reject(
          intl.formatMessage(messages.incorrectMinAndMaxValues, {
            val: getFieldValue(
              ActivityAlertFields.REPEATING_STEP_OUT_MIN_DURATION,
            ) as string,
          }),
        );
      }

      return Promise.resolve();
    },
  }),
];
export const positionChangeHoursValidator = (
  areSelectedTenantsSettingsEquals?: boolean,
): Rule[] => [
  ({ getFieldValue }) => ({
    validator(_, value: number) {
      if (!getFieldValue(ActivityAlertFields.POSITION_CHANGE_ENABLED)) {
        return Promise.resolve();
      }
      if (!areSelectedTenantsSettingsEquals) {
        return Promise.reject();
      }
      if (value > 0 && value < 24) {
        return Promise.resolve();
      }
      return Promise.reject(
        intl.formatMessage(messages.positionChangeValidation),
      );
    },
  }),
];
