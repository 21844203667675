import React from 'react';
import { styled } from 'styled-components';

import Icon from 'src/components/general-ui/Icon';
import { NoteType } from 'src/types/notes';
import {
  displayInTimezone,
  US_FORMAT_WITHOUT_SECONDS,
} from 'src/utils/timeUtils';
import Connector, { Props } from './Connector';

const NoteItemWrapper = styled.div`
  max-width: 15.7rem;
`;

const NoteItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3rem;
  gap: 0.4rem;
`;

const NoteItemAuthor = styled.div`
  font-weight: 600;
  font-size: 0.6rem;
  color: #252525;
`;

const NoteItemTimestamp = styled.div`
  font-weight: 400;
  font-size: 0.6rem;
  color: #767881;
`;

const ImportantIcon = styled(Icon)`
  width: 0.7rem;
  height: 0.7rem;
`;

const NoteItemContent = styled.div`
  font-weight: 400;
  font-size: 0.6rem;
  color: #252525;
`;

const NoteItem = ({ noteItem, timezone }: Props): JSX.Element | null => {
  if (!noteItem) {
    return null;
  }

  const { note, createdByName, type, creationTime } = noteItem;

  return (
    <NoteItemWrapper>
      <NoteItemHeader>
        <NoteItemAuthor>{createdByName}</NoteItemAuthor>
        <NoteItemTimestamp>
          {displayInTimezone(creationTime, timezone, US_FORMAT_WITHOUT_SECONDS)}
        </NoteItemTimestamp>
        {type === NoteType.IMPORTANT && <ImportantIcon type="attention-icon" />}
      </NoteItemHeader>
      <NoteItemContent>{note}</NoteItemContent>
    </NoteItemWrapper>
  );
};

export default Connector(NoteItem);
