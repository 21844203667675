import React from 'react';
import { useTheme } from 'styled-components';

import { NoteType } from 'src/types/notes';
import { deepEqual } from 'src/utils/comparators';
import { StyledCounter, StyledIcon, Wrapper } from './styled';
import Connector, { Props } from './Connector';
import NotesTooltip, { getTooltipMessage } from './NotesTooltip';

const AlertNotes = (props: Props): JSX.Element => {
  const {
    notes,
    counterMetadata,
    fetchPatientNotes,
    patientId,
    flexDirection = 'column',
  } = props;
  const theme = useTheme();

  const getIconColor = (counter: number, isImportant: boolean): string => {
    if (!counter) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return theme.colors.emptyNotes;
    }

    if (isImportant) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return theme.colors.importantNotes;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return theme.colors.normalNotes;
  };

  const counterNotes = counterMetadata ?? {
    regular: 0,
    important: 0,
  };
  const counter = Math.max(
    notes.length,
    counterNotes?.regular + counterNotes?.important,
  );
  const isImportant =
    notes.some(note => note.type === NoteType.IMPORTANT) ||
    counterNotes?.important > 0;
  return (
    <NotesTooltip
      title={getTooltipMessage(props)}
      placement="bottomRight"
      onOpenChange={isVisible =>
        isVisible && patientId && fetchPatientNotes(patientId)
      }
    >
      <Wrapper flexDirection={flexDirection}>
        <StyledCounter>{counter}</StyledCounter>
        <StyledIcon
          type="note-icon"
          color={getIconColor(counter, isImportant)}
        />
      </Wrapper>
    </NotesTooltip>
  );
};

export default Connector(
  React.memo(AlertNotes, (oldProps, newProps) => deepEqual(oldProps, newProps)),
);
