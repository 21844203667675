import { mapThresholdsArrayToState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';
import { mapBaselineThresholdsArrayToState } from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import { GenericThresholdMetrics } from 'src/types/alerts';

export const ALL_TENANTS_OPTION = 'all';

export const SETTINGS_TYPE = {
  COLLECTIVE: 'COLLECTIVE',
  UNIQUE: 'UNIQUE',
  NOT_SET: 'NOT_SET',
} as const;

export const activityAlertsArray: (keyof GenericThresholdMetrics)[] = [
  ALERT_METRIC_ENUM.BED_EXIT_FREQUENCY,
  ALERT_METRIC_ENUM.BED_TIME_BASELINE,
  ALERT_METRIC_ENUM.LONG_OUT_OF_BED,
  ALERT_METRIC_ENUM.POSITION_CHANGE,
  ALERT_METRIC_ENUM.BED_EXIT,
];

export type SubtenantSettingsType = keyof typeof SETTINGS_TYPE;

export const UNSET_THRESHOLD_STATE = mapThresholdsArrayToState([]);

export const UNSET_BASELINE_THRESHOLD_STATE = mapBaselineThresholdsArrayToState(
  [],
);
