import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { handleClickSubtenantPatientName } from 'src/routes/GMDashboard/modules/utils';
import AlertCard from '../../components/AlertCard';
import Connector, { PropsFromRedux } from './Connector';
import {
  MTMAlertCardsWrapper,
  PaginationWrapper,
  StyledText,
} from '../../styled';
import { deepEqual } from 'src/utils/comparators';
import { useGMCalculateLongOutOfBedTime } from 'src/utils/hooks/useGMCalculateLongOutOfBedTime';
import { ReloadOutlined } from '@ant-design/icons';
import {
  FieldsContainer,
  SpinnerContainer,
  StyledButtonWithIcon,
} from './styled';
import { useGetServerCalls } from './useGetServerCalls';
import Search from 'src/components/general-ui/Search';
import { Spin } from 'antd';

type Props = PropsFromRedux;

const CurrentAlerts = ({
  alertItems,
  suppressAlertType,
  fetchSubtenantData,
  unSuppressAlertType,
  clearAllPatientAlerts,
  areCurrentAlertsLoading,
  areNewAlertsFromLastSync,
  alertItemsMetadata,
}: Props): JSX.Element => {
  useGMCalculateLongOutOfBedTime();
  const {
    currentPage,
    searchQuery,
    alertsMetadata,
    onSearchChange,
    onPaginationChange,
    onRefreshHandler,
  } = useGetServerCalls(fetchSubtenantData, alertItemsMetadata);
  return (
    <>
      <FieldsContainer>
        {areNewAlertsFromLastSync && (
          <StyledButtonWithIcon
            size="small"
            type="primary"
            icon={<ReloadOutlined />}
            onClick={onRefreshHandler}
          >
            <FormattedMessage {...messages.newAlertItems} />
          </StyledButtonWithIcon>
        )}
        <Search value={searchQuery} onChange={onSearchChange} />
      </FieldsContainer>
      <MTMAlertCardsWrapper
        id={'mtmCurrentAlertsScrollableDiv'}
        data-cy={`alerts-sidebar-gm-current-alerts-loading=${areCurrentAlertsLoading.toString()}`}
      >
        {areCurrentAlertsLoading && (
          <SpinnerContainer centered>
            <Spin spinning size="large" />
          </SpinnerContainer>
        )}
        {!alertItems.length && !areCurrentAlertsLoading && (
          <StyledText>
            <FormattedMessage {...messages.emptyText} />
          </StyledText>
        )}
        {!areCurrentAlertsLoading &&
          alertItems?.map(
            ({ alertId, deviceId, patientId, tenantId, notesCount }) => (
              <AlertCard
                key={alertId}
                alertId={alertId}
                deviceId={deviceId}
                patientId={patientId}
                tenantId={tenantId}
                notesCount={notesCount}
                onPatientNameClick={() =>
                  patientId &&
                  deviceId &&
                  handleClickSubtenantPatientName(tenantId, patientId)
                }
                onSuppressAlertClick={id =>
                  suppressAlertType({ [tenantId]: id })
                }
                onUnSuppressAlertClick={id =>
                  unSuppressAlertType({ [tenantId]: id })
                }
                onDeleteAlertClick={id =>
                  clearAllPatientAlerts({ [tenantId]: id })
                }
              />
            ),
          )}
      </MTMAlertCardsWrapper>
      {!!alertsMetadata?.page?.limit && (
        <PaginationWrapper
          size={'small'}
          hideOnSinglePage={true}
          defaultCurrent={1}
          defaultPageSize={alertsMetadata?.page?.limit}
          current={currentPage}
          showSizeChanger={false}
          showLessItems={true}
          total={alertsMetadata?.page?.totalResults}
          onChange={onPaginationChange}
        />
      )}
    </>
  );
};

const messages = defineMessages({
  emptyText: {
    defaultMessage: 'No ongoing notifications at this moment.',
  },
  newAlertItems: {
    defaultMessage: 'New Items',
  },
});

export default Connector(
  React.memo(CurrentAlerts, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
