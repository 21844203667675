import { styled } from 'styled-components';
import { Radio } from 'antd';

export const RadioGroupWrap = styled(Radio.Group)`
  padding: 1.5rem 0;

  .ant-radio {
    padding-bottom: 1rem;
  }
`;
