import {
  AlertIndicatorsMessages,
  StatusMessages,
  VitalSignsMessages,
} from './messages';
import noConsentBadgeImg from './assets/NoConsentStatusBadge.png';
import deviceConnectedImg from './assets/DeviceCardWithConnectedStatus.png';
import deviceActiveAlertImg from './assets/DeviceCardWithActiveAlert.png';
import deviceSupressedAlertImg from './assets/DeviceCardWithSuppressedAlert.png';
import vitalSignsMeasurements from './assets/VitalSignsMeasurements.png';
import noAvailableMeasurements from './assets/NoAvailableMeasurements.png';
import emptyBedNoData from './assets/EmptyBedNoData.png';
import { LegendSvgIconType, LegendWithImgTag } from './types';

export const StatusIconsLegendCol1: LegendSvgIconType[] = [
  {
    description: StatusMessages.patientIsOutOfBed,
    iconType: 'bed-icon',
  },
  {
    description: StatusMessages.patientInBedMonitored,
    iconType: 'patient-in-bed-icon',
  },
  {
    description: StatusMessages.patientInBedWithBedExitSetting,
    iconType: 'patient-bed-with-bell-icon',
    height: '26',
    width: '39',
  },
  {
    description: StatusMessages.assignPatientButton,
    iconType: 'assign-patient-bordered-round',
  },
  {
    description: StatusMessages.deviceIsDisconnected,
    iconType: 'bed-icon',
    fillColor: '#fff',
  },
];

export const StatusIconsLegendCol2: LegendSvgIconType[] = [
  {
    description: StatusMessages.patientInBedActiveAlert,
    iconType: 'patient-in-bed-icon',
    fillColor: '#CA0000',
  },
  {
    description: StatusMessages.patientInBedWithSuppressed,
    iconType: 'patient-in-bed-icon',
    fillColor: '#FFAD0D',
  },
  {
    description: StatusMessages.activeBedExitAlert,
    iconType: 'bed-icon',
    fillColor: '#CA0000',
  },
  {
    description: StatusMessages.suppressededExitAlert,
    iconType: 'bed-icon',
    fillColor: '#FFAD0D',
  },
  {
    description: StatusMessages.bedExitDuration,
    iconType: 'bed-icon',
    fillColor: '#CA0000',
    alertValueExample: '2m',
  },
  {
    description: StatusMessages.bedExitFrequency,
    iconType: 'bed-exit-frequency-icon',
    alertValueExample: '5',
  },
  {
    description: StatusMessages.positionChange,
    iconType: 'position-change',
  },
];

export const VitalSignsLegend: LegendWithImgTag[] = [
  {
    description: VitalSignsMessages.noConsentStatus,
    imgSrc: noConsentBadgeImg,
  },
  {
    description: VitalSignsMessages.deviceConnected,
    imgSrc: deviceConnectedImg,
  },
  { description: VitalSignsMessages.activeAlert, imgSrc: deviceActiveAlertImg },
  {
    description: VitalSignsMessages.supressedAlert,
    imgSrc: deviceSupressedAlertImg,
  },
  {
    description: VitalSignsMessages.vitalSignsMeasurements,
    imgSrc: vitalSignsMeasurements,
  },
  {
    description: VitalSignsMessages.emptyBedNoData,
    imgSrc: emptyBedNoData,
  },
  {
    description: VitalSignsMessages.noAvailableMeasurements,
    imgSrc: noAvailableMeasurements,
  },
];

export const AlertIndicatorsLegend: LegendSvgIconType[] = [
  {
    description: AlertIndicatorsMessages.lowerThresholdAlert,
    iconType: 'down-right-arrow-icon',
    height: '16',
    width: '16',
    verticalAlign: '-3',
  },
  {
    description: AlertIndicatorsMessages.upperThresholdAlert,
    iconType: 'up-right-arrow-icon',
    height: '16',
    width: '16',
    verticalAlign: '-3',
  },
  {
    description: AlertIndicatorsMessages.LowBaselineDeviationAlert,
    iconType: 'down-trend-arrow-icon',
  },
  {
    description: AlertIndicatorsMessages.highBaselineDeviationAlert,
    iconType: 'up-trend-arrow-icon',
  },
];
