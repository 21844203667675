import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import Checkbox from 'src/components/general-ui/Checkbox';
import {
  upsertSubtenantBaselineThresholds,
  upsertSubtenantThresholds,
} from 'src/routes/GMDashboard/modules/settingsUtils';
import BaselineAlertSettingsForm, {
  TenantBaselineThresholdsState,
} from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import Connector, { Props } from './Connector';
import {
  CheckboxesWrapper,
  EmailSubscriptionWrapper,
  MedicalAlertSettingsWrapper,
  StyledAlertThresholdsForm,
  StyledWrapper,
} from './styled';
import { MTMAlertSettingsOnSaveHandlers } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/types';
import { deepEqual } from 'src/utils/comparators';
import {
  SubtenantItem,
  TenantSubscriptionDetails,
} from 'src/routes/GMDashboard/modules/types';
import { TenantThresholdsState } from 'src/components/AlertSettingsComponents/AlertThresholdsForm';

type ParentProps = {
  subscriptionDetails: TenantSubscriptionDetails;
  setSubscriptionDetails: React.Dispatch<
    React.SetStateAction<TenantSubscriptionDetails>
  >;
  intl: IntlShape;
  subtenant: SubtenantItem;
  setOnSaveHandlers: Dispatch<SetStateAction<MTMAlertSettingsOnSaveHandlers>>;
  thresholdsData: TenantThresholdsState;
  setThresholdsData: Dispatch<SetStateAction<TenantThresholdsState>>;
  baselineThresholdsData: TenantBaselineThresholdsState;
  setBaselineThresholdsData: Dispatch<
    SetStateAction<TenantBaselineThresholdsState>
  >;
};

const SubtenantCombinedSettings = ({
  subtenantId,
  subscriptionDetails,
  setSubscriptionDetails,
  createSubtenantAlertThresholds,
  editSubtenantAlertThresholds,
  createSubtenantBaselineAlertThresholds,
  editSubtenantBaselineAlertThresholds,
  setErrors,
  intl,
  setOnSaveHandlers,
  thresholdsData,
  setThresholdsData,
  baselineThresholdsData,
  setBaselineThresholdsData,
}: Props & ParentProps): JSX.Element => {
  useEffect(() => {
    setOnSaveHandlers(prevState => ({
      ...prevState,
      saveThresholds: () =>
        upsertSubtenantThresholds(
          subtenantId,
          thresholdsData,
          createSubtenantAlertThresholds,
          editSubtenantAlertThresholds,
        ),
      saveBaselineThresholds: () =>
        upsertSubtenantBaselineThresholds(
          subtenantId,
          baselineThresholdsData,
          createSubtenantBaselineAlertThresholds,
          editSubtenantBaselineAlertThresholds,
        ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholdsData, baselineThresholdsData]);

  return (
    <StyledWrapper>
      <MedicalAlertSettingsWrapper>
        <FormattedMessage {...messages.notificationInfo} />
        <CheckboxesWrapper>
          <EmailSubscriptionWrapper>
            <Checkbox
              label={intl.formatMessage(messages.email)}
              checked={subscriptionDetails.MEDICAL.EMAIL.isAssigned}
              handleChange={value =>
                setSubscriptionDetails({
                  ...subscriptionDetails,
                  MEDICAL: {
                    ...subscriptionDetails.MEDICAL,
                    EMAIL: {
                      ...subscriptionDetails.MEDICAL.EMAIL,
                      isAssigned: value,
                    },
                  },
                })
              }
            />
          </EmailSubscriptionWrapper>
          <Checkbox
            label={intl.formatMessage(messages.sms)}
            checked={subscriptionDetails.MEDICAL.SMS.isAssigned}
            handleChange={value =>
              setSubscriptionDetails({
                ...subscriptionDetails,
                MEDICAL: {
                  ...subscriptionDetails.MEDICAL,
                  SMS: {
                    ...subscriptionDetails.MEDICAL.SMS,
                    isAssigned: value,
                  },
                },
              })
            }
          />
        </CheckboxesWrapper>
      </MedicalAlertSettingsWrapper>
      <StyledAlertThresholdsForm
        thresholds={thresholdsData}
        setThresholdData={setThresholdsData}
        setHasErrors={isError =>
          setErrors(prevState => ({
            ...prevState,
            thresholds: isError,
          }))
        }
      />
      <BaselineAlertSettingsForm
        baselineThresholds={baselineThresholdsData}
        setBaselineThresholdData={setBaselineThresholdsData}
        setHasErrors={isError =>
          setErrors(prevState => ({
            ...prevState,
            baselineThresholds: isError,
          }))
        }
      />
    </StyledWrapper>
  );
};

const messages = defineMessages({
  notificationInfo: {
    defaultMessage: 'Send me notification by:',
  },
  email: {
    defaultMessage: 'Email',
  },
  sms: {
    defaultMessage: 'SMS',
  },
});

export default Connector(
  injectIntl(
    React.memo(SubtenantCombinedSettings, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
