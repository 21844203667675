import { defineMessages } from 'react-intl';

export const ToolTipMessages = defineMessages({
  alertSettings: {
    defaultMessage: 'Configure all types of Notifications',
  },
  currentAlertsTab: {
    defaultMessage: 'Ongoing active Notifications',
  },
  eventLogTab: {
    defaultMessage: 'Inactive Notifications and events from the last 24 hours',
  },
  patientNameAlertCard: {
    defaultMessage:
      'Ongoing active Notifications. Clicking the patient name will access its Individual dashboard for further review',
  },
  attentionListAlertCounter: {
    defaultMessage:
      'Number of notifications and events for the selected patient in the previous 24 hours',
  },
  MTMalertSettingsTenantCard: {
    defaultMessage:
      'Configure notification settings that will apply only for this selected tenant',
  },
  MtMtenantCardsTenantName: {
    defaultMessage: 'Clicking the tenant name will access its Nurses Station',
  },
});
