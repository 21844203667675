import { styled } from 'styled-components';

export const Wrapper = styled.div`
  text-align: start;
  padding: 0rem 2.5rem 2rem 2.5rem;
  font-size: 0.75rem;
`;

export const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 4rem;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const AlertDetails = styled.div`
  white-space: pre-wrap;
`;

export const SpacedLines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
