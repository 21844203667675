import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import Checkbox from 'src/components/general-ui/Checkbox';
import { CheckboxContainer } from 'src/routes/NursesStation/components/MonitoredPersons/styled';
import { MonitoredPatient } from 'src/routes/NursesStation/components/MonitoredPersons/types';
import Connector, { PropsFromRedux } from './ConsentConnector';
import { patientPageColumnsMessages } from './constants';
import { deepEqual } from 'src/utils/comparators';

type Props = PropsFromRedux & {
  intl: IntlShape;
  record: MonitoredPatient;
};
const PatientConsent = ({ intl, record, updatePatient }: Props) => {
  const [isPatientConsentModalVisible, setIsPatientConsentModalVisible] =
    useState(false);
  return (
    <>
      <CheckboxContainer>
        <Checkbox
          disabled={record.isConsentBlocked}
          checked={record?.isConsented ?? false}
          handleChange={() => setIsPatientConsentModalVisible(true)}
        />
        {record?.isConsented && (
          <FormattedMessage {...patientPageColumnsMessages.yesLabel} />
        )}
        {!record?.isConsented && (
          <FormattedMessage {...patientPageColumnsMessages.noLabel} />
        )}
      </CheckboxContainer>
      <ConfirmationModal
        isModalVisible={isPatientConsentModalVisible}
        setIsModalVisible={(value: boolean) =>
          setIsPatientConsentModalVisible(value)
        }
        onOk={() => {
          updatePatient({
            id: record.id,
            data: { isConsented: !record.isConsented },
          });
          setIsPatientConsentModalVisible(false);
        }}
        onCancel={() => setIsPatientConsentModalVisible(false)}
        message={intl.formatMessage(
          patientPageColumnsMessages.updateConsentDescription,
        )}
        title={intl.formatMessage(
          patientPageColumnsMessages.updateConsentTitle,
        )}
      />
    </>
  );
};
export default Connector(
  injectIntl(
    React.memo(PatientConsent, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
