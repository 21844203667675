import React, { useEffect, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { styled } from 'styled-components';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Tabs } from 'antd';

import RoomsAndBeds from './components/RoomsAndBeds';
import MonitoredPersons from './components/MonitoredPersons';
import SummaryPanel from './components/SummaryPanel';
import { NURSE_STATION_TABS } from './modules/tabConfig';
import { NurseStationTabKey } from './modules/types';
import Connector, { PropsFromRedux } from './Connector';
import { useDocumentVisibility } from 'src/utils/hooks/useDocumentVisibility';

const Wrapper = styled.div`
  height: 100%;
  font-family: ${props => props.theme.fonts.primary};
  background: #eff8f9;
  color: #252525;
  padding: 1.4rem 1.25rem;
`;

const StyledTabs = styled(Tabs)`
  position: relative;
  height: 90%;
  margin-top: 1rem;

  .ant-tabs-content-holder {
    height: 100%;
    overflow-y: auto;
  }

  .ant-tabs-content {
    position: initial;
  }

  .ant-tabs-tab {
    padding: 0.5rem 0rem;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #252525;
  }
  .ant-tabs-tab-btn {
    font-family: ${props => props.theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 0.65rem;
    color: #252525;
    padding: 0rem 0.75rem;
  }

  .ant-tabs-ink-bar {
    background: #252525;
  }
`;

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const onChange = (
  key: string,
  handler: ActionCreatorWithPayload<NurseStationTabKey>,
) => {
  if (key !== 'rooms-and-beds' && key !== 'monitored-persons') {
    return;
  }

  return handler(key);
};

const NurseStationPage = ({
  activeTab,
  changeActiveTab,
  nurseStationPageMounted,
  fetchPatientsSessions,
  intl,
}: Props): JSX.Element => {
  const isDocumentVisible = useDocumentVisibility();
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);

  useEffect(() => {
    if (!isDocumentVisible) {
      return;
    }
    nurseStationPageMounted();
    fetchPatientsSessions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentVisible]);

  return (
    <Wrapper>
      <SummaryPanel />
      <StyledTabs
        destroyInactiveTabPane
        activeKey={activeTab}
        onChange={key => onChange(key, changeActiveTab)}
        items={NURSE_STATION_TABS.map(({ key, labelMessage }) => ({
          key: key,
          label: <span>{intl.formatMessage(labelMessage)}</span>,
          children:
            key === 'rooms-and-beds' ? (
              <RoomsAndBeds />
            ) : (
              <MonitoredPersons
                setSearchKeyword={setSearchKeyword}
                searchKeyword={searchKeyword}
              />
            ),
        }))}
      />
    </Wrapper>
  );
};

export default Connector(injectIntl(NurseStationPage));
