import React, { useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { deepEqual } from 'src/utils/comparators';
import { useDetectClickOutside } from '../hooks';
import {
  PatientSettingsTooltip,
  StyledCheckboxText,
  StyledContainer,
  StyledLocationData,
  StyledLocationInfoContainer,
  StyledLocationValue,
  StyledOpenBtn,
  StyledWrapper,
} from './styled';
import Connector, { Props } from './Connector';
import Checkbox from 'src/components/general-ui/Checkbox';
import AccessControl from 'src/components/AccessControl';
import permissions from 'src/permissions';
import { NurseStationDevice } from 'src/routes/NursesStation/modules/types';

type LocationProps = Pick<NurseStationDevice, 'roomName' | 'bedName'>;
const PatientSettings = ({
  children,
  patientId,
  isVacancyAlertActive,
  isPositionChangeActive,
  setPatientVacancyAlertEnabled,
  alertsToggleSettings,
  roomName,
  bedName,
}: Props & LocationProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useDetectClickOutside(ref, isVisible, () => setIsVisible(false));
  const renderTooltipContent = () => (
    <StyledWrapper ref={ref}>
      <StyledLocationInfoContainer>
        <StyledLocationData
          ellipsis={{
            rows: 1,
            tooltip: roomName,
          }}
        >
          <FormattedMessage {...messages.roomLabel} />
          <StyledLocationValue>{roomName}</StyledLocationValue>
        </StyledLocationData>
        <StyledLocationData
          ellipsis={{
            rows: 1,
            tooltip: bedName,
          }}
        >
          <FormattedMessage {...messages.bedLabel} />
          <StyledLocationValue>{bedName}</StyledLocationValue>
        </StyledLocationData>
      </StyledLocationInfoContainer>
      <StyledContainer>
        <AccessControl
          permissions={[permissions.ALERT_SETTINGS_PATIENTS_ALERT_TYPES_UPDATE]}
        >
          <Checkbox
            disabled={!alertsToggleSettings.bedExit}
            checked={isVacancyAlertActive}
            handleChange={value =>
              setPatientVacancyAlertEnabled({
                patientId,
                isBedExitAlertEnabled: value,
                isPositionChangeEnabled: isPositionChangeActive,
              })
            }
          />
        </AccessControl>
        <StyledCheckboxText>
          <FormattedMessage {...messages.vacancyNotification} />
        </StyledCheckboxText>
      </StyledContainer>
      <StyledContainer>
        <AccessControl
          permissions={[permissions.ALERT_SETTINGS_PATIENTS_ALERT_TYPES_UPDATE]}
        >
          <Checkbox
            disabled={!alertsToggleSettings.positionChange}
            checked={isPositionChangeActive}
            handleChange={value =>
              setPatientVacancyAlertEnabled({
                patientId,
                isPositionChangeEnabled: value,
                isBedExitAlertEnabled: isVacancyAlertActive,
              })
            }
          />
        </AccessControl>
        <StyledCheckboxText>
          <FormattedMessage {...messages.positionChangeNotification} />
        </StyledCheckboxText>
      </StyledContainer>
    </StyledWrapper>
  );

  return (
    <PatientSettingsTooltip
      title={renderTooltipContent()}
      placement="bottom"
      open={isVisible}
    >
      <StyledOpenBtn onClick={() => setIsVisible(true)}>
        {children}
      </StyledOpenBtn>
    </PatientSettingsTooltip>
  );
};

const messages = defineMessages({
  vacancyNotification: {
    defaultMessage: 'Bed Exit',
  },
  positionChangeNotification: {
    defaultMessage: 'Position Change',
  },
  roomLabel: {
    defaultMessage: 'Room',
  },
  bedLabel: {
    defaultMessage: 'Bed',
  },
});

const MemoisedPatientSettings = React.memo(
  PatientSettings,
  (oldProps, newProps) => deepEqual(oldProps, newProps),
);

export default Connector(MemoisedPatientSettings);
