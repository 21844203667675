import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import {
  selectors as gmDashboardSelectors,
  actions as gmDashboardActions,
} from 'src/routes/GMDashboard';
import {
  actions as gmActions,
  selectors as gmSelectors,
} from 'src/redux/data/groupManager';
import { selectors as sessionSelectors } from 'src/redux/data/sessions';
import { selectors as patientSelectors } from 'src/redux/data/patient';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import dataSelectors from 'src/redux/data/dataSelectors';
import { UUID } from 'src/types/utility';
import { DisconnectionOverviewTableItem } from './types';
import {
  displayInTimezone,
  US_FORMAT_WITHOUT_SECONDS,
} from 'src/utils/timeUtils';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { extractLatestDeviceSession } from 'src/redux/data/dataUtils';
import dayjs from 'dayjs';
import { API_STATUS } from 'src/utils/api-constants';

type OwnProps = {
  subtenantId: UUID;
};

const mapStateToProps = (state: RootState, { subtenantId }: OwnProps) => {
  const subtenant = tenantSelectors.getTenant(state, subtenantId);
  const devicesDisconnectionOverview =
    gmDashboardSelectors.selectSubtenantDisconnectionOverview(
      state,
      subtenantId,
    );
  const sessions = sessionSelectors.selectSessions(state);
  const sessionDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.SESSIONS,
  );
  const subtenantSessions = sessions.filter(
    session => sessionDict[session.id] === subtenantId,
  );

  const patients = patientSelectors.getPatientsList(state);
  const patientsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.PATIENTS,
  );
  const subtenantPatients = patients.filter(
    patient => patientsDict[patient.id] === subtenantId,
  );

  const deviceDisconnectionTableItems: DisconnectionOverviewTableItem[] =
    devicesDisconnectionOverview.map(device => {
      const { roomName = '', bedName = '' } =
        dataSelectors.selectDeviceRoomAndBed(state, device.manufacturerId) ||
        {};

      const deviceLastSession = extractLatestDeviceSession(
        device.manufacturerId,
        subtenantSessions,
      );
      const hasLastSessionClosed = deviceLastSession
        ? !!deviceLastSession.endTime
        : true;

      const deviceLastPatient =
        deviceLastSession && !hasLastSessionClosed
          ? subtenantPatients.find(
              patient => patient.id === deviceLastSession.patientId,
            )
          : undefined;

      return {
        key: device.manufacturerId,
        deviceId: device.manufacturerId,
        tenantId: subtenantId,
        patientId: deviceLastPatient?.id,
        patientName: deviceLastPatient?.fullName || '',
        patientIsConsented: deviceLastPatient?.isConsented ?? false,
        deviceStatus: device.patientAssignmentStatus,
        deviceLocation: `${roomName}${bedName}`,
        roomName,
        bedName,
        lastConnectionTime:
          device.lastConnectedAt &&
          dayjs(device.lastConnectedAt).utc(true).unix()
            ? displayInTimezone(
                device.lastConnectedAt,
                subtenant?.timeZoneId || '',
                US_FORMAT_WITHOUT_SECONDS,
              )
            : 'N/A',
      };
    });

  const isDataLoading =
    gmSelectors.selectDataStatus(state, GM_DATA_KEYS.SESSIONS) ===
      API_STATUS.LOADING ||
    gmSelectors.selectDataStatus(state, GM_DATA_KEYS.PATIENTS) ===
      API_STATUS.LOADING;

  return {
    deviceDisconnectionTableItems,
    sessions: sessionSelectors.selectSessions(state),
    devicesDisconnectionOverview:
      gmDashboardSelectors.selectSubtenantDisconnectionOverview(
        state,
        subtenantId,
      ),
    devicesDisconnectionOverviewMetadata:
      gmDashboardSelectors.selectSubtenantDisconnectionOverviewMetadata(
        state,
        subtenantId,
      ),
    isDataLoading,
  };
};

const mapActionCreators = {
  fetchSubtenantDisconnectionOverview:
    gmDashboardActions.fetchSubtenantDisconnectionOverview,
  fetchSubtenantData: gmActions.fetchSubtenantData,
  fetchMultitenantPatients: gmActions.fetchMultitenantPatients,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type IProps = OwnProps & PropsFromRedux;

export default Connector;
