import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Note, NoteType } from 'src/types/notes';
import { SerialNumber, UUID } from 'src/types/utility';
import {
  FooterWrapper,
  IconContainer,
  InputWrapper,
  RadioBtn,
  RadioBtnLabel,
  RadioGroup,
  StyledIcon,
  StyledInput,
  Wrapper,
} from './styled';
import { CreateNotePayload } from 'src/services/types';

type Props = {
  deviceId: SerialNumber;
  patientId: UUID;
  tenantId: UUID;
  createNote: (payload: CreateNotePayload) => void;
  intl: IntlShape;
};

type IFormInput = Pick<Note, 'note' | 'type'>;

const NotesForm = ({
  createNote,
  deviceId,
  patientId,
  tenantId,
  intl,
}: Props): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const currentId = Math.random().toFixed(20);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IFormInput>();
  const { ref, ...rest } = register('note', { required: true, maxLength: 120 });

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus();
    }
  }, [isActive]);

  const onEnableInput = () => {
    if (isActive) {
      return;
    }

    setIsActive(true);
  };

  const onSubmit: SubmitHandler<IFormInput> = data => {
    createNote({ ...data, patientId, deviceId, tenantId });
    reset();
    setIsActive(false);
  };

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      e.preventDefault();

      handleSubmit(onSubmit)(e).catch(errors => console.error(errors));
    }
  };

  return (
    <Wrapper isActive={isActive} onClick={() => onEnableInput()}>
      <InputWrapper isActive={isActive} isInvalid={!!errors.note}>
        <StyledInput
          type="text"
          isActive={isActive}
          placeholder={intl.formatMessage(messages.placeholder)}
          onKeyDown={checkKeyDown}
          {...rest}
          ref={nodeRef => {
            inputRef.current = nodeRef;
            ref(nodeRef);
          }}
        />
        {!isActive && (
          <IconContainer isActive={isActive}>
            <StyledIcon type="up-arrow-icon" />
          </IconContainer>
        )}
      </InputWrapper>
      {isActive && (
        <FooterWrapper>
          <RadioGroup>
            <RadioBtn
              {...register('type', { required: true })}
              type="radio"
              value={NoteType.GENERAL}
              id={`note-general-${currentId}`}
              defaultChecked={true}
            />
            <RadioBtnLabel htmlFor={`note-general-${currentId}`}>
              <FormattedMessage {...messages.general} />
            </RadioBtnLabel>
            <RadioBtn
              {...register('type', { required: true })}
              type="radio"
              value={NoteType.IMPORTANT}
              id={`note-important-${currentId}`}
            />
            <RadioBtnLabel htmlFor={`note-important-${currentId}`}>
              <FormattedMessage {...messages.important} />
            </RadioBtnLabel>
          </RadioGroup>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <IconContainer isActive={isActive} onClick={handleSubmit(onSubmit)}>
            <StyledIcon type="up-arrow-icon" />
          </IconContainer>
        </FooterWrapper>
      )}
    </Wrapper>
  );
};

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Add a note...',
  },
  important: {
    defaultMessage: 'Important',
  },
  general: {
    defaultMessage: 'General',
  },
});

export default injectIntl(NotesForm);
