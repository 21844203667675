import React from 'react';
import { styled } from 'styled-components';
import { Layout } from 'antd';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { media } from 'src/utils/mediaQueryUtils';
import Logo from 'src/components/general-ui/Logo';

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  color: ${props => props.theme.colors.defaultTextColor};
`;

const MainContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 24px;
  background-color: ${props => props.theme.colors.layoutBackground};
  min-height: 500px;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 450px;
  min-height: 450px;
  width: 55vw;
  background-color: white;
  box-shadow: 7px 7px 11px 0px ${props => props.theme.colors.shadowColor};
  border-radius: 5px;
  overflow-y: auto;
  text-align: center;

  ${media.extraSmall`
    padding: 5%;
    min-width: 20px;
    min-height: 20px;
    width: 90%;
  `}
  ${media.small`
    padding: 1%;
    width: 50%;
    max-width: 600px;
  `}
`;

const StyledLogo = styled(Logo)``;

const CenteredContent = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
`;

const StylesTitle = styled.div`
  font-size: 26px;
  ${media.extraSmall`
    font-size: 20px;
  `}
`;

type Props = {
  children: React.ReactNode;
  title: MessageDescriptor;
  displayTitle?: boolean;
};

const AuthLayout = ({ children, title, displayTitle }: Props): JSX.Element => (
  <StyledLayout>
    <MainContent>
      <ContentBox data-cy="auth-layout-content">
        <StyledLogo data-cy="auth-layout-logo" maxWidth="200px" />
        <CenteredContent>
          {displayTitle && (
            <StylesTitle data-cy="auth-layout-title">
              {title && (
                <FormattedMessage
                  {...title}
                  values={{
                    br: <br />,
                  }}
                />
              )}
            </StylesTitle>
          )}
          {children}
        </CenteredContent>
      </ContentBox>
    </MainContent>
  </StyledLayout>
);

export default AuthLayout;
