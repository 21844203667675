import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import Modal from 'src/components/Modal';
import { styled } from 'styled-components';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import AlertModal from 'src/components/Modal/AlertModal';
import DeviceFieldSelect from 'src/components/DeviceFieldSelect';
import { hasAllPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';
import Spacer from 'src/components/Spacer';
import {
  NEW_BED_ID,
  NEW_ROOM_ID,
} from 'src/redux/data/rooms/modules/constants';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const DeviceHeader = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: ${props => props.theme.colors.successText};
`;
export const deviceModalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

const DeviceModal = ({
  isModalVisible,
  setIsModalVisible,
  onOk,
  mode,
  modalStatus,
  setModalStatus,
  intl,
  deviceListRow,
  roomsList,
}) => {
  const [form] = Form.useForm();
  const [selectedRoomId, setSelectedRoomId] = useState('');
  const [selectedBedId, setSelectedBedId] = useState('');
  const [bedOptions, setBedOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [newRoom, setNewRoom] = useState('');
  const [newBed, setNewBed] = useState('');
  const [
    isReAssignDeviceConfirmationVisible,
    setIsReAssignDeviceConfirmationVisible,
  ] = useState(false);
  const [isBedNotSelected, setIsBedNotSelected] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
    }
  }, [isModalVisible, form]);

  useEffect(() => {
    if (roomsList.length > 0) {
      setRoomOptions(roomsList);
    }
    if (deviceListRow?.roomBed?.bed?.name !== undefined) {
      const room = roomOptions.find(
        r => r.id === deviceListRow?.roomBed?.room.id,
      );
      setSelectedRoomId(room.value);
      setSelectedBedId(deviceListRow?.roomBed?.bed?.id);
      setBedOptions(room.beds);
    } else {
      setSelectedRoomId('');
      setSelectedBedId('');
      setBedOptions([]);
    }
  }, [deviceListRow, roomsList]);

  const modeToPropsMap = {
    [deviceModalModes.ADD]: {
      title: intl.formatMessage(messages.addDevice),
      okButtonText: intl.formatMessage(messages.add),
      initialValues: {},
    },
    [deviceModalModes.EDIT]: {
      title: intl.formatMessage(messages.editDevice),
      okButtonText: intl.formatMessage(messages.apply),
      initialValues: {
        name: deviceListRow?.name,
        manufacturerId: deviceListRow?.manufacturerId,
        room: deviceListRow?.roomBed?.room.id,
        bed: deviceListRow?.roomBed?.bed,
      },
    },
  };

  useEffect(() => {
    if (!modeToPropsMap[mode].initialValues) {
      return;
    }
    form.setFieldsValue(modeToPropsMap[mode].initialValues);
  }, [modeToPropsMap]);

  const onChangeRoom = room => {
    setSelectedRoomId(room);
    setBedOptions(roomOptions.find(r => r.value === room)?.beds || []);
    setNewBed('');
    setSelectedBedId('');
  };
  const addRoom = e => {
    e.preventDefault();
    if (newRoom.localeCompare('') === 0) return;

    setRoomOptions([...roomsList, { label: newRoom, value: NEW_ROOM_ID }]);
    onChangeRoom(NEW_ROOM_ID);
    setNewRoom('');
  };

  const setRoomInput = event => {
    setNewRoom(event.target.value);
  };

  const onChangeBed = bed => {
    setSelectedBedId(bed);
    if (bedOptions.find(b => b.value === bed)?.deviceId) {
      setIsReAssignDeviceConfirmationVisible(true);
    }
  };

  const addBed = e => {
    e.preventDefault();
    if (newBed.localeCompare('') === 0) return;
    setBedOptions([
      ...(roomOptions.find(r => r.value === selectedRoomId)?.beds || []),
      { label: newBed, value: NEW_BED_ID, deviceId: undefined },
    ]);
    onChangeBed(NEW_BED_ID);
    setNewBed('');
  };

  const setBedInput = event => {
    setNewBed(event.target.value);
  };

  const onFinish = values => {
    const room = roomOptions.find(r => r.value === selectedRoomId);
    const bed = bedOptions.find(b => b.value === selectedBedId);
    onOk({ ...values, room, bed });
    setIsModalVisible(false);
  };

  const canEditLocation = () =>
    hasAllPermissions(permissions.VIEW_ROOMS_DEVICE_MODAL);
  return (
    <>
      <Modal
        title={modeToPropsMap[mode].title}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        okButtonText={modeToPropsMap[mode].okButtonText}
        onOk={() => {
          if (selectedRoomId && !selectedBedId) {
            setIsBedNotSelected(true);
          } else form.submit();
        }}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={modeToPropsMap[mode].initialValues}
          validateMessages={deviceFormValidateMessages}
        >
          <Form.Item
            name="manufacturerId"
            label={intl.formatMessage(messages.manufacturerIdLabel)}
            rules={[
              {
                required: true,
                pattern: new RegExp('^\\S*$'),
                message: intl.formatMessage(messages.manufacturerIdError),
              },
            ]}
          >
            <Input
              disabled={mode !== deviceModalModes.ADD}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="name"
            label={intl.formatMessage(messages.nameFieldLabel)}
          >
            <Input autoComplete="off" />
          </Form.Item>
          {canEditLocation() ? (
            <>
              <StyledHeader>
                <DeviceHeader>
                  <FormattedMessage {...messages.location} />
                </DeviceHeader>
                <Spacer flex={'1 0 0.3rem'} />
              </StyledHeader>
              <Form.Item
                name="room"
                label={intl.formatMessage(messages.roomLabel)}
              >
                <DeviceFieldSelect
                  addNew={addRoom}
                  options={roomOptions}
                  onChange={onChangeRoom}
                  title={intl.formatMessage(messages.addRoom)}
                  newValue={newRoom}
                  onChangeInput={setRoomInput}
                  selectedValue={selectedRoomId}
                />
              </Form.Item>
              <Form.Item
                name="bed"
                label={intl.formatMessage(messages.bedLabel)}
              >
                <DeviceFieldSelect
                  disabled={selectedRoomId === ''}
                  addNew={addBed}
                  options={bedOptions}
                  onChange={onChangeBed}
                  title={intl.formatMessage(messages.addBed)}
                  newValue={newBed}
                  onChangeInput={setBedInput}
                  selectedValue={selectedBedId}
                />
              </Form.Item>
            </>
          ) : (
            <></>
          )}
        </Form>
      </Modal>
      <ConfirmationModal
        isModalVisible={isReAssignDeviceConfirmationVisible}
        setIsModalVisible={value => {
          setIsReAssignDeviceConfirmationVisible(value);
        }}
        onOk={() => {}}
        onCancel={() => {
          setSelectedBedId(null);
        }}
        message={intl.formatMessage(messages.reAssignBedMessage)}
      />
      <AlertModal
        isModalVisible={isBedNotSelected}
        setIsModalVisible={value => {
          setIsBedNotSelected(value);
        }}
        onOk={() => {
          setIsBedNotSelected(false);
        }}
        message={intl.formatMessage(messages.bedMustBeSelected)}
      />
    </>
  );
};

const messages = defineMessages({
  addDevice: {
    defaultMessage: 'Add Device',
  },
  add: {
    defaultMessage: 'Add',
  },
  editDevice: {
    defaultMessage: 'Edit Device',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  nameFieldLabel: {
    defaultMessage: 'Name',
  },
  manufacturerIdLabel: {
    defaultMessage: 'Manufacturer Id',
  },
  manufacturerIdError: {
    defaultMessage: 'No spaces allowed',
  },
  roomLabel: {
    defaultMessage: 'Room',
  },
  addRoom: {
    defaultMessage: 'Add new Room',
  },
  bedLabel: {
    defaultMessage: 'Bed',
  },
  addBed: {
    defaultMessage: 'Add new Bed',
  },
  reAssignBedMessage: {
    defaultMessage:
      'This bed is Assigned to another device, are you sure you want to reassign it?',
  },
  location: {
    defaultMessage: 'Location',
  },
  bedMustBeSelected: {
    defaultMessage:
      'Please select Room and Bed. Bed must be selected for device location',
  },
});

/* eslint-disable no-template-curly-in-string */
export const deviceFormValidateMessages = {
  required: '${label} is required!',
};
/* eslint-enable no-template-curly-in-string */

DeviceModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  deviceListRow: PropTypes.object,
  roomsList: PropTypes.array,
};

DeviceModal.defaultProps = {
  deviceListRow: {},
  roomsList: [],
};

export default injectIntl(DeviceModal);
