import { Alert, AlertLog, OccupancyAlert } from 'src/types/alerts';
import { convertToTimezone } from 'src/utils/timeUtils';
import dayjs from 'dayjs';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import { ALERT_STATUS_ENUM } from 'src/utils/constants';
import { defineMessages } from 'react-intl';

// update long out of bed triggerValue
export function getAlertLogsUpdated(
  alertLogs: AlertLog[] | OccupancyAlert[] | Alert[],
  timezone: string,
) {
  return alertLogs.map(eachAlert => {
    if (
      (eachAlert?.thresholdMetric !== ALERT_METRIC_ENUM.LONG_OUT_OF_BED &&
        eachAlert?.thresholdMetric !== ALERT_METRIC_ENUM.POSITION_CHANGE) ||
      !eachAlert.firstMeasurementTime
    ) {
      return { ...eachAlert };
    }
    const correctedFirstMeasurementTime = convertToTimezone(
      eachAlert.firstMeasurementTime ?? '',
      timezone,
    );
    const correctedEndTime =
      eachAlert.status === ALERT_STATUS_ENUM.OFF
        ? convertToTimezone(eachAlert.endTime ?? '', timezone)
        : convertToTimezone(dayjs(), timezone);
    return {
      ...eachAlert,
      triggerValue: correctedEndTime.diff(
        correctedFirstMeasurementTime,
        eachAlert?.thresholdMetric === ALERT_METRIC_ENUM.LONG_OUT_OF_BED
          ? 'minutes'
          : 'hours',
      ),
    };
  });
}

export const getLongOutOfBedTriggerValue = (triggerValue: number | null) =>
  triggerValue && triggerValue >= 60
    ? parseInt(String(triggerValue / 60))
    : triggerValue ?? 0;

export const getLongOutOfBedSuffix = (triggerValue: number) => {
  if (triggerValue === 60) {
    return messages.hour;
  }
  if (triggerValue > 60) {
    return messages.hours;
  }
  if (triggerValue === 1) {
    return messages.minute;
  }
  return messages.minutes;
};

const messages = defineMessages({
  hours: {
    defaultMessage: 'hours',
  },
  hour: {
    defaultMessage: 'hour',
  },
  minute: {
    defaultMessage: 'minute',
  },
  minutes: {
    defaultMessage: 'minutes',
  },
});
