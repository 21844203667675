import { styled } from 'styled-components';

interface TextWrapperProps {
  inactive: boolean;
}

export const StyledWrapper = styled.div`
  height: fit-content;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  margin: 0.75rem 0;
`;

export const StyledTextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.6rem;
  opacity: ${props => (props.inactive ? '0.6' : '1')};

  .baseline-checkbox {
    margin-right: 0.3rem;
  }
`;

export const StyledInputNumber = styled.input<{
  isError?: boolean;
  width?: string | number;
}>`
  width: 1.5rem;
  height: 1rem;
  padding: 0 0.25rem;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: center;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #000;
  ${props => (props.isError ? 'border: 1px solid #ca0000;' : '')}

  &:focus-within {
    box-shadow: none;
  }
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
