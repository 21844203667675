import React from 'react';

import { SVGProps } from '../types';

const PositionChange = ({
  width = '32',
  height = '26',
  color = '#CA0000',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M12.4922 6.81805C10.975 5.48341 11.0019 3.39146 12.578 2.0925C13.1958 1.5812 13.999 1.24702 14.8678 1.13982L14.8215 0C13.6018 0.120501 12.4678 0.575232 11.6042 1.29018C9.49212 3.02982 9.46663 5.83902 11.5217 7.61817L10.354 8.57963L14.0496 8.74482L14.0395 5.54323L12.4922 6.81805ZM16.9504 0.255183L16.9604 3.45677L18.5077 2.1825C20.0249 3.51823 19.998 5.61018 18.4219 6.90805C17.8043 7.41948 17.001 7.75368 16.1321 7.86073L16.1784 9C17.3981 8.87935 18.5322 8.42487 19.3964 7.71036C21.5078 5.96963 21.5333 3.16043 19.4782 1.38238L20.6459 0.419817L16.9504 0.255183Z"
      fill={color}
    />
    <path
      d="M30.7289 17.745C31.4242 10.0443 14.7529 11.0607 10.5 11.0283V17.75H30.7289V17.745Z"
      fill={color}
    />
    <path
      d="M5.47668 16.7048C3.90134 16.7048 2.62427 15.4278 2.62427 13.8524C2.62427 12.2771 3.90134 11 5.47668 11C7.05203 11 8.3291 12.2771 8.3291 13.8524C8.3291 15.4278 7.05203 16.7048 5.47668 16.7048Z"
      fill={color}
    />
    <path
      d="M0.742268 26C1.15206 26 1.48454 25.71 1.48454 25.3525V22.4029H30.5155V25.3525C30.5155 25.71 30.8479 26 31.2577 26C31.6675 26 32 25.71 32 25.3525V11.6835C32 11.326 31.6675 11.036 31.2577 11.036C30.8479 11.036 30.5155 11.326 30.5155 11.6835V17.6331H1.48454V6.64748C1.48454 6.29002 1.15206 6 0.742268 6C0.332474 6 0 6.29002 0 6.64748V25.3525C0 25.71 0.332474 26 0.742268 26ZM1.48454 21.1079V20.2446H30.5155V21.1079H1.48454Z"
      fill={color}
    />
  </svg>
);

export default PositionChange;
