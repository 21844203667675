import * as R from 'fp-ts/lib/Record';

import { BaselineAlertThreshold, BaselineMetric } from 'src/types/alerts';
import {
  EditableBaselineSetting,
  EditableBaselineThreshold,
  TenantBaselineThresholdsState,
  ThresholdsErrorState,
} from './types';
import {
  BASELINE_DAYS_INTERVAL,
  BASELINE_DEVIATION_HOURS,
  BASELINE_DEVIATION_PERCENTAGE,
} from './constants';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';

const inRange = (
  thresholdValue: number,
  interval: { min: number; max: number },
) => (thresholdValue - interval.min) * (thresholdValue - interval.max) <= 0;

export const checkBaselineMetricValid = (
  metric: BaselineMetric,
  thresholdKey: EditableBaselineSetting,
  thresholdValue: number,
): boolean => {
  if (metric === ALERT_METRIC_ENUM.HR_BASELINE) {
    if (thresholdKey === 'baselineDaysInterval') {
      return !inRange(thresholdValue, BASELINE_DAYS_INTERVAL);
    }
    if (thresholdKey === 'deviationPercentage') {
      return !inRange(thresholdValue, BASELINE_DEVIATION_PERCENTAGE);
    }
    if (thresholdKey === 'deviationHoursInterval') {
      return !inRange(thresholdValue, BASELINE_DEVIATION_HOURS);
    }
  }
  if (metric === ALERT_METRIC_ENUM.RR_BASELINE) {
    if (thresholdKey === 'baselineDaysInterval') {
      return !inRange(thresholdValue, BASELINE_DAYS_INTERVAL);
    }
    if (thresholdKey === 'deviationPercentage') {
      return !inRange(thresholdValue, BASELINE_DEVIATION_PERCENTAGE);
    }
    if (thresholdKey === 'deviationHoursInterval') {
      return !inRange(thresholdValue, BASELINE_DEVIATION_HOURS);
    }
  }
  return false;
};

export const mapValidationErrors = (
  thresholdState: TenantBaselineThresholdsState,
): ThresholdsErrorState => {
  const validationMap = R.mapWithIndex(
    (metric: BaselineMetric, threshold: EditableBaselineThreshold) => {
      // @ts-ignore filterWithIndex don't recognize type
      const filteredEditableThresholds: Record<string, number> =
        R.filterWithIndex(index =>
          [
            'baselineDaysInterval',
            'deviationPercentage',
            'deviationHoursInterval',
          ].includes(index),
        )(threshold);

      return R.mapWithIndex(
        (thresholdKey: EditableBaselineSetting, thresholdValue: number) => {
          if (!threshold.enable) return false;
          return checkBaselineMetricValid(metric, thresholdKey, thresholdValue);
        },
      )(filteredEditableThresholds);
    },
  )(thresholdState);

  return validationMap;
};

const defaultBaselineThreshold = ({
  metric,
}: Pick<BaselineAlertThreshold, 'metric'>): BaselineAlertThreshold => ({
  id: '',
  creationTime: '',
  lastModifiedTime: '',
  enable: false,
  metric,
  baselineDaysInterval: 0,
  deviationPercentage: 0,
  deviationHoursInterval: 0,
});

const findThresholdWithDefault = (
  baselineThresholds: BaselineAlertThreshold[],
  metric: BaselineAlertThreshold['metric'],
): EditableBaselineThreshold => ({
  ...(baselineThresholds.find(t => t.metric === metric) ||
    defaultBaselineThreshold({ metric })),
  dirty: false,
});

export const mapBaselineThresholdsArrayToState = (
  baselineThresholds: BaselineAlertThreshold[],
): TenantBaselineThresholdsState => ({
  HR_BASELINE: findThresholdWithDefault(baselineThresholds, 'HR_BASELINE'),
  RR_BASELINE: findThresholdWithDefault(baselineThresholds, 'RR_BASELINE'),
});
