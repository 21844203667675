import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { styled } from 'styled-components';
import { Form, Input, Select, DatePicker, FormInstance } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import {
  defaultFormFieldsRulesArray as defaultRulesArray,
  emailPattern,
} from 'src/utils/constants';
import { PatientWithFullName } from 'src/types/patients';
import {
  addPatientFormFields,
  addPatientFormFieldsLabelsMessages as labelMessages,
  addPatientFormFieldsPlaceholdersMessages as placeholderMessages,
  genderOptions,
  patientFormDateFormat,
  patientFormValidateMessages,
} from './constants';

const { Option } = Select;

const FormMultipleItems = styled.div`
  display: flex;
  place-content: space-between;
  .ant-row {
    width: 100%;
    :not(:last-of-type) {
      margin-right: 8px;
    }
  }
`;

type Props = {
  form: FormInstance<PatientWithFullName>;
  patient?: PatientWithFullName;
  onSubmit: (values: PatientWithFullName) => void;
  intl: IntlShape;
};

const PatientForm = ({ form, onSubmit, patient, intl }: Props): JSX.Element => {
  useEffect(() => {
    form.resetFields(); // In order for the initial values of the form to be updated with the details of the current patient
  }, [patient, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={values => {
        onSubmit({
          ...values,
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          patientId: values.patientId.trim(),
          dateOfBirth: dayjs(values.dateOfBirth).format(patientFormDateFormat),
        });
      }}
      scrollToFirstError
      validateMessages={patientFormValidateMessages}
    >
      <FormMultipleItems>
        <Form.Item
          name={addPatientFormFields.FIRST_NAME}
          label={intl.formatMessage(labelMessages.firstName)}
          initialValue={patient?.firstName}
          rules={defaultRulesArray}
        >
          <Input
            placeholder={intl.formatMessage(placeholderMessages.firstName)}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name={addPatientFormFields.LAST_NAME}
          label={intl.formatMessage(labelMessages.lastName)}
          initialValue={patient?.lastName}
          rules={defaultRulesArray}
        >
          <Input
            placeholder={intl.formatMessage(placeholderMessages.lastName)}
            autoComplete="off"
          />
        </Form.Item>
      </FormMultipleItems>
      <Form.Item
        name={addPatientFormFields.PATIENT_ID}
        label={intl.formatMessage(labelMessages.id)}
        initialValue={patient?.patientId}
        rules={defaultRulesArray}
      >
        <Input
          placeholder={intl.formatMessage(placeholderMessages.id)}
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item
        name={addPatientFormFields.EMAIL}
        label={intl.formatMessage(labelMessages.email)}
        initialValue={patient?.email}
        rules={[{ pattern: emailPattern }]}
      >
        <Input
          placeholder={intl.formatMessage(placeholderMessages.email)}
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item
        name={addPatientFormFields.PHONE}
        label={intl.formatMessage(labelMessages.phone)}
        initialValue={patient?.phone}
      >
        <PhoneInput
          inputStyle={{ width: '100%' }}
          placeholder={intl.formatMessage(placeholderMessages.phone)}
          // @ts-ignore Ignore for now
          autoComplete="off"
        />
      </Form.Item>
      <FormMultipleItems>
        <Form.Item
          name={addPatientFormFields.DATE_OF_BIRTH}
          label={intl.formatMessage(labelMessages.dateOfBirth)}
          initialValue={
            patient?.dateOfBirth &&
            dayjs(patient?.dateOfBirth, patientFormDateFormat)
          }
        >
          <DatePicker
            format={patientFormDateFormat}
            style={{ width: '100%' }}
            disabledDate={date => date && date.valueOf() > Date.now()}
          />
        </Form.Item>

        <Form.Item
          name={addPatientFormFields.GENDER}
          label={intl.formatMessage(labelMessages.gender)}
          initialValue={patient?.gender}
        >
          <Select
            placeholder={intl.formatMessage(placeholderMessages.gender)}
            allowClear
          >
            <Option value={genderOptions.MALE}>
              {intl.formatMessage(messages.male)}
            </Option>
            <Option value={genderOptions.FEMALE}>
              {intl.formatMessage(messages.female)}
            </Option>
          </Select>
        </Form.Item>
      </FormMultipleItems>
      {/* Address */}
      <Form.Item
        name={addPatientFormFields.ADDRESS}
        label={intl.formatMessage(labelMessages.address)}
        initialValue={patient?.address?.address1}
      >
        <Input
          placeholder={intl.formatMessage(placeholderMessages.address)}
          autoComplete="off"
        />
      </Form.Item>
    </Form>
  );
};
const messages = defineMessages({
  address: {
    defaultMessage: 'Address',
  },
  male: {
    defaultMessage: 'Male',
  },
  female: {
    defaultMessage: 'Female',
  },
});

export default injectIntl(PatientForm);
