import { styled } from 'styled-components';

import { media } from 'src/utils/mediaQueryUtils';

export const PanelWrapper = styled.div`
  flex-shrink: 0;
  background: #fefeff;
  box-shadow: 0px 3px 2px rgba(78, 83, 96, 0.15);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 0.75rem;
  column-gap: 0.75rem;
  padding: 0.5rem 0.75rem;

  ${media.extraExtraExtraLarge`
    justify-content: space-between;
  `}
`;

export const StatisticsCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
  white-space: nowrap;
`;

export const CardTitle = styled.div`
  font-weight: 400;
  font-size: 0.6rem;
  color: #767881;
`;

export const CardValue = styled.div<{ isAltDisplay?: boolean }>`
  ${props =>
    props.isAltDisplay
      ? `
    font-family: ${props.theme.fonts.secondary};
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #767881;  
  `
      : `
    font-family: ${props.theme.fonts.secondary};
    font-weight: 700;
    font-size: 0.8rem;
    color: #252525;
  `}
`;
