import React from 'react';
import { defineMessages, FormattedMessage, IntlShape } from 'react-intl';

import { sortWithEmptyString } from 'src/utils/sorters/utils';
import AlertLogTypeCell from 'src/components/AlertLogTypeCell';
import { handleClickSubtenantPatientName } from 'src/routes/GMDashboard/modules/utils';
import { TableColumnConfig } from 'src/types/table';
import { AlertLogTableItem } from './types';
import { BoldItem, LocationItem, StyledPatientName } from './styled';
import { Tooltip } from 'antd';

const messages = defineMessages({
  patientName: {
    defaultMessage: 'Patient name',
  },
  roomAndBed: {
    defaultMessage: 'Room/Bed',
  },
  room: {
    defaultMessage: 'R',
  },
  bed: {
    defaultMessage: 'B',
  },
  startTime: {
    defaultMessage: 'Start Time',
  },
  endTime: {
    defaultMessage: 'End Time',
  },
  alertType: {
    defaultMessage: 'Notification type',
  },
  details: {
    defaultMessage: 'Details',
  },
  noConsentTooltip: {
    defaultMessage: 'No Consent - access to medical information is denied',
  },
});

export const getAlertsLogColumns = (
  intl: IntlShape,
): TableColumnConfig<AlertLogTableItem>[] => [
  {
    title: intl.formatMessage(messages.patientName),
    dataIndex: 'patientName',
    key: 'patientName',
    filtered: true,
    sorter: (a, b) => sortWithEmptyString(a.patientName, b.patientName),
    render: (_, { tenantId, patientId, patientName, patientIsConsented }) => (
      <StyledPatientName
        onClick={() => {
          if (!patientIsConsented) {
            return;
          }
          handleClickSubtenantPatientName(tenantId, patientId);
        }}
      >
        <Tooltip
          title={
            !patientIsConsented ? (
              <FormattedMessage {...messages.noConsentTooltip} />
            ) : null
          }
        >
          {patientName}
        </Tooltip>
      </StyledPatientName>
    ),
  },
  {
    title: intl.formatMessage(messages.roomAndBed),
    dataIndex: 'deviceLocation',
    key: 'deviceLocation',
    filtered: true,
    render: (_, record) => (
      <div>
        <BoldItem>
          <FormattedMessage {...messages.room} />
        </BoldItem>
        &nbsp;
        <LocationItem>{record.roomName}</LocationItem>
        &nbsp;
        <BoldItem>
          <FormattedMessage {...messages.bed} />
        </BoldItem>
        &nbsp;
        <LocationItem>{record.bedName}</LocationItem>
      </div>
    ),
    sorter: (a, b) =>
      sortWithEmptyString(a.deviceLocation, b.deviceLocation, {
        numeric: true,
      }),
  },
  {
    title: intl.formatMessage(messages.startTime),
    dataIndex: 'startTime',
    key: 'startTime',
    filtered: true,
    sorter: (a, b) => sortWithEmptyString(a.startTime, b.startTime),
    render: (_, record) => record.startTime,
  },
  {
    title: intl.formatMessage(messages.endTime),
    dataIndex: 'endTime',
    key: 'endTime',
    filtered: true,
    sorter: (a, b) => sortWithEmptyString(a.endTime, b.endTime),
  },
  {
    title: intl.formatMessage(messages.alertType),
    dataIndex: 'thresholdMetric',
    key: 'thresholdMetric',
    render: (_, record) => (
      <AlertLogTypeCell
        thresholdMetric={record.thresholdMetric}
        thresholdPreposition={record.thresholdPreposition}
        status={record.status}
      />
    ),
    sorter: (a, b) =>
      sortWithEmptyString(
        a.thresholdMetric + (a.thresholdPreposition || ''),
        b.thresholdMetric + (b.thresholdPreposition || ''),
      ),
  },
  {
    title: intl.formatMessage(messages.details),
    dataIndex: 'details',
    key: 'details',
    className: 'details-column',
    render: (_, record) => (
      <div dangerouslySetInnerHTML={{ __html: record.details }} />
    ),
  },
];
