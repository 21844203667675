import React, { useEffect } from 'react';
import { styled } from 'styled-components';
import PageHeader from './components/PageHeader';
import PageContent from './components/PageContent';
import { extractQpPatientId } from 'src/routes/IndividualDashboard/modules/dashboard/utils';
import Connector, { PropsFromRedux } from './Connector';

export const IndividualDashboardWrapper = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  background: #eff8f9;
  width: 100%;
  height: 100%;
  overflow: auto;

  a {
    padding: 0.5rem;
    border-radius: 0.25rem;
    background: ${props => props.theme.colors.actionButtonBackground};
    color: ${props => props.theme.colors.coloredButtonTextColor};
    font-weight: bold;
    font-size: 0.7rem;
    white-space: nowrap;
    :hover,
    :active {
      opacity: 0.7;
    }

    :focus {
      opacity: 0.9;
    }
  }
`;
const IndividualDashboardPage = ({
  onLoadIndividualDashboard,
  individualDashboardUnmounted,
}: PropsFromRedux): JSX.Element => {
  useEffect(() => {
    onLoadIndividualDashboard();
    return () => {
      individualDashboardUnmounted();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractQpPatientId()]);

  return (
    <IndividualDashboardWrapper>
      <PageHeader />
      <PageContent />
    </IndividualDashboardWrapper>
  );
};

export default Connector(React.memo(IndividualDashboardPage));
