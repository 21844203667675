import { styled } from 'styled-components';

export const VersionPanelWrap = styled.div`
  max-width: 12rem;
  margin-left: 3rem;
`;

export const Box = styled.div`
  padding: 0.1rem 0.7rem;
  border: 1px solid ${props => props.theme.colors.simpleModalHeaderBorder};
`;

export const VersionLabel = styled.p`
  font-size: 0.7rem;
  font-weight: 700;
  margin-bottom: 0;
`;

export const VersionValue = styled.p`
  font-size: 0.7rem;
  padding-left: 0.3rem;
  border: 1px solid ${props => props.theme.colors.simpleModalHeaderBorder};
  background-color: ${props => props.theme.colors.simpleModalBackground};
`;
