import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import { selectors as alertSelectors } from 'src/redux/data/alerts';

const mapStateToProps = (state: RootState) => {
  return {
    lastDayAlertEventsCount:
      alertSelectors.selectLastDayActiveAlerts(state).length,
    ...deviceSelectors.selectTenantDeviceConnectionInfo(state),
  };
};

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
