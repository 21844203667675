import * as M from 'fp-ts/lib/Monoid';
import * as R from 'fp-ts/lib/Record';
import * as Ord from 'fp-ts/lib/Ord';
import { Predicate } from 'fp-ts/lib/Predicate';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { SubTenantOverview } from './types';
import { getMonoidAll } from 'fp-ts/Predicate';

export enum TenantStatusFilterOption {
  TOTAL_DEVICES = 'TOTAL_DEVICES',
  TOTAL_ALERTS = 'TOTAL_ALERTS',
  TOTAL_DISCONNECTED_DEVICES = 'TOTAL_DISCONNECTED_DEVICES',
  TOTAL_PATIENT_DISCONNECTED_DEVICES = 'TOTAL_PATIENT_DISCONNECTED_DEVICES',
}
export enum TenantStatusFilterComparation {
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
}

export type TenantStatusFilterOptionState = {
  key: TenantStatusFilterOption;
  comparasionTerm: TenantStatusFilterComparation;
  value: number;
};

export type TenantStatusFilter = {
  filter: 'status';
  value: TenantStatusFilterOptionState[];
};

export type DeviceFilter = TenantStatusFilter;

export type StateFilters = {
  status: TenantStatusFilterOptionState[];
};
export type FilterItems = {
  TOTAL_DEVICES: string;
  TOTAL_DEVICES_SELECT: TenantStatusFilterComparation;
  TOTAL_DEVICES_VALUE: number;
  TOTAL_ALERTS: string;
  TOTAL_ALERTS_SELECT: TenantStatusFilterComparation;
  TOTAL_ALERTS_VALUE: number;
  TOTAL_DISCONNECTED_DEVICES: string;
  TOTAL_DISCONNECTED_DEVICES_SELECT: TenantStatusFilterComparation;
  TOTAL_DISCONNECTED_DEVICES_VALUE: number;
  TOTAL_PATIENT_DISCONNECTED_DEVICES: string;
  TOTAL_PATIENT_DISCONNECTED_DEVICES_SELECT: TenantStatusFilterComparation;
  TOTAL_PATIENT_DISCONNECTED_DEVICES_VALUE: number;
};

const {
  TOTAL_DEVICES,
  TOTAL_ALERTS,
  TOTAL_DISCONNECTED_DEVICES,
  TOTAL_PATIENT_DISCONNECTED_DEVICES,
} = TenantStatusFilterOption;

const messages = defineMessages({
  totalDevices: {
    defaultMessage: 'Total Devices',
  },
  totalAlerts: {
    defaultMessage: 'Total Notifications',
  },
  totalDisconnectedDevices: {
    defaultMessage: 'Total Disconnected Devices',
  },
  totalPatientDisconnectedDevices: {
    defaultMessage: 'Total Patient-Assigned Only Disconnected Devices',
  },
});

export type FilterItem = {
  displayOrder: number;
  label: MessageDescriptor;
  filter: (
    value: number,
    comparationTerm: string,
  ) => Predicate<SubTenantOverview>;
};

const totalDevicesFilter = (
  value: number,
  comparationTerm: string,
): Predicate<SubTenantOverview> => {
  return subtenant => {
    return comparationTerm === TenantStatusFilterComparation.GREATER_THAN
      ? subtenant.totalDevicesCounter > value
      : subtenant.totalDevicesCounter < value;
  };
};
const totalAlertsFilter = (
  value: number,
  comparationTerm: string,
): Predicate<SubTenantOverview> => {
  return subtenant => {
    return comparationTerm === TenantStatusFilterComparation.GREATER_THAN
      ? subtenant.lastDayAlertsCounter > value
      : subtenant.lastDayAlertsCounter < value;
  };
};
const totalDisconnectedDevicesFilter = (
  value: number,
  comparationTerm: string,
): Predicate<SubTenantOverview> => {
  return subtenant => {
    const totalDisconnectedDevices =
      subtenant.disconnectedAssignedDevicesCounter +
      subtenant.disconnectedUnassignedDevicesCounter;
    return comparationTerm === TenantStatusFilterComparation.GREATER_THAN
      ? totalDisconnectedDevices > value
      : totalDisconnectedDevices < value;
  };
};
const totalPatientDisconnectedDevicesFilter = (
  value: number,
  comparationTerm: string,
): Predicate<SubTenantOverview> => {
  return subtenant => {
    return comparationTerm === TenantStatusFilterComparation.GREATER_THAN
      ? subtenant.disconnectedAssignedDevicesCounter > value
      : subtenant.disconnectedAssignedDevicesCounter < value;
  };
};

export const STATUS_FILTERS: Record<TenantStatusFilterOption, FilterItem> = {
  [TOTAL_DEVICES]: {
    displayOrder: 1,
    label: messages.totalDevices,
    filter: totalDevicesFilter,
  },
  [TOTAL_ALERTS]: {
    displayOrder: 2,
    label: messages.totalAlerts,
    filter: totalAlertsFilter,
  },
  [TOTAL_DISCONNECTED_DEVICES]: {
    displayOrder: 3,
    label: messages.totalDisconnectedDevices,
    filter: totalDisconnectedDevicesFilter,
  },
  [TOTAL_PATIENT_DISCONNECTED_DEVICES]: {
    displayOrder: 4,
    label: messages.totalPatientDisconnectedDevices,
    filter: totalPatientDisconnectedDevicesFilter,
  },
};

const monoidPredicateAny: M.Monoid<Predicate<SubTenantOverview>> =
  getMonoidAll();

export const combineStatusFilters = (
  filters: TenantStatusFilterOptionState[],
): Predicate<SubTenantOverview> => {
  const predicates = filters.map(filter =>
    STATUS_FILTERS[filter.key].filter(filter.value, filter.comparasionTerm),
  );
  return M.concatAll(monoidPredicateAny)(predicates);
};

export const DISPLAY_STATUS_FILTERS = R.collect(Ord.trivial)(
  (key: TenantStatusFilterOption, item: FilterItem) => ({
    value: key,
    label: item.label,
    displayOrder: item.displayOrder,
  }),
)(STATUS_FILTERS);

export function notNull<T>(val: T | null): val is T {
  return val !== null;
}
