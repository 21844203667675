import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import moment from 'moment';
import { media } from 'src/utils/mediaQueryUtils';

const TimerDisplay = styled.div`
  font-size: 16px;
  padding: 0 5px;
  white-space: nowrap;

  ${media.extraExtraLarge`
    padding: 0 10px;
    font-size: 18px;
  `}
`;
const noData = '-- :  --  :  --';
let myTimeout = null;
const Timer = ({ isTimerOn, startTime, currentTime }) => {
  const [runningTime, setRunningTime] = useState(0);
  const [runningSession, setRunningSession] = useState(0);
  const [lastDur, setLastDur] = useState(null);

  const incrementTimer = () => {
    const newDuration = runningTime + 1000;
    setRunningSession(lastDur + newDuration);
    setRunningTime(newDuration);
  };

  useEffect(() => {
    if (isTimerOn && startTime && currentTime) {
      const milliSecondsStartTime = moment(startTime).valueOf();
      const milliSecondsCurrentTime = moment(currentTime).valueOf();
      const lastDuration = milliSecondsCurrentTime - milliSecondsStartTime;
      setLastDur(lastDuration);
    } else {
      setLastDur(null);
      setRunningTime(0);
      setRunningSession(0);
      clearTimeout(myTimeout);
    }
  }, [isTimerOn, startTime, currentTime]);

  useEffect(() => {
    if (lastDur !== null) {
      myTimeout = setTimeout(incrementTimer, 1000);
    }
    return () => {
      clearTimeout(myTimeout);
      myTimeout = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningTime, lastDur]);
  const seconds = `0${Math.floor(runningSession / 1000) % 60}`.slice(-2);
  const minutes = `0${Math.floor(runningSession / 60000) % 60}`.slice(-2);
  const hours = `${Math.floor(runningSession / 3600000)}`;

  return (
    <TimerDisplay>
      {runningSession
        ? `${hours < 10 ? `0${hours}` : hours} : ${minutes} : ${seconds}`
        : noData}
    </TimerDisplay>
  );
};

Timer.propTypes = {
  isTimerOn: PropTypes.bool.isRequired,
  startTime: PropTypes.string,
  currentTime: PropTypes.string,
};
Timer.defaultProps = {
  startTime: null,
  currentTime: null,
};

export default Timer;
