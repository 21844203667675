import React from 'react';
import { styled } from 'styled-components';

import history from 'src/utils/history';
import { DEFAULT_LOCALE } from 'src/utils/languageUtils';

interface Props {
  id: string;
  route: string;
  label: string;
  isCurrent: boolean;
}

export const StyledLink = styled.div<Pick<Props, 'isCurrent'>>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: ${props => (props.isCurrent ? 600 : 400)};
  font-style: normal;
  font-size: 0.8rem;
  padding: 0 0.7rem;
  font-family: ${props => props.theme.fonts.secondary};
  cursor: ${props => (props.isCurrent ? 'not-allowed' : 'pointer')};
  ${props => (props.isCurrent ? 'border-bottom: 3px solid black;' : '')}
`;

const NavigationLink = ({
  id,
  route,
  label,
  isCurrent,
}: Props): JSX.Element => (
  <StyledLink
    isCurrent={isCurrent}
    onClick={() => !isCurrent && history.push(`/${DEFAULT_LOCALE}/${route}`)}
    data-cy={`${id}-link`}
  >
    {label}
  </StyledLink>
);

export default NavigationLink;
