import React from 'react';
import { SVGProps } from 'src/components/general-ui/Icon/types';
import { StyledViewIcons } from './styled';

const GridViewIcon = ({
  width = '24',
  height = '20',
  color = '#000',
  viewBox = '512 512',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <StyledViewIcons
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect y="15.2381" width="6" height="4.7619" fill={color} />
    <rect y="15.2381" width="6" height="4.7619" fill={color} />
    <rect y="15.2381" width="6" height="4.7619" fill={color} />
    <rect y="7.61905" width="6" height="4.7619" fill={color} />
    <rect y="7.61905" width="6" height="4.7619" fill={color} />
    <rect y="7.61905" width="6" height="4.7619" fill={color} />
    <rect width="6" height="4.7619" fill={color} />
    <rect width="6" height="4.7619" fill={color} />
    <rect width="6" height="4.7619" fill={color} />
    <rect x="9" y="15.2381" width="6" height="4.7619" fill={color} />
    <rect x="9" y="15.2381" width="6" height="4.7619" fill={color} />
    <rect x="9" y="15.2381" width="6" height="4.7619" fill={color} />
    <rect x="9" y="7.61905" width="6" height="4.7619" fill={color} />
    <rect x="9" y="7.61905" width="6" height="4.7619" fill={color} />
    <rect x="9" y="7.61905" width="6" height="4.7619" fill={color} />
    <rect x="9" width="6" height="4.7619" fill={color} />
    <rect x="9" width="6" height="4.7619" fill={color} />
    <rect x="9" width="6" height="4.7619" fill={color} />
    <rect x="18" y="15.2381" width="6" height="4.7619" fill={color} />
    <rect x="18" y="15.2381" width="6" height="4.7619" fill={color} />
    <rect x="18" y="15.2381" width="6" height="4.7619" fill={color} />
    <rect x="18" y="7.61905" width="6" height="4.7619" fill={color} />
    <rect x="18" y="7.61905" width="6" height="4.7619" fill={color} />
    <rect x="18" y="7.61905" width="6" height="4.7619" fill={color} />
    <rect x="18" width="6" height="4.7619" fill={color} />
    <rect x="18" width="6" height="4.7619" fill={color} />
    <rect x="18" width="6" height="4.7619" fill={color} />
  </StyledViewIcons>
);

export default GridViewIcon;
