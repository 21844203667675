import * as R from 'fp-ts/lib/Record';

import {
  API_DATE_FORMAT,
  displayInTimezone,
  isInLast24Hours,
} from 'src/utils/timeUtils';
import { Alert, AlertLog, MedicalAlertLog } from 'src/types/alerts';
import { PatientAlerts } from 'src/services/types';
import {
  PatientAlertsByType,
  AlertsMap,
  AlertSoundSeverity,
  AlertSoundsMap,
  AlertSoundState,
  AlertSoundStatus,
  PatientAlertsMap,
} from './types';
import { isTechnicalAlert } from './typeGuards';
import { ALERT_METRIC_ENUM } from '../../constants';

export const mapCurrentAlerts = (alerts: Alert[]): AlertsMap =>
  alerts.reduce((acc: AlertsMap, alert) => {
    if (isTechnicalAlert(alert.thresholdMetric) || !alert.patientId) {
      return acc;
    }

    return {
      ...acc,
      [alert.patientId]: {
        ...(acc[alert.patientId] || {}),
        [alert.thresholdMetric]: alert,
      },
    };
  }, {});

export const partitionAlertsByType = (
  patientAlerts: PatientAlertsMap,
): AlertsMap =>
  R.map((alerts: Alert[]) =>
    alerts.reduce(
      (acc, alert) => ({
        ...acc,
        [alert.thresholdMetric]: alert,
      }),
      {},
    ),
  )(patientAlerts);

export const mapPatientAlertsByPatientId = (
  patientAlerts: PatientAlerts[],
): PatientAlertsMap =>
  patientAlerts.reduce(
    (acc, patientAlert) =>
      patientAlert.patientId
        ? { ...acc, [patientAlert.patientId]: patientAlert.alerts }
        : { ...acc, technicalAlerts: patientAlert.alerts },
    {},
  );

export const correctAlertTimestamps = (
  alert: Alert,
  timezone: string,
): Alert => ({
  ...alert,
  startTime: displayInTimezone(alert.startTime, timezone, API_DATE_FORMAT),
  endTime:
    alert.endTime &&
    displayInTimezone(alert.endTime, timezone, API_DATE_FORMAT),
  clearTime:
    alert.clearTime &&
    displayInTimezone(alert.clearTime, timezone, API_DATE_FORMAT),
});

export const correctAlertLogTimestamps = (
  alert: MedicalAlertLog,
  timezone: string,
): MedicalAlertLog => ({
  ...alert,
  startTime: displayInTimezone(alert.startTime, timezone, API_DATE_FORMAT),
  endTime:
    alert.endTime &&
    displayInTimezone(alert.endTime, timezone, API_DATE_FORMAT),
  clearTime:
    alert.clearTime &&
    displayInTimezone(alert.clearTime, timezone, API_DATE_FORMAT),
  lastModifiedTime:
    alert.lastModifiedTime &&
    displayInTimezone(alert.lastModifiedTime, timezone, 'HH:mm'),
  firstMeasurementTime:
    alert.firstMeasurementTime &&
    displayInTimezone(alert.firstMeasurementTime, timezone, API_DATE_FORMAT),
});

export const correctAlertLogTabelItemTimestamps = (
  alert: AlertLog,
  timezone: string,
): AlertLog => ({
  ...alert,
  startTime: displayInTimezone(alert.startTime, timezone, API_DATE_FORMAT),
  endTime:
    alert.endTime &&
    displayInTimezone(alert.endTime, timezone, API_DATE_FORMAT),
  firstMeasurementTime:
    alert.firstMeasurementTime &&
    displayInTimezone(alert.firstMeasurementTime, timezone, API_DATE_FORMAT),
});

const noAlertSoundState = undefined;

const alertSoundSeverity = (): AlertSoundSeverity => {
  return AlertSoundSeverity.SIMPLE_ALERT;
};

const getAlertSoundDetails = (alert?: Alert): AlertSoundState | undefined => {
  if (!alert) {
    return noAlertSoundState;
  }

  if (
    alert.status === 'OFF' ||
    alert.status === 'SUPPRESSED' ||
    alert.alertLevel !== 2
  ) {
    return {
      status: AlertSoundStatus.NO_ALERT,
      severity: AlertSoundSeverity.NO_ALERT,
    };
  }

  return {
    status: AlertSoundStatus.NEED_TO_PLAY,
    severity: alertSoundSeverity(),
  };
};

const applyValue = <T>(k: string, v: T) => (v ? { [k]: v } : {});

export const createSoundMap = (alertsMap: AlertsMap): AlertSoundsMap =>
  R.map((alerts: PatientAlertsByType) => ({
    ...applyValue(ALERT_METRIC_ENUM.HR, getAlertSoundDetails(alerts.HR)),
    ...applyValue(ALERT_METRIC_ENUM.RR, getAlertSoundDetails(alerts.RR)),
    ...applyValue(
      ALERT_METRIC_ENUM.HR_BASELINE,
      getAlertSoundDetails(alerts.HR_BASELINE),
    ),
    ...applyValue(
      ALERT_METRIC_ENUM.RR_BASELINE,
      getAlertSoundDetails(alerts.RR_BASELINE),
    ),
    ...applyValue(
      ALERT_METRIC_ENUM.BED_EXIT,
      getAlertSoundDetails(alerts.BED_EXIT),
    ),
    ...applyValue(
      ALERT_METRIC_ENUM.LONG_OUT_OF_BED,
      getAlertSoundDetails(alerts.LONG_OUT_OF_BED),
    ),
    ...applyValue(
      ALERT_METRIC_ENUM.BED_EXIT_FREQUENCY,
      getAlertSoundDetails(alerts.BED_EXIT_FREQUENCY),
    ),
    ...applyValue(
      ALERT_METRIC_ENUM.POSITION_CHANGE,
      getAlertSoundDetails(alerts.POSITION_CHANGE),
    ),
  }))(alertsMap);

export const hasAlertFinishedInLast24h = (alert: Alert): boolean =>
  alert.endTime ? isInLast24Hours(alert.endTime) : false;
