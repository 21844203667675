import { styled } from 'styled-components';

import FormModal from 'src/components/general-ui/FormModal';
import { Tabs } from 'antd';

export const StyledModal = styled(FormModal)`
  .ant-modal-content {
    min-height: 40rem;
    width: 26.85rem;
  }
`;
export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 0.5rem 0rem;
  }
  .ant-tabs-nav {
    margin-bottom: 0rem;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4a4a4a;
    font-weight: 700;
  }
  .ant-tabs-tab-btn {
    font-family: ${props => props.theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    color: #4a4a4a;
    padding: 0rem 0.75rem;
  }
  .ant-tabs-ink-bar {
    background: #304e64;
  }
  .ant-tabs-content-holder {
    margin-top: 1rem;
  }
`;

export const AlertSettingsSubtitle = styled.div`
  font-weight: 700;
  font-size: 0.8rem;
  margin: 0.25rem 0 0.25rem 0;
`;

export const CheckboxStyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 0.65rem;
  //cursor: pointer;
  width: fit-content;
`;
