import { styled } from 'styled-components';
import { Bar } from 'react-chartjs-2';

import { SubTitle, Row } from 'src/components/styled';
import { media } from 'src/utils/mediaQueryUtils';
import { Card } from './styled';

// Average Values

export const StyledAverageValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 0px;
  height: calc(100vh - 180px);
  width: 520px;
  ${media.extraExtraLarge`
    width: 667px;
  `}
`;

export const StyledStatisticsTitles = styled(Card)`
  background-color: ${props => props.theme.colors.appDirtyWhite};
  width: 100%;
  height: 100px;
  display: flex;
  margin-bottom: 10px;
  margin-top: 11px;
  padding-left: 110px;
  justify-content: normal;
`;

export const StyledTimeFrame = styled.div`
  margin-left: 0px;
  width: 180px;
  line-height: 36px;
  font-size: 14px;
  text-align: center;
`;

export const StyledDeviceCard = styled(Card)`
  background-color: ${props => props.theme.colors.appDirtyWhite};
  width: 100%;
  height: 100%;
  margin-bottom: 14px;
  display: block;
`;

export const MeasurementLogo = styled.img`
  width: 60px;
  height: 50px;
  display: block;
  margin-left: 16px;
`;

export const CardTitle = styled(SubTitle)`
  color: ${props => props.theme.colors.spotMeasurementCardTitleColor};
  font-weight: 500;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  display: block;
  width: 90px;
`;

export const StyledStatisticsLabelGroup = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledLogoGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const FullHeightRow = styled(Row)`
  height: 100%;
`;

export const AverageTitleBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  line-height: 24px;
  color: ${props => props.theme.colors.spotMeasurementCardTitleColor};
  font-weight: 500;
  font-size: 15px;
`;

export const MeasurementUnitBox = styled.div`
  text-align: center;
  font-size: 16px;
  padding: 8px;
  color: ${props => props.theme.colors.spotMeasurementCardTitleColor};
`;

// Average Values Card

export const StyledAverageValuesCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  margin-left: 15px;
`;

export const StyledBar = styled(Bar)`
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100px;
`;

export const CardStyled = styled(Card)`
  border: ${props =>
    `1px solid ${props.theme.colors.spotMeasurementCardBorderColor}`};
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  padding: 0px;
  align-items: center;
  border-radius: 5px;
  width: 120px;
  line-height: 1;
  height: calc((100vh - 388px) / 2);
  background-color: ${props =>
    props.theme.colors.measurementCardBackgroundColor};
  ${media.extraExtraLarge`
    width: 170px;
  `}
`;

export const AverageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 5px 10px;
`;

export const AverageStyled = styled.div`
  font-size: 24px;
`;

export const MeasurementUnit = styled.div`
  margin-left: 5px;
  color: ${props => props.theme.colors.spotMeasurementCardTitleColor};
`;
