import { all, call, put, takeEvery, takeLatest } from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import { actions } from './slice';

export function* fetchPatientNotes(
  action: ReturnType<typeof actions.fetchPatientNotes>,
) {
  try {
    const { data } = yield* call(
      BackendService.fetchPatientNotes,
      action.payload,
    );
    yield* put(
      actions.fetchPatientNotesSuccess({
        patientId: action.payload,
        notes: data.notes,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in fetchNotes: ', e);
    yield* put(actions.fetchPatientNotesFailed());
  }
}

function* fetchPatientsNoteCounterMetadata(
  action: ReturnType<typeof actions.fetchPatientsNoteCounterMetadata>,
) {
  try {
    const { data } = yield* call(
      BackendService.fetchPatientsNoteCounterMetadata,
      action.payload,
    );
    yield* put(
      actions.fetchPatientsNoteCounterMetadataSuccess(data.byPatientId),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in fetchPatientsNoteCounterMetadata: ', e);
  }
}

function* createNote(action: ReturnType<typeof actions.createNote>) {
  try {
    const { data } = yield* call(BackendService.createNote, action.payload);
    yield* put(actions.createNoteSuccess(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in createNote: ', e);
    yield* put(actions.createNoteFailed());
  }
}

export default function* watchTenantActions() {
  yield* all([
    takeEvery(actions.fetchPatientNotes, fetchPatientNotes),
    takeLatest(actions.createNote, createNote),
    takeLatest(
      actions.fetchPatientsNoteCounterMetadata,
      fetchPatientsNoteCounterMetadata,
    ),
  ]);
}
