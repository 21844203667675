import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  NurseStationDevice,
  DeviceAlerts,
} from 'src/routes/NursesStation/modules/types';
import Connector, { PropsFromRedux } from './Connector';
import DisplayValue from './DisplayValue';
import {
  AlertWrapperIndicatorCard,
  DeviceMeasurementsWrapper,
  MeasurementBox,
  StyledTitle,
  StyledTitleMetric,
  StyledTitleUnit,
  StyledValue,
} from './styled';
import {
  getBedExitDelay,
  getDeviceAlertDetails,
  getDisplayContinuousValue,
  getMeasurementBoxColors,
  getVSColorByQuality,
  isDeviceGatheringData,
} from './utils';
import { StyledGatherInfoIcon } from 'src/components/PatientStatusComponent/styled';
import { useTheme } from 'styled-components';
import { deepEqual } from 'src/utils/comparators';
import { ALERT_STATUS_ENUM } from 'src/utils/constants';
import { getLongOutOfBedTriggerValue } from 'src/utils/alertHelpers';

type Props = PropsFromRedux &
  Pick<NurseStationDevice, 'isDeviceActive' | 'continuousData'> & {
    isUnoccupied: boolean;
    ongoingDeviceAlerts: DeviceAlerts;
    shouldDisplayNoConsent: boolean | undefined;
  };

const DeviceMeasurements = ({
  continuousData,
  isDeviceActive,
  isUnoccupied,
  ongoingDeviceAlerts,
  shouldDisplayHighQualityOnly,
  timezone,
  shouldDisplayNoConsent,
}: Props): JSX.Element => {
  const theme = useTheme();
  const {
    latestHrAlert,
    latestRrAlert,
    occupancyAlert,
    longOutOfBed,
    positionChange,
  } = ongoingDeviceAlerts;
  const hasHrAlert = latestHrAlert?.status === ALERT_STATUS_ENUM.ON;
  const hasRrAlert = latestRrAlert?.status === ALERT_STATUS_ENUM.ON;

  const bedExitSecondsDelay = getBedExitDelay(occupancyAlert, timezone);
  const isBedExitAlertActive =
    occupancyAlert && occupancyAlert.status !== 'OFF';
  const isBedExit =
    (isUnoccupied && isBedExitAlertActive) ||
    (!isUnoccupied &&
      isBedExitAlertActive &&
      bedExitSecondsDelay &&
      bedExitSecondsDelay <= 10);
  const isLongOutOfBedAlert = longOutOfBed?.id;
  const isPositionChangeAlert = positionChange?.id;

  if (shouldDisplayNoConsent) {
    const backgroundColor = '#f4f6f8';
    const borderColor = '#E5E5E5';
    const titleColor = '#252525';

    return (
      <AlertWrapperIndicatorCard
        background={backgroundColor}
        border={borderColor}
      >
        <StyledValue color={titleColor}>
          <FormattedMessage {...messages.noConsent} />
        </StyledValue>
      </AlertWrapperIndicatorCard>
    );
  }

  if (
    isBedExit ||
    isLongOutOfBedAlert ||
    (isPositionChangeAlert && !hasHrAlert && !hasRrAlert)
  ) {
    const backgroundColor =
      occupancyAlert?.status === ALERT_STATUS_ENUM.ON ||
      longOutOfBed?.status === ALERT_STATUS_ENUM.ON ||
      positionChange?.status === ALERT_STATUS_ENUM.ON
        ? theme.colors.alertBackground
        : '#FAF3E2';
    const borderColor =
      occupancyAlert?.status === ALERT_STATUS_ENUM.ON ||
      longOutOfBed?.status === ALERT_STATUS_ENUM.ON ||
      positionChange?.status === ALERT_STATUS_ENUM.ON
        ? theme.colors.alertBorder
        : '#FFAD0D';

    return (
      <AlertWrapperIndicatorCard
        background={backgroundColor}
        border={borderColor}
      >
        {isLongOutOfBedAlert && !isPositionChangeAlert && (
          <>
            <FormattedMessage
              {...messages.longOutOfBedAlertText}
              values={{
                triggerValue: getLongOutOfBedTriggerValue(
                  longOutOfBed?.triggerValue,
                ),
              }}
            />
            {longOutOfBed?.triggerValue && longOutOfBed?.triggerValue >= 60 ? (
              <FormattedMessage {...messages.hour} />
            ) : (
              <FormattedMessage {...messages.minutes} />
            )}
          </>
        )}
        {isBedExit && !isLongOutOfBedAlert && !isPositionChangeAlert && (
          <FormattedMessage {...messages.vacancyAlertText} />
        )}
        {isPositionChangeAlert && (
          <FormattedMessage
            {...messages.positionChangeAlertText}
            values={{ lineBreak: <br /> }}
          />
        )}
      </AlertWrapperIndicatorCard>
    );
  }

  const {
    currentHrValue,
    currentHrQuality,
    currentRrValue,
    currentRrQuality,
    currentPatientStateValue,
  } = continuousData || {};
  const continuousValues = {
    HR: getDisplayContinuousValue(
      currentHrValue,
      currentHrQuality,
      shouldDisplayHighQualityOnly,
      'HR',
    ),
    isHrGatheringData: isDeviceGatheringData(
      currentHrValue,
      currentHrQuality,
      currentPatientStateValue,
      shouldDisplayHighQualityOnly,
    ),
    RR: getDisplayContinuousValue(
      currentRrValue,
      currentRrQuality,
      shouldDisplayHighQualityOnly,
      'RR',
    ),
    isRrGatheringData: isDeviceGatheringData(
      currentRrValue,
      currentRrQuality,
      currentPatientStateValue,
      shouldDisplayHighQualityOnly,
    ),
  };

  const alertDetails = {
    HR: getDeviceAlertDetails(isDeviceActive, latestHrAlert),
    RR: getDeviceAlertDetails(isDeviceActive, latestRrAlert),
  };
  const {
    backgroundColor: hrBackgroundColor,
    borderColor: hrBorderColor,
    titleColor: hrTitleColor,
    valueColor: hrValueColor,
  } = getMeasurementBoxColors(isDeviceActive, latestHrAlert?.status, theme);
  const {
    backgroundColor: rrBackgroundColor,
    borderColor: rrBorderColor,
    titleColor: rrTitleColor,
    valueColor: rrValueColor,
  } = getMeasurementBoxColors(isDeviceActive, latestRrAlert?.status, theme);

  return (
    <DeviceMeasurementsWrapper>
      <MeasurementBox
        backgroundColor={hrBackgroundColor}
        borderColor={hrBorderColor}
        isLeft
      >
        <StyledTitle>
          <StyledTitleMetric color={hrTitleColor}>
            <FormattedMessage {...messages.hr} />
          </StyledTitleMetric>
          <StyledTitleUnit color={hrTitleColor}>
            <FormattedMessage {...messages.bpm} />
          </StyledTitleUnit>
        </StyledTitle>
        <StyledValue
          color={getVSColorByQuality(
            continuousValues.HR,
            !!currentHrQuality,
            hrValueColor,
          )}
          isGatheringInfo={continuousValues.isHrGatheringData}
        >
          <DisplayValue {...alertDetails.HR}>
            {continuousValues.isHrGatheringData ? (
              <StyledGatherInfoIcon type={'gathering-data-icon'} />
            ) : (
              <> {continuousValues.HR} </>
            )}
          </DisplayValue>
        </StyledValue>
      </MeasurementBox>

      <MeasurementBox
        backgroundColor={rrBackgroundColor}
        borderColor={rrBorderColor}
      >
        <StyledTitle>
          <StyledTitleMetric color={rrTitleColor}>
            <FormattedMessage {...messages.rr} />
          </StyledTitleMetric>
          <StyledTitleUnit color={rrTitleColor}>
            <FormattedMessage {...messages.brpm} />
          </StyledTitleUnit>
        </StyledTitle>
        <StyledValue
          color={getVSColorByQuality(
            continuousValues.RR,
            !!currentRrQuality,
            rrValueColor,
          )}
          isGatheringInfo={continuousValues.isRrGatheringData}
        >
          <DisplayValue {...alertDetails.RR}>
            {continuousValues.isRrGatheringData ? (
              <StyledGatherInfoIcon type={'gathering-data-icon'} />
            ) : (
              <> {continuousValues.RR} </>
            )}
          </DisplayValue>
        </StyledValue>
      </MeasurementBox>
    </DeviceMeasurementsWrapper>
  );
};

const messages = defineMessages({
  hr: {
    defaultMessage: 'HR',
  },
  rr: {
    defaultMessage: 'RR',
  },
  bpm: {
    defaultMessage: '(BPM)',
  },
  brpm: {
    defaultMessage: '(BRPM)',
  },
  vacancyAlertText: {
    defaultMessage: 'BedExit',
  },
  positionChangeAlertText: {
    defaultMessage: 'Position change {lineBreak} required',
  },
  longOutOfBedAlertText: {
    defaultMessage: 'Out of bed for {triggerValue}',
  },
  hour: {
    defaultMessage: 'h',
  },
  minutes: {
    defaultMessage: 'm',
  },
  noConsent: {
    defaultMessage: 'No Consent',
  },
});

export default Connector(
  React.memo(DeviceMeasurements, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
