import { Dropdown } from 'antd';

import Table from 'src/components/CrudCommonComponents/Table';
import { styled } from 'styled-components';

export const CustomerFormWrapper = styled('div')`
  input {
    margin-bottom: 0.5rem;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  margin-left: 0.7rem;
`;

export const StyledTable = styled(Table)`
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 0.5rem;
  }

  .ant-table-cell {
    min-width: 4rem !important;
  }

  input {
    font-size: 0.5rem;
    padding: 0.2rem;
    width: calc(100% - 40px);
    margin-top: 0;
    position: absolute;
    top: 2;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 0.2rem;
    cursor: pointer;
    min-height: 1.2rem;
    border: ${props => `1px solid ${props.theme.colors.borderPrimaryColor}`};
    border-radius: 2px;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 0.2rem;
    border: ${props => `1px solid ${props.theme.colors.borderHoverColor}`};
    border-radius: 2px;
  }
`;
