import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

import {
  ContinousMotionAggregation,
  ContinousPositionCount,
  ContinuousAggregatedMeasurement,
} from 'src/types/individual-dashboard';
import { API_STATUS } from 'src/utils/api-constants';
import AppConfig from 'src/config/AppConfig';
import { selectors as patientsSelectors } from 'src/redux/data/patient';
import { convertToTimezone } from 'src/utils/timeUtils';
import { AlertLog } from 'src/types/alerts';
import {
  DateUTC,
  PageMetaDataType,
  SerialNumber,
  UUID,
} from 'src/types/utility';
import { RootState } from 'src/redux/store';
import {
  formatIntervalWithResolution,
  getTimeAxisResolution,
} from './time-processors';
import { POINTS_DATE_FORMAT } from './utils';
import {
  computeIntervalAverage,
  countDataPoints,
  mapRawContinuousDataToGraphData,
  mapRawMotionDataToGraphData,
  mapRawPositionDataToGraphData,
} from './data-processors';
import { GraphTimeData, MainGraphDataPoint } from './types';
import { HistogramGraphData } from './histogram-processor';

export const STATE_KEY = 'individualDashboard';

export const INITIAL_STATE: {
  apiStatus: API_STATUS;
  currentPatientId: UUID | null;
  deviceInfo: { deviceId: SerialNumber | ''; deviceConnectionStatus: boolean };
  searchFocus: boolean;
  // TODO: Remove if possible
  saveRawData: boolean;
  bedOccupancy: {
    apiStatus: API_STATUS;
    hoursInBedData: GraphTimeData[];
    numberOfBedExitAlertsData: GraphTimeData[];
  };
  metricHistogram: {
    apiStatus: API_STATUS;
    data: HistogramGraphData | Record<string, never>;
  };
  alertsLog: {
    apiStatus: API_STATUS;
    data: AlertLog[];
    pageMetadata: PageMetaDataType;
  };
  alertsLogForModal: {
    apiStatus: API_STATUS;
    data: AlertLog[];
    pageMetadata: PageMetaDataType;
  };
  alertsLogStatus: API_STATUS;
  alertsLogStatusForModal: API_STATUS;
  mainGraph: {
    metadata: {
      isLoading: boolean;
      startTime: DateUTC;
      endTime: DateUTC;
      stepSize: number;
      unit: string;
      dataPointsCount: number;
      hrContinuousBaseline: number;
      rrContinuousBaseline: number;
    };
    data: {
      hrContinuousData: MainGraphDataPoint[];
      rrContinuousData: MainGraphDataPoint[];
      motionContinuousData: MainGraphDataPoint[];
      positionContinuousData: MainGraphDataPoint[];
    };
  };
} = {
  apiStatus: API_STATUS.LOADING,
  currentPatientId: null,
  deviceInfo: { deviceId: '', deviceConnectionStatus: false },
  searchFocus: false,
  saveRawData: AppConfig.SAVE_RAW_DATA_DEFAULT,
  bedOccupancy: {
    apiStatus: API_STATUS.LOADING,
    hoursInBedData: [],
    numberOfBedExitAlertsData: [],
  },
  metricHistogram: {
    apiStatus: API_STATUS.LOADING,
    data: {},
  },
  alertsLog: {
    apiStatus: API_STATUS.LOADING,
    data: [],
    pageMetadata: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
  },
  alertsLogForModal: {
    apiStatus: API_STATUS.LOADING,
    data: [],
    pageMetadata: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
  },
  alertsLogStatus: API_STATUS.LOADING,
  alertsLogStatusForModal: API_STATUS.LOADING,
  mainGraph: {
    metadata: {
      isLoading: true,
      startTime: '',
      endTime: '',
      stepSize: 1,
      unit: '',
      dataPointsCount: 0,
      hrContinuousBaseline: 0,
      rrContinuousBaseline: 0,
    },
    data: {
      hrContinuousData: [],
      rrContinuousData: [],
      motionContinuousData: [],
      positionContinuousData: [],
    },
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    loadIndividualDashboardSuccess: state => {
      state.apiStatus = API_STATUS.OK;
    },
    loadIndividualDashboardFailed: state => {
      state.apiStatus = API_STATUS.ERROR;
    },
    individualDashboardUnmounted: state => {
      state.bedOccupancy.apiStatus = API_STATUS.LOADING;
      state.metricHistogram.apiStatus = API_STATUS.LOADING;
      state.alertsLog.apiStatus = API_STATUS.LOADING;
      state.apiStatus = API_STATUS.LOADING;
      state.mainGraph.metadata.isLoading = true;
    },
    setAlertsLogStatus: (state, action: PayloadAction<API_STATUS>) => {
      state.alertsLogStatus = action.payload;
    },
    setAlertsLogForModalStatus: (state, action: PayloadAction<API_STATUS>) => {
      state.alertsLogStatusForModal = action.payload;
    },
    setIndividualDashboardStatus: (
      state,
      action: PayloadAction<API_STATUS>,
    ) => {
      state.apiStatus = action.payload;
    },
    setSelectedPatientId: (state, action: PayloadAction<UUID | null>) => {
      state.currentPatientId = action.payload;
    },
    setDeviceInfo: (
      state,
      action: PayloadAction<{
        deviceId: SerialNumber | '';
        deviceConnectionStatus: boolean;
      }>,
    ) => {
      state.deviceInfo = { ...action.payload };
    },
    setSearchFocusStatus: (state, action: PayloadAction<boolean>) => {
      state.searchFocus = action.payload;
    },
    getBedOccupancyData: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{ patientId: UUID }>,
    ) => {
      state.bedOccupancy.apiStatus = API_STATUS.LOADING;
    },
    loadBedOccupancyDataSuccess: (
      state,
      action: PayloadAction<{
        hoursInBed: GraphTimeData[];
        numberOfAlerts: GraphTimeData[];
      }>,
    ) => {
      const { hoursInBed, numberOfAlerts } = action.payload;

      state.bedOccupancy = {
        ...state.bedOccupancy,
        hoursInBedData: hoursInBed,
        numberOfBedExitAlertsData: numberOfAlerts,
        apiStatus: API_STATUS.OK,
      };
    },
    loadBedOccupancyDataFailed: state => {
      state.bedOccupancy.apiStatus = API_STATUS.ERROR;
    },
    clearBedOccupancyGraph: state => {
      state.bedOccupancy.hoursInBedData = [];
      state.bedOccupancy.numberOfBedExitAlertsData = [];
    },
    getHistogramDataRequest: (state, action: PayloadAction<API_STATUS>) => {
      state.bedOccupancy.apiStatus = action.payload;
    },
    getHistogramDataSuccess: (
      state,
      action: PayloadAction<HistogramGraphData>,
    ) => {
      state.metricHistogram.data = action.payload;
      state.metricHistogram.apiStatus = API_STATUS.OK;
    },
    clearHistogramData: state => {
      state.metricHistogram.data = {};
    },
    setAlertsLogData: (
      state,
      action: PayloadAction<{
        alerts: AlertLog[];
        pageMetadata: PageMetaDataType;
      }>,
    ) => {
      const { alerts, pageMetadata } = action.payload;

      state.alertsLog.data = alerts;
      state.alertsLog.pageMetadata = pageMetadata;
    },
    setAlertsLogDataForModal: (
      state,
      action: PayloadAction<{
        alerts: AlertLog[];
        pageMetadata: PageMetaDataType;
      }>,
    ) => {
      const { alerts, pageMetadata } = action.payload;

      state.alertsLogForModal.data = alerts.sort((a, b) =>
        b.startTime.localeCompare(a.startTime),
      );
      state.alertsLogForModal.pageMetadata = pageMetadata;
    },
    getPatientMetricData: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        patientId: UUID;
        startTime: Dayjs;
        endTime: Dayjs;
      }>,
    ) => {
      state.mainGraph.metadata.isLoading = true;
    },
    getPatientMetricDataSuccess: (
      state,
      action: PayloadAction<{
        hrData: ContinuousAggregatedMeasurement[];
        rrData: ContinuousAggregatedMeasurement[];
        motionData?: ContinousMotionAggregation[];
        positionData?: ContinousPositionCount[];
        startTime: Dayjs;
        endTime: Dayjs;
        timezone: string;
      }>,
    ) => {
      const {
        hrData,
        rrData,
        motionData,
        positionData,
        startTime,
        endTime,
        timezone,
      } = action.payload;
      const correctedStartTime = convertToTimezone(startTime, timezone);
      const correctedEndTime = convertToTimezone(endTime, timezone);

      const { startTime: binnedStartTime, endTime: binnedEndTime } =
        formatIntervalWithResolution(correctedStartTime, correctedEndTime);
      const { stepSize, unit } = getTimeAxisResolution(
        correctedStartTime,
        correctedEndTime,
      );

      state.mainGraph.metadata.startTime =
        binnedStartTime.format(POINTS_DATE_FORMAT);
      state.mainGraph.metadata.endTime =
        binnedEndTime.format(POINTS_DATE_FORMAT);
      state.mainGraph.metadata.stepSize = stepSize;
      state.mainGraph.metadata.unit = unit;

      state.mainGraph.data.hrContinuousData = mapRawContinuousDataToGraphData(
        hrData,
        timezone,
      );
      state.mainGraph.data.rrContinuousData = mapRawContinuousDataToGraphData(
        rrData,
        timezone,
      );

      state.mainGraph.data.motionContinuousData = mapRawMotionDataToGraphData(
        motionData,
        timezone,
      );

      state.mainGraph.data.positionContinuousData =
        mapRawPositionDataToGraphData(positionData, timezone);

      state.mainGraph.metadata.dataPointsCount = countDataPoints(
        hrData,
        rrData,
      );
      state.mainGraph.metadata.hrContinuousBaseline =
        computeIntervalAverage(hrData);
      state.mainGraph.metadata.rrContinuousBaseline =
        computeIntervalAverage(rrData);
      state.mainGraph.metadata.isLoading = false;
    },
    getPatientMetricDataFailure: state => {
      state.mainGraph.metadata.isLoading = false;
    },
  },
  extraReducers: {},
});

const extraActions = {
  onLoadIndividualDashboard: createAction(
    `${STATE_KEY}/onLoadIndividualDashboard`,
  ),
  individualDashboardUnmounted: createAction(
    `${STATE_KEY}/individualDashboardUnmounted`,
  ),
  getHistogramData: createAction<UUID>(`${STATE_KEY}/histogramData`),
  getAlertsLogData: createAction<{
    patientId: UUID;
    startDate: Dayjs;
    endDate: Dayjs;
    currentPage?: number;
    pageSize?: number;
  }>(`${STATE_KEY}/getAlertsLogData`),
  getLatestSessions: createAction(`${STATE_KEY}/getLatestSessions`),
  getAlertsLogDataForModal: createAction<{
    patientId: UUID;
    startDate: Dayjs;
    endDate: Dayjs;
    currentPage?: number | string;
    pageSize?: string | number;
  }>(`${STATE_KEY}/getAlertsLogDataForModal`),
};

const getState = (state: RootState) => state[STATE_KEY] || INITIAL_STATE;

export const selectors = {
  selectIsDataLoading: createSelector(
    getState,
    state => state.apiStatus === API_STATUS.LOADING,
  ),
  getHoursInBedData: createSelector(
    getState,
    state => state.bedOccupancy.hoursInBedData,
  ),
  getNumberOfBedExitAlertsData: createSelector(
    getState,
    state => state.bedOccupancy.numberOfBedExitAlertsData,
  ),
  selectHistogramData: createSelector(
    getState,
    state => state.metricHistogram.data,
  ),
  isHistogramDataLoading: createSelector(
    getState,
    state => state.metricHistogram.apiStatus === API_STATUS.LOADING,
  ),
  selectMainGraphMetadata: createSelector(
    getState,
    state => state.mainGraph.metadata,
  ),
  selectMainGraphMetricData: createSelector(
    getState,
    state => state.mainGraph.data,
  ),
  selectDataPointsCount: createSelector(
    getState,
    state => state.mainGraph.metadata.dataPointsCount,
  ),
  selectAlertsLog: createSelector(getState, state => state.alertsLog.data),
  selectAlertsLogForModal: createSelector(
    getState,
    state => state.alertsLogForModal.data,
  ),
  selectAlertsLogMetadata: createSelector(
    getState,
    state => state.alertsLog.pageMetadata,
  ),
  selectAlertsLogMetadataForModal: createSelector(
    getState,
    state => state.alertsLogForModal.pageMetadata,
  ),
  selectAlertsLogStatus: createSelector(
    getState,
    state => state.alertsLogStatus,
  ),
  selectAlertsLogStatusForModal: createSelector(
    getState,
    state => state.alertsLogStatusForModal,
  ),
  getCurrentPatientId: createSelector(
    getState,
    state => state.currentPatientId,
  ),
  getDeviceId: createSelector(getState, state => state.deviceInfo.deviceId),
  getDeviceConnectionStatus: createSelector(
    getState,
    state => state.deviceInfo.deviceConnectionStatus,
  ),
  getIsSaveRawData: createSelector(getState, state => state.saveRawData),
  getSearchFocusStatus: createSelector(getState, state => state.searchFocus),
  selectIsBedOccupancyDataLoading: createSelector(
    getState,
    state => state.bedOccupancy.apiStatus,
  ),
  getPatientDetails: createSelector(
    getState,
    patientsSelectors.getPatientsList,
    (state, patients) => {
      const patientId = state.currentPatientId;

      return patients.find(patient => patientId === patient.id);
    },
  ),
  selectIsDashboardLoading: createSelector(
    getState,
    state =>
      state.bedOccupancy.apiStatus === API_STATUS.LOADING ||
      state.metricHistogram.apiStatus === API_STATUS.LOADING ||
      state.mainGraph.metadata.isLoading,
  ),
};

export const actions = { ...slice.actions, ...extraActions };
const { reducer } = slice;
export default reducer;
