import React, { useEffect, useState } from 'react';
import * as R from 'fp-ts/lib/Record';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  IntlShape,
} from 'react-intl';
import { Row, Tooltip } from 'antd';

import { BaselineMetric } from 'src/types/alerts';
import Checkbox from '../../general-ui/Checkbox';
import {
  StyledTextWrapper,
  StyledTitle,
  StyledWrapper,
  StyledInputNumber,
} from './styled';
import {
  BASELINE_DAYS_INTERVAL,
  BASELINE_DEVIATION_PERCENTAGE,
  BASELINE_DEVIATION_HOURS,
} from './constants';
import {
  EditableBaselineSetting,
  EditableBaselineThreshold,
  TenantBaselineThresholdsState,
  ThresholdsErrorState,
} from './types';
import {
  mapBaselineThresholdsArrayToState,
  mapValidationErrors,
} from './utils';
import permissions from 'src/permissions';
import AccessControl from 'src/components/AccessControl';

type Props = {
  baselineThresholds: TenantBaselineThresholdsState;
  setBaselineThresholdData: (thresholds: TenantBaselineThresholdsState) => void;
  setHasErrors: (hasErrors: boolean) => void;
  intl: IntlShape;
  isFormEnabled?: boolean;
};

const BaselineAlertSettingsForm = ({
  baselineThresholds,
  setBaselineThresholdData,
  setHasErrors,
  intl,
  isFormEnabled = true,
}: Props): JSX.Element => {
  const [thresholdErrorState, setThresholdErrorState] =
    useState<ThresholdsErrorState>({
      HR_BASELINE: {
        baselineDaysInterval: false,
        deviationHoursInterval: false,
        deviationPercentage: false,
      },
      RR_BASELINE: {
        baselineDaysInterval: false,
        deviationHoursInterval: false,
        deviationPercentage: false,
      },
    });

  const enableThresholdSetting = (metric: BaselineMetric, value: boolean) =>
    setBaselineThresholdData({
      ...baselineThresholds,
      [metric]: {
        ...baselineThresholds[metric],
        enable: value,
        dirty: true,
      },
    });

  const setThresholdSettingValue = (
    metric: BaselineMetric,
    setting: EditableBaselineSetting,
    value: number,
  ) =>
    setBaselineThresholdData({
      ...baselineThresholds,
      [metric]: {
        ...baselineThresholds[metric],
        [setting]: value,
        dirty: true,
      },
    });

  useEffect(() => {
    setThresholdErrorState(mapValidationErrors(baselineThresholds));
  }, [baselineThresholds]);

  useEffect(() => {
    const isAnError = R.some((item: Record<EditableBaselineSetting, boolean>) =>
      R.some((isError: boolean) => isError)(item),
    )(thresholdErrorState);

    setHasErrors(isAnError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholdErrorState]);
  const { HR_BASELINE, RR_BASELINE } = baselineThresholds;

  // @ts-ignore event not seen on input
  const numberInputOnWheelPreventChange: React.WheelEventHandler<
    HTMLInputElement
  > = (event: WheelEvent) => {
    const target = event.target as HTMLInputElement;

    target?.blur();

    event.stopPropagation();
  };

  return (
    <AccessControl
      permissions={[
        permissions.ALERT_THRESHOLD_CREATE,
        permissions.ALERT_THRESHOLD_UPDATE,
      ]}
    >
      <StyledWrapper>
        <StyledTitle>
          <FormattedMessage {...messages.baselineTitle} />
        </StyledTitle>
        <Row align="middle" style={{ marginBottom: '0.75rem' }}>
          <StyledTextWrapper inactive={!HR_BASELINE.enable}>
            <Checkbox
              disabled={!isFormEnabled}
              className="baseline-checkbox"
              checked={HR_BASELINE.enable}
              handleChange={checked => {
                enableThresholdSetting('HR_BASELINE', checked);
              }}
            />
            <FormattedMessage {...messages.alertIfHR} />
            <Tooltip
              placement="top"
              title={`${intl.formatMessage(messages.rangeValues)} ${
                BASELINE_DAYS_INTERVAL.min
              } - ${BASELINE_DAYS_INTERVAL.max}`}
              trigger="focus"
            >
              <StyledInputNumber
                type="number"
                min={BASELINE_DAYS_INTERVAL.min}
                max={BASELINE_DAYS_INTERVAL.max}
                value={HR_BASELINE.baselineDaysInterval || ''}
                onChange={e =>
                  setThresholdSettingValue(
                    'HR_BASELINE',
                    'baselineDaysInterval',
                    parseInt(e.target.value || '0'),
                  )
                }
                isError={thresholdErrorState.HR_BASELINE.baselineDaysInterval}
                disabled={!HR_BASELINE.enable || !isFormEnabled}
                onWheel={numberInputOnWheelPreventChange}
              />
            </Tooltip>
            <FormattedMessage {...messages.daysBaseline} />
            <Tooltip
              placement="top"
              title={`${intl.formatMessage(messages.rangeValues)} ${
                BASELINE_DEVIATION_PERCENTAGE.min
              } - ${BASELINE_DEVIATION_PERCENTAGE.max}`}
              trigger="focus"
            >
              <StyledInputNumber
                type="number"
                min={BASELINE_DEVIATION_PERCENTAGE.min}
                max={BASELINE_DEVIATION_PERCENTAGE.max}
                value={HR_BASELINE.deviationPercentage || ''}
                onChange={e =>
                  setThresholdSettingValue(
                    'HR_BASELINE',
                    'deviationPercentage',
                    parseInt(e.target.value || ' 0'),
                  )
                }
                isError={thresholdErrorState.HR_BASELINE.deviationPercentage}
                disabled={!HR_BASELINE.enable || !isFormEnabled}
              />
            </Tooltip>
            % <FormattedMessage {...messages.for} />
            <Tooltip
              placement="top"
              title={`${intl.formatMessage(messages.rangeValues)} ${
                BASELINE_DEVIATION_HOURS.min
              } - ${BASELINE_DEVIATION_HOURS.max}`}
              trigger="focus"
            >
              <StyledInputNumber
                type="number"
                min={BASELINE_DEVIATION_HOURS.min}
                max={BASELINE_DEVIATION_HOURS.max}
                value={HR_BASELINE.deviationHoursInterval || ''}
                onChange={e =>
                  setThresholdSettingValue(
                    'HR_BASELINE',
                    'deviationHoursInterval',
                    parseInt(e.target.value || '0'),
                  )
                }
                isError={thresholdErrorState.HR_BASELINE.deviationHoursInterval}
                disabled={!HR_BASELINE.enable || !isFormEnabled}
              />
            </Tooltip>
            <FormattedMessage {...messages.hours} />
          </StyledTextWrapper>
        </Row>
        <Row align="middle" style={{ marginBottom: '0.75rem' }}>
          <StyledTextWrapper inactive={!RR_BASELINE.enable}>
            <Checkbox
              disabled={!isFormEnabled}
              className="baseline-checkbox"
              checked={RR_BASELINE.enable}
              handleChange={checked => {
                enableThresholdSetting('RR_BASELINE', checked);
              }}
            />
            <FormattedMessage {...messages.alertIfRR} />
            <Tooltip
              placement="top"
              title={`${intl.formatMessage(messages.rangeValues)} ${
                BASELINE_DAYS_INTERVAL.min
              } - ${BASELINE_DAYS_INTERVAL.max}`}
              trigger="focus"
            >
              <StyledInputNumber
                type="number"
                min={BASELINE_DAYS_INTERVAL.min}
                max={BASELINE_DAYS_INTERVAL.max}
                value={RR_BASELINE.baselineDaysInterval || ''}
                onChange={e =>
                  setThresholdSettingValue(
                    'RR_BASELINE',
                    'baselineDaysInterval',
                    parseInt(e.target.value || '0'),
                  )
                }
                isError={thresholdErrorState.RR_BASELINE.baselineDaysInterval}
                disabled={!RR_BASELINE.enable || !isFormEnabled}
              />
            </Tooltip>
            <FormattedMessage {...messages.daysBaseline} />
            <Tooltip
              placement="top"
              title={`${intl.formatMessage(messages.rangeValues)} ${
                BASELINE_DEVIATION_PERCENTAGE.min
              } - ${BASELINE_DEVIATION_PERCENTAGE.max}`}
              trigger="focus"
            >
              <StyledInputNumber
                type="number"
                min={BASELINE_DEVIATION_PERCENTAGE.min}
                max={BASELINE_DEVIATION_PERCENTAGE.max}
                value={RR_BASELINE.deviationPercentage || ''}
                onChange={e =>
                  setThresholdSettingValue(
                    'RR_BASELINE',
                    'deviationPercentage',
                    parseInt(e.target.value || '0'),
                  )
                }
                isError={thresholdErrorState.RR_BASELINE.deviationPercentage}
                disabled={!RR_BASELINE.enable || !isFormEnabled}
              />
            </Tooltip>
            % <FormattedMessage {...messages.for} />
            <Tooltip
              placement="top"
              title={`${intl.formatMessage(messages.rangeValues)} ${
                BASELINE_DEVIATION_HOURS.min
              } - ${BASELINE_DEVIATION_HOURS.max}`}
              trigger="focus"
            >
              <StyledInputNumber
                type="number"
                min={BASELINE_DEVIATION_HOURS.min}
                max={BASELINE_DEVIATION_HOURS.max}
                value={RR_BASELINE.deviationHoursInterval || ''}
                onChange={e =>
                  setThresholdSettingValue(
                    'RR_BASELINE',
                    'deviationHoursInterval',
                    parseInt(e.target.value || '0'),
                  )
                }
                isError={thresholdErrorState.RR_BASELINE.deviationHoursInterval}
                disabled={!RR_BASELINE.enable || !isFormEnabled}
              />
            </Tooltip>
            <FormattedMessage {...messages.hours} />
          </StyledTextWrapper>
        </Row>
      </StyledWrapper>
    </AccessControl>
  );
};

const messages = defineMessages({
  baselineTitle: {
    defaultMessage: 'Baseline Notification',
  },
  alertIfHR: {
    defaultMessage: 'Notify if HR baseline differs from last',
  },
  alertIfRR: {
    defaultMessage: 'Notify if RR baseline differs from last',
  },
  daysBaseline: {
    defaultMessage: 'days baseline by',
  },
  for: {
    defaultMessage: 'for',
  },
  hours: {
    defaultMessage: 'Hours',
  },

  rangeValues: {
    defaultMessage: 'Range values: ',
  },
});

export type { TenantBaselineThresholdsState, EditableBaselineThreshold };
export { mapBaselineThresholdsArrayToState };

export default injectIntl(BaselineAlertSettingsForm);
