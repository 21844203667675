import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';

const mapStateToProps = (state: RootState) => {
  const tenantsCounters = tenantSelectors.getTenantsAlertCounters(state);
  const lastDayAlertEvents =
    tenantsCounters?.totals.currentAlertsCount +
    tenantsCounters?.totals.attentionListAlertsCount;

  return {
    lastDayAlertEvents: lastDayAlertEvents,
    ...deviceSelectors.selectMTMDeviceStatistics(state),
  };
};

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
