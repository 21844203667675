import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import AlertCard from '../../components/AlertCard';
import Connector, { PropsFromRedux } from './Connector';
import { useInfiniteScrollProps } from 'src/utils/hooks/useInfiniteScrollProps';
import InfiniteScroll from 'react-infinite-scroll-component';
import { deepEqual } from 'src/utils/comparators';
import { AlertCardsWrapper, StyledText } from '../../styled';
import { useCalculateLongOutOfBedTime } from 'src/utils/hooks/useCalculateLongOutOfBedTime';
import { redirectToPatientIDB } from 'src/utils/patientHelpers';

type Props = PropsFromRedux;

const CurrentAlerts = ({
  alertItems,
  suppressAlertType,
  unSuppressAlertType,
  clearAllPatientAlerts,
  areAlertsLoading,
}: Props): JSX.Element => {
  const { noOfVisibleElements, fetchMoreData } = useInfiniteScrollProps();
  useCalculateLongOutOfBedTime();

  return (
    <AlertCardsWrapper
      data-cy={`alerts-sidebar-current-alerts-loading=${areAlertsLoading.toString()}`}
      id={'currentAlertsScrollableDiv'}
    >
      {alertItems.length ? (
        <InfiniteScroll
          dataLength={alertItems.slice(0, noOfVisibleElements).length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={alertItems.length > noOfVisibleElements}
          loader={
            <StyledText>
              <FormattedMessage {...messages.loadingOnScroll} />
            </StyledText>
          }
          scrollableTarget="currentAlertsScrollableDiv"
          style={{ gap: '0.5rem' }}
        >
          {alertItems
            .slice(0, noOfVisibleElements)
            .map(({ alertId, deviceId, patientId, notesCount }) => (
              <AlertCard
                key={alertId}
                alertId={alertId}
                deviceId={deviceId}
                patientId={patientId}
                notesCount={notesCount}
                onPatientNameClick={() => redirectToPatientIDB(patientId)}
                onSuppressAlertClick={id => suppressAlertType(id)}
                onUnSuppressAlertClick={id => unSuppressAlertType(id)}
                onDeleteAlertClick={id => clearAllPatientAlerts(id)}
              />
            ))}
        </InfiniteScroll>
      ) : (
        <StyledText>
          <FormattedMessage {...messages.emptyText} />
        </StyledText>
      )}
    </AlertCardsWrapper>
  );
};

const messages = defineMessages({
  emptyText: {
    defaultMessage: 'No ongoing notifications at this moment.',
  },
  loadingOnScroll: {
    defaultMessage: 'Loading...',
  },
});

export default Connector(
  React.memo(CurrentAlerts, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
