import React from 'react';
import { defineMessages } from 'react-intl';

import CurrentAlerts from '../CurrentAlerts';
import EventLog from '../EventLog';

export const sideBarLabelMessages = defineMessages({
  currentAlertsTab: {
    defaultMessage: 'Current Notifications',
  },
  eventLogTab: {
    defaultMessage: 'Event log',
  },
});

export const CURRENT_ALERTS = 'current-alerts';
export const EVENT_LOG = 'event-log';

export const ALERT_SIDEBAR_TABS = Object.freeze({
  [CURRENT_ALERTS]: {
    order: 1,
    render: () => <CurrentAlerts />,
    label: sideBarLabelMessages.currentAlertsTab,
  },
  [EVENT_LOG]: {
    order: 2,
    render: () => <EventLog />,
    label: sideBarLabelMessages.eventLogTab,
  },
});

export type AlertSidebarKey = keyof typeof ALERT_SIDEBAR_TABS;
