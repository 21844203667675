/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Table, Checkbox } from 'antd';
import { styled } from 'styled-components';
import { Searchbar } from '../styled';
import {
  ExportCsvButton,
  StyledButtonsContainer,
  StyledSpacedRow,
  StyledTableContainer,
} from './styled';
import ActionsRow from './ActionsRow';
import ActionButton from '../general-ui/Buttons/ActionButton';
import AccessControl from 'src/components/AccessControl';
import dayjs from 'dayjs';
import { getCsvData } from './utils';

export const CUSTOM_TABLE_COLUMN_FIELDS = Object.freeze({
  CHECKBOX: 'CHECKBOX',
});

const StyledTable = styled(Table)`
  .ant-table-small .ant-table-thead > tr > th {
    background-color: ${props => props.theme.colors.MainContentBackground};
  }
  table
    tr
    th.ant-table-cell.ant-table-selection-column.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
    display: inline-block;
  }
  table td.ant-table-cell {
    font-size: 0.6rem;
    padding: 0.2rem !important;
  }
  table th.ant-table-cell {
    font-size: 0.6rem;
    white-space: break-spaces;
  }
  .ant-table-cell {
    min-width: 6.375rem !important;
  }
  tbody {
    .ant-table-cell {
      padding: 0.25rem;
      div {
        height: 1.5rem;
        line-height: 1.5rem;
        button {
          height: 1.5rem !important;
        }
        hr {
          height: 0.8rem !important;
          margin: 0 0.5rem;
          place-self: center;
        }
      }
    }
  }
`;

const getTableCustomColumns = customColumns => {
  if (!customColumns || !customColumns.length) {
    return [];
  }

  return customColumns.reduce((acc, column) => {
    if (column.fieldType === CUSTOM_TABLE_COLUMN_FIELDS.CHECKBOX) {
      return [
        ...acc,
        {
          title: column.title,
          key: column.key,
          width: column.width || 'auto',
          render: (text, record, index) => (
            <Checkbox
              key={record}
              onChange={e => column.action(record.id, e.target.checked)}
              checked={record[column.columnFieldName]}
            />
          ),
        },
      ];
    }

    return acc;
  }, []);
};

const getTableActionColumn = (actions, actionsCustomWidth, intl) => {
  if (!actions || !actions.length) {
    return null;
  }

  return {
    title: `${intl.formatMessage(messages.actions)}`,
    key: 'actions',
    width: actionsCustomWidth || 'auto',
    render: (text, record, index) => (
      <ActionsRow
        actions={actions}
        record={record}
        rowProps={{ text, record, index }}
      />
    ),
  };
};

const injectActionsColumn = (columns, actionsColumn, actionsColumnIndex) => {
  if (!actionsColumn) {
    return columns;
  }

  if (!actionsColumnIndex) {
    return [...columns, actionsColumn];
  }

  const newColumns = [...columns];
  newColumns.splice(actionsColumnIndex, 0, actionsColumn);

  return newColumns;
};

const TableWrapper = ({
  data,
  columns,
  actions,
  intl,
  withSearch,
  searchPlaceholder,
  searchWidth,
  withAddButton,
  withCsvExport,
  exportCsvButtonText,
  addButtonPermission,
  addButtonText,
  addButtonOnClick,
  scroll,
  actionsMap,
  actionsColumnIndex,
  searchAligned,
  customColumns,
  actionsCustomWidth,
  pagination,
  handleTableChange,
  isSubComponent,
  searchFunction,
  csvFileName,
  ...restProps
}) => {
  const [filteredValue, setFilteredValue] = useState([]);
  const [displayedColumns, setDisplayedColumns] = useState(columns);

  const mapFilteredValueToColumns = d =>
    d.map(column =>
      column.filtered ? { ...column, filteredValue: [filteredValue] } : column,
    );

  useEffect(() => {
    const columnsWithCustom = [
      ...columns,
      ...getTableCustomColumns(customColumns),
    ];
    const filteredColumns =
      actions && actions.length && !searchFunction
        ? mapFilteredValueToColumns(columnsWithCustom)
        : columnsWithCustom;
    const displayColumns = injectActionsColumn(
      filteredColumns,
      getTableActionColumn(actions, actionsCustomWidth, intl),
      actionsColumnIndex,
    );

    setDisplayedColumns(displayColumns);
  }, [columns, actions, actionsColumnIndex]);

  useEffect(() => {
    if (searchFunction) {
      searchFunction(filteredValue);
    } else {
      setDisplayedColumns(mapFilteredValueToColumns);
    }
  }, [filteredValue]);

  const mergedColumns = displayedColumns.map(col => {
    if (col.editable) {
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    }

    return col;
  });

  const { csvData = [], csvHeaders = [] } = getCsvData(
    withCsvExport,
    mergedColumns,
    data,
  );

  return (
    <StyledTableContainer>
      <StyledSpacedRow
        className="table-search-bar-wrapper"
        isSubComponent={isSubComponent}
      >
        {withSearch && (
          <Searchbar
            width={searchWidth}
            placeholder={searchPlaceholder}
            onChange={event => {
              setFilteredValue(event.target.value);
            }}
            value={filteredValue}
          />
        )}
        {
          // TOOO: get rid of after read only implemented
        }
        <StyledButtonsContainer className={'buttons-container'}>
          {withAddButton && (
            <ActionButton onClick={addButtonOnClick}>
              {addButtonText}
            </ActionButton>
          )}
          {addButtonPermission && (
            <AccessControl permissions={[addButtonPermission]}>
              <ActionButton onClick={addButtonOnClick}>
                {addButtonText}
              </ActionButton>
            </AccessControl>
          )}
          {withCsvExport && (
            <ExportCsvButton
              className={'export-csv-button'}
              filename={`${csvFileName}.csv`}
              data={csvData}
              headers={csvHeaders}
            >
              {exportCsvButtonText}
            </ExportCsvButton>
          )}
        </StyledButtonsContainer>
      </StyledSpacedRow>
      <br />
      <StyledTable
        columns={mergedColumns}
        dataSource={data}
        rowClassName="editable-row"
        pagination={pagination || false}
        onChange={handleTableChange}
        scroll={scroll}
        tableLayout="auto"
        {...restProps}
        size="small"
      />
    </StyledTableContainer>
  );
};

const messages = defineMessages({
  actions: {
    defaultMessage: 'Actions',
  },
});

TableWrapper.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  actions: PropTypes.array,
  actionsMap: PropTypes.object,
  withSearch: PropTypes.bool,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  handleTableChange: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  searchWidth: PropTypes.string,
  withCsvExport: PropTypes.bool,
  withAddButton: PropTypes.bool,
  addButtonPermission: PropTypes.string,
  addButtonText: PropTypes.string,
  exportCsvButtonText: PropTypes.string,
  addButtonOnClick: PropTypes.func,
  scroll: PropTypes.object,
  actionsColumnIndex: PropTypes.number,
  searchAligned: PropTypes.string,
  customColumns: PropTypes.array,
  actionsCustomWidth: PropTypes.string,
  isSubComponent: PropTypes.bool,
  searchFunction: PropTypes.func,
  csvFileName: PropTypes.string,
};

TableWrapper.defaultProps = {
  actions: null,
  actionsMap: null,
  withSearch: false,
  pagination: false,
  handleTableChange: null,
  searchPlaceholder: '',
  withAddButton: false,
  addButtonPermission: '',
  addButtonText: 'Add',
  exportCsvButtonText: 'Export CSV',
  csvFileName: `export_${dayjs().format('MMDDYYYY')}`,
  addButtonOnClick: () => {},
  searchWidth: '21.875rem',
  scroll: { x: 'max-content' },
  actionsColumnIndex: null,
  searchAligned: 'left',
  customColumns: [],
  actionsCustomWidth: null,
  isSubComponent: false,
};

export default injectIntl(TableWrapper);
