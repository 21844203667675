import { connect, ConnectedProps } from 'react-redux';

import { selectors as alertSelectors } from 'src/redux/data/alerts';
import dataSelectors from 'src/redux/data/dataSelectors';
import { selectors as patientSelectors } from 'src/redux/data/patient';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';
import { getTimezoneId } from 'src/utils/timeUtils';
import { NoteCounterMetadata } from 'src/types/notes';

type OwnProps = {
  alertId: UUID;
  patientId: UUID | null;
  deviceId: UUID | null;
  tenantId?: UUID;
  onPatientNameClick: () => void;
  onSuppressAlertClick: (id: UUID) => void;
  onUnSuppressAlertClick: (id: UUID) => void;
  onDeleteAlertClick: (id: UUID) => void;
  notesCount?: NoteCounterMetadata;
};

const mapStateToProps = (
  state: RootState,
  { alertId, patientId, deviceId, tenantId }: OwnProps,
) => {
  const tenant = tenantSelectors.getTenant(state, tenantId || '');

  const timezone = tenant
    ? getTimezoneId(tenant.timeZoneId)
    : loggedInUserSelectors.getUserTenantTimezone(state);

  return {
    alert: alertSelectors.selectAlertById(state, alertId),
    deviceRoomAndBed: deviceId
      ? dataSelectors.selectDeviceRoomAndBed(state, deviceId)
      : undefined,
    patient: patientId
      ? patientSelectors.selectPatient(state, patientId)
      : undefined,
    tenant,
    loggedInUserTenantId: loggedInUserSelectors.getCurrentTenantId(state),
    timezone,
  };
};

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = PropsFromRedux & OwnProps;

export default Connector;
