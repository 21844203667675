import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { notification, Divider } from 'antd';
import { Chart } from 'react-chartjs-2';
import { styled } from 'styled-components';

import theme from 'src/themes/theme';
import { Row } from 'src/components/styled';
import { media } from 'src/utils/mediaQueryUtils';
import { apiStatusEnum } from 'src/utils/constants';
import DeviceStatus from 'src/components/DeviceStatus/index';
import { MEASUREMENT_TYPES } from 'src/redux/data/monitor/modules/constants';
import bedExitActiveAlertLogo from 'src/common-resources/bed-exit-active-alert-logo.svg';
import OnlineMonitor from 'src/redux/data/monitor/modules/OnlineMonitor';

import heartRateLogo from '../resources/heartRate.svg';
import respirationRateLogo from '../resources/respirationRate.svg';
import ieRatioLogo from '../resources/ieRatioLogo.svg';
import rabinLogo from '../resources/rabinLogo.svg';
import {
  measurementUnits,
  graphProperties,
  graphDataTypesEnum,
  notificationKey,
} from '../modules/constants';
import Timer from '../containers/timerContainer';
import Monitor from './Monitor';
import MeasurementGraphDisplay from './MeasurementGraphDisplay';
import {
  ButtonsArea,
  StartButton,
  StopButton,
  MonitorsLayout,
  ContinuousLayout,
  Title,
  TitleRow,
  Card,
  BannerBedExitLogo,
  BedExitAlertWrapper,
  BedExitTitle,
} from './styled';

const StyledDivider = styled(Divider)`
  border-color: ${props => props.theme.colors.hoverColor};
  margin: 10px 0 10px 0;
`;

const MonitorWrapper = styled(Card)`
  width: calc(100% - 20px);
  display: block;
  height: calc(100% - 12px);
  margin-top: 22px;
`;

const StyledMeasurements = styled(Row)`
  justify-content: space-between;
  margin-top: 26px;
`;

const FlexibleData = styled.div`
  justify-content: space-around;
  ${media.large`
    display: block;
  `}
  ${media.extraLarge`
    display: flex;
    line-height: 33px;
  `}
`;

const StyledTitleRow = styled(TitleRow)`
  height: 35px;
`;

const StyledTitle = styled(Title)`
  height: 20px;
  margin-right: 10px;
`;

const StyledStartButton = styled(StartButton)`
  background-color: ${props =>
    !props.isDeviceConnected && theme.colors.buttonDisabledColor};
  width: 80px;
  ${media.extraExtraLarge`
    width: 100px;
  `}
`;

const StyledStopButton = styled(StopButton)`
  height: 32px;
  background-color: ${props =>
    props.isStopButtonDisabled && theme.colors.buttonDisabledColor};
  width: 80px;
  ${media.extraExtraLarge`
    width: 100px;
  `}
`;

const ContinuousContent = props => {
  const {
    device,
    patientId,
    intl,
    onClickStartContinuous,
    onClickStopContinuous,
    isStartContinuousLoading,
    isStopContinuousLoading,
    isActive,
    shouldDisplayHighQualityOnly,
    onStartWithNoPatient,
    saveRawData,
    isDeviceConnected,
    isStopButtonDisabled,
    currentHrQuality,
    currentHrValue,
    currentRrQuality,
    currentRrValue,
    currentIERatioValue,
    currentRabinValue,
    spotData,
    deviceStatus,
    patientState,
    isBedExitAlertActive,
    isBedExitAlertEnabled,
  } = props;

  const onStartContinuousClick = event => {
    event.stopPropagation();
    if (patientId) {
      onClickStartContinuous({
        deviceId: device?.manufacturerId,
        patientId,
        saveRawData,
      });
    } else {
      onStartWithNoPatient(true);
    }
  };

  const onStopContinuousClick = event => {
    if (event) {
      event.stopPropagation();
    }
    notification.close(notificationKey);
    if (isActive) {
      onClickStopContinuous({ deviceId: device?.manufacturerId });
    }
  };

  useEffect(
    () => () => {
      // eslint-disable-next-line guard-for-in
      for (const index in Chart.instances) {
        Chart.instances[index].destroy();
      }
    },
    [],
  );

  const continuousMonitorsData = [
    {
      dataType: graphDataTypesEnum.HR_DATA,
      datasetLabel: intl.formatMessage(messages.hr),
      measurementType: MEASUREMENT_TYPES.HR,
      minValue: graphProperties.HR_MIN_VALUE,
      maxValue: graphProperties.HR_MAX_VALUE,
      stepSize: graphProperties.HR_STEP_SIZE,
    },
    {
      dataType: graphDataTypesEnum.RR_DATA,
      datasetLabel: intl.formatMessage(messages.rr),
      measurementType: MEASUREMENT_TYPES.RR,
      minValue: graphProperties.RR_MIN_VALUE,
      maxValue: graphProperties.RR_MAX_VALUE,
      stepSize: graphProperties.RR_STEP_SIZE,
    },
  ];

  const measurements = {
    heartRateMeasurements: {
      title: intl.formatMessage(messages.hr),
      unit: measurementUnits.BPM,
      logoSrc: heartRateLogo,
      quality: currentHrQuality,
      measurement: currentHrValue,
      measurementType: MEASUREMENT_TYPES.HR,
    },
    respirationRateMeasurements: [
      {
        title: intl.formatMessage(messages.rr),
        unit: measurementUnits.BRPM,
        logoSrc: respirationRateLogo,
        quality: currentRrQuality,
        measurement: currentRrValue,
        measurementType: MEASUREMENT_TYPES.RR,
      },
      {
        title: intl.formatMessage(messages.ieRatio),
        unit: '',
        logoSrc: ieRatioLogo,
        quality: currentRrQuality,
        measurement: currentIERatioValue,
        measurementType: MEASUREMENT_TYPES.IE,
      },
      {
        title: intl.formatMessage(messages.RAbin),
        unit: '',
        logoSrc: rabinLogo,
        quality: currentRrQuality,
        measurement: currentRabinValue,
        measurementType: null,
      },
    ],
  };

  const patientSpotData = (spotData && spotData[patientId]) || {};
  const isSpotValueActive = !!(spotData && spotData[patientId]);

  return (
    <>
      <MonitorWrapper>
        <StyledTitleRow>
          <FlexibleData>
            <StyledTitle>{intl.formatMessage(messages.continuous)}</StyledTitle>
          </FlexibleData>
          <ButtonsArea>
            <DeviceStatus
              isDeviceConnected={isDeviceConnected}
              patientState={patientState}
              deviceStatus={deviceStatus}
              displayIcon
            />
            <Timer />
            {isActive || isStopContinuousLoading ? (
              <StyledStopButton
                isStopButtonDisabled={isStopButtonDisabled}
                onClick={onStopContinuousClick}
                isLoading={isStopContinuousLoading}
                disabled={isStopButtonDisabled}
              >
                <FormattedMessage {...messages.stop} />
              </StyledStopButton>
            ) : (
              <StyledStartButton
                isDeviceConnected={isDeviceConnected}
                onClick={onStartContinuousClick}
                isLoading={isStartContinuousLoading}
                disabled={
                  isStartContinuousLoading ||
                  isStopContinuousLoading ||
                  !isDeviceConnected
                }
              >
                <FormattedMessage {...messages.start} />
              </StyledStartButton>
            )}
          </ButtonsArea>
        </StyledTitleRow>
        <StyledDivider />
        <ContinuousLayout>
          <MonitorsLayout>
            {isBedExitAlertEnabled && isBedExitAlertActive ? (
              <BedExitAlertWrapper>
                <BedExitTitle>
                  <FormattedMessage {...messages.bedExit} />
                </BedExitTitle>
                <BannerBedExitLogo
                  src={bedExitActiveAlertLogo}
                  alt="bed-exit-logo-alert"
                />
              </BedExitAlertWrapper>
            ) : (
              continuousMonitorsData.map(data => (
                <React.Fragment key={data.dataType}>
                  {data.dataType === 'hrData' ? (
                    <Row style={{ marginTop: '10px' }}>
                      <MeasurementGraphDisplay
                        key={`${data.dataType}-measurement`}
                        logo={measurements.heartRateMeasurements.logoSrc}
                        title={measurements.heartRateMeasurements.title}
                        measurement={
                          isSpotValueActive
                            ? patientSpotData.hr
                            : measurements.heartRateMeasurements.measurement
                        }
                        measurementType={
                          measurements.heartRateMeasurements.measurementType
                        }
                        unit={measurements.heartRateMeasurements.unit}
                        quality={measurements.heartRateMeasurements.quality}
                        shouldDisplayHighQualityOnly={
                          shouldDisplayHighQualityOnly
                        }
                        isSpotValueActive={isSpotValueActive}
                      />
                    </Row>
                  ) : (
                    <StyledMeasurements key={`${data.dataType}-measurement`}>
                      {measurements.respirationRateMeasurements.map(
                        measurement =>
                          !(
                            isSpotValueActive &&
                            measurement.unit !== measurementUnits.BRPM
                          ) ? (
                            <MeasurementGraphDisplay
                              key={measurement.title}
                              logo={measurement.logoSrc}
                              title={measurement.title}
                              measurement={
                                isSpotValueActive
                                  ? patientSpotData.rr
                                  : measurement.measurement
                              }
                              measurementType={measurement.measurementType}
                              unit={measurement.unit}
                              quality={measurement.quality}
                              shouldDisplayHighQualityOnly={
                                shouldDisplayHighQualityOnly
                              }
                              isSpotValueActive={isSpotValueActive}
                            />
                          ) : (
                            <React.Fragment key={measurement.title} />
                          ),
                      )}
                    </StyledMeasurements>
                  )}
                  <Monitor
                    key={`${data.dataType}-monitor`}
                    dataType={data.dataType}
                    measurementType={data.measurementType}
                    datasetLabel={data.datasetLabel}
                    minValue={data.minValue}
                    maxValue={data.maxValue}
                    shouldStart={isActive}
                    delayInMs={graphProperties.MONITOR_DELAY}
                    OnlineMonitor={OnlineMonitor}
                    stepSize={data.stepSize}
                    deviceId={device?.manufacturerId}
                    shouldDisplayHighQualityOnly={shouldDisplayHighQualityOnly}
                  />
                </React.Fragment>
              ))
            )}
          </MonitorsLayout>
        </ContinuousLayout>
      </MonitorWrapper>
    </>
  );
};

ContinuousContent.propTypes = {
  device: PropTypes.object.isRequired,
  patientId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  onClickStartContinuous: PropTypes.func.isRequired,
  onClickStopContinuous: PropTypes.func.isRequired,
  startContinuousStatus: PropTypes.oneOf(Object.values(apiStatusEnum)),
  stopContinuousStatus: PropTypes.oneOf(Object.values(apiStatusEnum)),
  startStatus: PropTypes.oneOf(Object.values(apiStatusEnum)),
  isActive: PropTypes.bool,
  isStartContinuousLoading: PropTypes.bool.isRequired,
  shouldDisplayHighQualityOnly: PropTypes.bool,
  isStopContinuousLoading: PropTypes.bool.isRequired,
  onStartWithNoPatient: PropTypes.func.isRequired,
  saveRawData: PropTypes.bool.isRequired,
  toggleRawData: PropTypes.func.isRequired,
  isDeviceConnected: PropTypes.bool,
  isStopButtonDisabled: PropTypes.bool,
  currentHrQuality: PropTypes.bool,
  currentHrValue: PropTypes.string,
  currentRrQuality: PropTypes.bool,
  currentRrValue: PropTypes.string,
  currentIERatioValue: PropTypes.string,
  currentRabinValue: PropTypes.string,
  spotData: PropTypes.object,
  deviceStatus: PropTypes.string,
  patientState: PropTypes.string,
  isBedExitAlertActive: PropTypes.bool.isRequired,
  isBedExitAlertEnabled: PropTypes.bool.isRequired,
};

ContinuousContent.defaultProps = {
  startContinuousStatus: null,
  stopContinuousStatus: null,
  startStatus: null,
  isActive: false,
  patientId: null,
  shouldDisplayHighQualityOnly: false,
  isDeviceConnected: false,
  isStopButtonDisabled: false,
  currentHrQuality: false,
  currentHrValue: null,
  currentRrQuality: false,
  currentRrValue: null,
  currentIERatioValue: null,
  currentRabinValue: null,
  spotData: {},
  deviceStatus: '',
  patientState: null,
};

const messages = defineMessages({
  hr: {
    defaultMessage: 'Heart Rate',
  },
  rr: {
    defaultMessage: 'Respiration Rate',
  },
  ieRatio: {
    defaultMessage: 'I/E Ratio',
  },
  RAbin: {
    defaultMessage: 'RA',
  },
  continuous: {
    defaultMessage: 'Continuous',
  },
  start: {
    defaultMessage: 'START',
  },
  stop: {
    defaultMessage: 'STOP',
  },
  hri: {
    defaultMessage: 'Health Gate Analysis',
  },
  bedExit: {
    defaultMessage: 'Unoccupied',
  },
});

export default injectIntl(ContinuousContent);
