import { styled } from 'styled-components';

import Table from 'src/components/CrudCommonComponents/Table';

export const StyledTable = styled(Table)`
  margin-top: -1.2rem;
  height: 26.5rem;
  font-weight: 400;

  .ant-table-thead > tr > .ant-table-cell {
    font-weight: 600;
    color: #252525;
  }

  .ant-table-tbody > tr > .ant-table-cell {
    font-weight: 400;
    padding: 0.4rem 0.8rem !important;
  }

  .details-column {
    white-space: normal !important;
    div {
      height: auto !important;
    }
  }
`;
export const StyledTimeIntervalContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-bottom: 0.5rem;
  margin-bottom: 8px;
}
`;
export const StyledPatientName = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export const BoldItem = styled.span`
  font-weight: 700;
`;

export const LocationItem = styled.span`
  color: #4f595b;
`;
