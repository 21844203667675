import React from 'react';
import { SVGProps } from 'src/components/general-ui/Icon/types';
import { StyledViewIcons } from './styled';

const ListViewIcon = ({
  width = '26',
  height = '20',
  color = '#000',
  viewBox = '512 512',
  className,
  ...rest
}: SVGProps): JSX.Element => (
  <StyledViewIcons
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_6485_54078)">
      <path
        d="M23.1111 0H2.88889C1.2934 0 0 0.994923 0 2.22222C0 3.44952 1.2934 4.44444 2.88889 4.44444H23.1111C24.7066 4.44444 26 3.44952 26 2.22222C26 0.994923 24.7066 0 23.1111 0Z"
        fill={color}
      />
      <path
        d="M23.1111 7.77777H2.88889C1.2934 7.77777 0 8.77269 0 9.99999C0 11.2273 1.2934 12.2222 2.88889 12.2222H23.1111C24.7066 12.2222 26 11.2273 26 9.99999C26 8.77269 24.7066 7.77777 23.1111 7.77777Z"
        fill={color}
      />
      <path
        d="M23.1111 15.5556H2.88889C1.2934 15.5556 0 16.5505 0 17.7778C0 19.0051 1.2934 20 2.88889 20H23.1111C24.7066 20 26 19.0051 26 17.7778C26 16.5505 24.7066 15.5556 23.1111 15.5556Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_6485_54078">
        <rect width="26" height="20" fill="white" />
      </clipPath>
    </defs>
  </StyledViewIcons>
);

export default ListViewIcon;
