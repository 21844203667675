import { styled } from 'styled-components';

export const AlertSidebarHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e5e5e5;
  align-items: baseline;
  margin-bottom: 0.5rem;
`;

export const AlertSidebarTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  flex: 1;
`;

export const StyledLink = styled.div<{ isCurrent: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: ${props => (props.isCurrent ? 600 : 400)};
  font-style: normal;
  font-size: 0.8rem;
  margin-bottom: -1px;
  padding-bottom: 1.25rem;
  cursor: pointer;
  ${props => (props.isCurrent ? 'border-bottom: 2px solid black;' : '')}
`;
