import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Button } from 'antd';
import Modal from './index';

const AlertModal = ({
  isModalVisible,
  setIsModalVisible,
  message,
  intl,
  onOk,
  dataCy,
}) => (
  <Modal
    title={intl.formatMessage(messages.alert)}
    isModalVisible={isModalVisible}
    setIsModalVisible={setIsModalVisible}
    footer={[
      <Button key="okButton" onClick={onOk}>
        {intl.formatMessage(messages.ok)}
      </Button>,
    ]}
  >
    <div data-cy={dataCy}>{message}</div>
  </Modal>
);

const messages = defineMessages({
  alert: {
    defaultMessage: 'Notification',
  },
  ok: {
    defaultMessage: 'Ok',
  },
});

AlertModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onOk: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
};

export default injectIntl(AlertModal);
