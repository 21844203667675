import { Rule } from 'rc-field-form/lib/interface';

export const valueValidator = (filterName: string): Rule[] => [
  ({ getFieldValue }) => ({
    validator(_, value: number | string) {
      if (!getFieldValue(filterName)) {
        return Promise.resolve();
      }
      if (isNaN(parseInt(value.toString())) || parseInt(value.toString()) < 0) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
  }),
];
