import { styled } from 'styled-components';

import Table from 'src/components/CrudCommonComponents/Table';

export const StyledTableContainer = styled.div`
  margin-top: -1.2rem;
  height: calc(100% - 30px - 1rem);
  .ant-table-cell {
    text-align: center;
  }
  .export-csv-button {
    display: none;
  }
`;
export const StyledTable = styled(Table)`
  .ant-table-thead > tr > .ant-table-cell {
    font-weight: 600;
    color: #252525;
    text-align: center;
  }

  .ant-table-tbody > tr > .ant-table-cell {
    font-weight: 400;
    padding: 0.4rem 0.8rem !important;
  }
  .ant-table-tbody > tr > .ant-table-cell > div {
    justify-content: center;
  }

  .ant-table-tbody > tr > .ant-table-cell > .list-view-percentage-cell {
    height: 1.45rem;
    line-height: 1.45rem;
  }
`;
export const Label = styled.div<{
  withUnderline?: boolean;
  withPointer?: boolean;
}>`
  font-weight: 400;
  ${props => (props.withUnderline ? 'text-decoration-line: underline;' : '')}
  ${props => (props.withPointer ? 'cursor: pointer;' : '')}
`;

export const StyledTenantName = styled.div`
  font-weight: 400;
  cursor: pointer;
  text-decoration-line: underline;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const StyledUtilizationCell = styled.div<{
  backgroundColor: string;
}>`
  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
  display: table;
  min-width: 3.25rem;
  margin: auto;
  font-size: 0.65rem;
  color: ${props => props.theme.colors.defaultViewButtonsColor};
`;
