import React from 'react';
import { Typography } from 'antd';
import { styled } from 'styled-components';

const { Paragraph } = Typography;

const StyledEllipses = styled(Paragraph)`
  display: inline-block;
  margin-bottom: 0 !important;
  font-size: 0.7rem;
  line-height: 1rem;
  width: 100%;
`;

const EllipsisWithSuffix: React.FC<{
  children: string;
  rows: number;
}> = ({ rows, children }) => {
  const suffix =
    children?.split(' ').length > 2 ? children.split(' ').pop() : '';
  const start =
    children?.split(' ').length > 2
      ? children?.slice(0, children.length - (suffix?.length || 0)).trim()
      : children;

  return (
    <StyledEllipses
      ellipsis={{
        rows,
        suffix,
        tooltip: children,
      }}
    >
      {start}&nbsp;
    </StyledEllipses>
  );
};

export default React.memo(EllipsisWithSuffix);
