import { styled } from 'styled-components';

import Icon from 'src/components/general-ui/Icon';

export const Wrapper = styled.form<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  width: 16rem;
  ${props => (!props.isActive ? 'cursor: pointer;' : '')}
`;

export const InputWrapper = styled.div<{
  isActive: boolean;
  isInvalid: boolean;
}>`
  display: flex;
  flex-direction: row;
  padding: 0.4rem;

  padding: ${props => (props.isActive ? '0.75rem 0.4rem' : '0.4rem')};
  border: ${props => (props.isInvalid ? '1px solid #ca0000' : 'none')};
`;

export const StyledInput = styled.input<{ isActive: boolean }>`
  outline: none;
  border: none;
  flex: 1;
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 0.65rem;
  color: #252525;

  ${props =>
    !props.isActive
      ? `
    pointer-events: none;
    opacity: 0.4;
  `
      : ''}
`;
export const StyledIcon = styled(Icon)`
  height: 0.55rem;
  width: 0.45rem;
  color: #fff;
`;
export const IconContainer = styled.div<{ isActive: boolean }>`
  padding: 0.35rem 0.4rem;
  background: ${props => (props.isActive ? '#63b7c3' : '#E5E5E5')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding: 0.3rem 0.4rem 0.4rem;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RadioBtn = styled.input`
  margin-right: 0.3rem;

  accent-color: #385b75;
`;

export const RadioBtnLabel = styled.label`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 0.65rem;
  color: #252525;
  margin-right: 0.75rem;
`;
