import { defineMessages, IntlShape } from 'react-intl';

import { Sorter } from 'src/components/general-ui/Sort';
import { AlertLogItem } from '../../components/AlertLogCard/types';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import { GenericThresholdMetrics } from 'src/types/alerts';

export const messages = defineMessages({
  noAlertsRecieved: {
    defaultMessage: 'No notifications in the last 24 hours.',
  },
  noAlertsMatched: {
    defaultMessage: 'No notifications match the filters.',
  },
  timeSorter: {
    defaultMessage: 'Time',
  },
  rbSorter: {
    defaultMessage: 'Room/Bed',
  },
  alertSorter: {
    defaultMessage: 'Notification',
  },
  patientNameSorter: {
    defaultMessage: 'Patient',
  },
  loadingOnScroll: {
    defaultMessage: 'Loading...',
  },
});

export const searchKeys: (keyof AlertLogItem)[] = [
  'deviceRoomName',
  'deviceBedName',
  'patientFirstName',
  'patientLastName',
  'thresholdMetric',
  'thresholdPreposition',
];

const availableThresholdMetricSorterArray: (keyof GenericThresholdMetrics)[] = [
  ALERT_METRIC_ENUM.BED_EXIT,
  ALERT_METRIC_ENUM.ALL_DEVICES_DISCONNECTED,
  ALERT_METRIC_ENUM.DEVICE_DISCONNECTED,
  ALERT_METRIC_ENUM.LONG_OUT_OF_BED,
  ALERT_METRIC_ENUM.POSITION_CHANGE,
  ALERT_METRIC_ENUM.BED_TIME_BASELINE,
  ALERT_METRIC_ENUM.BED_EXIT_FREQUENCY,
];

export const buildSorters = (intl: IntlShape): Sorter<AlertLogItem>[] => [
  {
    key: 'time',
    label: intl.formatMessage(messages.timeSorter),
    sort: (a, b) => a.startTime.localeCompare(b.startTime),
  },
  {
    key: 'roomBed',
    label: intl.formatMessage(messages.rbSorter),
    sort: (a, b) => {
      const { deviceRoomName: aRoomName = '', deviceBedName: aBedName = '' } =
        a;
      const { deviceRoomName: bRoomName = '', deviceBedName: bBedName = '' } =
        b;
      const roomOrder = aRoomName.localeCompare(bRoomName);

      if (roomOrder) {
        return roomOrder;
      }

      return aBedName.localeCompare(bBedName);
    },
  },
  {
    key: 'alertType',
    label: intl.formatMessage(messages.alertSorter),
    sort: (a, b) => {
      const metricOrder = a.thresholdMetric.localeCompare(b.thresholdMetric);

      if (
        metricOrder ||
        availableThresholdMetricSorterArray.includes(a.thresholdMetric) ||
        availableThresholdMetricSorterArray.includes(b.thresholdMetric)
      ) {
        return metricOrder;
      }

      if (a.thresholdPreposition && b.thresholdPreposition) {
        return a.thresholdPreposition.localeCompare(b.thresholdPreposition);
      }

      return 0;
    },
  },
  {
    key: 'patient',
    label: intl.formatMessage(messages.patientNameSorter),
    sort: (a, b) => {
      const {
        patientFirstName: aFirstName = '',
        patientLastName: aLastName = '',
      } = a;
      const {
        patientFirstName: bFirstName = '',
        patientLastName: bLastName = '',
      } = b;
      const firstNameOrder = aFirstName.localeCompare(bFirstName);

      if (firstNameOrder) {
        return firstNameOrder;
      }

      return aLastName.localeCompare(bLastName);
    },
  },
];
