import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { styled } from 'styled-components';
import { Form } from 'antd';
import Modal from 'src/components/Modal';
import { userFormModes } from './constants';
import UserForm from './UserForm';
import Connector from './Connector';

const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    overflow: auto !important;
    .ant-form-item-explain-error {
      font-size: 0.7rem;
      min-height: 1rem;
    }
  }
`;

const UserModal = ({
  mode,
  isModalVisible,
  setIsModalVisible,
  onSubmit,
  currentUser,
  intl,
  modalStatus,
  setModalStatus,
  fields,
  userTypes,
  modalMessage,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
    }
  }, [isModalVisible, form]);

  const modeToTextMap = {
    [userFormModes.INVITE]: {
      title: intl.formatMessage(modalMessage || messages.inviteUser),
      okButtonText: intl.formatMessage(modalMessage || messages.invite),
    },
    [userFormModes.EDIT]: {
      title: intl.formatMessage(messages.editUser),
      okButtonText: intl.formatMessage(messages.apply),
    },
  };

  return (
    <ModalWrapper
      title={modeToTextMap[mode].title}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={modeToTextMap[mode].okButtonText}
      onOk={form.submit}
      bodyStyle={{ overflowY: 'scroll', overflowX: 'hidden' }}
      destroyOnClose
      onClose={form.resetFields}
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
      forceRender
    >
      <UserForm
        form={form}
        user={currentUser}
        onSubmit={onSubmit}
        mode={mode}
        userTypes={userTypes}
        fields={fields && { ...fields }}
      />
    </ModalWrapper>
  );
};

const messages = defineMessages({
  inviteUser: {
    defaultMessage: 'Invite User',
  },
  editUser: {
    defaultMessage: 'Edit User',
  },
  invite: {
    defaultMessage: 'Invite',
  },
  apply: {
    defaultMessage: 'Apply',
  },
});

UserModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(userFormModes)).isRequired,
  currentUser: PropTypes.object,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  fields: PropTypes.object,
  userTypes: PropTypes.array,
  modalMessage: PropTypes.object,
};

UserModal.defaultProps = {
  currentUser: {},
};

export default Connector(injectIntl(UserModal));
