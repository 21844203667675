import React, { useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { SubtenantSettingItem, StyledDropdownIcon } from '../styled';
import StatusFilterSelector from './StatusFilterSelector';
import {
  StyledFiltersContainer,
  StyledFiltersWrapper,
} from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/SubtenantStatus/styled';
import { useDetectClickOutside } from 'src/routes/NursesStation/components/RoomsAndBeds/hooks';

const SubtenantStatus = () => {
  const [displayFilters, setDisplayFilters] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useDetectClickOutside(ref, displayFilters, () => setDisplayFilters(false));
  return (
    <StyledFiltersWrapper>
      <SubtenantSettingItem
        onClick={() => setDisplayFilters(prevState => !prevState)}
      >
        <FormattedMessage {...messages.tenantStatus} />
        <StyledDropdownIcon type="dropdown-arrow-icon" />
      </SubtenantSettingItem>
      <StyledFiltersContainer ref={ref}>
        <StatusFilterSelector
          style={{ display: displayFilters ? 'block' : 'none' }}
          onActionsClick={() => setDisplayFilters(false)}
        />
      </StyledFiltersContainer>
    </StyledFiltersWrapper>
  );
};

const messages = defineMessages({
  tenantStatus: {
    defaultMessage: 'Filter',
  },
});

export default SubtenantStatus;
