import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import { styled } from 'styled-components';
import {
  CONTINUOUS_TABS_KEYS,
  historyMetricsToDisplay,
  dateTimeFormat,
} from 'src/utils/graphsUtils/graphConstants';
import HistoryInnerTabs from './HistoryInnerTabs';
import Table from './ContinuousTable';

const Container = styled.div``;

const initialState = {
  selectedSession: null,
  activeInnerTab: CONTINUOUS_TABS_KEYS.TABLE,
  filteredTableData: [],
  graphData: null,
  defaultDatesRange: { from: undefined, to: undefined },
};

const Continuous = ({
  intl,
  getAllSessions,
  sessionsData,
  sessionsDataStatus,
  viewSessionGraphs,
  allSessionsGraphsPoints,
  patientId,
}) => {
  const [selectedSession, setSelectedSession] = useState(
    initialState.selectedSession,
  );
  const [activeInnerTab, setActiveInnerTab] = useState(
    initialState.activeInnerTab,
  );
  const [filteredTableData, setFilteredTableData] = useState(
    initialState.filteredTableData,
  );
  const [graphData, setGraphData] = useState(initialState.graphData);
  const [defaultDatesRange, setDefaultDatesRange] = useState(
    initialState.defaultDatesRange,
  );

  useEffect(() => {
    getAllSessions({ patientId });
  }, [getAllSessions, patientId]);

  const filterSessionDataByDates = useCallback(
    (from, to) => {
      if (from && to) {
        // Table
        const filteredByDates = sessionsData?.filter(
          record =>
            new Date(record.startTime) >= new Date(from) &&
            new Date(record.endTime) <= new Date(to),
        );
        setFilteredTableData(filteredByDates || []);
      } else {
        setFilteredTableData(sessionsData || []);
      }
    },
    [sessionsData],
  );

  useEffect(() => {
    filterSessionDataByDates(defaultDatesRange.from, defaultDatesRange.to);
  }, [defaultDatesRange, filterSessionDataByDates, sessionsData]);

  useEffect(() => {
    setGraphData(allSessionsGraphsPoints?.[selectedSession]);
  }, [selectedSession, allSessionsGraphsPoints]);

  const onViewSessionClicked = sessionInfo => {
    const { sessionId, startTime, endTime } = sessionInfo;
    const fromMoment = moment(startTime, dateTimeFormat);
    const toMoment = moment(endTime, dateTimeFormat);
    setSelectedSession(sessionId);
    viewSessionGraphs({
      sessionId,
      metrics: historyMetricsToDisplay,
      patientId,
    });
    setDefaultDatesRange({ from: fromMoment, to: toMoment });
    filterSessionDataByDates(fromMoment, toMoment);
    onTabChange(CONTINUOUS_TABS_KEYS.GRAPHS);
  };

  const onTabChange = tabKey => {
    setActiveInnerTab(tabKey);
  };

  // currently we filter the data for all tabs in advanced.
  // we can do it differently and filter only for the current tab and send the dates to onTabChangeSync
  // but then it is possible that we filter even if the user didn't press on apply
  const filterDataByDates = (startTime, endTime) => {
    filterSessionDataByDates(startTime, endTime);
  };

  const innerTabs = [
    {
      key: CONTINUOUS_TABS_KEYS.TABLE,
      title: intl.formatMessage(messages.tableTabTitle),
      contentComponent: () => (
        <Table
          status={sessionsDataStatus}
          data={filteredTableData}
          onViewClicked={onViewSessionClicked}
        />
      ),
    },
    // {
    //   key: CONTINUOUS_TABS_KEYS.GRAPHS,
    //   title: intl.formatMessage(messages.graphsTabTitle),
    //   contentComponent: () => (
    //     <ContinuousGraphs
    //       data={graphData}
    //     />
    //   ),
    // },
  ];
  return (
    <Container>
      <HistoryInnerTabs
        filterDataByDates={filterDataByDates}
        tabs={innerTabs}
        activeTabKey={activeInnerTab}
        onTabChangeSync={onTabChange}
        defaultDatesRange={defaultDatesRange}
      />
    </Container>
  );
};

Continuous.propTypes = {
  getAllSessions: PropTypes.func.isRequired,
  sessionsDataStatus: PropTypes.string,
  sessionsData: PropTypes.array,
  viewSessionGraphs: PropTypes.func.isRequired,
  allSessionsGraphsPoints: PropTypes.object,
  patientId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
};

Continuous.defaultProps = {
  allSessionsGraphsPoints: null,
  sessionsDataStatus: '',
  sessionsData: [],
};

const messages = defineMessages({
  tableTabTitle: {
    defaultMessage: 'Table',
  },
  graphsTabTitle: {
    defaultMessage: 'Graphs',
  },
});

export default injectIntl(Continuous);
