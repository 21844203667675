import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { Tooltip } from 'antd';

const LongColumn = styled.div`
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LongTableColumn = ({ text }) => (
  <Tooltip title={text} placement="bottomLeft">
    <LongColumn>{text}</LongColumn>
  </Tooltip>
);

LongTableColumn.propTypes = {
  text: PropTypes.string,
};
LongTableColumn.defaultProps = {
  text: null,
};
export default LongTableColumn;
