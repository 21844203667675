import { styled } from 'styled-components';
import { Select, Typography } from 'antd';

import { media } from 'src/utils/mediaQueryUtils';
import {
  FlexColVerticalCenter,
  JustifyBetween,
  FlexRowVerticalCenter,
  FlexRowVerticalBaseline,
  TextCenter,
  Ml10,
  FontSize15,
} from 'src/utility-styles';
import Icon from 'src/components/general-ui/Icon';

const { Paragraph } = Typography;
export const StyledIcon = styled(Icon)<{ opacity: string }>`
  min-width: 1rem;
  width: 1.5rem;
  height: 1rem;
  opacity: ${props => props.opacity};
`;

export const IconText = styled.div`
  font-size: 0.6rem;
`;

export const PatientDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.theme.colors.individualDashboardBackground};
  padding-left: 2%;
`;

export const PatientInformationSide = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: calc(98% - 300px);
  padding-top: 0.6rem;

  .ant-tooltip {
    backgorund-color: white;
  }
`;

export const PatientInfoWrapper = styled.div`
  ${FlexRowVerticalCenter}
  ${Ml10}
  white-space: nowrap;
  max-width: calc(100% - 200px);
`;

export const PatientIconStatusWrapper = styled.div`
  ${FlexRowVerticalBaseline}
  ${JustifyBetween}
  white-space: nowrap;
  gap: 0.5rem;
`;

export const FlexColWrapper = styled.div<{ alignSelf?: string }>`
  ${FlexColVerticalCenter}
  margin-right: 1rem;
  align-self: ${props => props.alignSelf && 'self-end'};

  p {
    ${FontSize15}
    ${TextCenter}
    margin-bottom: 0;
  }
`;

export const Title = styled.span`
  color: ${props => props.theme.colors.individualDashboardBoxTitleColor};
  padding-right: 10px;

  ${media.small`
    font-size: 16px;
    line-height: 16px;
  `}
  ${media.extraLarge`
    font-size: 24px;
    line-height: 19px;
  `}
`;

export const SpacedItem = styled.span`
  margin-left: 7px;
`;

export const SmallTitle = styled.span`
  color: ${props => props.theme.colors.individualdDashboardHeaderTextColor};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.7rem;
  line-height: 1rem;
  padding: 0 0.5rem;
`;

export const RoomLocationWrap = styled.div`
  ${media.small`
    max-width: 6rem;
  `}

  ${media.extraLarge`
    max-width: 8rem;
  `}
`;

export const VerticalSeparator = styled.span`
  color: ${props => props.theme.colors.individualdDashboardHeaderTextColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.75rem;
`;

export const OptionsSide = styled.span`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  justify-content: end;

  ${media.small`
    padding-right: 1%;
  `}
  ${media.extraLarge`
    padding-right: 30px;
  `}
`;

export const PatientSelectorWrapper = styled(Select)`
  width: 220px;
  .ant-select-selector {
    margin-top: 2px;
    width: 220px;
    background: ${props => props.theme.colors.individualDashboardSelectorWhite};
    border: ${props =>
      `1px solid ${props.theme.colors.individualDashboardBorderGrey}`} !important;
    border-radius: 3px !important;
    height: 2rem !important;

    &:hover {
      border: ${props =>
        `1px solid ${props.theme.colors.individualDashboardBorderGrey}`} !important;
      outline: none !important;
    }
  }
  .ant-select-selection-placeholder {
    color: ${props => props.theme.colors.individualDashboardSelectorText};
    font-size: 0.65rem;
    line-height: 1.8rem !important;
  }
  .ant-select-selection-item {
    span:nth-child(2) {
      display: none;
    }
    ${media.small`
      line-height: 32px !important;
    `}
    ${media.extraLarge`
      line-height: 40px !important;
    `}
  }
  .ant-select-item {
    font-family: ${props => props.theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    color: ${props => props.theme.colors.individualDashboardSelectorText};
    &:hover {
      background: ${props =>
        props.theme.colors.individualDashboardHoverBackground};
    }
    .ant-select-item-option-selected {
      background: ${props =>
        props.theme.colors.individualDashboardHoverBackground};
    }

    ${media.small`
      font-size: 11px;
      line-height: 16px;
    `}
    ${media.extraLarge`
      font-size: 13px;
      line-height: 20px;
    `}
  }
  .ant-select-arrow {
    height: 12px;
  }
  .dropdown-info-text {
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.5px;
    color: ${props => props.theme.colors.individualDashboardTextGrey};
    border-top: ${props =>
      ` 1px solid ${props.theme.colors.individualDashboardBorderGrey3}`};
    padding: 9px 0;
    cursor: default;
    background: ${props => props.theme.colors.individualDashboardSelectorWhite};

    &:hover {
      background: ${props =>
        props.theme.colors.individualDashboardSelectorWhite};
    }

    ${media.small`
      font-size: 9px !important;
      line-height: 11px;
    `}
    ${media.extraLarge`
      font-size: 11px !important;
      line-height: 13px;
    `}
  }
  .ant-select-dropdown {
    border: ${props =>
      `1px solid ${props.theme.colors.individualDashboardBorderGrey}`};
    border-radius: 5px;
    box-shadow: none;
  }
  .search-input {
    background: ${props => props.theme.colors.individualDashboardSelectorWhite};
    padding: 5px 10px;
    margin-bottom: 5px;
    input {
      background: ${props =>
        props.theme.colors.individualDashboardSelectorWhite};
      border: ${props =>
        `1px solid ${props.theme.colors.individualDashboardBorderGrey2}`};
      border-radius: 2px;
      font-family: ${props => props.theme.fonts.primary};
      font-style: normal;
      font-weight: 400;
      color: ${props => props.theme.colors.individualDashboardSelectorGrey};
      &:focus {
        outline: none !important;
      }
      &:hover {
        border: ${props =>
          `1px solid ${props.theme.colors.individualDashboardBorderGrey2}`} !important;
        border-right: none !important;
      }
      ::placeholder {
        color: ${props => props.theme.colors.individualDashboardSelectorGrey};
        opacity: 1;
        border: none !important;
      }

      ${media.small`
        font-size: 10px;
        line-height: 12px;
        height: 18px;
        margin-top: 2px;
      `}
      ${media.extraLarge`
        font-size: 12px;
        line-height: 14px;
        height: 24px;
        margin-top: 0px;
      `}
    }
    button {
      border: ${props =>
        `1px solid ${props.theme.colors.individualDashboardBorderGrey2}`};
      border-left: none;
      span {
        transform: scale(0.7);
      }
      ${media.small`
        height: 18px;
      `}
      ${media.extraLarge`
        height: 24px;
      `}
    }
    &:hover {
      background: ${props =>
        props.theme.colors.individualDashboardSelectorWhite};
    }
  }
`;

export const ActionButton = styled.img<{ isDisabled: boolean }>`
  margin-left: 0.4rem;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  width: 2.1rem;
  height: 2.2rem;
`;

export const PatientDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PatientName = styled.span`
  display: inline-block;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${media.small`
    line-height: 22px;
    font-size: 10px;
  `}
  ${media.extraLarge`
    line-height: 38px;
    font-size: 14px;
  `}
`;

export const PatientStatus = styled.span`
  border: ${props =>
    `0.7px solid ${props.theme.colors.individualDashboardBorderGrey4}`};

  color: ${props => props.theme.colors.individualDashboardStatusColor};
  text-align: center;
  border-radius: 8.5px;
  align-self: center;

  ${media.small`
    width: 44px;
    height: 12px;
    line-height: 12px;
    font-size: 6px;
  `}
  ${media.extraLarge`
    width: 62px;
    line-height: 17px;
    height: 17px;
    font-size: 9px;
  `}
`;

export const LocationItem = styled.span`
  color: #4f595b;
`;

export const StyledParagraph = styled(Paragraph)`
  &.ant-typography {
    margin-bottom: 0;
  }
`;

export const AlertNotesWrapper = styled.div`
  svg {
    min-width: 1rem;
    width: 1.5rem;
    height: 1rem;
  }
`;
