import { styled } from 'styled-components';

import TypedCustomTooltip from 'src/components/TypedCustomTooltip';

const AlertTooltip = styled(TypedCustomTooltip)`
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #ffffff;
  }
  div.ant-tooltip-inner {
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 600;
    font-size: 0.6rem;
    line-height: 0.75rem;
    color: #252525;
    padding: 0.5rem 0.6rem;

    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.233002);
    border-radius: 3px;
  }
`;

export default AlertTooltip;
