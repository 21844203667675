import { styled } from 'styled-components';

import Tooltip from 'src/components/general-ui/Tooltip';
import { Typography } from 'antd';

const { Paragraph } = Typography;
export const StyledOpenBtn = styled.div`
  cursor: pointer;
  align-self: flex-start;
`;

export const StyledContainer = styled.div`
  font-size: 0.65rem;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;
export const StyledWrapper = styled.div`
  padding: 0.6rem;
`;

export const StyledLocationData = styled(Paragraph)`
  gap: 0.2rem;
  display: flex;
`;

export const StyledLocationInfoContainer = styled.div`
  gap: 0.3rem;
  display: flex;
`;
export const StyledLocationValue = styled.div`
  font-weight: 800;
`;

export const StyledCheckboxText = styled.div`
  margin-right: 0.5rem;
`;

export const PatientSettingsTooltip = styled(Tooltip)`
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #d8d8d8;
  }
  div.ant-tooltip-inner {
    font-family: ${props => props.theme.fonts.primary};
    color: #252525;
    padding: 0;
    width: 10rem;

    background: #ffffff;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-left: 0.5px solid #dddddd;
    border-right: 0.5px solid #dddddd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.125109);
    border-radius: 3px;
  }
`;
