import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { styled } from 'styled-components';

import {
  mapPatientStateToState,
  patientStateToMessageMap,
} from 'src/utils/constants';
import { Row } from 'src/components/styled';
import EmptyStateIcon from './resources/Empty.svg';
import OccupiedStateIcon from './resources/Occupied.svg';
import ConnectedStateIcon from './resources/Connected.svg';
import DisconnectedStateIcon from './resources/Disconnected.svg';
import { DeviceMonitorStatus } from 'src/types/devices';

const deviceStateIconEnum = {
  empty: EmptyStateIcon,
  occupied: OccupiedStateIcon,
  disconnected: DisconnectedStateIcon,
  connected: ConnectedStateIcon,
};

const StyledDeviceStatus = styled(Row)``;

const StyledStateIcon = styled.img`
  height: 1.2rem;
  width: 1.2rem;
  margin-right: ${props => (props.displayMessage ? 8 : 0)}px;
`;

const StyledMessage = styled.div`
  align-self: center;
`;

const DeviceStatus = props => {
  const {
    intl,
    isDeviceConnected,
    patientState,
    deviceStatus,
    displayIcon,
    displayMessage,
    iconSize,
  } = props;

  const getPatientStateMessage = () => {
    let patientStateMessage = patientStateToMessageMap.DISCONNECTED;
    if (isDeviceConnected) {
      if (patientState === null) {
        patientStateMessage =
          deviceStatus === DeviceMonitorStatus.ACTIVE
            ? patientStateToMessageMap.EMPTY
            : patientStateToMessageMap.CONNECTED;
      } else {
        patientStateMessage =
          mapPatientStateToState[patientState] ||
          patientStateToMessageMap.EMPTY;
      }
    } else {
      patientStateMessage = patientStateToMessageMap.DISCONNECTED;
    }
    return intl.formatMessage(messages[patientStateMessage]);
  };

  const getPatientState = () => {
    if (isDeviceConnected) {
      if (patientState === null) {
        return deviceStatus === DeviceMonitorStatus.ACTIVE
          ? patientStateToMessageMap.EMPTY
          : patientStateToMessageMap.CONNECTED;
      }
      return (
        mapPatientStateToState[patientState] || patientStateToMessageMap.EMPTY
      );
    } else {
      return deviceStatus === DeviceMonitorStatus.ACTIVE
        ? patientStateToMessageMap.EMPTY
        : patientStateToMessageMap.DISCONNECTED;
    }
  };

  return (
    <StyledDeviceStatus>
      {displayIcon && (
        <StyledStateIcon
          iconSize={iconSize}
          displayMessage={displayMessage}
          src={deviceStateIconEnum[getPatientState()]}
          alt={intl.formatMessage(messages?.[getPatientState()])}
        />
      )}
      {displayMessage && (
        <StyledMessage>{getPatientStateMessage()}</StyledMessage>
      )}
    </StyledDeviceStatus>
  );
};

DeviceStatus.propTypes = {
  isDeviceConnected: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  patientState: PropTypes.string,
  deviceStatus: PropTypes.string,
  displayIcon: PropTypes.bool,
  displayMessage: PropTypes.bool,
  iconSize: PropTypes.number,
};

DeviceStatus.defaultProps = {
  displayIcon: false,
  displayMessage: false,
  iconSize: 25,
  patientState: null,
};

const messages = defineMessages({
  disconnected: {
    defaultMessage: 'Disconnected',
  },
  connected: {
    defaultMessage: 'Ready for use',
  },
  idle: {
    defaultMessage: 'Idle',
  },
  occupied: {
    defaultMessage: 'Occupied',
  },
  connectionError: {
    defaultMessage: 'Connection Error',
  },
  empty: {
    defaultMessage: 'Empty',
  },
});

export default injectIntl(DeviceStatus);
