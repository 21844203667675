import React from 'react';
import { styled } from 'styled-components';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Button } from 'antd';

const StyledButton = styled(Button)`
  width: 3rem;
  padding: 0;
  font-family: ${props => props.theme.fonts.secondary};
`;
const ClickedButton = styled(StyledButton)`
  background-color: ${props => props.theme.colors.dateTypeButtonBackground};
`;

type Props = {
  isSelected: boolean;
  message: MessageDescriptor;
  onChangeDateType: () => void;
};

const DateTypeButton = ({
  message,
  isSelected,
  onChangeDateType,
}: Props): JSX.Element => {
  const _renderContent = () =>
    isSelected ? (
      <ClickedButton onClick={onChangeDateType}>
        <FormattedMessage {...message} />
      </ClickedButton>
    ) : (
      <StyledButton onClick={onChangeDateType}>
        <FormattedMessage {...message} />
      </StyledButton>
    );
  return _renderContent();
};

export default DateTypeButton;
