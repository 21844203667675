import React from 'react';
import { styled } from 'styled-components';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Button } from 'antd';

import theme from 'src/themes/theme';
import { TREND_LABELS } from 'src/utils/graphsUtils/graphConstants';
import { StatisticsTrendType } from 'src/routes/IndividualDashboard/modules/statistics/types';

const StyledButton = styled(Button)`
  padding: 0.4rem 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.2rem;
  justify-content: space-around;
  font-family: ${props => props.theme.fonts.secondary};
`;

const StyledSelectedButton = styled(StyledButton)`
  background-color: ${props => props.theme.colors.trendButtonBackground};
`;

const StyledHr = styled.hr`
  margin-left: 0.5rem;
  width: 1.5rem;
  color: ${props => props.color};
  height: 0.2rem;
`;

const messages = defineMessages({
  trendMax: {
    defaultMessage: 'Trend Max',
  },
  trendMin: {
    defaultMessage: 'Trend Min',
  },
  trendAvg: {
    defaultMessage: 'Trend Avg',
  },
});

const trendMap = {
  [TREND_LABELS.MAX_TREND]: {
    text: messages.trendMax,
    color: theme.colors.maxTrendLine,
  },
  [TREND_LABELS.MIN_TREND]: {
    text: messages.trendMin,
    color: theme.colors.minTrendLine,
  },
  [TREND_LABELS.AVG_TREND]: {
    text: messages.trendAvg,
    color: theme.colors.avgTrendLine,
  },
};

type Props = {
  trendType: StatisticsTrendType;
  isSelected: boolean;
  onClick: (trendType: StatisticsTrendType) => void;
};

const TrendButton = ({
  trendType,
  isSelected,
  onClick,
}: Props): JSX.Element => {
  const props = {
    onClick: () => onClick(trendType),
  };
  const RenderButton = isSelected ? StyledSelectedButton : StyledButton;
  return (
    <RenderButton {...props}>
      <FormattedMessage {...trendMap[trendType].text} />
      <StyledHr color={trendMap[trendType].color} />
    </RenderButton>
  );
};

export default TrendButton;
