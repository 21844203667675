import React from 'react';

import { Alert } from 'src/types/alerts';
import {
  AlertIndicatorWrapper,
  StyledAlertIcon,
  StyledArrowIcon,
  StyledTriggerValue,
} from './styled';
import { alertIcons, getArrowIcons } from './utils';
import { useTheme } from 'styled-components';
import { ALERT_STATUS_ENUM } from 'src/utils/constants';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import {
  isAbnormalAlert,
  isAverageAlert,
  isBaselineAlert,
  isTechnicalAlert,
  isVacancyAlert,
} from 'src/redux/data/alerts/modules/typeGuards';
import { getLongOutOfBedTriggerValue } from 'src/utils/alertHelpers';

type Props = Alert & {
  className?: string;
};

const AlertIndicator = ({
  triggerValue,
  thresholdMetric,
  thresholdPreposition,
  deviationPercentage,
  status,
  className,
}: Props): JSX.Element | null => {
  const theme = useTheme();
  const color =
    status === ALERT_STATUS_ENUM.SUPPRESSED
      ? theme.colors.suppressedAlertColor
      : theme.colors.alertBorder;

  if (isVacancyAlert(thresholdMetric)) {
    const longOutOfBedSuffixValue = `${triggerValue && triggerValue >= 60 ? `h+` : `m`}`;
    const longOutOfBedTriggerValue = `${getLongOutOfBedTriggerValue(triggerValue)}${longOutOfBedSuffixValue}`;

    return (
      <AlertIndicatorWrapper color={color} className={className}>
        <StyledAlertIcon
          type={alertIcons[thresholdMetric]}
          color={color}
          iwidth="1rem"
          imarginright="0.2rem"
        />
        {thresholdMetric === ALERT_METRIC_ENUM.POSITION_CHANGE && (
          <StyledTriggerValue ml="0.25rem">{`${triggerValue ?? 0}h+`}</StyledTriggerValue>
        )}
        {thresholdMetric === ALERT_METRIC_ENUM.LONG_OUT_OF_BED && (
          <StyledTriggerValue ml="0.25rem">
            {longOutOfBedTriggerValue}
          </StyledTriggerValue>
        )}
      </AlertIndicatorWrapper>
    );
  }

  if (isAbnormalAlert(thresholdMetric)) {
    const suffix =
      thresholdMetric === ALERT_METRIC_ENUM.BED_TIME_BASELINE ? '%' : '';
    return (
      <AlertIndicatorWrapper color={color} className={className}>
        <StyledAlertIcon
          type={alertIcons[thresholdMetric]}
          color={color}
          iwidth="1rem"
          imarginright="0.2rem"
        />
        <StyledTriggerValue>
          {triggerValue}
          {suffix}
        </StyledTriggerValue>
      </AlertIndicatorWrapper>
    );
  }

  if (isBaselineAlert(thresholdMetric)) {
    return (
      <AlertIndicatorWrapper color={color} className={className}>
        <StyledAlertIcon type={alertIcons[thresholdMetric]} color={color} />
        <StyledArrowIcon
          type={getArrowIcons(thresholdMetric, thresholdPreposition)}
          height="1rem"
        />
        <StyledTriggerValue>
          {!!deviationPercentage && Math.floor(Math.abs(deviationPercentage))}%
        </StyledTriggerValue>
      </AlertIndicatorWrapper>
    );
  }

  if (isTechnicalAlert(thresholdMetric)) {
    return (
      <StyledAlertIcon
        type={alertIcons.DEVICE_DISCONNECTED}
        color={color}
        iwidth="2rem"
      />
    );
  }

  if (isAverageAlert(thresholdMetric)) {
    return (
      <AlertIndicatorWrapper color={color} className={className}>
        <StyledAlertIcon type={alertIcons[thresholdMetric]} color={color} />
        <StyledArrowIcon
          type={getArrowIcons(thresholdMetric, thresholdPreposition)}
        />
        <StyledTriggerValue>
          {!!triggerValue && Math.round(triggerValue)}
        </StyledTriggerValue>
      </AlertIndicatorWrapper>
    );
  }

  return null;
};

export default AlertIndicator;
