import { UUID } from 'src/types/utility';
import ums from 'src/libs/ums-js-logic';
import history from 'src/utils/history';
import { DEFAULT_LOCALE } from 'src/utils/languageUtils';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';

export const redirectToPatientIDB = (patientId: UUID | undefined | null) => {
  const impersonatedSubtenantId = ums.getImpersonatedSubtenantId();
  const searchParams = new URLSearchParams();
  searchParams.set('patientId', patientId || '');
  if (impersonatedSubtenantId) {
    searchParams.set('impersonatedSubtenantId', impersonatedSubtenantId);
  }
  history.push({
    pathname: `/${DEFAULT_LOCALE}/${appRoutes.INDIVIDUAL_DASHBOARD}`,
    search: searchParams.toString(),
  });
};
