import React from 'react';

import Search from 'src/components/general-ui/Search';
import { styled } from 'styled-components';
import Connector, { PropsFromRedux } from './Connector';

type Props = PropsFromRedux;

const StyledSearch = styled(Search)`
  & > input {
    outline-color: #252525;
    outline-style: solid;
    outline-width: thin;
  }
`;

const DeviceSearch = ({
  searchQuery,
  applySearchQuery,
}: Props): JSX.Element => {
  return (
    <StyledSearch
      value={searchQuery}
      onChange={e => applySearchQuery(e.target.value)}
    />
  );
};

export default Connector(DeviceSearch);
