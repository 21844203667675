import {
  createSlice,
  createSelector,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';

import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { RootState } from 'src/redux/store';
import { AlertLog, AlertSoundSettings } from 'src/types/alerts';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import { DeviceFilter, StateFilters } from './filterUtils';
import { SubTenantOverview } from './types';
import { PageMetaDataType, UUID } from 'src/types/utility';
import { API_STATUS } from 'src/utils/api-constants';
import { DeviceDisconnectionOverview } from 'src/types/devices';
import { Dayjs } from 'dayjs';

export const STATE_KEY = 'groupManagerDashboard';

export const INITIAL_STATE: {
  alertSoundStatus: AlertSoundSettings;
  fetchStatus: keyof typeof DATA_FETCHING_STATUS;
  filters: StateFilters;
  searchQuery: string;
  alertLogs: {
    data: Record<UUID, AlertLog[]>;
    apiStatus: API_STATUS;
    pageMetadata?: PageMetaDataType;
  };
  devicesDisconnectionOverview: {
    data: Record<UUID, DeviceDisconnectionOverview[]>;
    apiStatus: API_STATUS;
    pageMetadata: Record<UUID, PageMetaDataType>;
  };
} = {
  alertSoundStatus: AlertSoundSettings.ENABLED,
  fetchStatus: DATA_FETCHING_STATUS.SUCCESS,
  filters: {
    status: [],
  },
  searchQuery: '',
  alertLogs: {
    data: {},
    apiStatus: API_STATUS.LOADING,
    pageMetadata: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
  },
  devicesDisconnectionOverview: {
    data: {},
    apiStatus: API_STATUS.LOADING,
    pageMetadata: {},
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action: PayloadAction<DeviceFilter>) => {
      const { filter, value } = action.payload;

      switch (filter) {
        case 'status':
          state.filters[filter] = [...value];
          return;
      }
    },
    resetFilters: (state, action: PayloadAction<DeviceFilter['filter']>) => {
      const filter = action.payload;

      switch (filter) {
        case 'status':
          state.filters[filter] = [];
          return;
      }
    },
    applySearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    changeAlertSoundStatus: (
      state,
      action: PayloadAction<AlertSoundSettings>,
    ) => {
      state.alertSoundStatus = action.payload;
    },
    fetchSubtenantAlertLogSuccess: (
      state,
      action: PayloadAction<{ subtenantId: UUID; alerts: AlertLog[] }>,
    ) => {
      const { subtenantId, alerts } = action.payload;

      state.alertLogs.apiStatus = API_STATUS.OK;
      state.alertLogs.data[subtenantId] = alerts;
    },
    fetchSubtenantAlertLogMetadataSuccess: (
      state,
      action: PayloadAction<PageMetaDataType | undefined>,
    ) => {
      state.alertLogs.pageMetadata = action?.payload;
    },
    fetchSubtenantAlertLogFailed: state => {
      state.alertLogs.apiStatus = API_STATUS.ERROR;
    },
    fetchSubtenantDisconnectionOverviewSuccess: (
      state,
      action: PayloadAction<{
        subtenantId: UUID;
        disconnectionOverview: DeviceDisconnectionOverview[];
        metaData: PageMetaDataType;
      }>,
    ) => {
      const { subtenantId, disconnectionOverview, metaData } = action.payload;

      state.devicesDisconnectionOverview.apiStatus = API_STATUS.OK;
      state.devicesDisconnectionOverview.data[subtenantId] =
        disconnectionOverview;
      state.devicesDisconnectionOverview.pageMetadata[subtenantId] =
        metaData || {};
    },
    fetchSubtenantDisconnectionOverviewFailed: state => {
      state.devicesDisconnectionOverview.apiStatus = API_STATUS.ERROR;
    },
  },
  extraReducers: builder => {
    builder.addCase(actions.fetchSubtenantAlertLog, state => {
      state.alertLogs.apiStatus = API_STATUS.LOADING;
    });
    builder.addCase(actions.fetchSubtenantDisconnectionOverview, state => {
      state.devicesDisconnectionOverview.apiStatus = API_STATUS.LOADING;
    });
  },
});

const extraActions = {
  gmDashboardPageMounted: createAction(`${STATE_KEY}/gmDashboardPageMounted`),
  gmDashboardPageUnmounted: createAction(
    `${STATE_KEY}/gmDashboardPageUnmounted`,
  ),
  fetchSubtenantAlertLog: createAction<{
    subtenantId: UUID;
    currentPage: number | string;
    pageSize: string | number;
    startDateTime?: Dayjs;
    endDateTime?: Dayjs;
  }>(`${STATE_KEY}/fetchSubtenantAlertLog`),
  fetchSubtenantDisconnectionOverview: createAction<{
    subtenantId: UUID;
    continuousDisconnectionSeconds: number;
    currentPage: number | string;
    pageSize: string | number;
  }>(`${STATE_KEY}/fetchSubtenantDisconnectionOverview`),
};

const getState = (state: RootState) => state[STATE_KEY] || INITIAL_STATE;

const getSubtenantId = (_state: RootState, subtenantId: UUID) => subtenantId;

export const selectors = {
  selectFilters: createSelector(getState, state => state.filters),
  selectSearchQuery: createSelector(getState, state => state.searchQuery),
  selectAlertSoundStatus: createSelector(
    getState,
    state => state.alertSoundStatus,
  ),
  selectSubtenantAlertLog: createSelector(
    getState,
    getSubtenantId,
    (state, subtenantId) => state.alertLogs.data[subtenantId] || [],
  ),
  selectSubtenantAlertLogMetadata: createSelector(
    getState,
    state => state.alertLogs.pageMetadata,
  ),
  selectSubtenantAlertLogStatus: createSelector(
    getState,
    state => state.alertLogs.apiStatus,
  ),
  selectSubtenantDisconnectionOverview: createSelector(
    getState,
    getSubtenantId,
    (state, subtenantId) =>
      state.devicesDisconnectionOverview.data[subtenantId] || [],
  ),
  selectSubtenantDisconnectionOverviewMetadata: createSelector(
    getState,
    getSubtenantId,
    (state, subtenantId) =>
      state.devicesDisconnectionOverview.pageMetadata[subtenantId],
  ),
  selectSubtenantDisconnectionOverviewStatus: createSelector(
    getState,
    state => state.devicesDisconnectionOverview.apiStatus,
  ),
};

export const extraSelectors = {
  selectSubtenants: createSelector(
    (state: RootState) => state,
    tenantSelectors.getTenantsList,
    tenantSelectors.getTenantsAlertCounters,
    (state, tenants, tenantsAlertCounters): SubTenantOverview[] =>
      tenants.map(tenant => {
        const deviceInfoData = deviceSelectors.selectSubtenantDeviceStatistics(
          state,
          tenant.id,
        );
        const tenantAlertsCounters = tenantsAlertCounters?.tenants?.[tenant.id];
        const potentialUtilization = deviceInfoData.totalDevices
          ? +Number(
              ((deviceInfoData.connectedAssignedDevices +
                deviceInfoData.disconnectedAssignedDevices) /
                deviceInfoData.totalDevices) *
                100,
            ).toFixed(2)
          : 0;
        const currentUtilization = deviceInfoData.totalDevices
          ? +Number(
              (deviceInfoData.connectedAssignedDevices /
                deviceInfoData.totalDevices) *
                100,
            ).toFixed(0)
          : 0;
        return {
          id: tenant.id,
          name: tenant.name,
          noConsent: deviceInfoData.noConsent,
          timeZoneOffset: tenant.timeZoneOffset,
          timeZoneId: tenant.timeZoneId,
          address: tenant.address,
          totalDevicesCounter: deviceInfoData.totalDevices,
          disconnectedAssignedDevicesCounter:
            deviceInfoData.disconnectedAssignedDevices,
          disconnectedUnassignedDevicesCounter:
            deviceInfoData.disconnectedUnassignedDevices,
          connectedAssignedDevicesCounter:
            deviceInfoData.connectedAssignedDevices,
          connectedUnassignedDevicesCounter:
            deviceInfoData.connectedUnassignedDevices,
          lastDayAlertsCounter: tenantAlertsCounters
            ? tenantAlertsCounters.currentAlertsCount +
              tenantAlertsCounters.attentionListAlertsCount
            : 0,
          currentUtilization,
          potentialUtilization,
          gapUtilization: potentialUtilization - currentUtilization,
          hasActiveAlert: !!(
            tenantAlertsCounters &&
            tenantAlertsCounters?.onStatusAlertsCount > 0
          ),
        };
      }),
  ),
};

export const actions = { ...slice.actions, ...extraActions };
const { reducer } = slice;
export default reducer;
