import React from 'react';
import DisconnectionAlertsSettingsForm from 'src/components/AlertSettingsComponents/DisconnectionAlertsSettingsForm';
import Connector, { Props } from './Connector';
import { StyledWrapper } from './styled';
import { TenantSubscriptionDetails } from 'src/routes/GMDashboard/modules/types';

const ConnectivityAlertsTab = ({
  subscriptionDetails,
  setSubscriptionDetails,
  setErrors,
}: Props & {
  subscriptionDetails: TenantSubscriptionDetails;
  setSubscriptionDetails: React.Dispatch<
    React.SetStateAction<TenantSubscriptionDetails>
  >;
}): JSX.Element => {
  return (
    <StyledWrapper>
      <DisconnectionAlertsSettingsForm
        technicalSubscriptionDetails={subscriptionDetails.TECHNICAL}
        handleSubscriptionDetails={technicalSubscriptionDetails => {
          setSubscriptionDetails(prevState => ({
            ...prevState,
            TECHNICAL: technicalSubscriptionDetails,
          }));
        }}
        setHasErrors={isError =>
          setErrors(prevState => ({
            ...prevState,
            technicalAlerts: isError,
          }))
        }
      />
    </StyledWrapper>
  );
};

export default Connector(ConnectivityAlertsTab);
