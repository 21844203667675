import React, { useEffect, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { UUID } from 'src/types/utility';
import { ActiveUserWithStatus } from 'src/redux/data/user/modules/types';
import SubscribersTable from './SubscribersTable';
import { getSMSEmailAlertsColumns } from './tableConfig';
import { StyledService } from './styled';
import Connector, { PropsFromRedux } from './Connector';
import { SubscriptionService, validateUsersByService } from './utils';
import { mergeArraysWithCustomUniqueIds } from 'src/redux/data/dataUtils';
import {
  AlertCategory,
  UserActivitySubscription,
  UserMedicalSubscription,
  UserTechnicalSubscription,
} from 'src/types/subscribers';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const AlertNotificationSettings = ({
  hpUsers,
  onLoadSMSEmailAlert,
  hpSubscriptionsByTargetMedia,
  nonHpSubscriptionsByTargetMedia,
  updateUserPhone,
  updateSubscribersList,
  isLoadingSMSEmailAlerts,
  intl,
}: Props): JSX.Element => {
  const [tableData, setTableData] = useState<ActiveUserWithStatus[]>([]);
  const columns = getSMSEmailAlertsColumns(intl);
  const [usersAssignedToMedicalSMS, setUsersAssignedToMedicalSMS] = useState<
    UserMedicalSubscription[]
  >([]);
  const [usersAssignedToMedicalEmail, setUsersAssignedToMedicalEmail] =
    useState<UserMedicalSubscription[]>([]);
  const [usersAssignedToTechnicalSMS, setUsersAssignedToTechnicalSMS] =
    useState<UserTechnicalSubscription[]>([]);
  const [usersAssignedToTechnicalEmail, setUsersAssignedToTechnicalEmail] =
    useState<UserTechnicalSubscription[]>([]);
  const [usersAssignedToActivitySMS, setUsersAssignedToActivitySMS] = useState<
    UserActivitySubscription[]
  >([]);
  const [usersAssignedToActivityEmail, setUsersAssignedToActivityEmail] =
    useState<UserActivitySubscription[]>([]);

  const [changesStatus, setChangesStatus] = useState(false);
  useEffect(() => {
    onLoadSMSEmailAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hpSubscriptionsByTargetMedia) {
      setUsersAssignedToMedicalSMS(
        hpSubscriptionsByTargetMedia.SMS.MEDICAL || [],
      );
      setUsersAssignedToMedicalEmail(
        hpSubscriptionsByTargetMedia.EMAIL.MEDICAL || [],
      );
      setUsersAssignedToTechnicalSMS(
        hpSubscriptionsByTargetMedia.SMS.TECHNICAL || [],
      );
      setUsersAssignedToTechnicalEmail(
        hpSubscriptionsByTargetMedia.EMAIL.TECHNICAL || [],
      );
      setUsersAssignedToActivitySMS(
        hpSubscriptionsByTargetMedia.SMS.ACTIVITY || [],
      );
      setUsersAssignedToActivityEmail(
        hpSubscriptionsByTargetMedia.EMAIL.ACTIVITY || [],
      );
    }
  }, [hpSubscriptionsByTargetMedia]);

  useEffect(() => {
    setTableData(hpUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(hpUsers)]);

  const handleUserPersonalDetailsUpdate =
    (service: SubscriptionService) => (userId: UUID, checked: boolean) => {
      if (!changesStatus) {
        setChangesStatus(true);
      }

      const usersAssignedToService =
        service === 'EMAIL'
          ? usersAssignedToMedicalEmail
          : usersAssignedToMedicalSMS;
      const setUsersAssignedToService =
        service === 'EMAIL'
          ? setUsersAssignedToMedicalEmail
          : setUsersAssignedToMedicalSMS;

      setUsersAssignedToService(
        usersAssignedToService.map(item =>
          item.id === userId ? { ...item, withPersonalDetails: checked } : item,
        ),
      );
    };

  const handleAssignmentToService =
    (service: SubscriptionService) =>
    (category: AlertCategory) =>
    (userId: UUID | null, checked: boolean) => {
      if (!changesStatus) {
        setChangesStatus(true);
      }

      if (category === 'MEDICAL') {
        const usersAssignedToService =
          service === 'EMAIL'
            ? usersAssignedToMedicalEmail
            : usersAssignedToMedicalSMS;
        const setUsersAssignedToService =
          service === 'EMAIL'
            ? setUsersAssignedToMedicalEmail
            : setUsersAssignedToMedicalSMS;

        if (userId === null) {
          if (!checked) {
            setUsersAssignedToService([]);
            return;
          }

          const allValidUsersToBeAssigned: UserMedicalSubscription[] = tableData
            .filter(validateUsersByService(service))
            .map(user => ({
              alertCategory: 'MEDICAL',
              id: user.id,
              targetMedia: service,
              withPersonalDetails: false,
              summaryReportEnabled: null,
              summaryReportTime: null,
              singleDisconnectionAllowedType: null,
              emailCcEnabled: null,
              emailCcRecipients: null,
            }));

          const x = mergeArraysWithCustomUniqueIds(
            allValidUsersToBeAssigned,
            usersAssignedToService,
            (a, b) => a.id === b.id,
          );

          setUsersAssignedToService(x);
          return;
        }

        checked
          ? setUsersAssignedToService([
              ...usersAssignedToService,
              {
                alertCategory: 'MEDICAL',
                id: userId,
                targetMedia: service,
                withPersonalDetails: false,
                summaryReportEnabled: null,
                summaryReportTime: null,
                singleDisconnectionAllowedType: null,
                emailCcEnabled: null,
                emailCcRecipients: null,
              },
            ])
          : setUsersAssignedToService(
              usersAssignedToService.filter(
                userSubscription => userSubscription.id !== userId,
              ),
            );

        return;
      }

      if (category === 'TECHNICAL') {
        const usersAssignedToService =
          service === 'EMAIL'
            ? usersAssignedToTechnicalEmail
            : usersAssignedToTechnicalSMS;
        const setUsersAssignedToService =
          service === 'EMAIL'
            ? setUsersAssignedToTechnicalEmail
            : setUsersAssignedToTechnicalSMS;

        if (userId === null) {
          if (!checked) {
            setUsersAssignedToService([]);
            return;
          }

          const allValidUsersToBeAssigned: UserTechnicalSubscription[] =
            tableData.filter(validateUsersByService(service)).map(user => ({
              alertCategory: 'TECHNICAL',
              id: user.id,
              targetMedia: service,
              withPersonalDetails: false,
              summaryReportEnabled: null,
              summaryReportTime: null,
              singleDisconnectionAllowedType: null,
              emailCcEnabled: null,
              emailCcRecipients: null,
            }));

          const x = mergeArraysWithCustomUniqueIds(
            allValidUsersToBeAssigned,
            usersAssignedToService,
            (a, b) => a.id === b.id,
          );

          setUsersAssignedToService(x);
          return;
        }

        checked
          ? setUsersAssignedToService([
              ...usersAssignedToService,
              {
                alertCategory: 'TECHNICAL',
                id: userId,
                targetMedia: service,
                withPersonalDetails: false,
                summaryReportEnabled: null,
                summaryReportTime: null,
                singleDisconnectionAllowedType: null,
                emailCcEnabled: null,
                emailCcRecipients: null,
              },
            ])
          : setUsersAssignedToService(
              usersAssignedToService.filter(
                userSubscription => userSubscription.id !== userId,
              ),
            );

        return;
      }

      if (category === 'ACTIVITY') {
        const usersAssignedToService =
          service === 'EMAIL'
            ? usersAssignedToActivityEmail
            : usersAssignedToActivitySMS;
        const setUsersAssignedToService =
          service === 'EMAIL'
            ? setUsersAssignedToActivityEmail
            : setUsersAssignedToActivitySMS;

        if (userId === null) {
          if (!checked) {
            setUsersAssignedToService([]);
            return;
          }

          const allValidUsersToBeAssigned: UserActivitySubscription[] =
            tableData.filter(validateUsersByService(service)).map(user => ({
              alertCategory: 'ACTIVITY',
              id: user.id,
              targetMedia: service,
            }));

          const x = mergeArraysWithCustomUniqueIds(
            allValidUsersToBeAssigned,
            usersAssignedToService,
            (a, b) => a.id === b.id,
          );

          setUsersAssignedToService(x);
          return;
        }

        checked
          ? setUsersAssignedToService([
              ...usersAssignedToService,
              {
                alertCategory: 'ACTIVITY',
                id: userId,
                targetMedia: service,
              },
            ])
          : setUsersAssignedToService(
              usersAssignedToService.filter(
                userSubscription => userSubscription.id !== userId,
              ),
            );

        return;
      }

      return;
    };

  const applySMSEmailAlertsConfig = () => {
    setChangesStatus(false);

    const allUsersAssignedToMedicalEmail = [
      ...usersAssignedToMedicalEmail,
      ...nonHpSubscriptionsByTargetMedia.EMAIL.MEDICAL,
    ];
    const allUsersAssignedToTechnicalEmail = [
      ...usersAssignedToTechnicalEmail,
      ...nonHpSubscriptionsByTargetMedia.EMAIL.TECHNICAL,
    ];
    const allUsersAssignedToActivityEmail = [
      ...usersAssignedToActivityEmail,
      ...nonHpSubscriptionsByTargetMedia.EMAIL.ACTIVITY,
    ];
    const allUsersAssignedToMedicalSMS = [
      ...usersAssignedToMedicalSMS,
      ...nonHpSubscriptionsByTargetMedia.SMS.MEDICAL,
    ];
    const allUsersAssignedToTechnicalSMS = [
      ...usersAssignedToTechnicalSMS,
      ...nonHpSubscriptionsByTargetMedia.SMS.TECHNICAL,
    ];
    const allUsersAssignedToActivitySMS = [
      ...usersAssignedToActivitySMS,
      ...nonHpSubscriptionsByTargetMedia.SMS.ACTIVITY,
    ];
    const payload = {
      targetMedias: {
        EMAIL: {
          HR: allUsersAssignedToMedicalEmail,
          RR: allUsersAssignedToMedicalEmail,
          HR_BASELINE: allUsersAssignedToMedicalEmail,
          RR_BASELINE: allUsersAssignedToMedicalEmail,
          DEVICE_DISCONNECTED: allUsersAssignedToTechnicalEmail,
          ALL_DEVICES_DISCONNECTED: allUsersAssignedToTechnicalEmail,
          BED_EXIT: allUsersAssignedToActivityEmail,
          BED_EXIT_FREQUENCY: allUsersAssignedToActivityEmail,
          BED_TIME_BASELINE: allUsersAssignedToActivityEmail,
          LONG_OUT_OF_BED: allUsersAssignedToActivityEmail,
          POSITION_CHANGE: allUsersAssignedToActivityEmail,
        },
        SMS: {
          HR: allUsersAssignedToMedicalSMS,
          RR: allUsersAssignedToMedicalSMS,
          HR_BASELINE: allUsersAssignedToMedicalSMS,
          RR_BASELINE: allUsersAssignedToMedicalSMS,
          DEVICE_DISCONNECTED: allUsersAssignedToTechnicalSMS,
          ALL_DEVICES_DISCONNECTED: allUsersAssignedToTechnicalSMS,
          BED_EXIT: allUsersAssignedToActivitySMS,
          BED_EXIT_FREQUENCY: allUsersAssignedToActivitySMS,
          BED_TIME_BASELINE: allUsersAssignedToActivitySMS,
          LONG_OUT_OF_BED: allUsersAssignedToActivitySMS,
          POSITION_CHANGE: allUsersAssignedToActivitySMS,
        },
      },
    };

    updateSubscribersList({ data: payload, callback: onLoadSMSEmailAlert });
  };

  return (
    <StyledService>
      <SubscribersTable
        loading={isLoadingSMSEmailAlerts}
        data={tableData}
        columns={columns}
        updateCell={(userId: UUID, phone: string) => {
          updateUserPhone({ userId, data: { phone } });
        }}
        applyChanges={applySMSEmailAlertsConfig}
        handleAssignmentToSMS={handleAssignmentToService('SMS')}
        handleAssignmentToEmail={handleAssignmentToService('EMAIL')}
        handleEmailPersonalDetailsUpdate={handleUserPersonalDetailsUpdate(
          'EMAIL',
        )}
        usersAssignedToMedicalSMS={usersAssignedToMedicalSMS}
        usersAssignedToMedicalEmail={usersAssignedToMedicalEmail}
        usersAssignedToTechnicalSMS={usersAssignedToTechnicalSMS}
        usersAssignedToTechnicalEmail={usersAssignedToTechnicalEmail}
        changesStatus={changesStatus}
      />
    </StyledService>
  );
};

export default Connector(injectIntl(AlertNotificationSettings));
