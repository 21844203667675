import { call, put, take, all, select } from 'typed-redux-saga/macro';

import {
  actions as alertActions,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { actions as gmActions } from 'src/redux/data/groupManager';
import { UUID } from 'src/types/utility';
import { GM_DATA_KEYS } from './constants';
import { hasGMPermissions } from 'src/utils/permissions';
import { filterDuplicates } from 'src/utils/dataUtils';
import { ALERT_TYPES } from 'src/types/alerts';
import { Action } from '@reduxjs/toolkit';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

function* onAttentionListLoaded() {
  if (!hasGMPermissions()) {
    return;
  }

  const mtmAttentionList = yield* select(alertSelectors.selectMTMAttentionList);
  const attentionListPatientIds = filterDuplicates(
    mtmAttentionList
      .filter(alert => alert.type === ALERT_TYPES.MEDICAL)
      .reduce((acc: UUID[], alert) => {
        return [...acc, alert.patientId || ''];
      }, []),
  );
  const attentionListDeviceIds = filterDuplicates(
    mtmAttentionList
      .filter(elem => elem.type === ALERT_TYPES.MEDICAL)
      .reduce((acc: UUID[], alert) => {
        return [...acc, alert.deviceId || ''];
      }, []),
  );
  yield* all([
    put(gmActions.fetchMultitenantPatients(attentionListPatientIds)),
    put(
      gmActions.fetchSubtenantData({
        dataKey: GM_DATA_KEYS.ROOMS,
        payload: attentionListDeviceIds,
      }),
    ),
  ]);
}

export default function* watchTenantDataActions() {
  while (true) {
    yield* take((action: Action) => {
      return (
        action.type === alertActions.fetchMTMAttentionListSuccess.type ||
        (action.type === alertActions.setMTMAttentionListStatus.type &&
          // @ts-ignore Redux saga does not have types for RTK
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          action?.payload === DATA_FETCHING_STATUS.SUCCESS)
      );
    });
    yield* call(onAttentionListLoaded);
  }
}
