import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import * as dayjs from 'dayjs';

import theme from 'src/themes/theme';
import { Wrapper, StyledBar } from './styled';
import { getGraphOptions, calculateTrendLineData } from './utils';
import { useWindowSize } from 'src/utils/hooks/useWindowSize';
import { BREAKPOINT_SIZES } from 'src/utils/mediaQueryUtils';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  {
    id: 'customSpacingLegend',
    beforeInit(chart) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 10;
      };
    },
  },
);

const BedOccupancyHistogram = ({
  intl,
  hoursInBedData,
  numberOfBedExitAlertsData,
  isLoading,
}) => {
  const [width] = useWindowSize();
  const barData = {
    labels: hoursInBedData.map(data1 => dayjs(data1.date).format('DD/MM/YY')),
    //trendline data should follow bar chart data in order to
    //sync show/hide behavior for both data types
    datasets: [
      {
        label: `${intl.formatMessage(messages.hoursInBed)}`,
        data: hoursInBedData.map(data => data.value),
        backgroundColor: theme.colors.bedOccupancyHoursInBedBar,
        borderRadius: 2,
      },
      {
        type: 'line',
        data: calculateTrendLineData(hoursInBedData),
        backgroundColor: theme.colors.hrRrHistogramLineColor,
        borderColor: theme.colors.hrRrHistogramLineColor,
        borderDash: [4, 5],
        borderWidth: 1,
        pointRadius: 0,
        pointStyle: 'line',
        label: 'trend_hours',
      },
      {
        label: `${intl.formatMessage(messages.numberOfAlerts)}`,
        data: numberOfBedExitAlertsData.map(data => data.value),
        backgroundColor: theme.colors.bedOccupancyNumberOfBedAlertsBar,
        borderRadius: 2,
      },
      {
        type: 'line',
        data: calculateTrendLineData(numberOfBedExitAlertsData),
        backgroundColor: theme.colors.hrRrHistogramLineColor,
        borderColor: theme.colors.hrRrHistogramLineColor,
        borderDash: [4, 5],
        borderWidth: 1,
        pointRadius: 0,
        pointStyle: 'line',
        label: 'trend_no_alerts',
      },
    ],
  };
  return (
    <Wrapper>
      {isLoading && <LoadingOverlay />}
      <StyledBar>
        <Bar
          options={getGraphOptions(width > BREAKPOINT_SIZES.XL)}
          data={barData}
          plugins={[ChartDataLabels]}
        />
      </StyledBar>
    </Wrapper>
  );
};

const messages = defineMessages({
  hoursInBed: {
    defaultMessage: 'Hours in bed',
  },
  numberOfAlerts: {
    defaultMessage: 'Number of notifications',
  },
});

BedOccupancyHistogram.propTypes = {
  intl: PropTypes.object.isRequired,
  hoursInBedData: PropTypes.array.isRequired,
  numberOfBedExitAlertsData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default injectIntl(BedOccupancyHistogram);
