import { defineMessages, FormattedMessage, IntlShape } from 'react-intl';
import { TableActionsConfig, TableColumnConfig } from 'src/types/table';
import React from 'react';
import { SubTenantOverview } from 'src/routes/GMDashboard/modules/types';
import { updateText } from 'src/utils/stringUtils';
import DisconnectionOverviewCell from 'src/routes/GMDashboard/components/SubtenantCards/ListView/DisconnectionOverviewCell';
import NotificationsCell from 'src/routes/GMDashboard/components/SubtenantCards/ListView/NotificationsCell';
import { StyledTenantName, StyledUtilizationCell } from './styled';
import { handleClickTenantName } from 'src/routes/GMDashboard/modules/utils';
import { Tooltip } from 'antd';
import { ToolTipMessages } from 'src/translations/messages/toolTipMessages';
import { USERS_TABLE_ACTIONS_KEYS } from 'src/components/UserCrudComponents/constants';

const getCurrentUtilizationBackground = (currentUtilization: number) => {
  if (currentUtilization >= 80 && currentUtilization <= 100) return '#59DDAA';
  if (currentUtilization >= 65 && currentUtilization <= 79) return '#F08838';
  return '#FF4F4F';
};
const getUtilizationGapBackground = (utilizationGap: number) => {
  if (utilizationGap >= 0 && utilizationGap <= 10) return '#59DDAA';
  if (utilizationGap >= 11 && utilizationGap <= 20) return '#F08838';
  return '#FF4F4F';
};
const convertNullToEmptyString = (string?: string | null) => string ?? '';
const sorterCompare = (
  a: SubTenantOverview,
  b: SubTenantOverview,
  field: keyof SubTenantOverview,
) => {
  if (!a || !b || !field || !a[field] || !b[field]) {
    return -1;
  }
  return convertNullToEmptyString(a[field]?.toString()).localeCompare(
    convertNullToEmptyString(b[field]?.toString()),
  );
};

const messages = defineMessages({
  tenantName: {
    defaultMessage: 'Tenant Name',
  },
  addressLabel: {
    defaultMessage: 'Address',
  },
  totalDevicesLabel: {
    defaultMessage: 'Total devices',
  },
  connectedAssignedDevices: {
    defaultMessage: 'Connected & Assigned',
  },
  connectedUnassignedDevices: {
    defaultMessage: 'Connected & Unassigned',
  },
  disconnectedAssignedDevices: {
    defaultMessage: 'Disconnected & Assigned',
  },
  disconnectedUnassignedDevices: {
    defaultMessage: 'Disconnected & Unassigned',
  },
  noConsent: {
    defaultMessage: 'No Consent',
  },
  lastDayAlerts: {
    defaultMessage: 'Notifications',
  },
  currentUtilization: {
    defaultMessage: 'Current Utilization',
  },
  potentialUtilization: {
    defaultMessage: 'Potential Utilization',
  },
  gapUtilization: {
    defaultMessage: 'Utilization Gap',
  },
  settingsActionTable: {
    defaultMessage: 'Settings',
  },
});
export const getListViewColumns = (
  intl: IntlShape,
): TableColumnConfig<SubTenantOverview>[] => [
  {
    title: intl.formatMessage(messages.tenantName),
    dataIndex: 'name',
    key: 'name',
    filtered: true,
    sorter: (a, b) => sorterCompare(a, b, 'name'),
    render: (_, { name, id }) => (
      <StyledTenantName onClick={() => handleClickTenantName(id)}>
        <Tooltip
          title={
            <FormattedMessage {...ToolTipMessages.MtMtenantCardsTenantName} />
          }
        >
          {name}
        </Tooltip>
      </StyledTenantName>
    ),
  },
  {
    title: intl.formatMessage(messages.addressLabel),
    dataIndex: 'address',
    key: 'address',
    filtered: true,
    sorter: (a, b) => {
      const firstAddress = `${a.address.city || ''}${updateText(
        ', ',
        a.address.state,
      )}${updateText(', ', a.address.countryCode)} `;
      const secondAddress = `${b.address.city || ''}${updateText(
        ', ',
        b.address.state,
      )}${updateText(', ', b.address.countryCode)} `;
      // sorterCompare(a, b, 'name')
      return firstAddress.localeCompare(secondAddress);
    },
    render: (_, { address }) =>
      `${address.city || ''}${updateText(
        ', ',
        address.state,
      )}${updateText(', ', address.countryCode)} `,
  },
  {
    title: intl.formatMessage(messages.totalDevicesLabel),
    dataIndex: 'totalDevicesCounter',
    key: 'totalDevicesCounter',
    filtered: true,
    sorter: (a, b) => a.totalDevicesCounter - b.totalDevicesCounter,
  },
  {
    title: intl.formatMessage(messages.connectedAssignedDevices),
    dataIndex: 'connectedAssignedDevicesCounter',
    key: 'connectedAssignedDevicesCounter',
    filtered: true,
    sorter: (a, b) =>
      a.connectedAssignedDevicesCounter - b.connectedAssignedDevicesCounter,
  },
  {
    title: intl.formatMessage(messages.connectedUnassignedDevices),
    dataIndex: 'connectedUnassignedDevicesCounter',
    key: 'connectedUnassignedDevicesCounter',
    filtered: true,
    sorter: (a, b) =>
      a.connectedUnassignedDevicesCounter - b.connectedUnassignedDevicesCounter,
  },
  {
    title: intl.formatMessage(messages.disconnectedAssignedDevices),
    dataIndex: 'disconnectedAssignedDevicesCounter',
    key: 'disconnectedAssignedDevicesCounter',
    filtered: true,
    sorter: (a, b) =>
      a.disconnectedAssignedDevicesCounter -
      b.disconnectedAssignedDevicesCounter,
    render: (_, { id, name, disconnectedAssignedDevicesCounter }) => {
      return (
        <DisconnectionOverviewCell
          subtenantId={id}
          subtenantName={name}
          value={disconnectedAssignedDevicesCounter}
        />
      );
    },
  },
  {
    title: intl.formatMessage(messages.disconnectedUnassignedDevices),
    dataIndex: 'disconnectedUnassignedDevicesCounter',
    key: 'disconnectedUnassignedDevicesCounter',
    filtered: true,
    sorter: (a, b) =>
      a.disconnectedUnassignedDevicesCounter -
      b.disconnectedUnassignedDevicesCounter,
    render: (_, { id, name, disconnectedUnassignedDevicesCounter }) => (
      <DisconnectionOverviewCell
        subtenantId={id}
        subtenantName={name}
        value={disconnectedUnassignedDevicesCounter}
      />
    ),
  },
  {
    title: intl.formatMessage(messages.noConsent),
    dataIndex: 'noConsent',
    key: 'noConsent',
    filtered: true,
    sorter: (a, b) => a.noConsent - b.noConsent,
  },
  {
    title: intl.formatMessage(messages.lastDayAlerts),
    dataIndex: 'lastDayAlertsCounter',
    key: 'lastDayAlertsCounter',
    filtered: true,
    sorter: (a, b) => a.lastDayAlertsCounter - b.lastDayAlertsCounter,
    render: (_, { id, name, lastDayAlertsCounter }) => (
      <NotificationsCell
        subtenantId={id}
        subtenantName={name}
        value={lastDayAlertsCounter}
      />
    ),
  },
  {
    title: intl.formatMessage(messages.currentUtilization),
    dataIndex: 'currentUtilization',
    key: 'currentUtilization',
    filtered: true,
    sorter: (a, b) => a.currentUtilization - b.currentUtilization,
    width: 100,
    render: (_, { currentUtilization }) => (
      <StyledUtilizationCell
        backgroundColor={getCurrentUtilizationBackground(
          Math.round(currentUtilization),
        )}
        className="list-view-percentage-cell"
      >
        {`${Math.round(currentUtilization)}%`}
      </StyledUtilizationCell>
    ),
  },
  {
    title: intl.formatMessage(messages.potentialUtilization),
    dataIndex: 'potentialUtilization',
    key: 'potentialUtilization',
    filtered: true,
    sorter: (a, b) => a.potentialUtilization - b.potentialUtilization,
    width: 100,
    render: (_, { potentialUtilization }) => (
      <StyledUtilizationCell
        backgroundColor={getCurrentUtilizationBackground(
          Math.round(potentialUtilization),
        )}
        className="list-view-percentage-cell"
      >
        {`${Math.round(potentialUtilization)}%`}
      </StyledUtilizationCell>
    ),
  },
  {
    title: intl.formatMessage(messages.gapUtilization),
    dataIndex: 'gapUtilization',
    key: 'gapUtilization',
    filtered: true,
    width: 100,
    sorter: (a, b) => a.gapUtilization - b.gapUtilization,
    render: (_, { gapUtilization }) => (
      <StyledUtilizationCell
        backgroundColor={getUtilizationGapBackground(
          Math.round(gapUtilization),
        )}
        className="list-view-percentage-cell"
      >
        {`${Math.round(gapUtilization)}%`}
      </StyledUtilizationCell>
    ),
  },
];

export const getListViewTableActions = (
  intl: IntlShape,
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedTenantId: React.Dispatch<React.SetStateAction<string>>,
): TableActionsConfig<SubTenantOverview>[] => [
  {
    key: USERS_TABLE_ACTIONS_KEYS.EDIT,
    name: intl.formatMessage(messages.settingsActionTable),
    onClick: (_, { record }) => {
      setModalVisible(true);
      setSelectedTenantId(record.id);
    },
    // isDisabled: ({ record }) => record.status === USER_STATUS.PENDING,
    // permissions: () => [permissions.ORGANIZATION_USER_UPDATE],
  },
];
