import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  DeviceLegendWrap,
  LegendModal,
  List,
  ListItem,
  StyledIcon,
  StyledImg,
  StyledInfoIcon,
  Subtitle,
  IconWithValue,
} from './styled';
import {
  AlertIndicatorsLegend,
  StatusIconsLegendCol1,
  StatusIconsLegendCol2,
  VitalSignsLegend,
} from './constants';
import {
  AlertIndicatorsMessages,
  StatusMessages,
  VitalSignsMessages,
} from './messages';

const DeviceLegend = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <>
      <DeviceLegendWrap onClick={() => setIsModalVisible(true)}>
        <StyledInfoIcon />
        <FormattedMessage {...messages.deviceLegend} />
      </DeviceLegendWrap>
      <LegendModal
        open={isModalVisible}
        title={<FormattedMessage {...messages.modalTitle} />}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
      >
        <Subtitle>
          <FormattedMessage {...StatusMessages.title} />
        </Subtitle>

        <List direction="row">
          <List direction="column" maxHeight="11rem">
            {StatusIconsLegendCol1.map((item, index) => (
              <ListItem key={index} direction="row">
                <StyledIcon
                  type={item.iconType}
                  color={item.fillColor}
                  width={item.width}
                  height={item.height}
                />
                <FormattedMessage
                  {...item.description}
                  values={{
                    br: <br />,
                  }}
                />
              </ListItem>
            ))}
          </List>

          <List direction="column">
            {StatusIconsLegendCol2.map((item, index) => (
              <ListItem key={index} direction="row">
                <IconWithValue>
                  <StyledIcon
                    height={item?.height ?? undefined}
                    type={item.iconType}
                    color={item.fillColor}
                  />
                  <p>{item.alertValueExample}</p>
                </IconWithValue>
                <FormattedMessage
                  {...item.description}
                  values={{
                    br: <br />,
                  }}
                />
              </ListItem>
            ))}
          </List>
        </List>

        <Subtitle>
          <FormattedMessage {...VitalSignsMessages.title} />
        </Subtitle>
        <List direction="row" wrap="wrap">
          {VitalSignsLegend.map((item, index) => (
            <ListItem key={index} direction="column">
              <span className="legend-desc">
                <FormattedMessage {...item.description} />
              </span>
              <StyledImg src={item.imgSrc} />
            </ListItem>
          ))}
        </List>
        <Subtitle>
          <FormattedMessage {...AlertIndicatorsMessages.title} />
        </Subtitle>
        <List direction="column" maxHeight="5rem" wrap="wrap">
          {AlertIndicatorsLegend.map((item, index) => (
            <ListItem key={index} direction="row">
              <StyledIcon
                type={item.iconType}
                color={item.fillColor}
                width={item.width}
                height={item.height}
                verticalAlign={item.verticalAlign}
              />
              <FormattedMessage {...item.description} />
            </ListItem>
          ))}
        </List>
      </LegendModal>
    </>
  );
};

const messages = defineMessages({
  deviceLegend: {
    defaultMessage: 'Legend',
  },
  modalTitle: {
    defaultMessage: 'Nurses Station Legend',
  },
});

export default DeviceLegend;
