import { defineMessages } from 'react-intl';
import { ALERT_SETTINGS_TAB_KEYS, AlertSettingsTabType } from './types';
import ActivityAlerts from 'src/components/AlertSettingsComponents/ActivityAlerts/Container';
import MedicalAlerts from 'src/routes/NursesStation/components/RoomsAndBeds/DeviceSettings/AlertSettings/MedicalAlerts';
import permissions from 'src/permissions';

const messages = defineMessages({
  medicalAlerts: {
    defaultMessage: 'Medical',
  },
  activityAlerts: {
    defaultMessage: 'Bed Activity',
  },
  connectivityAlerts: {
    defaultMessage: 'Connectivity Notifications',
  },
});
export const ALERT_SETTINGS_TABS: AlertSettingsTabType[] = [
  {
    key: ALERT_SETTINGS_TAB_KEYS.MEDICAL_ALERTS,
    labelMessage: messages.medicalAlerts,
    component: MedicalAlerts,
  },
  {
    key: ALERT_SETTINGS_TAB_KEYS.ACTIVITY_ALERTS,
    labelMessage: messages.activityAlerts,
    component: ActivityAlerts,
    editablePermissions: [permissions.ACTIVITY_ALERT_SETTINGS_CREATE_UPDATE],
  },
];
