import { styled } from 'styled-components';

import Table from 'src/components/CrudCommonComponents/Table';

export const Wrapper = styled.div``;

export const PatientsTable = styled(Table)`
  .ant-table-small .ant-table-thead > tr > th {
    font-weight: 500;
    background-color: #f4f7fa;
    border-bottom: 1px solid #717171;
    padding: 0.4rem 0.8rem !important;
  }
  .ant-table-row.discharging-patient {
    background-color: #ededed;
  }
  .ant-table-column-sorters {
    display: inline-flex;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #252525;
  }

  tbody .ant-table-cell div {
  }

  .ant-table-tbody > tr > .ant-table-cell {
    font-weight: 400;
    padding: 0.4rem 0.8rem !important;
  }
`;

export const AlertIndicatorCell = styled.div`
  display: flex;
  width: 3.4rem;
`;

export const PatientName = styled.div`
  font-weight: 700;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
