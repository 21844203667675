import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { styled } from 'styled-components';

const StyledSelect = styled(Select)`
  .rc-virtual-list-holder-inner div {
    font-size: 0.7rem;
    padding: 0.1rem 0.4rem;
  }
  .ant-divider {
    margin: 0.35rem 0 !important;
  }
  .ant-space {
    div,
    input {
      font-size: 0.7rem;
    }
  }
`;

const DeviceFieldSelect = ({
  options,
  onChange,
  addNew,
  title,
  newValue,
  onChangeInput,
  selectedValue,
  ...restProps
}) => {
  const [inputStatus, setInputStatus] = useState('');
  const [openSelect, setOpenSelect] = useState(false);
  const testChangeAndAct = event => {
    if (event.target.value.match(/^[a-zA-Z0-9 _]+$/)) {
      setInputStatus('');
      onChangeInput(event);
    } else if (event.target.value.match(/^$/)) {
      setInputStatus('warning');
      onChangeInput(event);
    }
  };
  const onAdd = e => {
    if (inputStatus.match(/^$/)) {
      addNew(e);
      setOpenSelect(false);
    }
    return;
  };

  const onDropdownVisibleChange = open => {
    setOpenSelect(open);
  };
  return (
    <StyledSelect
      {...restProps}
      showSearch
      open={openSelect}
      options={options}
      onChange={onChange}
      optionFilterProp="label"
      value={selectedValue}
      getPopupContainer={trigger => trigger}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space align="center" style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="..."
              status={inputStatus}
              value={newValue}
              onChange={testChangeAndAct}
            />
            <Typography.Link onClick={onAdd} style={{ whiteSpace: 'nowrap' }}>
              <PlusOutlined /> {title}
            </Typography.Link>
          </Space>
        </>
      )}
      filterSort={(optionA, optionB) =>
        optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
      }
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
};

DeviceFieldSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  addNew: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  newValue: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default injectIntl(DeviceFieldSelect);
