import React from 'react';
import { defineMessages } from 'react-intl';

import CurrentAlerts from '../CurrentAlerts';
import AttentionList from '../AttentionList';

export const MTMsideBarLabelMessages = defineMessages({
  currentAlertsTab: {
    defaultMessage: 'Current Notifications',
  },
  attentionList: {
    defaultMessage: 'Attention List',
  },
});

export const CURRENT_ALERTS = 'current-alerts';
export const ATTENTION_LIST = 'attention-list';

export const ALERT_SIDEBAR_GM_TABS = Object.freeze({
  [CURRENT_ALERTS]: {
    order: 1,
    render: () => <CurrentAlerts />,
    label: MTMsideBarLabelMessages.currentAlertsTab,
  },
  [ATTENTION_LIST]: {
    order: 2,
    render: () => <AttentionList />,
    label: MTMsideBarLabelMessages.attentionList,
  },
});

export type AlertSidebarGMKey = keyof typeof ALERT_SIDEBAR_GM_TABS;
