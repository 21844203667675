import React from 'react';
import PropTypes from 'prop-types';

import { styled } from 'styled-components';

const Box = styled.section`
  width: ${props => props.width};
  height: ${props => props.height};
  flex: ${props => props.flex};
`;

const Spacer = props => {
  const { width, height, flex } = props;
  return <Box width={width} height={height} flex={flex ? flex : undefined} />;
};

Spacer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  flex: PropTypes.string,
};

Spacer.defaultProps = {
  width: '0',
  height: '0',
  flex: '',
};

export default Spacer;
