import { styled } from 'styled-components';
import { Button } from 'antd';

import { SpacedRow, SubTitle } from 'src/components/styled';

export const BoxTitle = styled(SubTitle)`
  color: ${props => props.theme.colors.alertBoxTitleColor};
  font-weight: 500;
  margin-bottom: 0;
  font-size: 0.7rem;
`;
export const AlertBox = styled(SpacedRow)`
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: column;
  width: 50%;
  margin: 5%;
  padding: 1%;
  border: ${props => `1px solid ${props.theme.colors.settingBoxBorderColor}`};
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
`;
export const ThresholdWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 1%;
  span {
    font-size: 0.7rem;
    line-height: 1rem;
    place-self: center;
  }
  .ant-input-number {
    font-size: 0.7rem;
    height: 1.6rem;
    width: 4.5rem;
    align-self: center;
    input {
      height: 1.6rem;
    }
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  button {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    height: 1.8rem;
  }
`;
export const AlertButton = styled(Button)`
  margin-left: 3%;
`;
export const ThresholdsBox = styled.div`
  display: table-row;
  align-self: end;
  margin-bottom: 0.2rem;
  font-size: 0.7rem;
  margin-right: 0.2rem;
`;
export const TreshholdsValues = styled.div`
  display: block;
  text-align: center;
  font-size: 0.6rem;
  line-height: 0.8rem;
`;
