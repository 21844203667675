import React from 'react';

import { isEmptyBed } from 'src/routes/NursesStation/modules/filterUtils';
import DeviceMeasurements from '../DeviceMeasurements';
import Connector, { Props } from './Connector';
import DevicePatient from './DevicePatient';
import DeviceLocation from './DeviceLocation';
import { CardWrapper, CardHeaderWrapper, DeviceInfoWrapper } from './styled';
import DeviceInfo from './DeviceInfo';
import { deepEqual } from 'src/utils/comparators';

const DeviceCard = (props: Props): JSX.Element => {
  const {
    id,
    patientId,
    roomName,
    bedName,
    patientName,
    continuousData,
    isDeviceActive,
    isDeviceConnected,
    ongoingDeviceAlerts,
    isVacancyAlertActive,
    isLongOutOfBedAlertActive,
    isPositionChangeAlertActive,
    shouldDisplayNoConsent,
  } = props;
  const hasAssignedPatient = !!(patientId && patientName);
  const hasAssignedLocation = !!(roomName && bedName);
  if (!isDeviceConnected && !hasAssignedPatient) {
    return (
      <CardWrapper>
        <CardHeaderWrapper>
          <DeviceLocation
            id={id}
            roomName={roomName}
            bedName={bedName}
            isDeviceConnected={isDeviceConnected}
          />
        </CardHeaderWrapper>
        <DeviceInfoWrapper>
          <DeviceInfo
            deviceId={id}
            patientId={patientId}
            patientName={patientName}
            hasAssignedPatient={hasAssignedPatient}
            hasAssignedLocation={hasAssignedLocation}
          />
        </DeviceInfoWrapper>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper>
      <CardHeaderWrapper>
        <DeviceLocation
          id={id}
          roomName={roomName}
          bedName={bedName}
          isDeviceConnected={isDeviceConnected}
        />
        {!shouldDisplayNoConsent && (
          <DevicePatient
            hasAssignedPatient={hasAssignedPatient}
            deviceId={id}
            patientId={patientId || ''}
            isDeviceConnected={isDeviceConnected}
            isUnoccupied={isEmptyBed(props)}
            ongoingDeviceAlerts={ongoingDeviceAlerts}
            hasVacancyNotificationEnabled={isVacancyAlertActive}
            isPositionChangeAlertActive={isPositionChangeAlertActive}
            isLongOutOfBedAlertActive={!!isLongOutOfBedAlertActive}
            roomName={roomName}
            bedName={bedName}
          />
        )}
      </CardHeaderWrapper>
      {!shouldDisplayNoConsent && (
        <DeviceInfo
          deviceId={id}
          patientId={patientId}
          patientName={patientName}
          hasAssignedPatient={hasAssignedPatient}
          hasAssignedLocation={hasAssignedLocation}
        />
      )}
      <DeviceMeasurements
        continuousData={continuousData}
        isDeviceActive={isDeviceActive}
        isUnoccupied={isEmptyBed(props)}
        ongoingDeviceAlerts={ongoingDeviceAlerts}
        shouldDisplayNoConsent={shouldDisplayNoConsent}
      />
    </CardWrapper>
  );
};

export default Connector(
  React.memo(DeviceCard, (oldProps, newProps) => deepEqual(oldProps, newProps)),
);
