import { styled } from 'styled-components';
import { Tabs } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 1.25rem;
  flex: 1;
  flex-grow: 1;
`;

export const Header = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledCounter = styled.div`
  font-weight: 600;
  font-size: 0.65rem;
`;

export const CardsWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1rem;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 0.5rem 0rem;
  }
  .ant-tabs-nav {
    margin-bottom: 0rem;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4a4a4a;
    font-weight: 700;
  }
  .ant-tabs-tab-btn {
    font-family: ${props => props.theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    color: #4a4a4a;
    padding: 0rem 0.75rem;
  }
  .ant-tabs-ink-bar {
    background: #304e64;
  }
  .ant-tabs-content-holder {
    margin-top: 1rem;
  }
`;
