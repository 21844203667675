import { styled } from 'styled-components';

import Tooltip from 'src/components/general-ui/Tooltip';
import { Select } from 'antd';

export const StyledAssignBtn = styled.div`
  width: max-content;
  font-weight: 600;
  font-size: 0.6rem;
  white-space: nowrap;
`;

export const LocationTooltip = styled(Tooltip)`
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #d8d8d8;
  }
  div.ant-tooltip-inner {
    font-family: ${props => props.theme.fonts.primary};
    color: #252525;
    padding: 0.6rem 0.5rem 0 0.5rem;
    width: 11rem;

    background: #ffffff;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-left: 0.5px solid #dddddd;
    border-right: 0.5px solid #dddddd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.125109);
    border-radius: 3px;
  }
`;

export const Label = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 0.7rem;
`;

export const StyledInfo = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: 0.7rem;
  margin-bottom: 0.3rem;
  font-weight: 600;
`;

export const StyledSelector = styled(Select)`
  margin-bottom: 0.8rem;

  .ant-select-selection-placeholder {
    font-family: ${props => props.theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 0.65rem;
    padding: 0.3rem 0.75rem;
    line-height: unset;
    min-height: unset;
  }

  .ant-select-selection-item {
    font-family: ${props => props.theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 0.65rem;
    line-height: 1.2rem;
    padding: 0.3rem 0.75rem;
    line-height: unset;
    min-height: unset;
  }

  .ant-select-dropdown {
    .ant-select-item {
      font-family: ${props => props.theme.fonts.primary};
      font-style: normal;
      font-weight: 400;
      font-size: 0.65rem;
      padding: 0.3rem 0.75rem;
      line-height: unset;
      min-height: unset;

      &:hover {
        background: #f5f7f9;
      }
    }

    .ant-select-item-option-selected {
      background: #f5f7f9;
      border: 1px solid #dddddd;
      font-weight: 500;
    }
  }

  .ant-empty {
    margin: 0;

    .ant-empty-image {
      height: 2rem;
      margin-bottom: 0.2rem;
    }

    .ant-empty-description {
      font-size: 0.8rem;
    }
  }
`;

export const StyledError = styled.div`
  color: #ca0000;
  font-weight: 500;
  font-size: 0.6rem;
`;

export const StyledAdderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 0.65rem;
  padding: 0.3rem 0.75rem;
  color: #252525;
`;

export const StyledAddInput = styled.input`
  width: 5rem;
  margin-right: 0.4rem;
  outline: none;
  cursor: text !important;
`;
export const StyledDisplayValue = styled.div`
  font-weight: 600;
`;
