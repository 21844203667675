import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import expandIcon from 'src/routes/IndividualDashboard/resources/expand-icon.svg';
import {
  BoxWrapper,
  BoxTitleWrapper,
  BoxTitle,
  BoxContent,
  BoxValue,
  BoxValueSuffix,
  StyledExtend,
  StyledQuestionMarkInfo,
} from '../styled';
import AlertLogModal from './AlertLogModal';
import Connector, { IProps } from './Connector';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
import { Dayjs } from 'dayjs';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

type Props = IProps & {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
};

const AlertLogBox = ({
  alertLogMetadata,
  isLoading,
  startDate,
  endDate,
}: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <BoxWrapper>
      <AlertLogModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        // alertsLogData={alertLogWithPatients}
        isLoading={isLoading}
        startDate={startDate}
        endDate={endDate}
      />
      <BoxTitleWrapper>
        <BoxTitle>
          <FormattedMessage {...messages.boxTitle} />
        </BoxTitle>
        <StyledExtend onClick={() => setIsModalVisible(true)}>
          <img src={expandIcon} alt="expand" role="button" />
        </StyledExtend>
      </BoxTitleWrapper>
      <BoxContent>
        {isLoading && <LoadingOverlay />}
        <BoxValue>{alertLogMetadata.totalResults || 0}</BoxValue>
        <BoxValueSuffix>
          <FormattedMessage {...messages.boxSuffix} />
        </BoxValueSuffix>
        <Tooltip
          placement="top"
          title={<FormattedMessage {...messages.questionMarkTooltip} />}
        >
          <StyledQuestionMarkInfo>
            <QuestionCircleOutlined />
          </StyledQuestionMarkInfo>
        </Tooltip>
      </BoxContent>
    </BoxWrapper>
  );
};

const messages = defineMessages({
  boxTitle: {
    defaultMessage: 'Notification Log',
  },
  boxSuffix: {
    defaultMessage: 'Total Notifications',
  },
  questionMarkTooltip: {
    defaultMessage:
      'The graph displays only the first 30 alerts. To view alerts for a specific day, please select a custom date range.',
  },
});

export default Connector(AlertLogBox);
