import { TargetMedia } from './subscribers';
import { UUID, DateUTC, SerialNumber } from './utility';
import { NoteCounterMetadata } from 'src/types/notes';

export type AlertLevel = 0 | 1 | 2;
export type AlertStatus = 'OFF' | 'ON' | 'SUPPRESSED';
export type ThresholdPreposition = 'ABOVE' | 'BELOW';
export interface GenericThresholdMetrics {
  HR: 'HR';
  RR: 'RR';
  HR_BASELINE: 'HR_BASELINE';
  RR_BASELINE: 'RR_BASELINE';
  BED_EXIT: 'BED_EXIT';
  LONG_OUT_OF_BED: 'LONG_OUT_OF_BED';
  POSITION_CHANGE: 'POSITION_CHANGE';
  BED_TIME_BASELINE: 'BED_TIME_BASELINE';
  BED_EXIT_FREQUENCY: 'BED_EXIT_FREQUENCY';
  DEVICE_DISCONNECTED: 'DEVICE_DISCONNECTED';
  ALL_DEVICES_DISCONNECTED: 'ALL_DEVICES_DISCONNECTED';
}

export type AverageThresholdMetric = keyof Pick<
  GenericThresholdMetrics,
  'HR' | 'RR'
>;

export type BaselineMetric = keyof Pick<
  GenericThresholdMetrics,
  'RR_BASELINE' | 'HR_BASELINE'
>;

export type VacancyMetric = keyof Pick<
  GenericThresholdMetrics,
  'BED_EXIT' | 'LONG_OUT_OF_BED' | 'POSITION_CHANGE'
>;

export type AbnormalMetric = keyof Pick<
  GenericThresholdMetrics,
  'BED_TIME_BASELINE' | 'BED_EXIT_FREQUENCY'
>;

export type TechnicalAlertType = keyof Pick<
  GenericThresholdMetrics,
  'ALL_DEVICES_DISCONNECTED' | 'DEVICE_DISCONNECTED'
>;

export const ClosureReasons = {
  STANDARD: 'STANDARD',
  PATIENT_CONSENT_DISABLED: 'PATIENT_CONSENT_DISABLED',
};

export type ClosureReasonTypes = typeof ClosureReasons;

export type MedicalAlertType =
  | AverageThresholdMetric
  | BaselineMetric
  | VacancyMetric
  | AbnormalMetric;

export type AlertType = MedicalAlertType | TechnicalAlertType;

export type VsAlert = {
  id: UUID;
  patientId: UUID;
  startTime: DateUTC;
  endTime: DateUTC | null;
  clearTime: DateUTC | null;
  deviceId: SerialNumber;
  alertLevel: AlertLevel;
  status: AlertStatus;
  thresholdMetric: AverageThresholdMetric;
  thresholdPreposition: ThresholdPreposition;
  thresholdValue: number;
  triggerValue: number;
  averageValue: number;
  averageInterval: number;
  hqCount: number;
  reminderCount: number;
  baselineValue: null;
  deviationPercentage: null;
  deviationHours: null;
  deviationValue: null;
  baselineDaysInterval: null;
  tenantId?: UUID;
  notesCount?: NoteCounterMetadata;
  firstMeasurementTime?: DateUTC | null;
  lastModifiedTime: DateUTC;
  closureReason: keyof ClosureReasonTypes;
};

export type BaselineAlert = {
  id: UUID;
  patientId: UUID;
  startTime: DateUTC;
  endTime: DateUTC | null;
  clearTime: DateUTC | null;
  deviceId: SerialNumber;
  alertLevel: 2;
  status: AlertStatus;
  thresholdMetric: BaselineMetric;
  thresholdPreposition: ThresholdPreposition;
  hqCount: number;
  reminderCount: number;
  baselineValue: number;
  deviationValue: number;
  deviationPercentage: number;
  deviationHours: number;
  baselineDaysInterval: number;
  thresholdValue: 0;
  triggerValue: 0;
  averageValue: null;
  averageInterval: null;
  tenantId?: UUID;
  notesCount?: NoteCounterMetadata;
  firstMeasurementTime?: DateUTC | null;
  lastModifiedTime: DateUTC;
  closureReason: keyof ClosureReasonTypes;
};

export type OccupancyAlert = {
  id: UUID;
  patientId: UUID;
  startTime: DateUTC;
  endTime: DateUTC | null;
  clearTime: DateUTC | null;
  deviceId: SerialNumber;
  alertLevel: 2;
  status: AlertStatus;
  triggerValue: number;
  deviationPercentage: null;
  thresholdPreposition: null;
  thresholdMetric: VacancyMetric | AbnormalMetric;
  reminderCount: number;
  tenantId?: UUID;
  notesCount?: NoteCounterMetadata;
  lastModifiedTime: DateUTC;
  averageInterval: null;
  baselineValue: null;
  deviationHours: null;
  thresholdValue: null;
  averageValue: null;
  deviationPeriodDays?: number;
  // Long Out of time field
  firstMeasurementTime?: DateUTC | null;
  closureReason: keyof ClosureReasonTypes;
};

export type DeviceDisconnectedAlert = {
  id: UUID;
  patientId: UUID | null;
  startTime: DateUTC;
  endTime: DateUTC | null;
  clearTime: DateUTC | null;
  deviceId: UUID;
  alertLevel: 2;
  status: AlertStatus;
  thresholdMetric: 'DEVICE_DISCONNECTED';
  thresholdPreposition: null;
  hqCount: number;
  reminderCount: number;
  thresholdValue: null;
  triggerValue: null;
  averageValue: null;
  averageInterval: null;
  baselineValue: null;
  deviationPercentage: null;
  deviationHours: null;
  deviationValue: null;
  baselineDaysInterval: null;
  sessionId: UUID | null;
  tenantId?: UUID;
  notesCount?: NoteCounterMetadata;
  firstMeasurementTime?: DateUTC | null;
  closureReason: keyof ClosureReasonTypes;
};

export type AllTenantDevicesDisconnectedAlert = {
  id: UUID;
  patientId: null;
  startTime: DateUTC;
  endTime: DateUTC | null;
  timestamp: DateUTC;
  clearTime: DateUTC | null;
  deviceId: null;
  alertLevel: 2;
  status: AlertStatus;
  thresholdMetric: 'ALL_DEVICES_DISCONNECTED';
  thresholdPreposition: null;
  hqCount: number;
  reminderCount: number;
  thresholdValue: null;
  triggerValue: null;
  averageValue: null;
  type: string;
  averageInterval: null;
  baselineValue: null;
  deviationPercentage: null;
  deviationHours: null;
  deviationValue: null;
  baselineDaysInterval: null;
  sessionId: null;
  tenantId?: UUID;
  notesCount?: NoteCounterMetadata;
  firstMeasurementTime?: DateUTC | null;
  closureReason: keyof ClosureReasonTypes;
};

export type MedicalAlert = VsAlert | OccupancyAlert | BaselineAlert;
export type TechnicalAlert =
  | DeviceDisconnectedAlert
  | AllTenantDevicesDisconnectedAlert;

export type Alert = MedicalAlert | TechnicalAlert;

export type MedicalAlertLog = (
  | Omit<VsAlert, 'hqCount' | 'reminderCount'>
  | Omit<OccupancyAlert, 'hqCount' | 'reminderCount'>
  | Omit<BaselineAlert, 'hqCount'>
) & {
  tenantId: UUID;
};

export type TechnicalAlertLog = (
  | Omit<DeviceDisconnectedAlert, 'hqCount' | 'reminderCount'>
  | Omit<AllTenantDevicesDisconnectedAlert, 'hqCount' | 'reminderCount'>
) & {
  tenantId: UUID;
};

export type AlertLog = MedicalAlertLog | TechnicalAlertLog;

export type AlertThreshold = {
  id: UUID;
  creationTime: DateUTC;
  lastModifiedTime: DateUTC;
  enable: boolean;
  metric: AverageThresholdMetric;
  preposition: ThresholdPreposition;
  value: number;
};

export type BaselineAlertThreshold = {
  id: UUID;
  creationTime: DateUTC;
  lastModifiedTime: DateUTC;
  enable: boolean;
  metric: BaselineMetric;
  baselineDaysInterval: number;
  deviationPercentage: number;
  deviationHoursInterval: number;
};

export type AlertSettings = {
  id: UUID;
  levelOneThreshold: number;
  levelTwoThreshold: number;
  soundDuration: number;
  metricAverageTimeInterval: number;
  metricAverageRefreshInterval: number;
  hqPercentageThreshold: number;
  notificationAntiFlickeringTimeout: number;
};

export type PatientAlertTypeSettings = {
  id: UUID;
  patientId: UUID;
} & Partial<{
  basicAlertsEnabled: boolean;
  bedExitEnabled: boolean;
  positionChangeEnabled: boolean;
  urgentAlertsEnabled: boolean;
}>;

export enum AlertSoundSettings {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export type AverageAlertWithPersonalDetails = {
  id: UUID;
  tenantId: UUID;
  userId: UUID;
  roomId: UUID;
  roomName: string;
  bedId: UUID;
  bedName: string;
  alertType: AverageThresholdMetric;
  targetMedia: TargetMedia;
  withPersonalDetails: boolean;
  deviceId: SerialNumber;
  patientId: UUID;
  thresholdPreposition: ThresholdPreposition;
  triggerValue: number;
  thresholdValue: number;
  averageValue: number;
  averageInterval: number;
  hqCount: number;
  alertId: UUID;
  alertTypeUnit: string;
  tenantName: string;
  cityName: string;
  countryName: string;
  baselineValue: null;
  baselineDaysInterval: null;
  deviationValue: null;
  deviationPercentage: null;
  deviationHours: null;
  alertStartTime: DateUTC;
  lastDayBaseline: string;
  continuesAlertCount: number;
  creationTime: DateUTC;
  lastModifiedTime: DateUTC;
  patientData: {
    id: UUID;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  };
  stabilized: boolean;
};

export type BaselineAlertWithPersonalDetails = {
  id: UUID;
  tenantId: UUID;
  userId: UUID;
  roomId: UUID;
  roomName: string;
  bedId: UUID;
  bedName: string;
  alertType: BaselineMetric;
  targetMedia: TargetMedia;
  withPersonalDetails: boolean;
  deviceId: SerialNumber;
  patientId: UUID;
  thresholdPreposition: ThresholdPreposition;
  triggerValue: 0;
  thresholdValue: 0;
  averageValue: null;
  averageInterval: null;
  hqCount: null;
  alertId: UUID;
  alertTypeUnit: string;
  tenantName: string;
  cityName: string;
  countryName: string;
  baselineValue: number;
  baselineDaysInterval: number;
  deviationValue: number;
  deviationPercentage: number;
  deviationHours: number;
  alertStartTime: DateUTC;
  lastDayBaseline: string;
  continuesAlertCount: number;
  creationTime: DateUTC;
  lastModifiedTime: DateUTC;
  patientData: {
    id: UUID;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  };
  stabilized: boolean;
};

export type OccupancyAlerttWithPersonalDetails = {
  id: UUID;
  tenantId: UUID;
  userId: UUID;
  roomId: UUID;
  roomName: string;
  bedId: UUID;
  bedName: string;
  alertType: VacancyMetric | AbnormalMetric;
  targetMedia: TargetMedia;
  withPersonalDetails: boolean;
  deviceId: SerialNumber;
  patientId: UUID;
  thresholdPreposition: ThresholdPreposition;
  triggerValue: number;
  thresholdValue: number;
  averageValue: number;
  averageInterval: number;
  hqCount: number;
  alertId: UUID;
  alertTypeUnit: string;
  tenantName: string;
  cityName: string;
  countryName: string;
  baselineValue: null;
  baselineDaysInterval: null;
  deviationValue: null;
  deviationPercentage: null;
  deviationHours: null;
  alertStartTime: DateUTC;
  lastDayBaseline: string;
  continuesAlertCount: number;
  creationTime: DateUTC;
  lastModifiedTime: DateUTC;
  patientData: {
    id: UUID;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  };
  stabilized: boolean;
};

export type AlertWithPersonalDetails = { timeZoneId: string | null } & (
  | AverageAlertWithPersonalDetails
  | BaselineAlertWithPersonalDetails
  | OccupancyAlerttWithPersonalDetails
);

export type PatientAttentionItem = {
  alertsCount: number;
  deviceId: SerialNumber;
  timestamp: DateUTC;
  notesCount?: NoteCounterMetadata;
  type: string;
  tenantId: UUID;
  patientId: UUID;
  closureReason: keyof ClosureReasonTypes;
  id: UUID;
};

export enum ALERT_TYPES {
  MEDICAL = 'Medical',
}
