import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import TokenOperation from 'src/libs/js-token-operation';
import appConfig from 'src/config/AppConfig';
import {
  tokenStatusEnum,
  TOKEN_STATUS_COMPONENT_MAPPER,
  tokenOperationErrorToStatus,
} from 'src/utils/constants';
import { actionSuccessNotification } from 'src/utils/errorHandling/notifications';
import { ErrorText } from 'src/routes/Auth/components/styled';
import { actions } from 'src/redux/data/auth/modules/slice';
import PasswordForm from '../../../components/PasswordForm';
import { CenteredContainer } from '../components/styled';

const TokenInvalidWrapper = styled(ErrorText)`
  font-size: 16px;
`;
const AcceptInvitationPage = () => {
  const [status, setStatus] = useState<keyof typeof tokenStatusEnum>(
    tokenStatusEnum.LOADING,
  );
  const [password, setPassword] = useState<string | null>(null);
  const [mfaPhone, setMfaPhone] = useState<string | null>(null);
  const [shouldExecute, setShouldExecute] = useState(false);

  const handleSubmit = (values: { password: string; mfaPhone?: string }) => {
    setPassword(values.password);
    if (values.mfaPhone) {
      setMfaPhone(`+${values.mfaPhone}`);
    }
    setShouldExecute(true);
    setStatus(tokenStatusEnum.SUBMITTING_BODY);
  };

  const onExecuteSuccess = () => {
    setStatus(tokenStatusEnum.SUCCESS);
    actionSuccessNotification(actions.resetPassword.type, null);
    setShouldExecute(false);
  };

  const onExecuteFail = (error: unknown) => {
    setStatus(
      tokenOperationErrorToStatus(error, tokenStatusEnum.GENERAL_ERROR),
    );
    setShouldExecute(false);
  };

  const onValidateFail = (error: unknown) => {
    setStatus(
      tokenOperationErrorToStatus(error, tokenStatusEnum.GENERAL_ERROR),
    );
    setShouldExecute(false);
  };

  const onValidateSuccess = () => {
    setStatus(tokenStatusEnum.VALIDATED);
  };
  return (
    <CenteredContainer>
      <TokenOperation
        baseURL={`${appConfig.API_URL}/organization`}
        shouldExecute={shouldExecute}
        onExecuteSuccess={onExecuteSuccess}
        onExecuteFail={onExecuteFail}
        onValidateFail={onValidateFail}
        onValidateSuccess={onValidateSuccess}
        body={{ password, mfaPhone }}
      />
      {status === tokenStatusEnum.TOKEN_NOT_VALID ? (
        <TokenInvalidWrapper>
          <FormattedMessage {...messages.tokenInvalid} />
        </TokenInvalidWrapper>
      ) : (
        <PasswordForm
          onFinish={handleSubmit}
          statusComponentMapper={TOKEN_STATUS_COMPONENT_MAPPER(messagesStatus)}
          status={status}
          isLoading={status === tokenStatusEnum.SUBMITTING_BODY}
          isDisabled={
            status === tokenStatusEnum.LOADING ||
            status === tokenStatusEnum.SUBMITTING_BODY
          }
          submitMessage={messages.submitMessage}
        />
      )}
    </CenteredContainer>
  );
};

const messagesStatus = defineMessages({
  tokenError: {
    defaultMessage: 'Your invitation has expired',
  },
  validationFailed: {
    defaultMessage: 'Could not activate user with the given details',
  },
  generalError: {
    defaultMessage: 'Could not activate user',
  },
});
const messages = defineMessages({
  submitMessage: {
    defaultMessage: 'Set Password',
  },
  tokenInvalid: {
    defaultMessage:
      'Your invitation link has expired, please contact your Tenant Admin for a new invitation',
  },
});

export default AcceptInvitationPage;
