import dayjs from 'dayjs';

import { DeviceAlerts } from 'src/routes/NursesStation/modules/types';
import { Alert, AlertStatus, BaselineAlert, VsAlert } from 'src/types/alerts';
import { PatientAlertsByType } from 'src/redux/data/alerts/modules/types';

export const checkIfAlertStatusIs =
  (status: AlertStatus) =>
  (a: Alert | undefined): boolean =>
    !!(a && a.status === status);

export const checkPatientHasAlert =
  (status: AlertStatus, isUnoccupied = false) =>
  (ongoingDeviceAlerts: DeviceAlerts): boolean => {
    const {
      latestHrAlert,
      latestRrAlert,
      occupancyAlert,
      bedTimeBaseline,
      longOutOfBed,
      bedExitFrequency,
      positionChange,
    } = ongoingDeviceAlerts;

    const checkAlertStatus = checkIfAlertStatusIs(status);
    return (
      checkAlertStatus(latestHrAlert) ||
      checkAlertStatus(latestRrAlert) ||
      checkAlertStatus(bedTimeBaseline) ||
      checkAlertStatus(longOutOfBed) ||
      checkAlertStatus(bedExitFrequency) ||
      checkAlertStatus(positionChange) ||
      (checkAlertStatus(occupancyAlert) && isUnoccupied)
    );
  };

const getLatestAlert = (
  a1: VsAlert | BaselineAlert | undefined,
  a2: VsAlert | BaselineAlert | undefined,
): VsAlert | BaselineAlert | undefined => {
  if (!a1) {
    return a2;
  }

  if (!a2) {
    return a1;
  }

  return dayjs(a1.startTime).isAfter(a2.startTime) ? a1 : a2;
};

export const selectDeviceAlerts = ({
  HR,
  RR,
  HR_BASELINE,
  RR_BASELINE,
  BED_EXIT,
  BED_TIME_BASELINE,
  LONG_OUT_OF_BED,
  BED_EXIT_FREQUENCY,
  POSITION_CHANGE,
}: PatientAlertsByType): DeviceAlerts => ({
  latestHrAlert: getLatestAlert(HR, HR_BASELINE),
  latestRrAlert: getLatestAlert(RR, RR_BASELINE),
  occupancyAlert: BED_EXIT,
  bedTimeBaseline: BED_TIME_BASELINE,
  longOutOfBed: LONG_OUT_OF_BED,
  bedExitFrequency: BED_EXIT_FREQUENCY,
  positionChange: POSITION_CHANGE,
});
